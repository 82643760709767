import React from 'react';

/** Represents a circle outline pause button, used by the Sermon Player */
export const IconCirclePause = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 86" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(3 3)">
        <circle cx="40" cy="40" r="40" fill="#000" fillOpacity=".3" stroke="#FFF" strokeWidth="6" />
        <g fill="#FFF">
          <path d="M37 23.635v34.368H26.631c-.9 0-1.631-.73-1.631-1.631V23.635c0-.901.73-1.632 1.631-1.632h8.738c.9 0 1.631.73 1.631 1.632ZM55 23.635v34.368H44.631c-.9 0-1.631-.73-1.631-1.631V23.635c0-.901.73-1.632 1.631-1.632h8.738c.9 0 1.631.73 1.631 1.632Z" />
        </g>
      </g>
    </svg>
  );
};
