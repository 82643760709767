import * as yup from 'yup';

export interface PublishablePrivacyControls {
  active: boolean;
  criteria: {
    connectionType?: Array<string>;
  };
}

const criteriaSchema: yup.SchemaOf<PublishablePrivacyControls['criteria']> = yup.object({
  connectionType: yup.array().of(yup.string()).optional(),
});

export const PublishablePrivacyControlsSchema: yup.SchemaOf<PublishablePrivacyControls> = yup.object({
  active: yup.boolean().required(),
  criteria: criteriaSchema,
});
