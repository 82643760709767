import { ListSectionWeb } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SectionLayout } from '../../sectionLayouts/SectionLayout';
import { ListBlockLayoutCollage } from './ListBlockLayoutCollage';
import { ListBlockLayoutContentOnly } from './ListBlockLayoutContentOnly';
import { ListBlockLayoutCover } from './ListBlockLayoutCover';
import { ListBlockLayoutHorizontal } from './ListBlockLayoutHorizontal';
import { ListBlockLayoutLongText } from './ListBlockLayoutLongText';
import { ListBlockLayoutVertical } from './ListBlockLayoutVertical';
import { ListBlockLayoutProps } from './ListLayout';

export const ListSection = (): JSX.Element => {
  const section = useSection<ListSectionWeb>();

  const blockProps = { ...section.payload };
  delete blockProps.backgroundMedia;

  return (
    <Section className={classNames(nucleusClass('section-list'), getSectionClasses(section))}>
      <SectionLayout {...section.payload}>
        <BlockLayout {...blockProps} />
      </SectionLayout>
    </Section>
  );
};

const BlockLayout = (props: ListBlockLayoutProps) => {
  switch (props.blockLayout) {
    case 'layout1':
      return <ListBlockLayoutHorizontal {...props} />;
    case 'layout2':
      return <ListBlockLayoutVertical {...props} />;
    case 'layout3':
      return <ListBlockLayoutCollage {...props} />;
    case 'layout4':
      return <ListBlockLayoutLongText {...props} />;
    case 'layout5':
      return <ListBlockLayoutCover {...props} />;
    case 'layout6':
      return <ListBlockLayoutContentOnly {...props} />;
    default:
      return <ListBlockLayoutHorizontal {...props} />;
  }
};
