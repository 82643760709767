import { Site } from '@nucleus/types/web/site';
import React from 'react';
import { ApplicationContext, ApplicationContextValue } from './ApplicationContext';

interface ApplicationProviderProps {
  children: React.ReactNode;
  DataResourceProvider: ApplicationContextValue['DataResourceProvider'];
  site: Site;
}
export const ApplicationProvider = (props: ApplicationProviderProps): JSX.Element => {
  const context: ApplicationContextValue = {
    DataResourceProvider: props.DataResourceProvider,
    getSite: () => props.site,
  };

  return <ApplicationContext.Provider value={context}>{props.children}</ApplicationContext.Provider>;
};
