import React from 'react';
import { ThemeProvider } from 'styled-components';
import { WebThemeElementsTheme } from '@nucleus/web-theme-elements';

interface Props {
  children?: React.ReactNode;
  theme: WebThemeElementsTheme;
}

export const WebThemeElementsThemeProvider = (props: Props): JSX.Element => {
  return <ThemeProvider theme={{ _webThemeElements: props.theme }}>{props.children}</ThemeProvider>;
};
