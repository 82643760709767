import React from 'react';
import { Descendant } from 'slate';
import { Editable, RenderElementProps, RenderLeafProps } from 'slate-react';
import styled from 'styled-components';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';
import { CustomEditor } from '../slate/components/CustomEditor';
import { BodyElement } from '../slate/elements/BodyElement';
import { LinkElement } from '../slate/elements/LinkElement';
import { ParagraphElement } from '../slate/elements/ParagraphElement';
import { TextAreaElement } from '../slate/elements/TextAreaElement';
import { TitleElement } from '../slate/elements/TitleElement';
import { withForcedLayout } from '../slate/plugins/withForcedLayout';
import { withInsertBreak } from '../slate/plugins/withInsertBreak';
import { withToPlainText } from '../slate/plugins/withToPlainText';
import { CustomEditor as Editor } from '../slate/types/custom-types';

declare module '@nucleus/sermon-theme-elements' {
  interface NotesEditorElements {}

  interface SermonThemeElementsTheme {
    NotesEditor?: {
      background?: string;
      border?: string;
      color?: string;
      focus?: {
        border?: string;
        boxShadow?: string;
      };
      padding?: string;
      elements?: NotesEditorElements;
    };
  }
}

const defaultValue: Descendant[] = [
  {
    type: 'title',
    placeholder: 'Add a title…?',
    children: [{ text: '' }],
  },
  {
    type: 'body',
    placeholder:
      'You can type as much or as little as you want. And when you’re done, you can email the notes to yourself, download them, or share.',
    children: [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
  },
];

type Props = {
  initialValue?: Descendant[];
  onValueChange: (value: Descendant[]) => void;
} & Omit<React.TextareaHTMLAttributes<HTMLDivElement>, 'onChange'>;

export const NotesEditor = React.forwardRef<Editor, Props>(
  ({ initialValue = defaultValue, onValueChange, ...props }, ref): JSX.Element => {
    return (
      <CustomEditor
        initialValue={initialValue}
        onValueChange={onValueChange}
        plugins={[withForcedLayout, withInsertBreak, withToPlainText]}
        ref={ref}
      >
        <StyledEditable renderElement={renderElement} renderLeaf={renderLeaf} {...props} />
      </CustomEditor>
    );
  }
);

NotesEditor.displayName = 'NotesEditor';

const StyledEditable = styled(Editable)`
  background: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.background};
  color: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.color};
  border: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.border};
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '24px',
    pill: '25px',
  })};
  padding: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.padding};
  outline: none;
  min-height: 250px;
  max-height: 60vh;
  overflow: scroll;
  transition:
    border 0.2s ease,
    box-shadow 0.2s ease;

  :focus-within {
    border: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.focus?.border};
    box-shadow: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.focus?.boxShadow};
  }
`;

const renderElement = (props: RenderElementProps): JSX.Element => {
  switch (props.element.type) {
    case 'title':
      return <TitleElement {...props} />;
    case 'body':
      return <BodyElement {...props} />;
    case 'textarea':
      return <TextAreaElement {...props} />;
    case 'link':
      return <LinkElement {...props} />;
    case 'paragraph':
    default:
      return <ParagraphElement {...props} />;
  }
};

const renderLeaf = ({ attributes, children, leaf }: RenderLeafProps): JSX.Element => {
  switch (true) {
    case leaf.bold:
      return <strong {...attributes}>{children}</strong>;
    case leaf.code:
      return <code {...attributes}>{children}</code>;
    case leaf.italic:
      return <em {...attributes}>{children}</em>;
    case leaf.underline:
      return <u {...attributes}>{children}</u>;
    case leaf.highlight:
      return <mark {...attributes}>{children}</mark>;
    case leaf.strike:
      return <s {...attributes}>{children}</s>;
    default:
      return <span {...attributes}>{children}</span>;
  }
};
