import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCollage } from '../../blocks/BlockCollage';
import { BlockEmbedGrid } from '../../blocks/BlockEmbedGrid';
import { BlockInfo } from '../../blocks/BlockInfo';
import { EmbedBlockLayoutProps } from './EmbedLayout';

export const EmbedBlockLayoutCollage = (props: EmbedBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const embedsOnTheBottom = props.blockLayoutVariant === 'variant2';

  return (
    <StyledBlockLayout {...props} embedsOnTheBottom={embedsOnTheBottom}>
      <StyledBlockCollage {...innerProps} block={props.blocks[0]} />
      <StyledBlockEmbedGrid {...innerProps} block={props.blocks[0]} />
      <StyledBlockInfo {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const embedsOnTheBottom = booleanPropHelperFactory('embedsOnTheBottom');

const StyledBlockCollage = styled(BlockCollage)``;
const StyledBlockEmbedGrid = styled(BlockEmbedGrid)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)<{ embedsOnTheBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledBlockEmbedGrid} {
    background: var(--color-section-background);
    padding: calc(var(--unit-length) * 0.5);
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '50vh';
        case 'large':
          return '80vh';
        default:
          return undefined;
      }
    }};
  }

  ${embedsOnTheBottom(css`
    ${StyledBlockCollage} {
      order: 3;
    }

    ${StyledBlockInfo} {
      order: 2;
    }
  `)}
`;
