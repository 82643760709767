import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToHash = (id: string, offsetTop = 0): React.RefObject<HTMLElement> => {
  const ref = useRef<HTMLElement>(null);
  const location = useLocation();

  useEffect(() => {
    // 3% of the window height
    const offset = Math.max(document.body.clientHeight, document.documentElement.clientHeight) * 0.03;

    const scrollToElement = () => {
      if (window.location.hash === `#${id}` && ref.current) {
        window.scrollTo({
          top: ref.current.offsetTop - offset - offsetTop,
          behavior: 'smooth',
        });
      }
    };

    scrollToElement();

    const handleHashChange = () => {
      scrollToElement();
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [id, offsetTop, location]);

  return ref;
};
