import { SectionConfigBuilder } from '../ThemeConfigBuilder';
import { body, byline, headline, overline } from '../fields/fields';

export default new SectionConfigBuilder()
  .setContext('picker', {
    label: 'HTML (Embed)',
    description: 'Embed video, audio, or other resources',
    icon: 'Code',
  })
  .setContext('editor', {
    label: 'HTML (Embed) Section',
    description: '',
    icon: 'Code',
  })
  .addLayout('layout1', (layout) => {
    layout
      .setIcon('EmbedLayout1')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Embed Placement',
        controlType: 'left right',
        options: [
          {
            value: 'variant1',
            label: 'Left',
          },
          {
            value: 'variant2',
            label: 'Right',
          },
        ],
      });
  })
  .addLayout('layout2', (layout) => {
    layout
      .setIcon('EmbedLayout2')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Embed Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout3', (layout) => {
    layout
      .setIcon('EmbedLayout3')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(3);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Image Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout4', (layout) => {
    layout
      .setIcon('EmbedLayout4')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockBodyMaxColumns(3);
  })
  .addLayout('layout5', (layout) => {
    layout
      .setIcon('EmbedLayout5')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout6', (layout) => {
    layout
      .setDescription('Embed Only')
      .setIcon('EmbedLayout6')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([]);
        block.setMedia(0);
        block.setActions(0);
      });
  })
  .build();
