import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockInfo } from '../../blocks/BlockInfo';
import { BlockMedia } from '../../blocks/BlockMedia';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { InfoBlockLayoutProps } from './InfoLayout';

export const InfoBlockLayoutHorizontal = (props: InfoBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const mediaOnTheLeft = props.blockLayoutVariant === 'variant1';

  return (
    <StyledBlockLayout {...props} mediaOnTheLeft={mediaOnTheLeft}>
      <StyledInfoColumn>
        <BlockInfo {...innerProps} block={props.blocks[0]} />
      </StyledInfoColumn>
      <StyledMediaColumn>
        <StyledBlockMedia media={props.blocks[0]?.mediaItems?.[0]} isSticky={true} />
      </StyledMediaColumn>
    </StyledBlockLayout>
  );
};

const mediaOnTheLeft = booleanPropHelperFactory('mediaOnTheLeft');

const StyledBlockMedia = styled(BlockMedia)``;
const StyledHorizontalColumn = styled.div``;
const StyledInfoColumn = styled(StyledHorizontalColumn)``;
const StyledMediaColumn = styled(StyledHorizontalColumn)``;

const StyledBlockLayout = styled(BlockLayout)<{
  mediaOnTheLeft: boolean;
}>`
  flex-direction: row;
  flex-wrap: wrap;

  ${StyledHorizontalColumn} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    flex: 1 1 auto;
  }

  ${StyledMediaColumn} {
    justify-content: flex-start;

    ${SpacerTop} {
      display: none;
    }
  }

  ${StyledBlockMedia} {
    flex-grow: 1;
  }

  ${mediaOnTheLeft(css`
    ${StyledInfoColumn} {
      order: 2;
    }
    ${StyledMediaColumn} {
      order: 1;
    }
  `)}

  @media (max-width: 899px) {
    ${StyledInfoColumn} {
      min-height: ${(props) => {
        switch (props.height) {
          case 'medium':
            return '66vh';
          case 'large':
            return '80vh';
          default:
            return undefined;
        }
      }};
    }
  }

  @media (min-width: 900px) {
    flex-wrap: nowrap;
    ${StyledHorizontalColumn} {
      width: 50%;
    }

    ${StyledMediaColumn} {
      ${SpacerTop} {
        display: block;
      }
    }
  }
`;
