export function objectToCssRules(obj: Record<string, string | number>): string {
  return Object.entries(obj)
    .map(([key, value]) => `${key}: ${value};`)
    .join('');
}

export function objectToCssClass(className: string, obj: Record<string, string | number>): string {
  return `
      .${className} {
        ${objectToCssRules(obj)}
      }
    `;
}

export function buildTransparentColorFromCssVariable(colorVariable: string, alpha = 1): string {
  return `hsl(var(${colorVariable}-h), calc(var(${colorVariable}-s) * 1%), calc(var(${colorVariable}-l) * 1%), ${alpha})`;
}
