import { useIsomorphicLayoutEffect } from '@nucleus/react-components';
import { useOnOutsideClick } from '@nucleus/web-theme-elements';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useHideLauncherEffect } from '../../hooks/useHideLauncherEffect';
import { useSearchParams } from '../../hooks/useSearchParams';
import { getDocument, getWindow } from '../../lib/dom';
import { BodyScrollLock } from '../BodyLockScroll';

export type OverlayContextValue = {
  closeOverlay: () => void;
  navigateBack: (data?: Record<string, string>) => void;
  navigateTo: (path: string, data?: Record<string, string>) => void;
  navigateRoot: (path: string, data?: Record<string, string>) => void;
};
export const OverlayContext = React.createContext({} as OverlayContextValue);

export const OVERLAY_SEARCH_PARAMS = ['overlay', 'overlayPath', 'overlayDataItem'];

export const useNavigateToOverlay = (): ((overlayId: string, params: Record<string, string>) => void) => {
  const navigate = useNavigate();

  return (overlayId: string, params: Record<string, string>) => {
    const path = {
      search: createSearchParams({ overlay: overlayId, ...params }).toString(),
    };
    navigate(path);
  };
};

type OverlayControllerProps = {
  currentPath: string;
  defaultPath: string;
  children: React.ReactNode;
};
export const OverlayController = ({ children, currentPath, defaultPath }: OverlayControllerProps): JSX.Element => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams({ include: OVERLAY_SEARCH_PARAMS });

  const closeOverlay = React.useCallback(() => {
    // remove all search params to close overlay
    navigate({ search: '' }, { replace: true });
  }, [navigate]);

  const navigateBack = React.useCallback(
    (data?: Record<string, string>) => {
      // navigate to previous path
      const newPath = currentPath.split(',').slice(0, -1).join(',');

      if (newPath === '' || newPath === currentPath) {
        // if there is no previous path, close the overlay
        closeOverlay();
        return;
      }

      setSearchParams.push({
        overlayPath: newPath,
        ...data,
      });
    },
    [currentPath, setSearchParams, closeOverlay]
  );

  const navigateTo = React.useCallback(
    (path: string, data?: Record<string, string>) => {
      // navigate to relative path
      let newPath;
      if (path === '') {
        newPath = currentPath;
      } else if (path === '..') {
        newPath = currentPath.split(',').slice(0, -1).join(',');
      } else {
        newPath = currentPath.split(',').concat(path).join(',');
      }

      setSearchParams.push({
        overlayPath: newPath,
        ...data,
      });
    },
    [currentPath, setSearchParams]
  );

  const navigateRoot = React.useCallback(
    (path: string = currentPath, data?: Record<string, string>) => {
      // navigate from root
      setSearchParams.push({
        overlayPath: path || defaultPath,
        ...data,
      });
    },
    [currentPath, setSearchParams, defaultPath]
  );

  const contextValue: OverlayContextValue = React.useMemo(
    () => ({
      closeOverlay,
      navigateBack,
      navigateTo,
      navigateRoot,
    }),
    [closeOverlay, navigateBack, navigateTo, navigateRoot]
  );

  return <OverlayContext.Provider value={contextValue}>{children}</OverlayContext.Provider>;
};

type OverlayProps = {
  contentWidth: string;
  show: boolean;
  backdropColor?: string;
  backdropOpacity: number;
  backdropClose: boolean;
  onPrev?: () => void;
  onNext?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  hideInlineBanners?: boolean;
};

const useKeyDown = (targetKey: string, callback?: () => void) => {
  const downHandler = (event: KeyboardEvent) => {
    if (event.key === targetKey && callback !== undefined) {
      callback();
    }
  };

  React.useEffect(() => {
    if (callback === undefined) {
      return;
    }

    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });

  return;
};

type KeyboardControlsProps = Pick<OverlayProps, 'onPrev' | 'onNext' | 'onClose'>;

const KeyboardControls = ({ onPrev, onNext, onClose }: KeyboardControlsProps) => {
  useKeyDown('Escape', onClose);
  useKeyDown('ArrowLeft', onPrev);
  useKeyDown('ArrowRight', onNext);

  return null;
};

const OverlayComponent = styled((props: OverlayProps): React.ReactPortal | null => {
  const [container, setContainer] = useState<Element | null>(null);
  const ref = useRef<any>(null);

  useOnOutsideClick([ref], props.onClose, props.show && props.backdropClose);

  useEffect(() => {
    setContainer(document.getElementById('overlay-root'));
  }, []);

  if (container === null) {
    return null;
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {props.show && (
        <Wrapper id={props.id} className={props.className} style={props.style}>
          <BodyScrollLock />
          <HideLauncher hideInlineBanners={props.hideInlineBanners} />
          <Background {...enterExitAnimation} color={props.backdropColor} opacity={props.backdropOpacity} />
          <Container>
            <Content ref={ref} {...enterExitAnimation} $contentWidth={props.contentWidth}>
              {props.children}
            </Content>
          </Container>
        </Wrapper>
      )}
    </AnimatePresence>,
    container
  ) as React.ReactPortal;
})``;

const Panel = styled(motion.div)`
  height: 100%;
`;

const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const PanelHeaderWrapper = styled.div``;

const PanelHeaderContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  background: ${(props) => props.backgroundColor ?? 'transparent'};
  padding: var(--overlay-padding);
`;

const PanelHeaderLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const PanelHeaderCenter = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PanelHeaderRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

interface OverlayPanelHeaderProps {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  backgroundColor?: string;
}

const PanelHeader = (props: OverlayPanelHeaderProps) => {
  return (
    <PanelHeaderWrapper>
      <PanelHeaderContainer backgroundColor={props.backgroundColor}>
        <PanelHeaderLeft>{props.left}</PanelHeaderLeft>
        <PanelHeaderCenter>{props.center}</PanelHeaderCenter>
        <PanelHeaderRight>{props.right}</PanelHeaderRight>
      </PanelHeaderContainer>
    </PanelHeaderWrapper>
  );
};

const PanelBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: scroll;
`;

const PanelFooter = styled.div`
  flex: 0 0 auto;
`;

const OverlayFullscreen = styled(motion.div)`
  background: #fff;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

export const Overlay = Object.assign(OverlayComponent, {
  Panel: Panel,
  PanelContent: PanelContent,
  PanelHeader: PanelHeader,
  PanelBody: PanelBody,
  PanelFooter: PanelFooter,
  Fullscreen: OverlayFullscreen,
  KeyboardControls: KeyboardControls,
  defaultProps: {
    contentWidth: 'auto',
    show: false,
  },
});

const Wrapper = styled.div`
  --overlay-padding: min(var(--unit-length), calc(var(--max-unit-length) * 0.66));

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  z-index: 200;

  &:after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    vertical-align: middle;
    margin-right: -1px;
  }
`;

const Background = (props: any) => {
  const initialColorRef = useRef(getThemeColor());
  const ref = useRef(null);

  useIsomorphicLayoutEffect(() => {
    if (ref.current === null) {
      return;
    }

    const match = props.color.match(/var\((.*)\)/);

    if (match[1] === undefined) {
      return;
    }

    const themeColor = getCssVar(ref.current, match[1]);

    if (themeColor === '') {
      return;
    }

    setThemeColor(themeColor);

    return () => {
      if (initialColorRef.current === null) {
        return;
      }

      setThemeColor(initialColorRef.current);
    };
  }, []);

  return <Backdrop {...props} ref={ref} />;
};

const getCssVar = (element: HTMLElement, value: string) =>
  getWindow()?.getComputedStyle(element).getPropertyValue(value) ?? '';
const getThemeColor = () => getDocument()?.querySelector("meta[name='theme-color']")?.getAttribute('content') ?? null;
const setThemeColor = (color: string) =>
  getDocument()?.querySelector("meta[name='theme-color']")?.setAttribute('content', color);

const Backdrop = styled(motion.div)<{ color?: string; opacity?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color ?? 'rgba(255, 255, 255, 1)'};
  opacity: ${(props) => props.opacity ?? 1};
`;

const Container = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 0;
  white-space: normal;
`;

type ContentProps = {
  $contentWidth: string;
};
const Content = styled(motion.div)<ContentProps>`
  width: ${(props) => props.$contentWidth};
  max-width: 100%;
  min-width: 0;
`;

const variants = {
  show: { opacity: 1 },
  hide: { opacity: 0 },
};

const transition = {
  type: 'spring',
  delay: 0,
  stiffness: 1000,
  damping: 55,
  mass: 0.5,
};

const enterExitAnimation = {
  initial: 'hide',
  animate: 'show',
  exit: 'hide',
  transition: transition,
  variants: variants,
};

const HideLauncher = (props: { hideInlineBanners?: boolean }) => {
  useHideLauncherEffect(props.hideInlineBanners);
  return null;
};
