import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ReactComponentsTheme } from '@nucleus/react-components';

interface Props {
  children?: React.ReactNode;
  theme: ReactComponentsTheme;
}

export const ReactComponentsThemeProvider = (props: Props): JSX.Element => {
  return <ThemeProvider theme={{ _reactComponents: props.theme }}>{props.children}</ThemeProvider>;
};
