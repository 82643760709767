import { ListSermonsWebEndpoint, ReadSermonWebEndpoint } from '@nucleus/web-hosting';
import axios from 'axios';

const getBaseUrl = (isUserAuthenticated: boolean): string => {
  return isUserAuthenticated ? '/_api/private/sermon-hub' : '/_api/public/sermon-hub';
};

export const querySermons = async (
  engineId: string | undefined,
  jwtToken?: string,
  params?: ListSermonsWebEndpoint['queryStringParameters']
): Promise<ListSermonsWebEndpoint['responseBody']> => {
  const isUserAuthenticated = jwtToken !== undefined;

  const response = await axios.request<ListSermonsWebEndpoint['responseBody']>({
    url: `${getBaseUrl(isUserAuthenticated)}/${engineId}/sermons`,
    params: params,
    headers: {
      Authorization: jwtToken,
    },
  });
  return response.data;
};

export const querySermonBySlug = async (
  engineId: string | undefined,
  slug: string,
  jwtToken?: string
): Promise<ReadSermonWebEndpoint['responseBody']> => {
  const isUserAuthenticated = jwtToken !== undefined;

  const response = await axios.request<ReadSermonWebEndpoint['responseBody']>({
    url: `${getBaseUrl(isUserAuthenticated)}/${engineId}/sermons/${slug}`,
    headers: {
      Authorization: jwtToken,
    },
  });

  return response.data;
};
