import { SermonIndexDocumentWeb } from '@nucleus/sermons/types/Sermon';
import { DynamicSermonListDataSource, SermonBlockWeb, StaticSermonListDataSource } from '@nucleus/types/web';
import { ListSermonsWebEndpoint } from '@nucleus/web-hosting';
import invariant from 'invariant';
import { flatMap as _flatMap, pick as _pick } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useSermonPath } from '../hooks/useSermonPath';
import { useSermons } from '../hooks/useSermons';
import { getCollectionTypeFromId } from '../lib/collection';

type UseSermons = ReturnType<typeof useSermons>;

type Context = Pick<UseSermons, 'status' | 'hasNextPage' | 'fetchNextPage'> & {
  sermons: SermonIndexDocumentWeb[];
  currentlyPlayingSermon?: SermonIndexDocumentWeb;
  order: NonNullable<ListSermonsWebEndpoint['queryStringParameters']['order']>;
  setOrder: React.Dispatch<React.SetStateAction<NonNullable<ListSermonsWebEndpoint['queryStringParameters']['order']>>>;
  orderBy: NonNullable<ListSermonsWebEndpoint['queryStringParameters']['orderBy']>;
  setOrderBy: React.Dispatch<
    React.SetStateAction<NonNullable<ListSermonsWebEndpoint['queryStringParameters']['orderBy']>>
  >;
  availableToOrderBy: NonNullable<ListSermonsWebEndpoint['queryStringParameters']['orderBy']>[];
  getSermonPath: (sermon: SermonIndexDocumentWeb) => string;
};

const Context = React.createContext<Context>(null!);
Context.displayName = 'SermonListController';

type Props = { children?: React.ReactNode } & Options;

export const SermonListController = ({ children, ...props }: Props): JSX.Element => {
  return <Context.Provider value={useController(props)}>{children}</Context.Provider>;
};

export const useSermonListController = (): Context => {
  invariant(
    useContext(Context) !== null,
    'useSermonListController() may be used only in the context of a <SermonListController> object'
  );
  return useContext(Context);
};

type Options = {
  engineId: string;
  basePath: string;
  currentlyPlayingSermonId?: string;
  params?: ListSermonsWebEndpoint['queryStringParameters'];
};

const useController = ({ basePath, ...options }: Options): Context => {
  const initialOrderBy = options.params?.orderBy ?? 'date';
  const [orderBy, setOrderBy] = useState<Context['orderBy']>(initialOrderBy);

  const initialOrder = options.params?.order ?? 'desc';
  const [order, setOrder] = useState<Context['order']>(initialOrder);

  useEffect(() => {
    setOrder((current) => options.params?.order ?? current);
  }, [options.params?.order]);

  const { data, status, hasNextPage, fetchNextPage } = useSermons(options.engineId, {
    ...options.params,
    order: order,
    orderBy: orderBy,
  });

  const getSermonPath = useSermonPath(basePath);

  let availableToOrderBy: NonNullable<ListSermonsWebEndpoint['queryStringParameters']['orderBy']>[] = ['date'];

  if (options.params?.playlist !== undefined) {
    availableToOrderBy.push('playlist');
  }

  if (options.params?.ids !== undefined) {
    availableToOrderBy = [];
  }

  const sermons = _flatMap(data?.pages, 'sermons');
  const currentlyPlaying = sermons.find((sermon) => sermon.id === options.currentlyPlayingSermonId);

  return {
    currentlyPlayingSermon: currentlyPlaying,
    sermons: sermons,
    status: status,
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage,
    order: order,
    setOrder: setOrder,
    orderBy: orderBy,
    setOrderBy: setOrderBy,
    availableToOrderBy: availableToOrderBy,
    getSermonPath: getSermonPath,
  };
};

const getQueryParams = (dataSource: SermonBlockWeb['dataSource']) => {
  const safeProps = _pick(dataSource, 'limit', 'order', 'orderBy');

  const paramEntries = Object.entries(safeProps).map(([key, value]) => [key, String(value)]);

  return Object.fromEntries(paramEntries);
};

export const useSermonBlockDataSourceSermonListParams = ({
  dataSource,
  items,
}: SermonBlockWeb): ListSermonsWebEndpoint['queryStringParameters'] | undefined => {
  const baseParams = getQueryParams(dataSource);

  if (isStaticSermonDataSource(dataSource)) {
    return {
      ...baseParams,
      ids: items?.map((item) => item.sourceId).join(','),
      order: 'asc',
    };
  }

  if (isDynamicSermonListDataSource(dataSource) && dataSource.sourceId !== undefined) {
    const key = getCollectionTypeFromId(dataSource.sourceId);

    if (key === undefined) {
      return baseParams;
    }

    return {
      ...baseParams,
      [key]: dataSource.sourceId,
      order: dataSource.order,
      orderBy: dataSource.orderBy ?? key === 'playlist' ? key : undefined,
    };
  }

  return baseParams;
};

export const useCollectionDataSourceParams = (
  collectionId: string
): ListSermonsWebEndpoint['queryStringParameters'] | undefined => {
  const key = getCollectionTypeFromId(collectionId);

  if (key === undefined) {
    return;
  }

  return {
    [key]: collectionId,
    orderBy: key === 'playlist' ? key : undefined,
  };
};

export const isStaticSermonDataSource = (dataSource: any): dataSource is StaticSermonListDataSource => {
  return dataSource?.type === 'static' && dataSource?.itemType === 'sermon';
};

export const isDynamicSermonListDataSource = (dataSource: any): dataSource is DynamicSermonListDataSource => {
  return dataSource?.type === 'dynamic' && dataSource?.itemType === 'sermon';
};
