export default {
  contexts: {
    editor: {
      label: 'Button 1 - Primary Style',
    },
  },
  layouts: [
    {
      layoutId: 'simpleButton',
      fields: [
        {
          defaultValue: 'cornerStyle2',
          fieldId: 'cornerStyle',
          options: [
            { value: 'cornerStyle1', label: 'Square' },
            { value: 'cornerStyle2', label: 'Rounded Corners' },
            { value: 'cornerStyle3', label: 'Pill' },
          ],
        },
      ],
    },
  ],
};
