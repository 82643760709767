import { ThemeColorPaletteBuilder } from '../ThemeConfigBuilder';
import { serviceName } from '../constants';

const builder = new ThemeColorPaletteBuilder({
  id: `${serviceName}_goldenAndGrounded`,
  title: 'Golden & Grounded ⚜️',
  description:
    'Warm, muted tones across a similar hue are a signature look, and the mid-tonal effect is super flexible for both traditional & trendy.',
  lightMode: {
    color1: '#A6834E',
    color2: '#EFE8DC',
    color3: '#8D6D49',
    color4: '#E3D2B5',
    color5: '#EDDFBF',
    color6: '#EDDFBF',
    colorLight: '#F0E8DB',
    colorDark: '#A7834E',
  },
});

export default builder.build();
