import { Link as NavLink } from 'react-router-dom';
import {
  ThemeActionElement as ActionElement,
  ThemeCustomElement as CustomElement,
  ThemeImageElement as ImageElement,
  ThemeLinkElement as LinkElement,
  ThemeParameterElement as ParameterElement,
  ThemeLinkElement,
} from '@nucleus/types/web';
import { RichTextCustomRichTextComponent, RichTextRegistryProvider, useRichTextParameters } from '@nucleus/web-theme';
import { Text, WebImage, buildTransparentColorFromCssVariable } from '@nucleus/web-theme-elements';
import React from 'react';
import styled from 'styled-components';
import { formatSrcSet } from '../lib/richtext';

const H1 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.H1>{children}</Text.H1>;
const H2 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.H2>{children}</Text.H2>;
const H3 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.H3>{children}</Text.H3>;
const H4 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.H4>{children}</Text.H4>;
const H5 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.H5>{children}</Text.H5>;
const H6 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.H6>{children}</Text.H6>;

const P1 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.P1>{children}</Text.P1>;
const P2 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.P2>{children}</Text.P2>;
const P3 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.P3>{children}</Text.P3>;
const P4 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.P4>{children}</Text.P4>;

const L1 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.L1>{children}</Text.L1>;
const L2 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.L2>{children}</Text.L2>;
const L3 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.L3>{children}</Text.L3>;
const L4 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.L4>{children}</Text.L4>;
const L5 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.L5>{children}</Text.L5>;
const L6 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <Text.L6>{children}</Text.L6>;

const Span = ({ children }: { children: React.ReactNode; node: CustomElement }) => <span>{children}</span>;

// TODO: remove these unused elements from the registry
const T1 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const T2 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

const D1 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const D2 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

const N1 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const N2 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const N3 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const N4 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const N5 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

const B1 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const B2 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const B3 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const B4 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

const Unstyled = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

// TODO: What are the styles for UL and OL? And do they inherit font styles from the parent?
const UL = ({ children }: { children: React.ReactNode; node: CustomElement }) => <ol>{children}</ol>;
const OL = ({ children }: { children: React.ReactNode; node: CustomElement }) => <ul>{children}</ul>;
const LI = ({ children }: { children: React.ReactNode; node: CustomElement }) => <li>{children}</li>;

const Q1 = (props: { children: React.ReactNode }) => <Text.Q1>{props.children}</Text.Q1>;
const Q2 = (props: { children: React.ReactNode }) => <Text.Q2>{props.children}</Text.Q2>;

const Image = ({ node }: { children: React.ReactNode; node: ImageElement & { link: Partial<ThemeLinkElement> } }) => {
  if (node.link?.href !== undefined && node.link?.href !== '') {
    return (
      <Link node={node?.link as ThemeLinkElement}>
        <WebImage
          blurHash={node.blurHash}
          dimensions={node.dimensions}
          size={node.size}
          src={node.src}
          srcSet={formatSrcSet(node.srcSet)}
        />
      </Link>
    );
  }

  return (
    <WebImage
      blurHash={node.blurHash}
      dimensions={node.dimensions}
      size={node.size}
      src={node.src}
      srcSet={formatSrcSet(node.srcSet)}
    />
  );
};

const Parameter = ({ node }: { node: ParameterElement }) => {
  const parameters = useRichTextParameters();
  return <>{parameters?.[node.parameter] ?? node.fallback}</>;
};

const Action = ({ children }: { children: React.ReactNode; node: ActionElement }) => {
  return <>{children}</>;
};

const Link = ({ children, node }: { children: React.ReactNode; node: LinkElement }) => {
  const anchorProps = node.openInNewTab === true ? { target: '_blank', rel: 'noreferrer' } : {};

  if (typeof window === 'undefined' || isUrl(node.href)) {
    return (
      <a href={node.href} {...anchorProps}>
        {children}
      </a>
    );
  }

  if (node?.href?.includes('_api/file')) {
    return (
      <a href={node.href} {...anchorProps}>
        {children}
      </a>
    );
  }

  return (
    <NavLink {...anchorProps} to={node?.href}>
      {children}
    </NavLink>
  );
};

const isUrl = (maybeUrl: string): boolean => {
  try {
    const url = new URL(maybeUrl);
    return url.toString() !== undefined;
  } catch (error) {
    return false;
  }
};

// Text components
type TextProps = Parameters<RichTextCustomRichTextComponent>[0];

const StyledMark = styled.mark`
  border-radius: 1em 0 1em 0;
  background-color: transparent;
  background-image: linear-gradient(
    -100deg,
    ${buildTransparentColorFromCssVariable('--color-highlight-background2', 0.3)},
    ${buildTransparentColorFromCssVariable('--color-highlight-background2', 0.7)} 95%,
    ${buildTransparentColorFromCssVariable('--color-highlight-background2', 0.1)}
  );
  color: inherit;

  &:before,
  &:after {
    content: ' ';
    display: inline;
    white-space: pre;
  }
`;

const Bold = (props: TextProps) => <b>{props.children}</b>;
const Italic = (props: TextProps) => <i>{props.children}</i>;
const Underline = (props: TextProps) => <u>{props.children}</u>;
const Strike = (props: TextProps) => <s>{props.children}</s>;
const Code = (props: TextProps) => <code>{props.children}</code>;
const Mark = (props: TextProps) => <StyledMark>{props.children}</StyledMark>;

interface RichTextContextProps {
  children: React.ReactNode;
}
export const RichTextProvider = (props: RichTextContextProps): JSX.Element => {
  const ElementRegistry: Required<Parameters<typeof RichTextRegistryProvider>[0]['registerElements']> = {
    action: Action,
    headline1: H1,
    headline2: H2,
    headline3: H3,
    headline4: H4,
    headline5: H5,
    headline6: H6,
    paragraph1: P1,
    paragraph2: P2,
    paragraph3: P3,
    paragraph4: P4,
    label1: L1,
    label2: L2,
    label3: L3,
    label4: L4,
    label5: L5,
    label6: L6,
    blockquote1: Q1,
    blockquote2: Q2,
    // misc
    paragraph: Unstyled, // @deprecated
    image: Image,
    parameter: Parameter,
    link: Link,
    'bulleted-list': UL,
    'numbered-list': OL,
    'list-item': LI,
    span: Span,
    // TODO: remove these unused elements from the registry type
    // unused
    title1: T1,
    title2: T2,
    description1: D1,
    description2: D2,
    navigation1: N1,
    navigation2: N2,
    navigation3: N3,
    navigation4: N4,
    navigation5: N5,
    button1: B1,
    button2: B2,
    button3: B3,
    button4: B4,
    // backwards compatibility
    // TODO: Remove these old headline types once they've been fully migrated
    headlineOne: H1,
    headlineTwo: H2,
    headlineThree: H3,
    headlineFour: H4,
    headlineFive: H5,
    headlineSix: H6,
  };

  const RichTextRegistry: Required<Parameters<typeof RichTextRegistryProvider>[0]['registerText']> = {
    bold: Bold,
    italic: Italic,
    underline: Underline,
    strike: Strike,
    code: Code,
    highlight: Mark,
  };

  return (
    <RichTextRegistryProvider registerElements={ElementRegistry} registerText={RichTextRegistry}>
      {props.children}
    </RichTextRegistryProvider>
  );
};
