import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockListGrid } from '../../blocks/BlockListGrid';
import { BlockInfo, blockInfoHasContent } from '../../blocks/BlockInfo';
import { Body } from '../../components/Base';
import { ListBlockLayoutProps } from './ListLayout';
import { StandardList } from '../../components/StandardList';
import { AccordionList } from '../../components/AccordionList';

export const ListBlockLayoutLongText = (props: ListBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const bodyMaxColumns = props.blocks[0]?.bodyMaxColumns ?? props.blockBodyMaxColumns;

  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout {...props} bodyMaxColumns={bodyMaxColumns} renderInfo={renderInfo}>
      <StyledBlockInfo {...innerProps} block={props.blocks[0]} />
      <StyledBlockListGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const FallbackMaxColumns = 1;

const StyledBlockListGrid = styled(BlockListGrid)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)<{
  bodyMaxColumns?: number;
  renderInfo: boolean;
}>`
  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${Body} {
    column-count: ${(props) => props.bodyMaxColumns ?? FallbackMaxColumns};
    column-width: max(calc(var(--unit-length) * 7), 300px);
    column-gap: var(--unit-length);
  }

  ${StyledBlockListGrid} {
    display: flex;
    justify-content: center;
    padding: var(--unit-length);
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};

    ${StandardList} {
      padding: var(--unit-length);
      max-width: 1600px;
    }

    ${AccordionList} {
      padding: var(--unit-length);
      max-width: 912px;
    }
  }
`;
