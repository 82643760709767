import { createGlobalStyle } from 'styled-components';

interface GlobalStyleProps {
  content?: string;
  selector?: string;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  ${(props) => (props.selector ? `${props.selector} {` : '')}
  ${(props) => props.content ?? ''}
  ${(props) => (props.selector ? '}' : '')}
`;
