import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { decode } from 'blurhash';

export type BlurHashProps = React.HTMLAttributes<HTMLDivElement> & BlurHashCanvasProps & ContainerProps;

/**
 * Renders a BlurHash conveniently wrapped in a styled div
 */
export const BlurHash = ({
  hash,
  height = '100%',
  punch,
  resolutionX,
  resolutionY,
  width = '100%',
  ...props
}: BlurHashProps): JSX.Element => {
  return (
    <Container {...props} height={height} width={width}>
      <StyledBlurHashCanvas hash={hash} resolutionY={resolutionY} resolutionX={resolutionX} punch={punch} />
    </Container>
  );
};

export type BlurHashCanvasProps = React.HTMLAttributes<HTMLCanvasElement> & {
  hash: string;
  /**
   * Controls the "punch" value (~contrast) of the blurhash decoding algorithm. (Default: 1)
   */
  punch?: number;
  /**
   * The X-axis resolution in which the decoded image will be rendered at.
   * Recommended min. 32px. Large sizes (>128px) will greatly decrease rendering performance. (Default: 32)
   */
  resolutionX?: number;
  /**
   * The Y-axis resolution in which the decoded image will be rendered at.
   * Recommended min. 32px. Large sizes (>128px) will greatly decrease rendering performance. (Default: 32)
   */
  resolutionY?: number;
};

export const BlurHashCanvas = ({
  hash,
  resolutionX = 32,
  resolutionY = 32,
  punch,
  ...props
}: BlurHashCanvasProps): JSX.Element => {
  const ref = useRef<HTMLCanvasElement>(null);

  useLayoutEffect(() => {
    const canvas = ref.current;

    if (canvas) {
      const pixels = decode(hash, resolutionX, resolutionY, punch);
      const ctx = canvas.getContext('2d')!;
      const imageData = ctx.createImageData(resolutionX, resolutionY);
      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);
    }
  }, [hash, resolutionX, resolutionY, punch]);

  return <canvas {...props} height={resolutionY} width={resolutionX} ref={ref} />;
};

type ContainerProps = {
  height?: number | string | 'auto';
  width?: number | string | 'auto';
};

const Container = styled.div<ContainerProps>`
  display: inline-block;
  position: relative;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const StyledBlurHashCanvas = styled(BlurHashCanvas)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
