import React from 'react';

/** Icon representing the Sermon Grid View */
export const IconGridView = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <rect width="9" height="9" rx="1" />
        <rect width="9" height="9" x="11" rx="1" />
        <rect width="9" height="9" y="11" rx="1" />
        <rect width="9" height="9" x="11" y="11" rx="1" />
      </g>
    </svg>
  );
};
