import { ColorPaletteColors } from '@nucleus/types/web';
import { ThemeColorsCssVariablesByVariation, objectToCssClass, objectToCssRules } from '@nucleus/web-theme-elements';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { buildCssVariablesForColorPaletteAndVariations, buildThemeColorsVariationClassName } from '../utils/styles';

export const ColorGlobalStyle = ({ colorPalette }: { colorPalette: ColorPaletteColors }): JSX.Element => {
  const [globalCssVariables, colorCssVariables, colorCssVariablesByVariation] =
    buildCssVariablesForColorPaletteAndVariations(colorPalette);

  return (
    <GlobalStyle
      globalCssVariables={globalCssVariables}
      colorCssVariables={colorCssVariables}
      colorCssVariablesByVariation={colorCssVariablesByVariation}
    />
  );
};

interface GlobalStyleProps {
  globalCssVariables: Record<string, string | number>;
  colorCssVariables: Record<string, string | number>;
  colorCssVariablesByVariation: ThemeColorsCssVariablesByVariation;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    :root {
      ${(props) => objectToCssRules(props.globalCssVariables)}

      ${(props) => objectToCssRules(props.colorCssVariables)}

      ${(props) => objectToCssRules(props.colorCssVariablesByVariation['variation-1'])}
    }

    /* output css variables grouped by theme colors variation class */
    ${(props) =>
      Object.entries(props.colorCssVariablesByVariation)
        .map(([key, value]) => objectToCssClass(buildThemeColorsVariationClassName(key), value))
        .join('')}


    .nucleus-section-header {
        &:has(+ .theme-colors-variation-1) {
            ${(props) => objectToCssRules(props.colorCssVariablesByVariation['variation-1'])}
        }

        &:has(+ .theme-colors-variation-2) {
            ${(props) => objectToCssRules(props.colorCssVariablesByVariation['variation-2'])}
        }

        &:has(+ .theme-colors-variation-3) {
            ${(props) => objectToCssRules(props.colorCssVariablesByVariation['variation-3'])}
        }

        &:has(+ .theme-colors-variation-4) {
            ${(props) => objectToCssRules(props.colorCssVariablesByVariation['variation-4'])}
        }
    }
`;
