import React, { Children } from 'react';

interface Props {
  children?: React.ReactNode;
  separator?: React.ReactNode;
}

/**
 * A Component that joins it's children by inserting a separator between them
 */
export const Join = (props: Props): JSX.Element => {
  const children = Children.toArray(props.children);

  return (
    <>
      {children.reduce<React.ReactNode[]>((acc, child, index) => {
        if (index < children.length - 1) {
          return acc.concat([child, props.separator]);
        }
        return acc.concat(child);
      }, [])}
    </>
  );
};
