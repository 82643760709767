import React from 'react';
import styled from 'styled-components';
import { IconGridView } from '../icons/IconGridView';
import { IconListView } from '../icons/IconListView';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    ViewToggle?: {
      background?: string;
    };
    ViewToggleButton?: {
      color?: string;
      background?: string;
      activated?: {
        color?: string;
        background?: string;
      };
      hover?: {
        background?: string;
      };
    };
  }
}

type View = 'list' | 'grid';

interface Props {
  value: View;
  onChange: (value: View) => void;
}

/**
 * Toggle the Sermon View between Grid, and List Views
 */
export const ViewToggle = ({ value, onChange }: Props): JSX.Element => {
  return (
    <Container>
      <Button checked={value === 'grid'} onClick={() => onChange('grid')}>
        <IconGridView width="20px" />
      </Button>
      <Button checked={value === 'list'} onClick={() => onChange('list')}>
        <IconListView width="21px" />
      </Button>
    </Container>
  );
};

const Container = styled.div`
  background: ${({ theme }) => theme._sermonThemeElements.ViewToggle?.background};
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '10px',
    pill: '25px',
  })};
  display: flex;
  align-items: center;
  height: 50px;
  padding: 5px;
`;

const Button = styled.button<{ checked: boolean }>`
  outline: none;
  color: ${({ checked, theme }) =>
    checked
      ? theme._sermonThemeElements.ViewToggleButton?.activated?.color
      : theme._sermonThemeElements.ViewToggleButton?.color};
  background: ${({ checked, theme }) =>
    checked
      ? theme._sermonThemeElements.ViewToggleButton?.activated?.background
      : theme._sermonThemeElements.ViewToggleButton?.background};
  border: none;
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '10px',
    pill: '20px',
  })};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme._sermonThemeElements.ViewToggleButton?.hover?.background};
  }
`;
