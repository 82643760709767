import { MediaWeb } from '@nucleus/types/web';
import {
  inverseAspectRatioAsPercent,
  inverseAspectRatioAsPercentFromImage,
} from '@nucleus/web-theme/src/lib/utilities';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { MediaItemBackground } from './Background';

const MediaShapes = ['square', 'rounded', 'circle', 'original'] as const;
export type MediaShape = typeof MediaShapes[number];

interface MediaContainerProps {
  $image: MediaWeb;
  $shape: MediaShape;
}

const MEDIA_SHAPE = {
  circle: {
    borderRadius: '50%',
    overflow: 'hidden',
    height: 0,
    paddingBottom: '100%',
  },
  square: {
    overflow: 'hidden',
    height: 0,
    paddingBottom: '100%',
  },
  rounded: {
    overflow: 'hidden',
    borderRadius: '2.4rem',
  },
  original: {},
};

const MediaContainer = styled.div<MediaContainerProps>((props) => {
  return {
    width: '100%',
    ...(MEDIA_SHAPE[props.$shape] ?? {}),
    overflow: 'hidden',
  };
});

interface MediaInnerProps {
  $image: MediaWeb;
  $aspectRatio?: [number, number];
}

const MediaInner = styled.div<MediaInnerProps>((props) => {
  let aspectRatioPercentage = inverseAspectRatioAsPercentFromImage(props.$image.image);
  if (props.$aspectRatio) {
    aspectRatioPercentage = inverseAspectRatioAsPercent(props.$aspectRatio[0], props.$aspectRatio[1]);
  }

  return {
    backgroundColor: 'var(--color-dark)',
    width: '100%',
    paddingTop: aspectRatioPercentage,
    position: 'relative',
  };
});

interface GridItemBlockMediaProps {
  media: MediaWeb;
  shape?: MediaShape;
  aspectRatio?: [number, number];
  className?: string;
  content?: ReactNode;
}

export const GridItemBlockMedia = styled(
  ({ media, content, shape, aspectRatio, ...props }: GridItemBlockMediaProps) => {
    return (
      <MediaContainer {...props} $image={media} $shape={shape ?? 'original'}>
        <MediaInner $image={media} $aspectRatio={aspectRatio}>
          <MediaItemBackground background={media} fallbackColor="transparent" />
          {content && <MediaContentOverlay>{content}</MediaContentOverlay>}
        </MediaInner>
      </MediaContainer>
    );
  }
)``;

const MediaContentOverlay = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
`;
