import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';

/**
 * Slider component.
 *
 * This component displays a slideshow of images or content in a horizontal scrollable container.
 * It provides buttons to navigate between slides and highlights the active slide.
 *
 */
export const Slider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (ref.current) {
      const slideWidth = ref.current.offsetWidth;
      const currentIndex = Math.round(ref.current.scrollLeft / slideWidth);
      setActiveIndex(currentIndex);
    }
  };

  const handleClick = (index: number) => {
    if (ref.current) {
      const slideWidth = ref.current.offsetWidth;
      ref.current.scrollTo({
        left: index * slideWidth,
        behavior: 'smooth',
      });
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Container>
      <Slides ref={ref}>
        {React.Children.map(children, (child) => (
          <Slide>{child}</Slide>
        ))}
      </Slides>
      {React.Children.count(children) > 1 && (
        <Controls>
          {React.Children.map(children, (_, index) => (
            <Button onClick={() => handleClick(index)} isActive={index === activeIndex} />
          ))}
        </Controls>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const Slides = styled.div`
  display: flex;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Slide = styled.div`
  flex: 1 0 100%;
  scroll-snap-align: start;
`;

const Controls = styled.div`
  display: flex;
  gap: 9px;
  position: absolute;
  padding-bottom: 23px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

interface ButtonProps {
  isActive: boolean;
}

const Button = styled.button<ButtonProps>`
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
  background: var(--color-light);
  border: 2px solid var(--color-dark);
  border-radius: 9px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.35)};
`;
