import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockListGrid } from '../../blocks/BlockListGrid';
import { BlockInfo, blockInfoHasContent } from '../../blocks/BlockInfo';
import { BlockMedia } from '../../blocks/BlockMedia';
import { ListBlockLayoutProps } from './ListLayout';
import { StandardList } from '../../components/StandardList';
import { AccordionList } from '../../components/AccordionList';

export const ListBlockLayoutVertical = (props: ListBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout {...props} renderInfo={renderInfo}>
      <StyledBlockMedia media={props.blocks[0]?.mediaItems?.[0]} />
      {renderInfo && <StyledBlockInfo {...innerProps} block={props.blocks[0]} />}
      <StyledBlockListGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const StyledBlockInfo = styled(BlockInfo)``;
const StyledBlockMedia = styled(BlockMedia)``;
const StyledBlockListGrid = styled(BlockListGrid)``;

const StyledBlockLayout = styled(BlockLayout)<{
  renderInfo: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledBlockMedia} {
    position: relative;
    z-index: 1;

    background-color: transparent;

    & > div {
      padding-top: ${(props) => {
        switch (props.height) {
          case 'large':
            return '30vh';
          case 'small':
            return '20%';
          case 'medium':
          default:
            return '30vh';
        }
      }};

      @media (min-width: 600px) {
        padding-top: ${(props) => (props.height === 'large' ? '40vh' : undefined)};
      }
    }
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${StyledBlockListGrid} {
    display: flex;
    justify-content: center;
    padding: var(--unit-length);
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};

    ${StandardList} {
      padding: var(--unit-length);
      max-width: 1600px;
    }

    ${AccordionList} {
      padding: var(--unit-length);
      max-width: 912px;
    }
  }
`;
