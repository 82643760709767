import { ThemeRichTextNodeArray } from '@nucleus/types/web';
import { RichText } from '@nucleus/web-theme';
import React from 'react';
import { AnyStyledComponent } from 'styled-components';

export interface WrappedStyledRichTextProps {
  nodes?: string | ThemeRichTextNodeArray;
  className?: string;
}
export const WrappedStyledRichText = (StyledComponent: AnyStyledComponent) =>
  function WrappedRichText(props: WrappedStyledRichTextProps): JSX.Element | null {
    if (props.nodes === undefined) {
      return null;
    }

    return (
      <StyledComponent className={props.className}>
        <RichText nodes={props.nodes} />
      </StyledComponent>
    );
  };
