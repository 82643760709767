import { Alignment, Position } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCover } from '../../blocks/BlockCover';
import { BlockEmbedGrid } from '../../blocks/BlockEmbedGrid';
import { SectionBackground } from '../../components/Background';
import { Overline } from '../../components/Base';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { getPositionRow } from '../Calendar/CalendarLayout';
import { EmbedBlockLayoutProps } from './EmbedLayout';

export const EmbedBlockLayoutCover = (props: EmbedBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const row = getPositionRow(props.blockInfoPosition);

  const renderBackground = props.backgroundMedia !== undefined;

  return (
    <StyledBlockLayout {...props} bodyAlignment={props.blocks[0]?.bodyAlignment ?? props.blockBodyAlignment} row={row}>
      {renderBackground && <SectionBackground background={props.backgroundMedia} />}
      <SpacerTop />
      <StyledInfoContainer>
        <BlockCover {...innerProps} block={props.blocks[0]}>
          <StyledBlockEmbedGrid {...innerProps} block={props.blocks[0]} />
        </BlockCover>
        <SpacerTop />
      </StyledInfoContainer>
    </StyledBlockLayout>
  );
};

const StyledBlockEmbedGrid = styled(BlockEmbedGrid)``;
const StyledInfoContainer = styled.div``;

const StyledBlockLayout = styled(BlockLayout)<{ bodyAlignment?: Alignment; row: Position }>`
  ${StyledBlockEmbedGrid} {
    padding-top: max(
      calc(var(--min-unit-length) * 3.5),
      calc(var(--section-padding-multiplier) * var(--unit-length) * 1.5)
    );
  }

  ${StyledInfoContainer} {
    display: flex;
    flex-direction: column;

    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '60vh';
        case 'large':
          return '80vh';
        default:
          return undefined;
      }
    }};

    ${SpacerTop} {
      display: ${(props) => (props.row === Position.Center ? 'block' : 'none')};
    }
  }

  ${Overline} {
    text-align: ${(props) => props.bodyAlignment ?? 'left'};
  }
`;
