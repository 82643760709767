import { createPrefixedKey, isPrefixedKey } from '@nucleus/lib-shape';
import { PublishableRevision, PublishableRevisionSchema } from '../../types/PublishableRevision';
import { RepositoryHelperOptionsWithoutType, RepositoryHelperOptionsWithType } from '../../types/repository';
import { publishableEngineRepositoryHelpers } from './publishableEngineRepositoryHelpers';
import { publishableEntityRepositoryHelpers } from './publishableEntityRepositoryHelpers';

export const ProtectedRevisionKeys = ['id', 'engineId', 'entityId', 'createdAt', 'createdBy', 'publishedAt'] as const;
export type ProtectedRevisionKeys = typeof ProtectedRevisionKeys[number];

const VersionKey = 'version';

function publishableRevisionRepositoryHelpersFunction<T extends PublishableRevision>(
  options: RepositoryHelperOptionsWithType
): {
  entityType: RepositoryHelperOptionsWithType['entityType'];
  isPrefixedKey: (key: any) => key is T['id'];
  isType: (element: any) => element is T;
  key: 'revision';
  namespace: RepositoryHelperOptionsWithType['namespace'];
  createPrefixedKey: () => T['id'];
  schema: typeof PublishableRevisionSchema;
};

function publishableRevisionRepositoryHelpersFunction(options: RepositoryHelperOptionsWithoutType): {
  key: 'revision';
  namespace: RepositoryHelperOptionsWithoutType['namespace'];
};

function publishableRevisionRepositoryHelpersFunction<T extends PublishableRevision>(options: any): any {
  const baseReturn = {
    key: 'revision',
    namespace: options.namespace,
  };

  if (!('entityType' in options)) {
    return baseReturn;
  }

  // constants
  const keyPrefix = [options.entityType, VersionKey].join('');

  // Helpers
  const isPrefixedRevisionKey = (key: any): key is T['id'] => isPrefixedKey(keyPrefix, key);
  const prefixedRevisionKey = (): T['id'] => createPrefixedKey(keyPrefix);

  const isRevisionType = (element: any): element is T => revisionSchema.required().isValidSync(element);

  const engineHelpers = publishableEngineRepositoryHelpers(options);
  const entityHelpers = publishableEntityRepositoryHelpers(options);

  const revisionSchema = PublishableRevisionSchema.shape({
    id: PublishableRevisionSchema.fields.id.test(
      'is this revision type',
      `\${path}: \${value} is not a "${options.entityType}" revisions`,
      (value) => isPrefixedRevisionKey(value)
    ),
    engineId: engineHelpers.schema.fields.id,
    entityId: entityHelpers.schema.fields.id,
  });

  return {
    ...baseReturn,
    entityType: options.entityType,
    isPrefixedKey: isPrefixedRevisionKey,
    isType: isRevisionType,
    createPrefixedKey: prefixedRevisionKey,
    schema: revisionSchema,
  } as const;
}

export const publishableRevisionRepositoryHelpers = publishableRevisionRepositoryHelpersFunction;
