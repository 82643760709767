import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCardGrid, StackedGrid } from '../../blocks/BlockCardGrid';
import { BlockInfo } from '../../blocks/BlockInfo';
import { BlockMedia } from '../../blocks/BlockMedia';
import { CardBlockLayoutProps } from './CardLayout';

export const CardBlockLayoutVertical = (props: CardBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  return (
    <StyledBlockLayout {...props}>
      <StyledBlockMedia media={props.blocks[0]?.mediaItems?.[0]} />
      <StyledBlockInfo {...innerProps} block={props.blocks[0]} />
      <StyledBlockCardGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const StyledBlockMedia = styled(BlockMedia)``;
const StyledBlockInfo = styled(BlockInfo)``;
const StyledBlockCardGrid = styled(BlockCardGrid)``;

const StyledBlockLayout = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledBlockMedia} {
    position: relative;
    z-index: 1;

    background-color: transparent;

    & > div {
      padding-top: ${(props) => {
        switch (props.height) {
          case 'large':
            return '30vh';
          case 'small':
            return '20%';
          case 'medium':
          default:
            return '30vh';
        }
      }};

      @media (min-width: 600px) {
        padding-top: ${(props) => (props.height === 'large' ? '40vh' : undefined)};
      }
    }
  }

  ${StyledBlockCardGrid} {
    position: relative;
    z-index: 1;

    background-color: transparent;
    ${StackedGrid} {
      padding: 0 var(--unit-length) var(--unit-length);
    }
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '60vh';
        case 'large':
          return '80vh';
        default:
          return undefined;
      }
    }};
  }
`;
