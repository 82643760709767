import { isNonNullable } from '@nucleus/lib-shape';
import { EmbedBlockWeb, EmbedItemWeb } from '@nucleus/types/web';
import { HTMLElement, nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Iframe } from '../components/Iframe';
import { BlockProps, ComponentizedTypeProps } from '../types/component';

type BlockEmbedGridProps = Extract<BlockProps, { block?: EmbedBlockWeb }>;

export const BlockEmbedGrid = (props: BlockEmbedGridProps): JSX.Element => {
  const items = (props.block?.items ?? []).filter(isNonNullable);

  return (
    <LayoutGrid className={classNames([props.className, nucleusClass('block-embed')])}>
      {items.map((embed) => (
        <BlockEmbed key={embed.id} {...embed} />
      ))}
    </LayoutGrid>
  );
};

type EmbeddedMediaProps = ComponentizedTypeProps<EmbedItemWeb>;

const EmbedContainer = styled.div`
  width: 100%;
`;

const BlockEmbed = styled((props: EmbeddedMediaProps): JSX.Element | null => {
  const embedHtmlString = props.htmlString;
  const sizing = props.sizing ?? 'original';

  const renderEmbed = embedHtmlString !== undefined;

  if (sizing === 'raw') {
    return <HTMLElement innerHTML={embedHtmlString} />;
  }

  return (
    <EmbedContainer className={classNames([props.className, nucleusClass('embed-item')])}>
      {renderEmbed && <Iframe content={embedHtmlString} sizing={sizing} />}
    </EmbedContainer>
  );
})``;

const LayoutGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 100%;
`;
