import { PolymorphicButtonProps } from '@nucleus/react-components';
import React from 'react';
import styled from 'styled-components';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    InfoItem?: {
      background?: string;
      color?: string;
    };
  }
}

export type InfoItemProps = PolymorphicButtonProps;

export const InfoItem = (props: InfoItemProps): JSX.Element => {
  return <Item>{props.children}</Item>;
};

const Item = styled.li`
  background: ${({ theme }) => theme._sermonThemeElements.InfoItem?.background};
  color: ${({ theme }) => theme._sermonThemeElements.InfoItem?.color};
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '12px',
    pill: '25px',
  })};
  display: flex;
  align-items: center;
  border: none;
  height: 81px;
  padding: 0 24px;
  letter-spacing: calc(-0.5 / 22) em;
  line-height: 1em;
  width: 100%;
`;
