import { FooterSectionWeb } from '@nucleus/types/web';
import { useNavigation, useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { FooterLayout } from './FooterLayout';

export const FooterSection = (): JSX.Element => {
  const section = useSection<FooterSectionWeb>();
  const navigation = useNavigation(section.payload.blocks[0].navigation?.id);

  return (
    <Section className={classNames(nucleusClass('section-footer'), getSectionClasses(section))}>
      <FooterLayout {...section.payload} navigation={navigation} />
    </Section>
  );
};
