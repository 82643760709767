import { useCallback, useRef } from 'react';

interface ScrollNav {
  ref: React.RefObject<HTMLDivElement>;
  next: () => void;
  prev: () => void;
}

/**
 * Returns a ref, and navigation functions for smooth scrolling forward and backward in a scrollable container
 */
export const useScrollNav = (): ScrollNav => {
  const ref = useRef<HTMLDivElement>(null);

  const next = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const currentIndex = Array.from(ref.current.children).findIndex((item) => item.getBoundingClientRect().left > 0);
    const nextIndex = Math.min(currentIndex + 1, ref.current.children.length);
    const nextItem = ref.current.children[nextIndex] as HTMLElement | undefined;

    if (!nextItem) {
      return;
    }

    ref.current.scrollTo({
      left: nextItem.offsetLeft,
      behavior: 'smooth',
    });
  }, []);

  const prev = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const currentIndex = Array.from(ref.current.children).findIndex((item) => item.getBoundingClientRect().left > 0);
    const prevIndex = Math.max(currentIndex - 1, 0);
    const prevItem = ref.current.children[prevIndex] as HTMLElement | undefined;

    if (!prevItem) {
      return;
    }

    ref.current.scrollTo({
      left: prevItem.offsetLeft,
      behavior: 'smooth',
    });
  }, []);

  return {
    ref: ref,
    next: next,
    prev: prev,
  };
};
