import styled from 'styled-components';

export const Icon = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
})`
  display: block;
  width: 100%;
  height: 100%;
`;
