import { ImageWeb } from '@nucleus/types/media/image';
import React, { useState } from 'react';
import ReactPlayer, { Config } from 'react-player';
import styled from 'styled-components';
import { Thumbnail } from './Thumbnail';
import { IconCirclePlay } from '../icons/IconCirclePlay';
import { ReactAudioPlayer } from './ReactAudioPlayer';
import { IconCircleArrow } from '../icons/IconCircleArrow';
import { DurationBadge } from './DurationBadge';
import { formatSrcSet } from '../lib/media';

// Register our custom sermon audio player
ReactPlayer.addCustomPlayer(ReactAudioPlayer);

interface Props {
  className?: string;
  style?: React.CSSProperties;
  thumbnail?: ImageWeb;
  url?: string;
}

/**
 * Used to render a Sermon Media Player
 */
export const Player = (props: Props): JSX.Element => {
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);

  if (props.url === undefined) {
    return (
      <Container className={props.className} style={props.style}>
        <ThumbnailContainer>
          <StyledThumbnail
            src={props.thumbnail?.src}
            srcSet={formatSrcSet(props.thumbnail?.srcSet)}
            blurHash={props.thumbnail?.blurHash}
          />
        </ThumbnailContainer>
      </Container>
    );
  }

  if (ReactPlayer.canPlay(props.url) === false) {
    return (
      <Container className={props.className} style={props.style}>
        <ThumbnailContainer>
          <StyledThumbnail
            src={props.thumbnail?.src}
            srcSet={formatSrcSet(props.thumbnail?.srcSet)}
            blurHash={props.thumbnail?.blurHash}
          />
          <StyledLink href={props.url} target="_blank">
            View Now <IconCircleArrow />
          </StyledLink>
          <DurationBadge>External URL</DurationBadge>
        </ThumbnailContainer>
      </Container>
    );
  }

  return (
    <Container className={props.className} style={props.style}>
      {isPlayerVisible ? (
        <StyledReactPlayer
          playing={true}
          url={props.url}
          thumbnail={props.thumbnail}
          config={playerConfig}
          width="100%"
          height="100%"
        />
      ) : (
        <ThumbnailContainer onClick={() => setIsPlayerVisible(true)}>
          <StyledThumbnail src={props.thumbnail?.src} blurHash={props.thumbnail?.blurHash} />
          <LinearGradient />
          <IconPlay />
        </ThumbnailContainer>
      )}
    </Container>
  );
};

const StyledLink = styled.a`
  font-size: 22px;
  color: #ffffff;
  letter-spacing: -0.5px;
  text-align: center;
  text-decoration: none;
  line-height: 24px;
  background: rgba(0, 0, 0, 0.3);
  border: 6px solid #ffffff;
  border-radius: 40px;
  position: relative;
  display: flex;
  gap: 18px;
  white-space: nowrap;
  height: 68px;
  padding: 16px 18px 16px 36px;
`;

const IconPlay = styled(IconCirclePlay)`
  position: absolute;
  width: 100px;
  height: 100px;
`;

const LinearGradient = styled.div`
  position: absolute;
  inset: 0;

  pointer-events: none;
  background-image: linear-gradient(0deg, #000000, transparent);

  opacity: 0;
  transition: opacity 900ms cubic-bezier(0.23, 1, 0.32, 1);
`;

const Container = styled.div`
  background: #000;
  border-radius: 10px;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  box-shadow: 0 12px 46px 0 rgba(0, 0, 0, 0.42);
  border-radius: 16.21px;
`;

const ThumbnailContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: ${(props) => (props.onClick === undefined ? 'inherit' : 'pointer')};

  ${DurationBadge} {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 18px;
  }

  ${StyledLink}, ${IconPlay} {
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    :hover {
      transform-origin: center;
      transform: scale(1.1);
    }
  }

  :hover {
    ${LinearGradient} {
      opacity: 0.75;
    }
  }
`;

const StyledThumbnail = styled(Thumbnail)`
  position: absolute;
  inset: 0;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  inset: 0;
`;

const playerConfig = Object.freeze<Config>({
  file: {
    attributes: {
      controls: true,
    },
  },
  vimeo: {
    playerOptions: {
      controls: true,
      playsinline: false,
    },
  },
  youtube: {
    playerVars: {
      controls: 1,
      playsinline: 0,
    },
  },
});
