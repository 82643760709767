import { parseHtmlTags, usePageHtmlSnippets, useSiteHtmlSnippets } from '@nucleus/web-theme';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEnabledCodeSnippets } from '../hooks/useEnabledCodeSnippets';

export const PageGlobalHtml = (): JSX.Element => {
  const siteHtml = useSiteHtmlSnippets();
  const pageHtml = usePageHtmlSnippets();
  const enabledSnippets = useEnabledCodeSnippets([...siteHtml, ...pageHtml]);
  const headSnippets = enabledSnippets.filter(
    (snippet) => snippet.location === 'head' || snippet.location === undefined
  );
  const enabledWithParsedContentSnippets = headSnippets.map((snippet) => ({
    ...snippet,
    content: parseHtmlTags(snippet.content),
  }));

  return (
    <>
      {headSnippets.map((snippet) => (
        <Helmet key={snippet.id}>
          {enabledWithParsedContentSnippets.map((snippet) =>
            snippet.content.map((parsedHtmlContent) => {
              // output parsed html tags formatted for each valid tag type
              switch (parsedHtmlContent.tagName) {
                case 'title':
                  return <title data-snippet-id={snippet.id}>{parsedHtmlContent.content}</title>;
                case 'meta':
                  return <meta {...parsedHtmlContent.attributes} data-snippet-id={snippet.id} />;
                case 'link':
                  return <link {...parsedHtmlContent.attributes} data-snippet-id={snippet.id} />;
                case 'style':
                  return (
                    <style {...parsedHtmlContent.attributes} data-snippet-id={snippet.id}>
                      {parsedHtmlContent.content}
                    </style>
                  );
                case 'script':
                  return (
                    <script {...parsedHtmlContent.attributes} data-snippet-id={snippet.id}>
                      {parsedHtmlContent.content}
                    </script>
                  );
                case 'template':
                  return (
                    <template {...parsedHtmlContent.attributes} data-snippet-id={snippet.id}>
                      {parsedHtmlContent.content}
                    </template>
                  );
                case 'noscript':
                  return (
                    <noscript {...parsedHtmlContent.attributes} data-snippet-id={snippet.id}>
                      {parsedHtmlContent.content}
                    </noscript>
                  );
                case 'base':
                  return <base {...parsedHtmlContent.attributes} data-snippet-id={snippet.id} />;
                default:
                  return null;
              }
            })
          )}
        </Helmet>
      ))}
    </>
  );
};
