import React from 'react';

/** Represents an X icon, used on a close action button */
export const IconX = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <g
        stroke="currentColor"
        strokeWidth="3"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="m2 2 12 12M14 2 2 14" />
      </g>
    </svg>
  );
};
