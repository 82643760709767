export const slugifyString = (source: string, maxLength?: number): string => {
  source = source.replace(/[^a-z0-9]+/gi, '-');
  source = source.replace(/-{2,}/g, '-');
  source = source.replace(/^-+/g, '');
  source = source.replace(/-+$/g, '');
  source = source.toLowerCase();

  return maxLength !== undefined ? source.slice(0, maxLength).replace(/-$/, '') : source;
};

export const slugifyPath = (source: string, maxSlugLength?: number): string => {
  return source
    .split('/')
    .map((slug) => slugifyString(slug, maxSlugLength))
    .join('/');
};
