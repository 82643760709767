import { TimeoutError } from '@nucleus/lib-error';

const timeout = (timeoutInMs: number): [Promise<unknown>, () => void] => {
  let timer: NodeJS.Timeout;
  const cancel = () => clearTimeout(timer);

  const promise = new Promise((_, reject) => {
    timer = setTimeout(() => reject(new TimeoutError()), timeoutInMs);
  });

  return [promise, cancel];
};

export const withTimeout = async <T extends Promise<any>>(handler: T, timeoutInMs: number): Promise<Awaited<T>> => {
  const [promise, cancel] = timeout(timeoutInMs);
  try {
    return await Promise.race([handler, promise as Promise<T>]);
  } finally {
    cancel();
  }
};

/**
 * Logs the reasons for rejected promises in an array of PromiseSettledResult objects.
 */
export const logRejectedErrors =
  (message = 'Rejected promise error:', logger = console.error) =>
  <T>(results: PromiseSettledResult<T>[]): PromiseSettledResult<T>[] => {
    for (const result of results) {
      if (result.status !== 'rejected') {
        continue;
      }

      logger(message);
      console.info(`Reason: ${result.reason}`);
    }

    return results;
  };

/**
 * * Accepts an object where all values are promises and returns an object with all promises resolved
 *
 * @param {PromiseObject} promiseObject
 * @return {*}  {Promise<any>} object with resolved keys
 */
export const promiseAllKeys = async (promiseObject: {
  [key: string]: Promise<any>;
}): Promise<{ [key: string]: any }> => {
  const result: { [key: string]: any } = {};

  await Promise.all(
    Object.keys(promiseObject).map(async (promiseKey) => {
      result[promiseKey] = await promiseObject[promiseKey];
    })
  );

  return result;
};

/**
 * Delays the execution of code by a specified amount of time.
 *
 * @param {number} ms - The time in milliseconds to wait before resolving the promise.
 * @returns {Promise<void>} A promise that resolves after the specified delay.
 *
 * @example
 * // Delay execution for 1 second
 * async function withSleepAction() {
 *   console.log("Start");
 *   await sleep(1000);
 *   console.log("After 1 second");
 * }
 */
export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, Math.max(ms, 0)));

type LogRejectedOptions = {
  logger?: typeof console.info;
  title?: string;
};

/**
 * Logs any failures that happen when the promises are awaited.
 *
 * @param promises An array of promises to await on
 * @param options Options for the way the results are logged
 * @returns the results of the promises using Promise.allSettled
 */
export const withLogRejected = async <T>(
  promises: Array<Promise<T>>,
  options?: LogRejectedOptions
): Promise<Array<PromiseSettledResult<T>>> => {
  const results = await Promise.allSettled(promises);
  logRejectedPromises(results, options);
  return results;
};

const logRejectedPromises = (
  settledPromises: Array<PromiseSettledResult<any>>,
  options?: LogRejectedOptions
): Array<string> => {
  const rejectedPromises = settledPromises.filter(
    (result): result is PromiseRejectedResult => result.status === 'rejected'
  );

  if (rejectedPromises.length < 1) {
    return [];
  }
  const logger = options?.logger ?? console.info;

  logger(`${rejectedPromises.length} of ${settledPromises.length} ${options?.title ?? ''} promises were rejected.`);
  rejectedPromises.forEach((result) => {
    const originalIndex = settledPromises.indexOf(result);
    console.info(`${originalIndex}: ${result.reason}`);
  });

  return rejectedPromises.map((result) => result.reason);
};
