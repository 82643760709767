import { getKeyPrefix } from '@nucleus/lib-shape';
import { PlaylistWeb } from '@nucleus/types/web/sermons/playlist';
import { SermonTagWeb } from '@nucleus/types/web/sermons/sermonTag';
import { SpeakerWeb } from '@nucleus/types/web/sermons/speaker';
import { useLocalization } from './useLocalization';

export const useCollectionTerm = (collection: PlaylistWeb | SpeakerWeb | SermonTagWeb): string => {
  const localize = useLocalization();
  const prefixKey = getKeyPrefix(collection.id);

  switch (prefixKey) {
    case 'playlist':
      return localize('playlist', 'Playlist');
    case 'speaker':
      return localize('speaker', `Speaker`);
    case 'sermontag':
      return localize('topic', 'Topic');
    case 'referece':
      return localize('scripture', 'Book');

    default:
      return ``;
  }
};
