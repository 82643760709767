import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  id: string;
  value: string;
}

/**
 * Represents a TabPanel component, a container for the content of a specific tab.
 */
export const TabPanel = ({ children, id, value, ...props }: Props): JSX.Element => {
  return (
    <div {...props} role="tabpanel" id={`tabpanel-${id}`} hidden={id !== value} aria-labelledby={id}>
      {children}
    </div>
  );
};
