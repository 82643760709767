import { Position } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockInfo } from '../../blocks/BlockInfo';
import { Body } from '../../components/Base';
import { getPositionRow, InfoBlockLayoutProps } from './InfoLayout';

export const InfoBlockLayoutLongText = (props: InfoBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const bodyMaxColumns = props.blocks[0]?.bodyMaxColumns ?? props.blockBodyMaxColumns;
  const row = getPositionRow(props.blockInfoPosition);

  return (
    <StyledBlockLayout {...props} bodyMaxColumns={bodyMaxColumns} row={row}>
      <BlockInfo {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const FallbackMaxColumns = 1;

const StyledInfoContainer = styled.div``;

const StyledBlockLayout = styled(BlockLayout)<{ bodyMaxColumns?: number; row: Position }>`
  ${StyledInfoContainer} {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  ${Body} {
    column-count: ${(props) => props.bodyMaxColumns ?? FallbackMaxColumns};
    column-width: max(calc(var(--unit-length) * 7), 300px);
    column-gap: var(--unit-length);
    width: 100%;
  }
`;
