import { useContext } from 'react';
import { DataResourceContext } from '../components/data/dataResourceContext';
import { SectionData } from '@nucleus/types/web';

interface PageSections {
  [sectionId: string]: SectionData;
}

export const usePageSections = (): PageSections => {
  const { page } = useContext(DataResourceContext);

  return page?.sections ?? {};
};
