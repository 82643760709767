import { nucleusClass } from '@nucleus/web-theme-elements';
import { SIZE } from '@nucleus/web-theme-elements';
import styled from 'styled-components';
import { WrappedStyledRichText } from './WrappedRichText';

interface BaseProps {
  className?: string;
}

// Base Components
const StyledOverline = styled.div``;
export const Overline = styled(WrappedStyledRichText(StyledOverline)).attrs<BaseProps>({
  className: nucleusClass('overline'),
})``;

const StyledHeadline = styled.div``;
export const Headline = styled(WrappedStyledRichText(StyledHeadline)).attrs<BaseProps>({
  className: nucleusClass('headline'),
})``;

const StyledByline = styled.div``;
export const Byline = styled(WrappedStyledRichText(StyledByline)).attrs<BaseProps>({
  className: nucleusClass('byline'),
})``;

export const Labels = styled.div.attrs<BaseProps>({
  className: nucleusClass('labels'),
})`
  gap: ${SIZE[1]};
  padding: ${SIZE[1]} 0;
`;

export const Label = styled.span.attrs<BaseProps>({
  className: nucleusClass('label'),
})`
  padding: 0.2rem;
  background: #f1f1f1; // TODO: theme color
`;

const StyledBody = styled.div``;
export const Body = styled(WrappedStyledRichText(StyledBody)).attrs<BaseProps>({
  className: nucleusClass('body'),
})``;

// NOTE: These may need to be moved. They compete in name and function to components in InfoButtons.tsx
export const ButtonStack = styled.div.attrs<BaseProps>({
  className: nucleusClass('button-stack'),
})`
  display: grid;
  gap: ${SIZE[1]};
`;

export const Button = styled.button.attrs<BaseProps>({
  className: nucleusClass('button'),
})`
  padding: 0.6rem;
  font-size: 1.6rem;
  min-width: 14rem;
`;
