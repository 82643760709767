import React from 'react';

/** Represents a Trash Can Icon, used for Delete actions */
export const IconTrashCan = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 21" {...props}>
      <defs>
        <filter x="-14.4%" y="-24.6%" width="130.7%" height="152.5%" filterUnits="objectBoundingBox" id="a">
          <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(-168 -81.5)" fill="currentColor" fillRule="nonzero">
        <path d="m181.334 86.084-.52 14.28a.19.19 0 0 1-.185.176h-9.258a.194.194 0 0 1-.184-.176l-.521-14.28a.98.98 0 0 0-1.018-.943.98.98 0 0 0-.945 1.015l.52 14.28a2.156 2.156 0 0 0 2.148 2.064h9.258a2.154 2.154 0 0 0 2.148-2.064l.52-14.28a.98.98 0 0 0-.945-1.015.98.98 0 0 0-1.018.943Z" />
        <path d="M175.298 88.92v9.52a.702.702 0 0 0 1.404 0v-9.52a.702.702 0 0 0-1.404 0ZM172.07 88.94l.28 9.52a.703.703 0 0 0 1.404-.04l-.28-9.52a.703.703 0 0 0-1.404.04ZM178.526 88.9l-.28 9.52a.702.702 0 0 0 1.403.04l.28-9.52a.702.702 0 0 0-1.403-.04ZM168.982 86.4h14.036a.981.981 0 1 0 0-1.96h-14.036a.981.981 0 1 0 0 1.96Z" />
        <path d="m174.146 84.957.29-1.152c.04-.165.271-.345.44-.345h2.248c.169 0 .4.18.44.345l.29 1.152 1.906-.474-.288-1.153c-.26-1.037-1.277-1.83-2.348-1.83h-2.248c-1.07 0-2.088.793-2.347 1.83l-.289 1.153 1.906.474Z" />
      </g>
    </svg>
  );
};
