import { ProfileAttribute } from '@nucleus/types/user/attribute';
import { fetchWithAuth } from '@nucleus/web-theme/src/lib/fetch';
import { Auth } from 'aws-amplify';
import invariant from 'invariant';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthentication } from '../../hooks/useAuthentication';

export interface CurrentUser {
  attributes: ProfileAttribute[];
  email_verified: boolean;
  emails: {
    address: string;
    primary: boolean;
    verified: boolean;
  }[];
  features: string[];
  id: string;
  login?: string;
  profile: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    phone?: string;
    address_mailing?: string;
    profile_file_id?: string;
    profile_file_url?: string;
  };
  static_roles: any;
}

interface UserContext {
  currentUser?: CurrentUser;
  jwtToken?: string;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const Context = React.createContext<UserContext>(null!);

export const useUser = (): UserContext => {
  invariant(useContext(Context) !== null, 'useUser() may be used only in the context of a <UserProvider> object');
  return useContext(Context);
};

interface Props {
  children: React.ReactNode;
}

export const UserProvider = (props: Props): JSX.Element => {
  return <Context.Provider value={useUserContext()}>{props.children}</Context.Provider>;
};

const useUserContext = (): UserContext => {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [jwtToken, setJwtToken] = useState<string | undefined>();

  const [isAuthenticated] = useAuthentication();

  const fetchCurrentUser = async () => {
    try {
      const currentSession = await Auth.currentSession();
      setJwtToken(currentSession.idToken.jwtToken);
    } catch (error) {
      setCurrentUser(undefined);
      setJwtToken(undefined);
      return;
    }

    const response = await fetchWithAuth(`${process.env.REACT_APP_PLATFORM_API_BASE_URL}/platform/me`);
    setCurrentUser(await response.json());
  };

  useEffect(() => {
    fetchCurrentUser();
  }, [isAuthenticated]);

  return {
    currentUser: currentUser,
    jwtToken: jwtToken,
  };
};
