/* eslint-disable no-prototype-builtins */
import { objectKeys } from '@nucleus/src/lib/object';
import { isPrefixedKey } from './key';
import { pick as _pick } from 'lodash';

type AnyObject = Record<string | number | symbol, unknown>;
type Path = string;

const getObjectPathsRecursively = (obj: AnyObject, prefix = ''): string[] =>
  objectKeys(obj).reduce((acc: string[], key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      return [...acc, ...getObjectPathsRecursively(value as AnyObject, `${prefix}${String(key)}.`)];
    }

    return [...acc, `${prefix}${String(key)}`];
  }, []);

export const getObjectPaths = (source: any): string[] => getObjectPathsRecursively(source);

export const flattenObject = (input: Record<string | number | symbol, any>): Record<Path, string> => {
  const toReturn: Record<Path, any> = {};

  for (const property in input) {
    if (!input.hasOwnProperty(property)) {
      continue;
    }

    if (typeof input[property] == 'object' && input[property] !== null) {
      const flatObject = flattenObject(input[property]);
      for (const key in flatObject) {
        if (!flatObject.hasOwnProperty(key)) {
          continue;
        }

        toReturn[property + '.' + key] = flatObject[key];
      }
    } else {
      toReturn[property] = input[property];
    }
  }
  return toReturn;
};

export const getObjectPrefixedKeys = (source: any): Record<string, string> => {
  const flattened = flattenObject(source);

  const prefixedKeys = objectKeys(flattened).filter((key) => isPrefixedKey(undefined, flattened[key]) === true);

  return _pick(flattened, prefixedKeys);
};
