import hexToUuid from 'hex-to-uuid';
import { uuid4InHex, uuid5InHex } from './uuid';
import { yupKeyStringSchema, yupPrefixedKeySchema } from './yupValidation';
import { UnableToParseError } from './error';

const JOIN_ON = '_';

/**
 * createPrefixedKey creates a prefixed UUID Hex key joined on `_`
 * @param prefix prefix to append to key on generation
 */
export const createPrefixedKey = (prefix: string): string => {
  yupPrefixedKeySchema.validateSync(prefix);

  return [prefix, uuid4InHex()].join(JOIN_ON);
};

/**
 * createDeterministicPrefixedKey will generate a predictable uuid based on the namespace
 * @param prefix prefix to append to key on generation
 * @param name string used for predictable based on namespace
 * @param namespace uuid string for namespaced keys
 */
export const createDeterministicPrefixedKey = (prefix: string, name: string, namespace: string): string => {
  return [prefix, uuid5InHex(name, namespace)].join(JOIN_ON);
};

/**
 * isPrefixedKey will check if a value is prefixed with the provided key
 * @param prefix key you're trying to match against (undefined will only validate that it's prefixed but not against a value)
 * @param value string your validating is prefixed with a key
 */
export const isPrefixedKey = (prefix: string | undefined, value: string): boolean => {
  if (yupKeyStringSchema.required().isValidSync(value) === false) {
    return false;
  }

  let parts = value.split(JOIN_ON);
  if (parts.length != 2) {
    return false;
  }

  if (prefix !== undefined && parts[0] !== prefix) {
    return false;
  }

  return true;
};

/**
 * getKeyPrefix will get the prefix of the key provided
 * @param value
 * @returns value of prefix (undefined means it's not a prefixed key)
 */
export const getKeyPrefix = (value: string): string | undefined => {
  if (!isPrefixedKey(undefined, value)) {
    return;
  }

  return value.split(JOIN_ON)[0];
};

/**
 * getKeyValue will return the value of a prefixed key
 * @param value
 * @returns value of prefixed key (undefined means it's not a prefixed key)
 */
export const getKeyValue = (value: string): string | undefined => {
  if (isPrefixedKey(undefined, value) === false) {
    return;
  }

  return value.split(JOIN_ON)[1];
};

export const getKeyUuidValue = (value: string): string => {
  const hex = getKeyValue(value);

  if (hex === undefined) {
    throw new UnableToParseError();
  }

  return hexToUuid(hex);
};

/**
 * createPrefixedKeyFactory will return a function to generate prefixed keys
 * @param prefix
 */
export const createPrefixedKeyFactory = (prefix: string): (() => string) => {
  return () => createPrefixedKey(prefix);
};

/**
 * isPrefixedKeyFactory will return a function to check if keys are prefixed
 * @param prefix if Array is provided it will check that one of the keys isPrefixedKey
 */
export const isPrefixedKeyFactory = (prefix: string | string[]): ((value: string) => boolean) => {
  if (Array.isArray(prefix)) {
    return (value) => prefix.some((prefix) => isPrefixedKey(prefix, value));
  }

  return (value) => isPrefixedKey(prefix, value);
};
