import { CircleProfileImage } from '@nucleus/sermon-theme-elements';
import { formatSrcSet, nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { SectionBackground } from '../components/Background';
import { Body, Byline, Headline, Label, Labels, Overline } from '../components/Base';
import { InfoButtons } from '../components/InfoButtons';
import { SpacerTop } from '../sectionLayouts/Spacers';
import { BlockProps } from '../types/component';
import {
  InfoContent,
  InfoPosition,
  InfoSizeGroup,
  InfoSizePartition,
  SizingByElementAndWidth,
  SizingConfiguration,
} from './Base';

const DEFAULT_BODY_MAX_COLUMNS = 1;

type Block = Exclude<BlockProps['block'], undefined>;

export const blockInfoHasContent = (block: Block = {}): boolean => {
  const contentKeys: Array<keyof Block> = ['buttons', 'byline', 'headline', 'labels', 'overline', 'body'];

  return contentKeys.some((key) => {
    const item = block[key];
    if (item === undefined) {
      return false;
    }
    if (Array.isArray(item)) {
      return item.length > 0;
    }

    return true;
  });
};

export const BlockInfo = (props: BlockProps): JSX.Element => {
  const infoPosition = props.block?.infoPosition ?? props.blockInfoPosition;
  const headlineAlignment = props.block?.headlineAlignment ?? props.blockHeadlineAlignment;
  const headlineMaxWidth = props.block?.headlineMaxWidth ?? props.blockHeadlineMaxWidth ?? 'medium';
  const bodyAlignment = props.block?.bodyAlignment ?? props.blockBodyAlignment;
  const bodyMaxWidth = props.block?.bodyMaxWidth ?? props.blockBodyMaxWidth ?? headlineMaxWidth;
  const buttonsAlignment = props.block?.buttonsAlignment ?? props.blockButtonsAlignment;
  const buttonsMaxWidth = props.block?.buttonsMaxWidth ?? props.blockButtonsMaxWidth ?? headlineMaxWidth;
  const buttonsLayout = props.block?.buttonsLayout ?? props.blockButtonsLayout;
  const buttonsWidth = props.block?.buttonsWidth ?? props.blockButtonsWidth;

  const renderBackground = props.backgroundMedia !== undefined;
  const renderButtons = props.block?.buttons !== undefined && props.block.buttons.length > 0;
  const renderByline = props.block?.byline !== undefined;
  const renderHeadline = props.block?.headline !== undefined;
  const renderLabels = props.block?.labels !== undefined;
  const renderOverline = props.block?.overline !== undefined;
  const renderBody = props.block?.body !== undefined;

  const renderHeadlineElements =
    renderByline === true || renderHeadline === true || renderLabels === true || renderOverline === true;

  const allSizingData = [
    renderHeadlineElements && SizingByElementAndWidth.Headline[headlineMaxWidth],
    SizingByElementAndWidth.Body[bodyMaxWidth],
    renderButtons && SizingByElementAndWidth.Button[buttonsMaxWidth],
  ].filter((sizeData): sizeData is SizingConfiguration => sizeData !== false);

  // Get the max of all sizing config parts.
  const maxSizingConfiguration = allSizingData.reduce((acc, next) => {
    return {
      maxWidthFactor: Math.max(acc.maxWidthFactor, next.maxWidthFactor),
      maxWidthRems: Math.max(acc.maxWidthRems, next.maxWidthRems),
      minWidthRems: Math.max(acc.minWidthRems, next.minWidthRems),
    };
  });

  const bodyMaxColumns = props.block?.bodyMaxColumns ?? props.blockBodyMaxColumns ?? DEFAULT_BODY_MAX_COLUMNS;
  const bodySizing = SizingByElementAndWidth.Body[bodyMaxWidth];
  // If there are more than 1 column we need to let the columns dictate the absolute max width of the body.
  if (bodyMaxColumns > 1) {
    bodySizing.maxWidthRems = maxSizingConfiguration.maxWidthRems;
  }

  const isButtonsOnly = renderButtons === true && renderHeadlineElements !== true && renderBody !== true;

  return (
    <InfoContent
      className={classNames([props.className, nucleusClass('block-info')])}
      headlineAlignment={headlineAlignment}
      bodyAlignment={bodyAlignment}
    >
      {renderBackground && <SectionBackground background={props.backgroundMedia} />}
      <SpacerTop />
      <InfoPosition blockInfoPosition={infoPosition}>
        {isButtonsOnly !== true ? (
          <InfoSizePartition sizingConfiguration={maxSizingConfiguration}>
            {renderHeadlineElements && (
              <InfoSizeGroup
                alignment={headlineAlignment}
                parentSizingConfiguration={maxSizingConfiguration}
                sizingConfiguration={SizingByElementAndWidth.Headline[headlineMaxWidth]}
              >
                {props.block?.featuredMediaItem?.image?.src && (
                  <CircleProfileImage
                    style={{ height: '68px', width: '68px', marginBottom: '30px' }}
                    src={props.block.featuredMediaItem.image.src}
                    blurHash={props.block.featuredMediaItem.image.blurHash}
                    srcSet={formatSrcSet(props.block.featuredMediaItem.image.srcSet)}
                  />
                )}
                <Overline nodes={props.block?.overline} />
                <Headline nodes={props.block?.headline} />
                <Byline nodes={props.block?.byline} />
                {props.block?.labels && (
                  <Labels>
                    {props.block?.labels?.map((label, index) => <Label key={index}>{label.title}</Label>)}
                  </Labels>
                )}
              </InfoSizeGroup>
            )}

            {renderBody && (
              <InfoSizeGroup
                alignment={headlineAlignment}
                parentSizingConfiguration={maxSizingConfiguration}
                sizingConfiguration={bodySizing}
              >
                <Body nodes={props.block?.body} />
              </InfoSizeGroup>
            )}

            {renderButtons && (
              <InfoSizeGroup
                alignment={headlineAlignment}
                parentSizingConfiguration={maxSizingConfiguration}
                sizingConfiguration={SizingByElementAndWidth.Button[buttonsMaxWidth]}
              >
                <InfoButtons
                  buttons={props.block?.buttons}
                  buttonsLayout={buttonsLayout}
                  buttonsWidth={buttonsWidth}
                  buttonsMaxWidth={buttonsMaxWidth}
                  buttonsAlignment={buttonsAlignment}
                />
              </InfoSizeGroup>
            )}

            {props.children && (
              <InfoSizeGroup
                alignment={headlineAlignment}
                parentSizingConfiguration={maxSizingConfiguration}
                sizingConfiguration={bodySizing}
              >
                {props.children}
              </InfoSizeGroup>
            )}
          </InfoSizePartition>
        ) : (
          <InfoSizePartition>
            <InfoSizeGroup>
              <InfoButtons
                buttons={props.block?.buttons}
                buttonsLayout={buttonsLayout}
                buttonsWidth={buttonsWidth}
                buttonsMaxWidth={buttonsMaxWidth}
                buttonsAlignment={buttonsAlignment}
              />
            </InfoSizeGroup>

            {props.children && (
              <InfoSizeGroup
                alignment={headlineAlignment}
                parentSizingConfiguration={maxSizingConfiguration}
                sizingConfiguration={bodySizing}
              >
                {props.children}
              </InfoSizeGroup>
            )}
          </InfoSizePartition>
        )}
      </InfoPosition>
    </InfoContent>
  );
};
