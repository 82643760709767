import { CodeSnippetWeb } from '@nucleus/sites/src/types/CodeSnippet';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from './useSearchParams';

export const useEnabledCodeSnippets = (codeSnippets: Array<CodeSnippetWeb>): Array<CodeSnippetWeb> => {
  // disable snippets if debug param is set
  const [searchParams] = useSearchParams();
  const debug = searchParams['debug'];

  const isSnippetEnabled = useCallback(
    (snippet: CodeSnippetWeb) => {
      if (snippet.enabled !== true) {
        return false;
      }
      if (!debug) {
        return true;
      }
      const debugParam = CodeSnippetTypeToDebugParam[snippet.language] ?? 'disable-snippets';
      if (debug.includes(debugParam) || debug.includes('disable-snippets')) {
        return false;
      }
      if (debug.includes(`disable-snippet-` + snippet.id)) {
        return false;
      }
      return true;
    },
    [debug]
  );

  const enabledCodeSnippets = useMemo(() => codeSnippets.filter(isSnippetEnabled), [codeSnippets, isSnippetEnabled]);

  return enabledCodeSnippets;
};

const CodeSnippetTypeToDebugParam: Record<CodeSnippetWeb['language'], string> = {
  css: 'disable-css-snippets',
  javascript: 'disable-javascript-snippets',
  html: 'disable-html-snippets',
};
