import { ThemeColorPaletteBuilder } from '../ThemeConfigBuilder';
import { serviceName } from '../constants';

const builder = new ThemeColorPaletteBuilder({
  id: `${serviceName}_springInAlaska`,
  title: 'Spring in Alaska ❄️',
  description:
    'Like waking up in Fairbanks on a chilly morning, this palette is icy cool. Adjust the hues only for an easily tuned effect.',
  lightMode: {
    color1: '#FFFFFF',
    color2: '#E1F6FE',
    color3: '#F6FBFE',
    color4: '#EDF4F8',
    color5: '#FFFFFF',
    color6: '#B5D2D9',
    colorLight: '#FFFFFF',
    colorDark: '#274A72',
  },
});

export default builder.build();
