import { PresetMedia } from '@nucleus/types/web';

export const cardMediaInline: PresetMedia = {
  mediaOptions: {
    blending: {
      blendMode: 'none',
      fade: 'none',
    },
    image: {
      tile: 'none',
      scale: 'cover',
    },
    video: {
      image: {
        tile: 'none',
        scale: 'cover',
      },
    },
    useColor: false,
  },
};
