import React from 'react';
import { Navbar } from './Navbar';
import { NavList } from './NavList';
import { NavItemProps } from './NavItem';

interface Props extends Omit<React.HtmlHTMLAttributes<HTMLElement>, 'value' | 'onChange'> {
  children:
    | React.ReactNode
    | React.ReactElement<NavItemProps & { id: string }>
    | React.ReactElement<NavItemProps & { id: string }>[];
  value: string;
  onChange: (value: string) => void;
}

/**
 * Represents the TabBar component, a navigation bar for managing tabs.
 *
 * @component
 * @example
 * // Example usage of TabBar component:
 * <TabBar value="tab1" onChange={(value) => console.log(value)}>
 *   <NavItem id="tab1">Tab 1</NavItem>
 *   <NavItem id="tab2">Tab 2</NavItem>
 *   <NavItem id="tab3">Tab 3</NavItem>
 * </TabBar>
 */
export const TabBar = ({ children, value, onChange, ...props }: Props): JSX.Element => {
  return (
    <Navbar {...props}>
      <NavList>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return child;
          }
          return React.cloneElement<any>(child, {
            ['aria-controls']: `tabpanel-${child.props.id}`,
            className: value === child.props.id ? 'active' : undefined,
            onClick: () => onChange(child.props.id),
          });
        })}
      </NavList>
    </Navbar>
  );
};
