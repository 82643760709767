import React from 'react';
import { Alignment, InfoMaxWidth } from '@nucleus/types/web';
import styled, { css } from 'styled-components';
import { Body, Headline, Labels, Overline } from '../components/Base';
import { InfoButtons } from '../components/InfoButtons';
import { BlockProps } from '../types/component';
import { positionToFlexStyles, positionToPaddingStyles } from '@nucleus/web-theme-elements';

export type SizingConfiguration = {
  minWidthRems: number; // The min width, but it will never by MORE than 100%.
  maxWidthFactor: number; // Percentage of the parent containers width as a decimal.
  maxWidthRems: number; // The largest the item will ever get in absolute value.
};

type SizingByWidth = {
  [size in InfoMaxWidth]: SizingConfiguration;
};

type Element = 'Headline' | 'Body' | 'Button';

export const SizingByElementAndWidth: {
  [element in Element]: SizingByWidth;
} = {
  Headline: {
    [InfoMaxWidth.Small]: {
      minWidthRems: 24,
      maxWidthFactor: 0.45,
      maxWidthRems: 140,
    },
    [InfoMaxWidth.Medium]: {
      minWidthRems: 28,
      maxWidthFactor: 0.65,
      maxWidthRems: 160,
    },
    [InfoMaxWidth.Large]: {
      minWidthRems: 32,
      maxWidthFactor: 1,
      maxWidthRems: 180,
    },
  },
  Body: {
    [InfoMaxWidth.Small]: {
      minWidthRems: 24,
      maxWidthFactor: 0.4,
      maxWidthRems: 100,
    },
    [InfoMaxWidth.Medium]: {
      minWidthRems: 28,
      maxWidthFactor: 0.55,
      maxWidthRems: 100,
    },
    [InfoMaxWidth.Large]: {
      minWidthRems: 32,
      maxWidthFactor: 0.7,
      maxWidthRems: 100,
    },
  },
  Button: {
    [InfoMaxWidth.Small]: {
      minWidthRems: 24,
      maxWidthFactor: 0.4,
      maxWidthRems: 100,
    },
    [InfoMaxWidth.Medium]: {
      minWidthRems: 28,
      maxWidthFactor: 0.55,
      maxWidthRems: 100,
    },
    [InfoMaxWidth.Large]: {
      minWidthRems: 32,
      maxWidthFactor: 0.7,
      maxWidthRems: 100,
    },
  },
};

type InfoContentProps = {
  headlineAlignment?: Alignment;
  bodyAlignment?: Alignment;
};

export const InfoContent = styled.div<InfoContentProps>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  text-align: ${(props) => props.headlineAlignment ?? 'left'};

  ${Overline} {
    text-align: ${(props) => props.headlineAlignment ?? 'left'};
  }

  ${Headline} {
    text-align: ${(props) => props.headlineAlignment ?? 'left'};
  }

  ${Body} {
    margin-top: max(2rem, 1.5vw);
    text-align: ${(props) => props.bodyAlignment ?? 'left'};
    width: 100%;
  }

  ${InfoButtons} {
  }

  ${Labels} {
    font-size: 0.75em;
    display: flex;
  }
`;

type InfoSizePartitionProps = {
  sizingConfiguration?: SizingConfiguration;
};

export const InfoSizePartition = styled.div<InfoSizePartitionProps>`
  ${(props) => sizingConfigurationCss(props.sizingConfiguration)}

  width: 100%;
  z-index: 1;
`;

const InfoSizeGroupInner = styled.div``;

interface InfoSizeGroupOuterProps {
  sizingConfiguration?: SizingConfiguration;
  alignment?: Alignment;
  children?: React.ReactNode;
}

const InfoSizeGroupOuter = styled.div<InfoSizeGroupOuterProps>`
  ${(props) => positionToFlexStyles(props.alignment ?? 'left', true)};
  ${(props) => sizingConfigurationCss(props.sizingConfiguration)};

  width: 100%;
  display: inline-flex;
  flex-direction: column;

  ${InfoSizeGroupInner} {
    ${(props) => positionToFlexStyles(props.alignment ?? 'left', true)};

    width: 100%;
    display: inline-flex;
    flex-direction: column;
  }

  &:first-child > ${InfoSizeGroupInner} > :first-child > :first-child {
    margin-top: 0;
  }

  &:last-child > ${InfoSizeGroupInner} > :last-child > :last-child {
    margin-bottom: 0;
  }
`;

interface InfoSizeGroupProps {
  parentSizingConfiguration?: SizingConfiguration;
  sizingConfiguration?: SizingConfiguration;
  alignment?: Alignment;
  children?: React.ReactNode;
}

export const InfoSizeGroup = (props: InfoSizeGroupProps): JSX.Element => {
  let computedSizingConfiguration: SizingConfiguration | undefined;

  if (props.sizingConfiguration !== undefined) {
    computedSizingConfiguration = {
      ...props.sizingConfiguration,
    };
    if (props.parentSizingConfiguration !== undefined) {
      computedSizingConfiguration.maxWidthFactor =
        props.sizingConfiguration.maxWidthFactor / props.parentSizingConfiguration.maxWidthFactor;
    }
  }

  return (
    <InfoSizeGroupOuter alignment={props.alignment} sizingConfiguration={computedSizingConfiguration}>
      <InfoSizeGroupInner>{props.children}</InfoSizeGroupInner>
    </InfoSizeGroupOuter>
  );
};

const sizingConfigurationCss = (sizing?: SizingConfiguration): ReturnType<typeof css> => {
  if (sizing === undefined) {
    return css``;
  }

  return css`
    min-width: calc(min(${sizing.minWidthRems}rem, 100%));
    max-width: calc(min(${sizing.maxWidthFactor * 100}%, ${sizing.maxWidthRems}rem));
  `;
};

type InfoPositionProps = Pick<BlockProps, 'blockInfoPosition'>;

export const InfoPosition = styled.div<InfoPositionProps>`
  ${(props) => positionToFlexStyles(props.blockInfoPosition ?? 'center')};
  ${(props) => positionToPaddingStyles(props.blockInfoPosition ?? 'center')};

  flex: 1 1 auto;
  display: flex;
  width: 100%;
`;
