import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockSermon } from '../../blocks/BlockSermon';
import { BlockInfo, blockInfoHasContent } from '../../blocks/BlockInfo';
import { Body } from '../../components/Base';
import { SermonBlockLayoutProps } from './SermonLayout';
import { BlockLayoutProps } from '../../types/component';
import { css } from 'styled-components';

export const SermonBlockLayoutLongText = (props: SermonBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const bodyMaxColumns = props.blocks[0]?.bodyMaxColumns ?? props.blockBodyMaxColumns;

  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout {...props} bodyMaxColumns={bodyMaxColumns} renderInfo={renderInfo}>
      <StyledBlockInfo {...innerProps} block={props.blocks[0]} />
      <StyledBlockSermon {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const SermonHeightMap = {
  small: 1,
  medium: 2,
  large: 3,
};

const getSermonPadding = (props: BlockLayoutProps) => {
  const defaultHeight = 'small';
  const multiplier = SermonHeightMap[props.height ?? defaultHeight];

  return css`
    padding: calc(var(--unit-length) * ${multiplier}) var(--unit-length);
  `;
};

const FallbackMaxColumns = 1;

const StyledBlockSermon = styled(BlockSermon)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)<{
  bodyMaxColumns?: number;
  renderInfo: boolean;
}>`
  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${Body} {
    column-count: ${(props) => props.bodyMaxColumns ?? FallbackMaxColumns};
    column-width: max(calc(var(--unit-length) * 7), 300px);
    column-gap: var(--unit-length);
    width: 100%;
  }

  ${StyledBlockSermon} {
    ${getSermonPadding}
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};
  }
`;
