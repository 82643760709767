import { deepFreeze } from '@nucleus/src-platform/lib/deepFreeze';
import { keyBy as _keyBy } from 'lodash';
import { BibleBook } from '../types/book';

export const bibleBooks = deepFreeze([
  { id: '01', slug: 'genesis', label: 'Genesis', totalChapters: 50, abbreviation: 'GEN' },
  { id: '02', slug: 'exodus', label: 'Exodus', totalChapters: 40, abbreviation: 'EXO' },
  { id: '03', slug: 'leviticus', label: 'Leviticus', totalChapters: 27, abbreviation: 'LEV' },
  { id: '04', slug: 'numbers', label: 'Numbers', totalChapters: 36, abbreviation: 'NUM' },
  { id: '05', slug: 'deuteronomy', label: 'Deuteronomy', totalChapters: 34, abbreviation: 'DEU' },
  { id: '06', slug: 'joshua', label: 'Joshua', totalChapters: 24, abbreviation: 'JOS' },
  { id: '07', slug: 'judges', label: 'Judges', totalChapters: 21, abbreviation: 'JDG' },
  { id: '08', slug: 'ruth', label: 'Ruth', totalChapters: 4, abbreviation: 'RUT' },
  { id: '09', slug: '1-samuel', label: '1 Samuel', totalChapters: 31, abbreviation: '1SA' },
  { id: '10', slug: '2-samuel', label: '2 Samuel', totalChapters: 24, abbreviation: '2SA' },
  { id: '11', slug: '1-kings', label: '1 Kings', totalChapters: 22, abbreviation: '1KI' },
  { id: '12', slug: '2-kings', label: '2 Kings', totalChapters: 25, abbreviation: '2KI' },
  { id: '13', slug: '1-chronicles', label: '1 Chronicles', totalChapters: 29, abbreviation: '1CH' },
  { id: '14', slug: '2-chronicles', label: '2 Chronicles', totalChapters: 36, abbreviation: '2CH' },
  { id: '15', slug: 'ezra', label: 'Ezra', totalChapters: 10, abbreviation: 'EZR' },
  { id: '16', slug: 'nehemiah', label: 'Nehemiah', totalChapters: 13, abbreviation: 'NEH' },
  { id: '17', slug: 'esther', label: 'Esther', totalChapters: 10, abbreviation: 'EST' },
  { id: '18', slug: 'job', label: 'Job', totalChapters: 42, abbreviation: 'JOB' },
  { id: '19', slug: 'psalms', label: 'Psalms', totalChapters: 150, abbreviation: 'PSA' },
  { id: '20', slug: 'proverbs', label: 'Proverbs', totalChapters: 31, abbreviation: 'PRO' },
  { id: '21', slug: 'ecclesiastes', label: 'Ecclesiastes', totalChapters: 12, abbreviation: 'ECC' },
  { id: '22', slug: 'song-of-solomon', label: 'Song of Solomon', totalChapters: 8, abbreviation: 'SNG' },
  { id: '23', slug: 'isaiah', label: 'Isaiah', totalChapters: 66, abbreviation: 'ISA' },
  { id: '24', slug: 'jeremiah', label: 'Jeremiah', totalChapters: 52, abbreviation: 'JER' },
  { id: '25', slug: 'lamentations', label: 'Lamentations', totalChapters: 5, abbreviation: 'LAM' },
  { id: '26', slug: 'ezekiel', label: 'Ezekiel', totalChapters: 48, abbreviation: 'EZK' },
  { id: '27', slug: 'daniel', label: 'Daniel', totalChapters: 12, abbreviation: 'DAN' },
  { id: '28', slug: 'hosea', label: 'Hosea', totalChapters: 14, abbreviation: 'HOS' },
  { id: '29', slug: 'joel', label: 'Joel', totalChapters: 3, abbreviation: 'JOL' },
  { id: '30', slug: 'amos', label: 'Amos', totalChapters: 9, abbreviation: 'AMO' },
  { id: '31', slug: 'obadiah', label: 'Obadiah', totalChapters: 1, abbreviation: 'OBA' },
  { id: '32', slug: 'jonah', label: 'Jonah', totalChapters: 4, abbreviation: 'JON' },
  { id: '33', slug: 'micah', label: 'Micah', totalChapters: 7, abbreviation: 'MIC' },
  { id: '34', slug: 'nahum', label: 'Nahum', totalChapters: 3, abbreviation: 'NAM' },
  { id: '35', slug: 'habakkuk', label: 'Habakkuk', totalChapters: 3, abbreviation: 'HAB' },
  { id: '36', slug: 'zephaniah', label: 'Zephaniah', totalChapters: 3, abbreviation: 'ZEP' },
  { id: '37', slug: 'haggai', label: 'Haggai', totalChapters: 2, abbreviation: 'HAG' },
  { id: '38', slug: 'zechariah', label: 'Zechariah', totalChapters: 14, abbreviation: 'ZEC' },
  { id: '39', slug: 'malachi', label: 'Malachi', totalChapters: 4, abbreviation: 'MAL' },
  { id: '40', slug: 'matthew', label: 'Matthew', totalChapters: 28, abbreviation: 'MAT' },
  { id: '41', slug: 'mark', label: 'Mark', totalChapters: 16, abbreviation: 'MRK' },
  { id: '42', slug: 'luke', label: 'Luke', totalChapters: 24, abbreviation: 'LUK' },
  { id: '43', slug: 'john', label: 'John', totalChapters: 21, abbreviation: 'JHN' },
  { id: '44', slug: 'acts', label: 'Acts', totalChapters: 28, abbreviation: 'ACT' },
  { id: '45', slug: 'romans', label: 'Romans', totalChapters: 16, abbreviation: 'ROM' },
  { id: '46', slug: '1-corinthians', label: '1 Corinthians', totalChapters: 16, abbreviation: '1CO' },
  { id: '47', slug: '2-corinthians', label: '2 Corinthians', totalChapters: 13, abbreviation: '2CO' },
  { id: '48', slug: 'galatians', label: 'Galatians', totalChapters: 6, abbreviation: 'GAL' },
  { id: '49', slug: 'ephesians', label: 'Ephesians', totalChapters: 6, abbreviation: 'EPH' },
  { id: '50', slug: 'philippians', label: 'Philippians', totalChapters: 4, abbreviation: 'PHP' },
  { id: '51', slug: 'colossians', label: 'Colossians', totalChapters: 4, abbreviation: 'COL' },
  { id: '52', slug: '1-thessalonians', label: '1 Thessalonians', totalChapters: 5, abbreviation: '1TH' },
  { id: '53', slug: '2-thessalonians', label: '2 Thessalonians', totalChapters: 3, abbreviation: '2TH' },
  { id: '54', slug: '1-timothy', label: '1 Timothy', totalChapters: 6, abbreviation: '1TI' },
  { id: '55', slug: '2-timothy', label: '2 Timothy', totalChapters: 4, abbreviation: '2TI' },
  { id: '56', slug: 'titus', label: 'Titus', totalChapters: 3, abbreviation: 'TIT' },
  { id: '57', slug: 'philemon', label: 'Philemon', totalChapters: 1, abbreviation: 'PHM' },
  { id: '58', slug: 'hebrews', label: 'Hebrews', totalChapters: 13, abbreviation: 'HEB' },
  { id: '59', slug: 'james', label: 'James', totalChapters: 5, abbreviation: 'JAS' },
  { id: '60', slug: '1-peter', label: '1 Peter', totalChapters: 5, abbreviation: '1PE' },
  { id: '61', slug: '2-peter', label: '2 Peter', totalChapters: 3, abbreviation: '2PE' },
  { id: '62', slug: '1-john', label: '1 John', totalChapters: 5, abbreviation: '1JN' },
  { id: '63', slug: '2-john', label: '2 John', totalChapters: 1, abbreviation: '2JN' },
  { id: '64', slug: '3-john', label: '3 John', totalChapters: 1, abbreviation: '3JN' },
  { id: '65', slug: 'jude', label: 'Jude', totalChapters: 1, abbreviation: 'JUD' },
  { id: '66', slug: 'revelation', label: 'Revelation', totalChapters: 22, abbreviation: 'REV' },
] as const satisfies ReadonlyArray<BibleBook>);

const bibleBooksById = _keyBy(bibleBooks, 'id');

export const getBibleBookById = (id: string): BibleBook | undefined => bibleBooksById[id];
