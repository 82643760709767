import { CollectionListDataSource, SermonBlockWeb } from '@nucleus/types/web';
import { ListCollectionsWebEndpoint } from '@nucleus/web-hosting';
import invariant from 'invariant';
import { flatMap as _flatMap, pick as _pick } from 'lodash';
import React, { useContext } from 'react';
import { useCollectionPath } from '../hooks/useCollectionPath';
import { useCollections } from '../hooks/useCollections';

type UseCollections = ReturnType<typeof useCollections>;

type Context = Pick<UseCollections, 'status' | 'hasNextPage' | 'fetchNextPage'> & {
  collections: any[];
  collectionType?: 'playlists' | 'tags' | 'speakers' | 'books';
  getCollectionPath: (collection: any) => string;
};

const Context = React.createContext<Context>(null!);
Context.displayName = 'CollectionListController';

type Props = { children?: React.ReactNode } & Options;

export const CollectionListController = ({ children, ...props }: Props): JSX.Element => {
  return <Context.Provider value={useController(props)}>{children}</Context.Provider>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCollectionListController = () => {
  invariant(
    useContext(Context) !== null,
    'useCollectionListController() may be used only in the context of a <CollectionListController> object'
  );
  return useContext(Context);
};

type Options = {
  engineId: string;
  basePath: string;
  collection?: 'playlists' | 'tags' | 'speakers' | 'books';
  params?: ListCollectionsWebEndpoint<any>['queryStringParameters'];
};

const useController = ({ basePath, ...options }: Options): Context => {
  const { data, status, hasNextPage, fetchNextPage } = useCollections(options.engineId, options.collection, {
    limit: '5',
    ...options.params,
  });

  const getCollectionPath = useCollectionPath(basePath);

  return {
    collections: _flatMap(data?.pages, 'collections'),
    collectionType: options.collection,
    status: status,
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage,
    getCollectionPath: getCollectionPath,
  };
};

const getQueryParams = (dataSource: SermonBlockWeb['dataSource']) => {
  const safeProps = _pick(dataSource, 'limit', 'order', 'orderBy');

  const paramEntries = Object.entries(safeProps).map(([key, value]) => [key, String(value)]);

  return Object.fromEntries(paramEntries);
};

export const useSermonBlockDataSourceCollectionListParams = ({
  dataSource,
  items,
}: SermonBlockWeb): Pick<Options, 'collection' | 'params'> | undefined => {
  if (!isCollectionDataSource(dataSource)) {
    return;
  }

  const baseParams = getQueryParams(dataSource);

  const ItemTypeCollectionMap = {
    playlist: 'playlists',
    speaker: 'speakers',
    tag: 'tags',
    bibleReference: 'books',
  } satisfies Record<string, 'playlists' | 'tags' | 'speakers' | 'books'>;

  if (dataSource.type === 'static' && items && items.length > 0) {
    return {
      collection: ItemTypeCollectionMap[dataSource.itemType],
      params: {
        ...baseParams,
        ids: items.map((item) => item.sourceId).join(','),
      },
    };
  }

  return {
    collection: ItemTypeCollectionMap[dataSource.itemType],
    params: baseParams,
  };
};

export const isCollectionDataSource = (dataSource: any): dataSource is CollectionListDataSource => {
  return ['playlist', 'speaker', 'tag', 'bibleReference'].includes(dataSource?.itemType);
};
