import { merge as _merge } from 'lodash';
import React, { useContext } from 'react';
import { ThemeContext, ThemeContextValue } from './ThemeContext';

interface ThemeProviderProps {
  children: React.ReactNode;
  value?: Record<string, any>;
}
export const ThemeProvider = (props: ThemeProviderProps): JSX.Element => {
  let theme = useContext<ThemeContextValue>(ThemeContext);

  const context: ThemeContextValue = _merge({}, theme, props.value);

  return <ThemeContext.Provider value={context}>{props.children}</ThemeContext.Provider>;
};
