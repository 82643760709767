import { createCompositeString } from '@nucleus/lib-shape';
import classnames from 'classnames';
import { omit as _omit } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useMountSafeState } from '../../../../hooks/useMountSafe';
import { useOnOutsideClick } from '../../../../hooks/useOnOutsideClick';
import { nucleusClass } from '../../../../lib/class';
import { DropdownMenu, DropdownMenuProps } from './DropdownMenu';
import { IconContainer } from './Icons';
import { Text } from './Text';
import { buildTransparentColorFromCssVariable } from './src/utils/styles';
import { media } from './style-utils';

const ButtonDisplay = styled.div``;

const ButtonIcon = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
`;

// TODO: Add responsive sizing
const SMALL_HEIGHT = 3.6;
const MEDIUM_HEIGHT = 5;
const LARGE_HEIGHT = 6.8;
const XLARGE_HEIGHT = 8.2;

const ButtonSize = {
  small: css`
    min-height: ${SMALL_HEIGHT}rem;
  `,
  medium: css`
    min-height: ${MEDIUM_HEIGHT}rem;
  `,
  large: css`
    min-height: ${LARGE_HEIGHT}rem;
  `,
  xlarge: css`
    min-height: ${XLARGE_HEIGHT}rem;
  `,
};

const ButtonPadding = {
  small: css`
    padding: 0.6rem 1.2rem;
  `,
  medium: css`
    padding: 0.6rem 1.8rem;
  `,
  large: css`
    padding: 0.6rem 2.4rem;
  `,
  xlarge: css`
    padding: 0.6rem 3.6rem;
  `,
};

const ButtonIconSize = {
  small: css`
    ${IconContainer} {
      width: ${SMALL_HEIGHT / 2.4}rem;
      height: ${SMALL_HEIGHT / 2.4}rem;
    }
  `,
  medium: css`
    ${IconContainer} {
      width: ${MEDIUM_HEIGHT / 2.4}rem;
      height: ${MEDIUM_HEIGHT / 2.4}rem;
    }
  `,
  large: css`
    ${IconContainer} {
      width: ${LARGE_HEIGHT / 2.4}rem;
      height: ${LARGE_HEIGHT / 2.4}rem;
    }
  `,
  xlarge: css`
    ${IconContainer} {
      width: ${XLARGE_HEIGHT / 2.4}rem;
      height: ${XLARGE_HEIGHT / 2.4}rem;
    }
  `,
};

const ButtonWidthByMode = {
  auto: css`
    width: auto;
    max-width: 100%;
  `,
  full: css`
    width: 100%;
    max-width: 100%;
  `,
  fullAuto: css`
    width: 100%;
    max-width: 100%;

    ${media.tabletPortraitAndUp`
      width: auto;
    `}
  `,
};

const ButtonShape = {
  square: css<{ size?: 'small' | 'medium' | 'xlarge' | 'large' }>`
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            width: ${SMALL_HEIGHT}rem;
            padding: 0;
          `;
        case 'medium':
          return css`
            width: ${MEDIUM_HEIGHT}rem;
            padding: 0;
          `;
        case 'xlarge':
          return css`
            width: ${XLARGE_HEIGHT}rem;
            padding: 0;
          `;
        case 'large':
        default:
          return css`
            width: ${LARGE_HEIGHT}rem;
            padding: 0;
          `;
      }
    }}
  `,
  rectangle: css``,
};

const ButtonRounding = {
  small: css`
    border-radius: 0.6rem;
  `,
  medium: css`
    border-radius: 1rem;
  `,
  large: css<{ size?: 'small' | 'medium' | 'xlarge' | 'large' }>`
    // pill
    ${(props) => {
      switch (props.size) {
        case 'small':
          return css`
            border-radius: ${SMALL_HEIGHT / 2}rem;
          `;
        case 'medium':
          return css`
            border-radius: ${MEDIUM_HEIGHT / 2}rem;
          `;
        case 'xlarge':
          return css`
            border-radius: ${XLARGE_HEIGHT / 2}rem;
          `;
        case 'large':
        default:
          return css`
            border-radius: ${LARGE_HEIGHT / 2}rem;
          `;
      }
    }}
  `,
  none: css`
    border-radius: none;
  `,
};

type ButtonCssProps = {
  rounding?: 'small' | 'medium' | 'large' | 'none';
  shape?: 'square' | 'rectangle';
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  widthMode?: 'auto' | 'full' | 'fullAuto';
};

// Buttons
const ButtonCss = css<ButtonCssProps>`
  display: inline;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  padding: 0;

  ${(props) => ButtonWidthByMode[props.widthMode as keyof typeof ButtonWidthByMode]}
  ${(props) => ButtonSize[props.size as keyof typeof ButtonSize]}
  ${(props) => ButtonShape[props.shape as keyof typeof ButtonShape]}
  ${(props) => ButtonRounding[props.rounding as keyof typeof ButtonRounding]}
  ${(props) => ButtonIconSize[props.size as keyof typeof ButtonIconSize]}


  ${ButtonDisplay} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0.6rem 1.4em;
    ${(props) => ButtonSize[props.size as keyof typeof ButtonSize]}
    ${(props) => ButtonPadding[props.size as keyof typeof ButtonPadding]}
    ${(props) => ButtonShape[props.shape as keyof typeof ButtonShape]}
    background-color: transparent;
    border: none;
    border-radius: inherit;
    outline: none;
    overflow: hidden;
    text-decoration: none !important;
    cursor: pointer;
  }
`;

const prefixClassName = (...classes: any[]) =>
  classes.map((className) => createCompositeString(['button', className], '-'));

const BaseButton = styled.button
  .withConfig({
    // Requires styled-components@^5.1
    shouldForwardProp: (prop, defaultValidatorFn) => {
      return !['rounding', 'shape', 'size', 'widthMode'].includes(prop) && defaultValidatorFn(prop);
    },
  })
  .attrs<ButtonCssProps>((props) => ({
    onTouchStart: () => true,
    className: classnames(nucleusClass(['button', ...prefixClassName(props.size, props.shape, props.widthMode)])),
  }))<ButtonCssProps>`
  ${ButtonCss}
`;

BaseButton.defaultProps = {
  size: 'large',
  shape: 'rectangle',
  rounding: 'medium',
  widthMode: 'auto',
};

const ButtonContent = styled.span`
  position: relative;
  pointer-events: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
`;

const cornerStyleMap = {
  cornerStyle1: { rounding: 'none' },
  cornerStyle2: { rounding: 'medium' },
  cornerStyle3: { rounding: 'large' },
};

const ButtonContainer = styled.div`
  position: relative;
`;

type DropdownButtonProps = {
  alignDropdown?: DropdownMenuProps['align'];
  as?: string | React.ComponentType<any>;
  dropdownIndicatorPosition?: 'left';
  dropdownItems?: DropdownMenuProps['items'];
  dropdown?: React.ReactNode;
  dropdownMenuClassName?: string;
  icon?: React.ReactElement;
  openOnHover?: boolean;
} & ButtonBaseProps;

const DropdownButton = styled((props: DropdownButtonProps) => {
  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useMountSafeState(false);

  const openDropdown = useCallback(() => setIsOpen(true), []);
  const closeDropdown = useCallback(() => setIsOpen(false), []);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!isOpen) {
        e.preventDefault();
        e.stopPropagation();
        openDropdown();
        return;
      }

      e.stopPropagation();
      closeDropdown();
      if (typeof props.onClick === 'function') {
        props.onClick(e);
      }
    },
    [isOpen]
  );

  const handleMouseEnter = useCallback(() => props.openOnHover && openDropdown(), []);
  const handleMouseLeave = useCallback(() => props.openOnHover && closeDropdown(), []);

  useOnOutsideClick([containerRef], closeDropdown, isOpen);

  const otherProps = _omit(props, [
    'as',
    'alignDropdown',
    'children',
    'className',
    'dropdownItems',
    'onClick',
    'style',
  ]);

  // We need to add the dropdown indicator props to the button base.
  const dropdownIndicatorProps: Partial<ButtonBaseProps> = {};
  // If the user has passed an icon, we don't want to overlap it with the dropdown indicator.
  if (props.icon === undefined) {
    const indicatorIconProp = props.dropdownIndicatorPosition === 'left' ? 'iconLeft' : 'iconRight';
    dropdownIndicatorProps[indicatorIconProp] = <DropdownMenu.Indicator isOpen={isOpen} />;
  } else {
    dropdownIndicatorProps.icon = React.cloneElement(props.icon, { isOpen: isOpen });
  }

  return (
    <ButtonContainer onMouseLeave={handleMouseLeave} ref={containerRef}>
      <ButtonBase
        forwardedAs={props.as}
        style={props.style}
        className={classnames(props.className, { activated: isOpen })}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        {...otherProps}
        {...dropdownIndicatorProps}
      >
        {props.children}
      </ButtonBase>
      <DropdownMenu
        className={props.dropdownMenuClassName}
        isOpen={isOpen}
        items={props.dropdownItems}
        align={props.alignDropdown}
        onClose={closeDropdown}
        parentRef={containerRef}
      >
        {props.dropdown}
      </DropdownMenu>
    </ButtonContainer>
  );
})``;

type ButtonProps = {
  as?: string | React.ComponentType<any>;
} & ButtonBaseProps &
  DropdownButtonProps;

const Button = styled((props: ButtonProps) => {
  const hasDropdown =
    props.dropdown !== undefined || (props.dropdownItems !== undefined && props.dropdownItems.length > 0);

  const { as, ...rest } = props;

  if (hasDropdown) {
    return <DropdownButton {...rest} forwardedAs={as} />;
  }

  return <ButtonBase {...rest} forwardedAs={as} />;
})``;

const Label = styled.span`
  display: inline-block;
  flex-shrink: 1;
  min-width: 0;
`;

const IconRight = styled.span`
  flex-shrink: 0;
  margin-left: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconLeft = styled.span`
  flex-shrink: 0;
  margin-right: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ButtonBaseProps = {
  icon?: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  cornerStyle?: 'cornerStyle1' | 'cornerStyle2' | 'cornerStyle3' | 'cornerStyle4';
  to?: any;
  as?: string | React.ComponentType<any>;
  href?: any;
  children?: React.ReactNode;
  loading?: boolean;
  withHoverEffect?: boolean;
  noShadow?: boolean;
} & ButtonCssProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonBase = styled((props: ButtonBaseProps) => {
  let buttonProps = _omit(props, ['children', 'loading']);

  const showIcon = props.icon !== undefined;
  const showIconLeft = props.iconLeft !== undefined;
  const showIconRight = props.iconRight !== undefined;

  if (buttonProps.to !== undefined) {
    buttonProps.as = Link;
  }

  if (buttonProps.href !== undefined) {
    buttonProps.as = 'a';
  }

  const baseProps = { ...buttonProps, ...(cornerStyleMap[props.cornerStyle as keyof typeof cornerStyleMap] ?? {}) };

  // TODO: add support for loading spinners
  return (
    <BaseButton {...baseProps}>
      <ButtonDisplay>
        <ButtonContent>
          {showIcon && <ButtonIcon>{props.icon}</ButtonIcon>}
          {showIconLeft && <IconLeft>{props.iconLeft}</IconLeft>}
          {props.children && <Label>{props.children}</Label>}
          {showIconRight && <IconRight>{props.iconRight}</IconRight>}
        </ButtonContent>
      </ButtonDisplay>
    </BaseButton>
  );
})``;

ButtonBase.defaultProps = {
  loading: false,
  withHoverEffect: false,
  size: 'large',
  shape: 'rectangle',
  rounding: 'medium',
  widthMode: 'auto',
  noShadow: false,
};

const ButtonPrimary = styled(Button).attrs({
  className: classnames(Text.ClassName['button-1'], nucleusClass('button-primary')),
})`
  ${ButtonDisplay} {
    background-color: var(--color-button-primary-background);
    color: var(--color-button-primary-text);
  }

  &:hover {
    ${ButtonDisplay} {
      background-color: var(--color-button-primary-background-hover);
    }
  }
`;

ButtonPrimary.defaultProps = {
  withHoverEffect: true,
};

const ButtonSecondary = styled(Button).attrs({
  className: classnames(Text.ClassName['button-2'], nucleusClass('button-secondary')),
})`
  ${ButtonDisplay} {
    background-color: none;
    color: var(--color-button-secondary-text);
    border: 2px solid var(--color-button-secondary-text);
  }

  &:hover {
    ${ButtonDisplay} {
      background-color: ${buildTransparentColorFromCssVariable('--color-button-secondary-text-hover', 0.1)};
      color: var(--color-button-secondary-text-hover);
      border: 2px solid var(--color-button-secondary-text-hover);
    }
  }
`;

const ButtonTertiary = styled(Button).attrs({ className: Text.ClassName['button-3'] })`
  ${ButtonDisplay} {
    background-color: var(--color-button-tertiary-background);
    color: var(--color-button-tertiary-text);
    border: 2px solid var(--color-button-tertiary-text);
  }

  &:hover {
    ${ButtonDisplay} {
      background-color: var(--color-button-tertiary-background-hover);
      border: 2px solid var(--color-button-tertiary-text-hover);
    }
  }
`;

const ButtonSecondaryAlt = styled(Button).attrs({
  className: nucleusClass('button-secondary-alt'),
})`
  ${ButtonDisplay} {
    background-color: ${buildTransparentColorFromCssVariable('--color-button-secondary-alt-background', 0.08)};
    color: var(--color-button-secondary-alt-text);
  }

  &:hover {
    ${ButtonDisplay} {
      background-color: ${buildTransparentColorFromCssVariable('--color-button-secondary-alt-background', 0.12)};
    }
  }

  &:active {
    ${ButtonDisplay} {
      background-color: ${buildTransparentColorFromCssVariable('--color-button-secondary-alt-background', 0.18)};
    }
  }
`;

const ButtonTextLink = styled(Button).attrs({
  className: classnames(Text.ClassName['button-4'], nucleusClass('button-text-link')),
})`
  background-color: transparent;
  appearance: none;
  border: 0;
  outline: none;
  color: inherit;
  padding: 0;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: underline;

  ${Label} {
    text-decoration: underline;
  }
`;

const ButtonNavPrimary = styled(Button).attrs({
  className: classnames(Text.ClassName['navigation4'], nucleusClass('button-nav-primary')),
  size: 'medium',
})`
  ${ButtonDisplay} {
    background-color: var(--color-button-primary-background);
    color: var(--color-button-primary-text);
    white-space: nowrap;
  }

  &:hover {
    ${ButtonDisplay} {
      background-color: var(--color-button-primary-background-hover);
    }
  }

  ${DropdownMenu.Indicator} {
    width: 0.75em;
  }
`;

const ButtonNavSecondary = styled(Button).attrs({
  className: classnames(Text.ClassName['navigation4'], nucleusClass('button-nav-secondary')),
  dropdownMenuClassName: Text.ClassName['navigation4'],
  size: 'medium',
})`
  ${ButtonDisplay} {
    background-color: none;
    color: var(--color-section-text);
    white-space: nowrap;
  }

  &:hover {
    ${ButtonDisplay} {
      background-color: ${buildTransparentColorFromCssVariable('--color-section-text', 0.1)};
      color: var(--color-section-text);
    }
  }

  ${DropdownMenu.Indicator} {
    width: 0.75em;
  }
`;

const ButtonNavTertiary = styled(Button).attrs({
  className: classnames(Text.ClassName['navigation4'], nucleusClass('button-nav-secondary')),
  dropdownMenuClassName: Text.ClassName['navigation4'],
  size: 'medium',
})`
  ${ButtonDisplay} {
    background-color: none;
    color: var(--color-section-text);
    border: 2px solid var(--color-section-text);
    white-space: nowrap;
  }

  &:hover {
    ${ButtonDisplay} {
      background-color: ${buildTransparentColorFromCssVariable('--color-section-text', 0.1)};
      color: var(--color-section-text);
      border: 2px solid var(--color-section-text);
    }
  }

  ${DropdownMenu.Indicator} {
    width: 0.75em;
  }
`;

export const ThemeLifeV1Button = {
  Alternate: ButtonTextLink,
  Primary: ButtonPrimary,
  Secondary: ButtonSecondary,
  SecondaryAlt: ButtonSecondaryAlt,
  Tertiary: ButtonTertiary,
  NavPrimary: ButtonNavPrimary,
  NavSecondary: ButtonNavSecondary,
  NavTertiary: ButtonNavTertiary,
};
