import { ThemeColorPaletteBuilder } from '../ThemeConfigBuilder';
import { serviceName } from '../constants';

const builder = new ThemeColorPaletteBuilder({
  id: `${serviceName}_longview`,
  title: 'Longview 🌲',
  description:
    'Earthy and grounded, this palette pulls in a tonal, darker muted grayscale approach with a pop of color that draws your eye.',
  lightMode: {
    color1: '#4D4D4D',
    color2: '#303030',
    color3: '#1C1C1C',
    color4: '#EEEEEE',
    color5: '#1C1C1C',
    color6: '#1AB159',
    colorLight: '#FFFFFF',
    colorDark: '#1C1C1C',
  },
});

export default builder.build();
