import { ContentRenderer, registry, ThemeProvider, WebTheme } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import React from 'react';
import { LifeV1ThemeConfig } from '../config/ThemeConfig';
import { LoadingSpinner } from './components/LoadingSpinner';
import { PageGlobalHtml } from './components/PageGlobalHtml';
import { PageGlobalScripts } from './components/PageGlobalScripts';
import { PageGlobalStyle } from './components/PageGlobalStyle';
import { PageLoading } from './components/PageLoading';
import { PageMetaData } from './components/PageMetaData';
import { StyledThemeProvider } from './components/StyledThemeProvider';
import { ThemeGlobalStyle } from './components/ThemeGlobalStyle';
import { RichTextProvider } from './contexts/RichTextContext';
import { sectionRenderErrorFallback } from './fallbacks';
import { useScrollToTop } from './hooks/useScrollToTop';
import { CalendarSection } from './sections/Calendar/CalendarSection';
import { CardSection } from './sections/Card/CardSection';
import { EmbedSection } from './sections/Embed/EmbedSection';
import { FooterSection } from './sections/Footer/FooterSection';
import { GallerySection } from './sections/Gallery/GallerySection';
import { HeaderSection } from './sections/Header/HeaderSection';
import { InfoSection } from './sections/Info/InfoSection';
import { ListSection } from './sections/List/ListSection';
import { SermonSection } from './sections/Sermon/SermonSection';
import { SermonMediaSection } from './sections/SermonMedia/SermonMediaSection';
import { SermonSearchRequestSection } from './sections/SermonSearchRequest/SermonSearchRequestSection';
import { SermonSearchResultsSection } from './sections/SermonSearchResultsSection.tsx/SermonSearchResultsSection';
import { fallbackFontPack } from './theme/fonts';

const Registry = registry(
  [
    ['calendar', CalendarSection],
    ['card', CardSection],
    ['embed', EmbedSection],
    ['footer', FooterSection],
    ['gallery', GallerySection],
    ['header', HeaderSection],
    ['info', InfoSection],
    ['leader', CardSection],
    ['list', ListSection],
    ['loading', LoadingSpinner],
    ['sermon', SermonSection],
    ['sermonMedia', SermonMediaSection],
    ['sermonSearchRequest', SermonSearchRequestSection],
    ['sermonSearchResults', SermonSearchResultsSection],
  ],
  {
    sectionRenderErrorData: sectionRenderErrorFallback,
  }
);

export const App = (): JSX.Element => {
  useScrollToTop();
  const color = LifeV1ThemeConfig().colorPalettes[0];

  const themeContext: Parameters<typeof ThemeProvider>[0]['value'] = {
    colors: color,
    fonts: fallbackFontPack,
  };

  return (
    <ThemeProvider value={themeContext}>
      <WebTheme>
        <ThemeGlobalStyle />
        <StyledThemeProvider>
          <Registry.Provider>
            <RichTextProvider>
              <div className={nucleusClass('page')}>
                <PageLoading>
                  <PageGlobalStyle />
                  <PageGlobalScripts />
                  <PageGlobalHtml />
                  <PageMetaData />
                  <ContentRenderer contentArea="main" />
                </PageLoading>
              </div>
            </RichTextProvider>
          </Registry.Provider>
        </StyledThemeProvider>
      </WebTheme>
    </ThemeProvider>
  );
};

export default App;
