import { isNonNullable } from '@nucleus/lib-shape';
import { ListBlockWeb } from '@nucleus/types/web';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { AccordionList } from '../components/AccordionList';
import { StandardList } from '../components/StandardList';
import { BlockProps } from '../types/component';

type BlockListGridProps = Extract<BlockProps, { block?: ListBlockWeb; sectionType?: 'list' }>;

export const BlockListGrid = (props: BlockListGridProps): JSX.Element => {
  return (
    <ListGrid
      className={classNames([
        props.className,
        nucleusClass('block-list'),
        nucleusClass(props.block?.listType ?? 'stacked'),
      ])}
    >
      <ListTypeComponent {...props} />
    </ListGrid>
  );
};

const ListGrid = styled.div``;

const ListTypeComponent = (props: BlockListGridProps) => {
  const items = (props.block?.items ?? []).filter(isNonNullable);

  switch (props.block?.listType) {
    case 'accordion':
      return <AccordionList {...props.block} items={items} />;
    case 'stacked':
      return <StandardList {...props.block} items={items} />;
    default:
      return <StandardList {...props.block} items={items} />;
  }
};
