import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { Suspended } from './components/Suspended';
import { SermonPage } from './routes/SermonPage';

export const SermonRoutes = (): JSX.Element | null => {
  const routeObjects: RouteObject[] = [
    {
      path: '*',
      index: true,
      element: <SermonPage />,
    },
  ];

  return useRoutes(routeObjects.map((route) => ({ ...route, element: <Suspended>{route.element}</Suspended> })));
};
