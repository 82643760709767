import { useIsomorphicLayoutEffect } from '@nucleus/react-components';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

export const PageMetaData = (): JSX.Element => {
  return (
    <Helmet>
      <meta name="theme-color" content={useFirstSectionBackgroundColor()} />
    </Helmet>
  );
};

const useFirstSectionBackgroundColor = () => {
  const [state, setState] = useState<string>();

  useIsomorphicLayoutEffect(() => {
    setState(getFirstSectionBackgroundColor());
  }, []);

  return state;
};

const getFirstSectionBackgroundColor = () => {
  const element = document.querySelector('.background');

  if (element === null) {
    return;
  }

  return window.getComputedStyle(element).getPropertyValue('--color-section-background');
};
