import { deepFreeze } from '@nucleus/src-platform/lib/deepFreeze';
import { BibleVersion } from '../types/version';

export const bibleVersions = deepFreeze([
  { id: 'amp', label: 'Amplified Bible', youVersionId: '1588', abbreviation: 'AMP' },
  { id: 'ampc', label: 'Amplified Bible, Classic Edition', youVersionId: '8', abbreviation: 'AMPC' },
  { id: 'asv', label: 'American Standard Version', youVersionId: '12', abbreviation: 'ASV' },
  { id: 'books', label: 'The Books of the Bible NT', youVersionId: '31', abbreviation: 'BOOKS' },
  { id: 'ceb', label: 'Common English Bible', youVersionId: '37', abbreviation: 'CEB' },
  { id: 'cev', label: 'Contemporary English Version', youVersionId: '392', abbreviation: 'CEV' },
  { id: 'cevdc', label: 'Contemporary English Version (Anglicised)', youVersionId: '294', abbreviation: 'CEVDC' },
  {
    id: 'cevuk',
    label: 'Contemporary English Version (British Edition) 2012',
    youVersionId: '303',
    abbreviation: 'CEVUK',
  },
  { id: 'cjb', label: 'Complete Jewish Bible', youVersionId: '1275', abbreviation: 'CJB' },
  { id: 'cpdv', label: 'Catholic Public Domain Version', youVersionId: '42', abbreviation: 'CPDV' },
  { id: 'csb', label: 'Christian Standard Bible', youVersionId: '1713', abbreviation: 'CSB' },
  { id: 'darby', label: 'Darby Translation 1890', youVersionId: '478', abbreviation: 'DARBY' },
  { id: 'drc1752', label: 'Douay-Rheims Challoner Revision 1752', youVersionId: '55', abbreviation: 'DRC1752' },
  { id: 'erv', label: 'Holy Bible: Easy-to-Read Version', youVersionId: '406', abbreviation: 'ERV' },
  { id: 'esv', label: 'English Standard Version', youVersionId: '59', abbreviation: 'ESV' },
  { id: 'fbv', label: 'Free Bible Version', youVersionId: '1932', abbreviation: 'FBV' },
  { id: 'gnb', label: 'Good News Bible', youVersionId: '431', abbreviation: 'GNB' },
  { id: 'gnbdc', label: 'Good News Bible (Anglicised)', youVersionId: '296', abbreviation: 'GNBDC' },
  {
    id: 'gnbdk',
    label: 'Good News Bible (Catholic edition in Septuagint order)',
    youVersionId: '416',
    abbreviation: 'GNBDK',
  },
  { id: 'gnt', label: 'Good News Translation', youVersionId: '69', abbreviation: 'GNT' },
  { id: 'gntd', label: 'Good News Translation (US Version)', youVersionId: '68', abbreviation: 'GNTD' },
  { id: 'gw', label: "GOD'S WORD Translation", youVersionId: '70', abbreviation: 'GW' },
  { id: 'gwc', label: 'St Paul from the Trenches 1916', youVersionId: '1047', abbreviation: 'GWC' },
  { id: 'hcsb', label: 'Holman Christian Standard Bible', youVersionId: '72', abbreviation: 'HCSB' },
  { id: 'icb', label: 'International Children’s Bible', youVersionId: '1359', abbreviation: 'ICB' },
  { id: 'jub', label: 'Jubilee Bible', youVersionId: '1077', abbreviation: 'JUB' },
  { id: 'kjv', label: 'King James Version', youVersionId: '1', abbreviation: 'KJV' },
  {
    id: 'kjvaa',
    label: 'King James Version with Apocrypha, American Edition',
    youVersionId: '546',
    abbreviation: 'KJVA',
  },
  { id: 'kjva', label: 'King James Version, American Edition', youVersionId: '547', abbreviation: 'KJVA' },
  { id: 'leb', label: 'Lexham English Bible', youVersionId: '90', abbreviation: 'LEB' },
  { id: 'mev', label: 'Modern English Version', youVersionId: '1171', abbreviation: 'MEV' },
  { id: 'mp1650', label: 'Metrical Psalms 1650', youVersionId: '1365', abbreviation: 'MP1650' },
  { id: 'msg', label: 'The Message', youVersionId: '97', abbreviation: 'MSG' },
  { id: 'nabre', label: 'New American Bible, revised edition', youVersionId: '463', abbreviation: 'NABRE' },
  { id: 'nasb', label: 'New American Standard Bible', youVersionId: '100', abbreviation: 'NASB' },
  { id: 'ncv', label: 'New Century Version', youVersionId: '105', abbreviation: 'NCV' },
  { id: 'net', label: 'New English Translation', youVersionId: '107', abbreviation: 'NET' },
  { id: 'nirv', label: "New International Reader's Version", youVersionId: '110', abbreviation: 'NIRV' },
  { id: 'niv', label: 'New International Version', youVersionId: '111', abbreviation: 'NIV' },
  { id: 'nivuk', label: 'New International Version (Anglicised)', youVersionId: '113', abbreviation: 'NIVUK' },
  { id: 'nkjv', label: 'New King James Version', youVersionId: '114', abbreviation: 'NKJV' },
  { id: 'nlt', label: 'New Living Translation', youVersionId: '116', abbreviation: 'NLT' },
  {
    id: 'nrsvci',
    label: 'New Revised Standard Version Catholic Interconfessional',
    youVersionId: '2015',
    abbreviation: 'NRSV',
  },
  { id: 'nrsv', label: 'New Revised Standard Version', youVersionId: '2016', abbreviation: 'NRSV' },
  { id: 'ojb', label: 'Orthodox Jewish Bible', youVersionId: '130', abbreviation: 'OJB' },
  { id: 'rsvci', label: 'Revised Standard Version (RSV-CI)', youVersionId: '2017', abbreviation: 'RSV' },
  { id: 'rsv', label: 'Revised Standard Version', youVersionId: '2020', abbreviation: 'RSV' },
  { id: 'rv1885', label: 'Revised Version 1885', youVersionId: '477', abbreviation: 'RV1885' },
  { id: 'rv1895', label: 'Revised Version with Apocrypha 1895', youVersionId: '1922', abbreviation: 'RV1895' },
  { id: 'tlv', label: 'Tree of Life Version', youVersionId: '314', abbreviation: 'TLV' },
  { id: 'tpt', label: 'The Passion Translation', youVersionId: '1849', abbreviation: 'TPT' },
  { id: 'ts2009', label: 'The Scriptures 2009', youVersionId: '316', abbreviation: 'TS2009' },
  { id: 'web', label: 'World English Bible', youVersionId: '1204', abbreviation: 'WEB' },
  { id: 'webbe', label: 'World English Bible British Edition', youVersionId: '206', abbreviation: 'WEBBE' },
  { id: 'wmb', label: 'World Messianic Bible', youVersionId: '1207', abbreviation: 'WMB' },
  { id: 'wmbbe', label: 'World Messianic Bible British Edition', youVersionId: '1209', abbreviation: 'WMBBE' },
  {
    id: 'ylt1898',
    label: "Young's Literal Translation 3rd Revision 1898",
    youVersionId: '821',
    abbreviation: 'YLT1898',
  },
] as const satisfies ReadonlyArray<BibleVersion>);
