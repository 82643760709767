import { sortBy as _sortBy } from 'lodash';
import { SrcSet } from '@nucleus/types/media/image';

export const formatSrcSet = (srcSet?: SrcSet | SrcSet[]): string | undefined => {
  if (srcSet === undefined) {
    return;
  }

  if (!Array.isArray(srcSet)) {
    srcSet = [srcSet];
  }

  return _sortBy(srcSet, 'dimensions.width')
    .map((srcSet) => `${srcSet.src} ${srcSet.dimensions.width}w`)
    .join(', ');
};
