import { HeaderSectionWeb } from '@nucleus/types/web';
import { useNavigation, useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { HeaderBlockLayout } from './HeaderBlockLayout';
import { HeaderBlockLayoutComponent } from './HeaderLayout';

export const HeaderSection = (): JSX.Element => {
  const section = useSection<HeaderSectionWeb>();
  const navigation = useNavigation(section.payload.blocks[0]?.navigation?.id);

  const isSticky = section.payload.blocks[0].behavior === 'sticky';

  return (
    <StyledSection
      forwardedAs="header"
      className={classNames(
        nucleusClass('section-header'),
        isSticky ? nucleusClass('section-header-sticky') : undefined,
        getSectionClasses(section)
      )}
    >
      <SectionLayout>
        <BlockLayoutComponent {...section.payload} navigation={navigation} />
      </SectionLayout>
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  position: relative;
  z-index: 100;
  width: 100%;

  & + section {
    ${SpacerTop} {
      width: 100%;
      height: calc(var(--unit-length) + var(--header-content-height));
    }
  }
`;

const SectionLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  & > * {
    z-index: 1;
    position: relative;
  }
`;

const BlockLayoutComponent: HeaderBlockLayoutComponent = (props) => {
  return <HeaderBlockLayout {...props} />;
};
