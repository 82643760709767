import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

type Props = React.HTMLAttributes<HTMLSpanElement> & ContainerProps & { direction?: 'up' | 'down' };

export const CircleIconFlip = styled(({ children, direction, ...props }: Props): JSX.Element => {
  const style = useSpring({
    to: {
      rotateX: direction === 'up' ? 180 : 0,
    },
    config: {
      friction: 55,
      mass: 0.5,
      tension: 2000,
    },
  });

  return (
    <Container {...props}>
      <animated.span style={style}>{children}</animated.span>
    </Container>
  );
})``;

type ContainerProps = {
  background?: string;
  color?: string;
  width?: string;
};

const Container = styled.span<ContainerProps>`
  position: relative;
  color: ${({ color = 'currentColor' }) => color};
  width: ${({ width = '2.4rem' }) => width};
  height: ${({ width = '2.4rem' }) => width};
  border-radius: 100%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 100%;
    background: ${({ background = 'currentColor' }) => background};
    z-index: 0;
  }

  svg {
    width: 100%;
    color: ${({ color = 'currentColor' }) => color};
    fill: ${({ color = 'currentColor' }) => color};
    z-index: 1;
  }

  span {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;
