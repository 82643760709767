import { objectKeys } from '@nucleus/src/lib/object';
import ColorApi from 'color';
import { BlockLayout } from '@nucleus/types/web/block';
import { PresetMedia, PresetMediaKeys } from '@nucleus/types/web/media';
import { SectionPayloadDefaults, SectionTypeEnum } from '@nucleus/types/web/sectiondata';
import { CardItemApi } from '@nucleus/types/web/sections/CardSection';
import { ListItemApi } from '@nucleus/types/web/sections/ListSection';
import { ColorConfig, ColorPaletteKey, FontPaletteKey } from '@nucleus/types/web/style';
import {
  BlockEditorConfig,
  BlockVariantConfig,
  Field,
  StyleConfig,
  ThemeButtonConfig,
  ThemeButtonConfigs,
  ThemeColorPalette,
  ThemeConfig,
  ThemeFieldConfig,
  ThemeLayoutConfig,
  ThemeSectionConfig,
  ThemeSectionLayoutIcon,
} from '@nucleus/types/web/theme';

export class ThemeConfigBuilder {
  private themeConfig: ThemeConfig;

  constructor(config: Required<ThemeConfig, 'id' | 'name'>) {
    this.themeConfig = {
      buildCssVariablesForColorPaletteAndVariations: () => [{}, {}, {}],
      buildColorPaletteVariationClassName: () => '',
      previewColors: {
        cardBackground: '',
        cardMediaBackground: '',
        mediaBackground: '',
        sectionBackground: '',
      },
      buttons: {
        configs: {},
      },
      presetMedia: {},
      colorPalettes: [],
      sections: {
        types: [],
        configs: {},
      },
      files: {
        placeholderImages: {},
      },
      ...config,
    } as ThemeConfig;
  }

  setId(id: string): this {
    this.themeConfig.id = id;
    return this;
  }

  setName(name: string): this {
    this.themeConfig.name = name;
    return this;
  }

  setBuildCssVariablesForColorPaletteAndVariations(
    buildCssVariablesForColorPaletteAndVariations: ThemeConfig['buildCssVariablesForColorPaletteAndVariations']
  ): this {
    this.themeConfig.buildCssVariablesForColorPaletteAndVariations = buildCssVariablesForColorPaletteAndVariations;
    return this;
  }

  setBuildColorPaletteVariationClassName(
    buildColorPaletteVariationClassName: ThemeConfig['buildColorPaletteVariationClassName']
  ): this {
    this.themeConfig.buildColorPaletteVariationClassName = buildColorPaletteVariationClassName;
    return this;
  }

  setPreviewColors(previewColors: ThemeConfig['previewColors']): this {
    this.themeConfig.previewColors = previewColors;
    return this;
  }

  addButtonConfig(id: keyof ThemeButtonConfigs, config: ThemeButtonConfig): this {
    this.themeConfig.buttons.configs[id] = config;
    return this;
  }

  addColorPalette(colorPalette: ThemeColorPalette): this {
    this.themeConfig.colorPalettes.push(colorPalette);
    return this;
  }

  addPresetMedia(key: PresetMediaKeys, presetMedia: PresetMedia): this {
    this.themeConfig.presetMedia[key] = presetMedia;
    return this;
  }

  addSection(
    type: SectionTypeEnum,
    config: ThemeSectionConfig
  ): {
    enabled: (bool?: boolean) => {
      available: (bool?: boolean) => void;
    };
  } {
    this.themeConfig.sections.configs[type] = config;

    this.themeConfig.sections.types.push({
      type: type,
      enabled: false,
      available: false,
    });

    const available = (bool = true) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.themeConfig.sections.types.find((item) => item.type === type)!.available = bool;
      return;
    };

    const enabled = (bool = true) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.themeConfig.sections.types.find((item) => item.type === type)!.enabled = bool;
      return { available: available };
    };

    return {
      enabled: enabled,
    };
  }

  setSectionDefaults(defaults: SectionPayloadDefaults): this {
    this.themeConfig.sections.defaults = defaults;
    return this;
  }

  setCardItemDefaults(defaults: Partial<CardItemApi>): this {
    this.themeConfig.sections.cardItemDefaults = defaults;
    return this;
  }

  setListItemDefaults(defaults: Partial<ListItemApi>): this {
    this.themeConfig.sections.listItemDefaults = defaults;
    return this;
  }

  addFile(id: string, image: ThemeConfig['files']['placeholderImages'][string]): this {
    this.themeConfig.files.placeholderImages[id] = image;
    return this;
  }

  build(): ThemeConfig {
    return this.themeConfig;
  }
}

export class ThemeColorPaletteBuilder {
  static buildColorConfigFromHex(hex: string): ColorConfig {
    const hsl = ColorApi(hex).hsl().object();
    return {
      hex: hex,
      h: hsl.h,
      s: hsl.s / 100,
      l: hsl.l / 100,
    };
  }

  private colorPalette: ThemeColorPalette;

  constructor(
    config: Omit<ThemeColorPalette, 'lightMode'> & {
      lightMode: Record<ColorPaletteKey, ColorConfig | string>;
    }
  ) {
    this.colorPalette = {
      ...config,
      lightMode: objectKeys(config.lightMode).reduce<Record<ColorPaletteKey, ColorConfig>>((acc, key) => {
        const colorConfig = config.lightMode[key];
        if (typeof colorConfig === 'string') {
          return {
            ...acc,
            [key]: ThemeColorPaletteBuilder.buildColorConfigFromHex(colorConfig),
          };
        }
        return {
          ...acc,
          [key]: colorConfig,
        };
      }, {} as any),
    };
  }

  build(): ThemeColorPalette {
    return this.colorPalette;
  }
}

export class SectionConfigBuilder {
  private sectionConfig: ThemeSectionConfig;

  constructor() {
    this.sectionConfig = {
      contexts: {
        picker: {},
        editor: {},
      },
      layouts: [],
    } as unknown as ThemeSectionConfig;
  }

  setContext<T extends keyof ThemeSectionConfig['contexts']>(id: T, config: ThemeSectionConfig['contexts'][T]): this {
    this.sectionConfig.contexts[id] = config;
    return this;
  }

  addLayout(layoutId: BlockLayout, callback: (builder: LayoutConfigBuilder) => void): this {
    const builder = new LayoutConfigBuilder(layoutId);

    callback(builder);

    this.sectionConfig.layouts.push(builder.build());

    return this;
  }

  build(): ThemeSectionConfig {
    return this.sectionConfig;
  }
}

export class LayoutConfigBuilder {
  static LayoutLabelMap = {
    layout1: 'Layout 1',
    layout2: 'Layout 2',
    layout3: 'Layout 3',
    layout4: 'Layout 4',
    layout5: 'Layout 5',
    layout6: 'Layout 6',
  };

  private layoutConfig: ThemeLayoutConfig;

  constructor(layoutId: BlockLayout) {
    this.layoutConfig = {
      layoutId: layoutId,
      label: LayoutConfigBuilder.LayoutLabelMap[layoutId],
      insetBlock: {},
      blocks: [],
      enabled: true,
      available: true,
    } as unknown as ThemeLayoutConfig;
  }

  setIcon(icon: ThemeSectionLayoutIcon): this {
    this.layoutConfig.icon = icon;
    return this;
  }

  setDescription(description?: string): this {
    this.layoutConfig.description = description;
    return this;
  }

  setInsetBlock(callback: (builder: BlockEditorConfigBuilder) => void): this {
    const builder = new BlockEditorConfigBuilder();
    builder.isInset();
    callback(builder);
    this.layoutConfig.insetBlock = builder.build();
    return this;
  }

  addBlock(callback: (builder: BlockEditorConfigBuilder) => void): this {
    const builder = new BlockEditorConfigBuilder();
    callback(builder);
    this.layoutConfig.blocks.push(builder.build());
    return this;
  }

  setBlockVariants(blockVariants?: BlockVariantConfig): this {
    this.layoutConfig.blockVariants = blockVariants;
    return this;
  }

  setBlockBodyMaxColumns(blockBodyMaxColumns?: number): this {
    this.layoutConfig.blockBodyMaxColumns = blockBodyMaxColumns;
    return this;
  }

  build(): ThemeLayoutConfig {
    return this.layoutConfig;
  }
}

export class BlockEditorConfigBuilder {
  private blockEditorConfig: BlockEditorConfig;

  private defaultLabels = {
    text: 'Text',
    media: 'Media',
    actions: 'Actions',
  };

  constructor() {
    this.blockEditorConfig = {
      text: {
        label: this.defaultLabels.text,
        fields: [],
      },
      media: {
        label: this.defaultLabels.media,
        limit: 0,
      },
      actions: {
        label: this.defaultLabels.actions,
        limit: 0,
      },
    };
  }

  isInset(): this {
    this.defaultLabels = {
      text: 'Lead-In Text (Above Inset)',
      media: '',
      actions: 'Lead-In Action (Above Inset)',
    };

    return this;
  }

  setText(fields: ThemeFieldConfig[], label = this.defaultLabels.text): this {
    this.blockEditorConfig.text = {
      label: label,
      fields: fields,
    };
    return this;
  }

  setMedia(limit = 0, label = this.defaultLabels.media, description?: string): this {
    this.blockEditorConfig.media = {
      label: label,
      description: description,
      limit: limit,
    };
    return this;
  }

  setItems(fields: ThemeFieldConfig[]): this {
    this.blockEditorConfig.items = {
      fields: fields,
    };
    return this;
  }

  setActions(limit = Infinity, label = this.defaultLabels.actions): this {
    this.blockEditorConfig.actions = {
      label: label,
      limit: limit,
    };

    return this;
  }

  build(): BlockEditorConfig {
    return this.blockEditorConfig;
  }
}

export class ThemeFieldConfigBuilder {
  static StyleLabelMap: Record<FontPaletteKey, string> = {
    headline1: 'Headline 1',
    headline2: 'Headline 2',
    headline3: 'Headline 3',
    headline4: 'Headline 4',
    headline5: 'Headline 5',
    headline6: 'Headline 6',
    paragraph1: 'Paragraph 1',
    paragraph2: 'Paragraph 2',
    paragraph3: 'Paragraph 3',
    paragraph4: 'Paragraph 4',
    title1: 'Title 1',
    title2: 'Title 2',
    description1: 'Description 1',
    description2: 'Description 2',
    blockquote1: 'Blockquote 1',
    blockquote2: 'Blockquote 2',
    label1: 'Label 1',
    label2: 'Label 2',
    label3: 'Label 3',
    label4: 'Label 4',
    label5: 'Label 5',
    label6: 'Label 6',
    navigation1: 'Navigation 1',
    navigation2: 'Navigation 2',
    navigation3: 'Navigation 3',
    navigation4: 'Navigation 4',
    navigation5: 'Navigation 5',
    button1: 'Button 1',
    button2: 'Button 2',
    button3: 'Button 3',
    button4: 'Button 4',
  };

  private themeFieldConfig: ThemeFieldConfig;

  constructor(fieldId: Field) {
    this.themeFieldConfig = {
      fieldId: fieldId,
      styles: [],
    };
  }

  addStyle(styleId: FontPaletteKey, label?: string): this {
    this.themeFieldConfig.styles.push({
      styleId: styleId,
      label: label ?? ThemeFieldConfigBuilder.StyleLabelMap[styleId],
    });

    return this;
  }

  setStyles(styles: (FontPaletteKey | StyleConfig)[]): this {
    styles.forEach((style) => {
      if (typeof style === 'string') {
        this.addStyle(style);
        return;
      }

      this.addStyle(style.styleId, style.label);
    });
    return this;
  }

  build(): ThemeFieldConfig {
    return this.themeFieldConfig;
  }
}

type Required<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;
