import { useAudio } from '@nucleus/react-components';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { IconCirclePause } from '../icons/IconCirclePause';
import { IconCirclePlay } from '../icons/IconCirclePlay';
import { DurationBadge } from './DurationBadge';
import { Thumbnail } from './Thumbnail';
import { ImageWeb } from '@nucleus/types/media/image';
import { formatSrcSet } from '../lib/media';

interface Props extends React.AudioHTMLAttributes<HTMLAudioElement> {
  src: string;
  thumbnail?: ImageWeb;
}

export const AudioPlayer = (props: Props): JSX.Element => {
  const [element, state, controls] = useAudio({ src: props.src, autoPlay: props.autoPlay });

  const handleClick = () => {
    if (state.playing) {
      controls.pause();
    } else {
      controls.play();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      controls.seek((value / 100) * state.duration);
    }
  };

  const progress = (state.time / state.duration) * 100;

  return (
    <Container className={props.className} style={props.style}>
      {element}
      <StyledThumbnail
        src={props.thumbnail?.src}
        srcSet={formatSrcSet(props.thumbnail?.srcSet)}
        blurHash={props.thumbnail?.blurHash}
      />
      <RadialBackground />
      <ToggleButton onClick={handleClick}>
        {state.playing ? (
          <IconCirclePause width="100px" height="100px" />
        ) : (
          <IconCirclePlay width="100px" height="100px" />
        )}
      </ToggleButton>
      <Controls>
        <ProgressBar type="range" min="0" max="100" value={isNaN(progress) ? 0 : progress} onChange={handleChange} />
        <DurationBadge>{formatTime(state.duration - state.time)}</DurationBadge>
      </Controls>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledThumbnail = styled(Thumbnail)`
  position: absolute;
  inset: 0;
`;

const RadialBackground = styled.div`
  position: absolute;
  inset: 0;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 1) 125%);
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  outline: none;
  user-select: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: relative;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 18px;
  padding-left: 36px;

  ${DurationBadge} {
    flex: 1 0 auto;
    min-width: 80px;
  }
`;

const ProgressBar = styled.input`
  --color-track: #ccc;
  --color-groove: #fff;
  --color-thumb: #fff;

  --max: ${({ max }) => max};
  --min: ${({ min }) => min};
  --value: ${({ value }) => value};

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));

  --background-track: linear-gradient(var(--color-track), var(--color-track)) 0 / var(--sx) 100% no-repeat
    var(--color-groove);

  /* Reset the default styles for the input range */
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px; /* Set the height of the track */
  background: transparent; /* Make the track transparent */
  outline: none; /* Remove the default outline */

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px; /* Set the height of the track */
    background: var(--background-track);
    border-radius: 5px; /* Set the border radius of the track */
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px; /* Set the width of the thumb */
    height: 20px; /* Set the height of the thumb */
    background: var(--color-thumb); /* Set the color of the thumb */
    border-radius: 50%; /* Set the border radius of the thumb to make it circular */
    cursor: pointer; /* Set the cursor to pointer to indicate interactivity */
    margin-top: -7px; /* Adjust the vertical alignment of the thumb */
  }

  &:focus {
    outline: none; /* Remove the outline on focus for better accessibility */
  }

  &:active::-webkit-slider-thumb {
    background: var(--color-thumb); /* Set a different color for the thumb when active */
  }

  &::-moz-range-track {
    width: 100%;
    height: 10px; /* Set the height of the track */
    background: var(--background-track);
    border-radius: 5px; /* Set the border radius of the track */
  }

  &::-moz-range-thumb {
    width: 20px; /* Set the width of the thumb */
    height: 20px; /* Set the height of the thumb */
    background: var(--color-thumb); /* Set the color of the thumb */
    border-radius: 50%; /* Set the border radius of the thumb to make it circular */
    cursor: pointer; /* Set the cursor to pointer to indicate interactivity */
  }

  &::-ms-track {
    width: 100%;
    height: 10px; /* Set the height of the track */
    background: #fff; /* Set the color of the track */
    border-radius: 5px; /* Set the border radius of the track */
  }

  &::-ms-fill-lower {
    background: var(--color-groove); /* Set the color of the lower fill */
    border-radius: 5px; /* Set the border radius of the lower fill */
  }

  &::-ms-fill-upper {
    background: var(--color-track); /* Set the color of the upper fill */
    border-radius: 5px; /* Set the border radius of the upper fill */
  }

  &::-ms-thumb {
    width: 20px; /* Set the width of the thumb */
    height: 20px; /* Set the height of the thumb */
    background: var(--color-thumb); /* Set the color of the thumb */
    border-radius: 50%; /* Set the border radius of the thumb to make it circular */
    cursor: pointer; /* Set the cursor to pointer to indicate interactivity */
    margin-top: -7px; /* Adjust the vertical alignment of the thumb */
  }
`;

/** Formats time as hh:mm:ss or mm:ss */
const formatTime = (value: number): string => {
  const padZero = (num: number): string => num.toString().padStart(2, '0');

  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.floor((value % 60) / 1);

  if (hours === 0) {
    return `${padZero(minutes)}:${padZero(seconds)}`;
  }

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
};
