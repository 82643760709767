import { set as _set } from 'lodash';
import { Auth } from 'aws-amplify';

declare module 'amazon-cognito-identity-js' {
  interface CognitoUserSession {
    idToken: {
      jwtToken: string;
    };
  }
}

export const fetchWithAuth = async (resource: RequestInfo | URL, options: RequestInit = {}): Promise<Response> => {
  const session = await Auth.currentSession();
  _set(options, 'headers.Authorization', session.idToken.jwtToken);
  return await fetch(resource, options);
};
