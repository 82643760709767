import { Alignment, Position } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCover } from '../../blocks/BlockCover';
import { blockInfoHasContent } from '../../blocks/BlockInfo';
import { BlockListGrid } from '../../blocks/BlockListGrid';
import { AccordionList } from '../../components/AccordionList';
import { SectionBackground } from '../../components/Background';
import { Overline } from '../../components/Base';
import { StandardList } from '../../components/StandardList';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { ListBlockLayoutProps, getPositionRow } from './ListLayout';

export const ListBlockLayoutCover = (props: ListBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const row = getPositionRow(props.blockInfoPosition);

  const renderBackground = props.backgroundMedia !== undefined;
  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout
      {...props}
      bodyAlignment={props.blocks[0]?.bodyAlignment ?? props.blockBodyAlignment}
      row={row}
      renderInfo={renderInfo}
    >
      {renderBackground && <SectionBackground background={props.backgroundMedia} />}
      <SpacerTop />
      <StyledBlockCover {...innerProps} block={props.blocks[0]} />
      <StyledSpacerBottom />
      <StyledBlockListGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const StyledSpacerBottom = styled(SpacerTop)``;
const StyledBlockListGrid = styled(BlockListGrid)``;
const StyledBlockCover = styled(BlockCover)``;

const StyledBlockLayout = styled(BlockLayout)<{ bodyAlignment?: Alignment; row: Position; renderInfo: boolean }>`
  ${StyledSpacerBottom} {
    display: ${(props) => (props.row === Position.Center ? 'block' : 'none')};
  }

  ${StyledBlockCover} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${Overline} {
    text-align: ${(props) => props.bodyAlignment ?? 'left'};
  }

  ${StyledBlockListGrid} {
    display: flex;
    justify-content: center;
    padding: var(--unit-length);
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};

    ${StandardList} {
      padding: var(--unit-length);
      max-width: 1600px;
    }

    ${AccordionList} {
      padding: var(--unit-length);
      max-width: 912px;
    }
  }
`;
