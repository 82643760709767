import styled from 'styled-components';
import React from 'react';
import { Button, ButtonProps } from './Button';
import { Menu, usePopoverState } from '@nucleus/react-components';
import { MenuButton } from '@nucleus/react-components';

export const DropdownButton = ({
  label,
  children,
  ...props
}: ButtonProps & { label?: React.ReactNode }): JSX.Element => {
  const {
    popoverProps,
    triggerProps: { activated, onClick },
  } = usePopoverState();
  return (
    <>
      <Button {...props} onClick={onClick}>
        <Content>
          <span>{label}</span>
          <MenuButton activated={activated} />
        </Content>
      </Button>
      <Menu {...popoverProps}>{children}</Menu>
    </>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 7px 0 24px;
`;
