import { ISO8601Schema } from '@nucleus/src-platform/data';
import { multiSlugSchema } from '@nucleus/src/lib/url';
import { ISO8601 } from '@nucleus/types/iso8601';
import * as yup from 'yup';
import { PublishableEntityBase, PublishableEntityBaseSchema } from './PublishableEntityBase';

export interface PublishableEntity extends PublishableEntityBase {
  name?: string;
  ownerId: string;
  slug: string;
  archivedAt?: ISO8601;
  archivedBy?: string;
}

export const PublishableEntitySchema: yup.SchemaOf<PublishableEntity> = PublishableEntityBaseSchema.shape({
  name: yup.string().optional(),
  ownerId: yup.string().required(),
  slug: multiSlugSchema.default(null).test('slug test', 'slug must be a string', (slug) => {
    if (typeof slug === 'string') {
      return true;
    } else {
      return false;
    }
  }),
  archivedAt: ISO8601Schema.optional(),
  archivedBy: yup.string().optional(),
});
