import { yupKeyStringSchema } from './yupValidation';
import * as yup from 'yup';

// Schema for type ActionItem
export const actionSchema = yup.object({
  id: yupKeyStringSchema.required(),
  title: yup.string().required(),
  type: yup.string().required(),
  destination: yup.string(),
  openInNewTab: yup.bool(),
});
