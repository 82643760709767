import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { IconAngleDown } from './icons/IconAngleDown';
import { CircleIconFlip } from './icons/CircleIconFlip';

declare module '@nucleus/react-components' {
  interface ReactComponentsTheme {
    MenuButton?: {
      background?: string;
      foreground?: string;
      className?: string;
      color?: string;
      hover?: {
        background?: string;
      };
    };
  }
}

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & { activated?: boolean };

export const MenuButton = forwardRef<HTMLButtonElement, Props>((props, ref): JSX.Element => {
  return (
    <StyledButton {...props} ref={ref}>
      <CircleIconFlip direction={props.activated ? 'up' : 'down'}>
        <IconAngleDown width="24" height="24" />
      </CircleIconFlip>
    </StyledButton>
  );
});

MenuButton.displayName = 'MenuButton';

const StyledButton = styled.button.attrs((props) => ({
  className: props.theme._reactComponents.MenuButton?.className,
}))`
  cursor: pointer;
  outline: none;
  user-select: none;
  background: ${(props) => props.theme._reactComponents?.MenuButton?.background};
  border-radius: 50%;
  border: none;

  height: 36px;
  width: 36px;

  margin: 0;
  padding: 6px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${CircleIconFlip} {
    color: ${(props) => props.theme._reactComponents?.MenuButton?.foreground};
  }

  svg {
    color: ${(props) => props.theme._reactComponents?.MenuButton?.color};
  }

  :active:not([disabled]) {
    transform: scale(0.96);
  }

  :hover:not([disabled]) {
    background: ${(props) => props.theme._reactComponents?.MenuButton?.hover?.background};
  }
`;
