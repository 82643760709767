import { Position } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockListGrid } from '../../blocks/BlockListGrid';
import { AccordionList } from '../../components/AccordionList';
import { StandardList } from '../../components/StandardList';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { ListBlockLayoutProps, getPositionRow } from './ListLayout';

export const ListBlockLayoutContentOnly = (props: ListBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const row = getPositionRow(props.blockInfoPosition);

  return (
    <StyledBlockLayout {...props} row={row}>
      <SpacerTop />
      <StyledContainer>
        <StyledBlockListGrid {...innerProps} block={props.blocks[0]} />
        <SpacerTop />
      </StyledContainer>
    </StyledBlockLayout>
  );
};

const StyledBlockListGrid = styled(BlockListGrid)``;
const StyledContainer = styled.div``;

const StyledBlockLayout = styled(BlockLayout)<{ row: Position }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledBlockListGrid} {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;

    background-color: transparent;

    ${StandardList} {
      padding: var(--unit-length);
      max-width: 1600px;
    }

    ${AccordionList} {
      padding: var(--unit-length);
      max-width: 912px;
    }
  }

  ${StyledContainer} {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    justify-content: ${(props) => {
      switch (props.row) {
        case Position.Top:
          return 'flex-start';
        case Position.Center:
          return 'center';
        case Position.Bottom:
          return 'flex-end';
        default:
          return;
      }
    }};

    ${SpacerTop} {
      display: ${(props) => (props.row === Position.Center ? 'block' : 'none')};
      display: ${(props) => (props.height === 'small' ? 'none' : undefined)};
    }
  }
`;
