import { SermonIndexDocumentWeb, SermonIndexSpeaker } from '@nucleus/sermons/types/Sermon';
import { slugifyString } from '@nucleus/src-platform/data/text';
import { SermonWeb } from '@nucleus/types/web/sermons/sermon';
import { SpeakerWeb } from '@nucleus/types/web/sermons/speaker';
import { get as _get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLocalization } from '../hooks/useLocalization';
import { useSpeaker } from '../hooks/useSpeaker';

interface Props {
  sermon?: SermonWeb | SermonIndexDocumentWeb;
}

export const SermonSpeakers = ({ sermon }: Props): JSX.Element | null => {
  if (sermon === undefined) {
    return null;
  }

  if (isSermonIndexDocumentWeb(sermon)) {
    return <SermonIndexSpeakers sermon={sermon} />;
  }

  if (isSermonWeb(sermon)) {
    return <SermonWebSpeakers sermon={sermon} />;
  }

  return null;
};

const SermonIndexSpeakers = ({ sermon }: { sermon: SermonIndexDocumentWeb }) => {
  const speaker = _get(sermon.speakers, sermon.speakerIds[0]);
  const speakerCount = sermon.speakerIds.length;

  if (speaker === undefined) {
    return null;
  }

  return (
    <>
      <StyledLink to={useSpeakerPath(speaker)}>{speaker.displayName}</StyledLink>
      {speakerCount > 1 ? ` +${speakerCount - 1}` : ''}
    </>
  );
};

const SermonWebSpeakers = ({ sermon }: { sermon: SermonWeb }) => {
  const { data, isLoading, isError } = useSpeaker(sermon.engineId, sermon.speakers[0]);

  if (isLoading || isError) {
    return null;
  }

  const speaker = data?.collection;

  if (speaker === undefined) {
    return null;
  }

  const speakerCount = sermon.speakers.length;

  return (
    <>
      <StyledLink to={useSpeakerPath(speaker)}>{speaker.displayName}</StyledLink>
      {speakerCount > 1 ? ` +${speakerCount - 1}` : ''}
    </>
  );
};

const StyledLink = styled(Link)`
  color: inherit;
`;

/** Sermon Index document has a speakers object keyed by id */
const isSermonIndexDocumentWeb = (sermon: any): sermon is SermonIndexDocumentWeb =>
  sermon.speakers && !Array.isArray(sermon.speakers);

/** Sermon web has an array of speaker ids */
const isSermonWeb = (sermon: any): sermon is SermonWeb => sermon.speakers && Array.isArray(sermon.speakers);

const useSpeakerPath = (speaker: SpeakerWeb | SermonIndexSpeaker) => {
  const localize = useLocalization();

  return [slugifyString(localize('speakers', 'speakers')), speaker.slug].join('/');
};
