import { Height } from '@nucleus/types/web';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { BlockInsetInfo } from '../blocks/BlockInsetInfo';
import { SectionBackground } from '../components/Background';
import { SectionLayoutProps } from '../types/component';
import { SpacerTop } from './Spacers';

export const SectionLayout = (props: SectionLayoutProps): JSX.Element => {
  switch (props.layout) {
    case 'full':
      return <FullSectionLayout {...props} />;
    case 'constrained':
      return <InsetSectionLayout {...props} />;
    case 'info':
      return <InsetPlusInfoSectionLayout {...props} />;
    default:
      return <FullSectionLayout {...props} />;
  }
};

const FullSectionLayout = (props: SectionLayoutProps) => {
  const renderBackground = props.backgroundMedia !== undefined;

  return (
    <StyledSectionLayout
      className={classNames([props.className, nucleusClass('section-full')])}
      sectionHeight={props.height}
    >
      {renderBackground && <SectionBackground background={props.backgroundMedia} />}
      <FullSectionWrapper>{props.children}</FullSectionWrapper>
    </StyledSectionLayout>
  );
};

const InsetSectionLayout = (props: SectionLayoutProps) => {
  return (
    <StyledSectionLayout
      className={classNames([props.className, nucleusClass('section-inset')])}
      sectionHeight={props.height}
    >
      <SectionBackground background={props.backgroundMedia} />
      <SpacerTop />
      <InsetSectionWrapper>{props.children}</InsetSectionWrapper>
    </StyledSectionLayout>
  );
};

const InsetPlusInfoSectionLayout = (props: SectionLayoutProps) => {
  return (
    <StyledSectionLayout
      className={classNames([props.className, nucleusClass('section-inset'), nucleusClass('section-info')])}
      sectionHeight={props.height}
    >
      <SectionBackground background={props.backgroundMedia} />
      <SpacerTop />
      <StyledBlockInsetInfo {...props} />
      <InsetSectionWrapper>{props.children}</InsetSectionWrapper>
    </StyledSectionLayout>
  );
};

const StyledBlockInsetInfo = styled(BlockInsetInfo)``;
const SectionBlocksWrapper = styled.div``;
const FullSectionWrapper = styled(SectionBlocksWrapper)``;
const InsetSectionWrapper = styled(SectionBlocksWrapper)`
  & ${SpacerTop} {
    display: none;
  }
`;

const StyledSectionLayout = styled.div<{ sectionHeight?: Height }>`
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--color-section-text);

  min-height: ${(props) => {
    switch (props.sectionHeight) {
      case 'large':
        return '100vh';
      case 'medium':
        return '66vh';
      case 'small':
      default:
        return 'auto';
    }
  }};

  ${StyledBlockInsetInfo} {
    padding: var(--unit-length);
    padding-bottom: 0;
  }

  ${SectionBlocksWrapper} {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  ${InsetSectionWrapper} {
    padding: var(--unit-length);
  }
`;
