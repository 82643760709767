import { useUser } from '@nucleus/web-theme';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryCollection } from '../queries/collection';

type QueryResult = Awaited<ReturnType<typeof queryCollection>>;

export const useCollection = (
  engineId: string | undefined,
  collectionType?: 'playlists' | 'tags' | 'speakers' | 'books',
  slugOrId?: string
): UseQueryResult<QueryResult> => {
  const { currentUser, jwtToken } = useUser();

  return useQuery({
    enabled: slugOrId !== undefined,
    queryKey: ['sermonHub', collectionType, slugOrId, currentUser?.id],
    queryFn: () => queryCollection(engineId, collectionType, slugOrId!, jwtToken),
    suspense: true,
  });
};
