import { SectionConfigBuilder } from '../ThemeConfigBuilder';
import { body, byline, headline, overline } from '../fields/fields';

export default new SectionConfigBuilder()
  .setContext('picker', {
    label: 'Cards',
    description: 'Create and display grids of beautiful cards',
    icon: 'Card',
  })
  .setContext('editor', {
    label: 'Card Section',
    description: '',
    icon: 'Card',
  })
  .addLayout('layout1', (layout) => {
    layout
      .setIcon('CardLayout1')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Cards Placement',
        controlType: 'left right',
        options: [
          {
            value: 'variant1',
            label: 'Left',
          },
          {
            value: 'variant2',
            label: 'Right',
          },
        ],
      });
  })
  .addLayout('layout2', (layout) => {
    layout
      .setIcon('CardLayout2')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(1);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout3', (layout) => {
    layout
      .setIcon('CardLayout3')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(3);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout4', (layout) => {
    layout
      .setIcon('CardLayout4')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockBodyMaxColumns(3);
  })
  .addLayout('layout5', (layout) => {
    layout
      .setIcon('CardLayout5')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout6', (layout) => {
    layout
      .setDescription('Card Data Only')
      .setIcon('CardLayout6')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([]);
        block.setMedia(0);
        block.setActions(0);
      });
  })
  .build();
