import React from 'react';

/** Icon representing a solid circle with an arrow pointing top-left, used on button for external url */
export const IconCircleArrow = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="18" cy="18" r="18" fill="currentColor" />
        <path
          fill="#000"
          d="M10.629 22.725c-.719.719-.69 1.913.063 2.666.754.754 1.947.782 2.666.064l8.434-8.429s.026-.163.124 3.946a1.997 1.997 0 0 0 1.93 1.929 1.78 1.78 0 0 0 1.839-1.84c-.081-3.332-.188-8.541-.188-8.541a1.997 1.997 0 0 0-1.93-1.93s-4.356-.086-8.465-.184a1.78 1.78 0 0 0-1.839 1.84 1.997 1.997 0 0 0 1.93 1.928c3.332.078 3.877.115 3.877.115s-5.699 5.694-8.441 8.436Z"
        />
      </g>
    </svg>
  );
};
