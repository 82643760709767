import { BlurHash } from '@nucleus/react-components';
import React from 'react';
import styled from 'styled-components';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    CircleProfileImage?: {
      background?: string;
      color?: string;
    };
  }
}

type Props = React.ImgHTMLAttributes<HTMLImageElement> & { blurHash?: string };

export const CircleProfileImage = styled(({ blurHash, className, style, ...props }: Props): JSX.Element | null => {
  return (
    <Wrapper className={className} style={style}>
      {blurHash && <StyledBlurHash hash={blurHash} />}
      <Image {...props} />
    </Wrapper>
  );
})``;

const Wrapper = styled.div`
  background: ${({ theme }) => theme._sermonThemeElements.CircleProfileImage?.background};
  border: 4.17px solid ${({ theme }) => theme._sermonThemeElements.CircleProfileImage?.color};
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
  position: absolute;
`;

const StyledBlurHash = styled(BlurHash)`
  position: absolute;
  inset: 0;
`;
