import { Prettify } from '@nucleus/types/utility';
import { nucleusClass } from '@nucleus/web-theme-elements';
import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import { omit as _omit } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { InfoContent } from '../blocks/Base';
import { BlockMedia } from '../blocks/BlockMedia';
import { BlockLayoutProps } from '../types/component';

export const BlockLayout = (props: BlockLayoutProps): JSX.Element => {
  const isLayoutInset = ['constrained', 'info'].includes(props.layout);
  const innerProps = _omit(props, ['name', 'height']);

  return (
    <StyledBlockLayout
      isLayoutInset={isLayoutInset}
      {...innerProps}
      className={classNames([nucleusClass('blocks'), innerProps.className])}
    >
      {props.children}
    </StyledBlockLayout>
  );
};

const isLayoutInset = booleanPropHelperFactory('isLayoutInset');

const StyledBlockLayout = styled.div<{ isLayoutInset: boolean }>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;

  position: relative;
  z-index: 0;

  ${InfoContent} {
    position: relative;
    z-index: 1;
  }

  ${BlockMedia} {
    z-index: 1;
  }

  ${isLayoutInset(css`
    --color-section-text-h: var(--color-section-inset-block-text-h);
    --color-section-text-s: var(--color-section-inset-block-text-s);
    --color-section-text-l: var(--color-section-inset-block-text-l);
    --color-section-text-a: var(--color-section-inset-block-text-a);

    --color-section-text: hsl(
      var(--color-section-text-h),
      calc(var(--color-section-text-s) * 1%),
      calc(var(--color-section-text-l) * 1%),
      calc(var(--color-section-text-a) * 1%)
    );

    --color-section-background-h: var(--color-section-inset-block-background-h);
    --color-section-background-s: var(--color-section-inset-block-background-s);
    --color-section-background-l: var(--color-section-inset-block-background-l);
    --color-section-background-a: var(--color-section-inset-block-background-a);

    --color-section-background: hsl(
      var(--color-section-background-h),
      calc(var(--color-section-background-s) * 1%),
      calc(var(--color-section-background-l) * 1%),
      calc(var(--color-section-background-a) * 1%)
    );

    background-color: var(--color-section-background);
    color: var(--color-section-text);

    --color-button-primary-background: var(--color-section-inset-block-button-primary-background);
    --color-button-primary-text: var(--color-section-inset-block-button-primary-text);
    --color-button-primary-background-hover: var(--color-section-inset-block-button-primary-background-hover);
    --color-button-primary-text-hover: var(--color-section-inset-block-button-primary-text-hover);

    --color-button-secondary-background: var(--color-section-inset-block-button-secondary-background);
    --color-button-secondary-text: var(--color-section-inset-block-button-secondary-text);
    --color-button-secondary-background-hover: var(--color-section-inset-block-button-secondary-background-hover);
    --color-button-secondary-text-hover: var(--color-section-inset-block-button-secondary-text-hover);

    --color-button-tertiary-background: var(--color-section-inset-block-button-tertiary-background);
    --color-button-tertiary-text: var(--color-section-inset-block-button-tertiary-text);
    --color-button-tertiary-background-hover: var(--color-section-inset-block-button-tertiary-background-hover);
    --color-button-tertiary-text-hover: var(--color-section-inset-block-button-tertiary-text-hover);

    --color-media-background: var(--color-section-inset-block-media-background);
    --color-media-background2: var(--color-section-inset-block-media-background2);
    --color-media-background3: var(--color-section-inset-block-media-background3);

    --color-highlight-background: var(--color-section-inset-block-highlight-background);
    --color-highlight-background-h: var(--color-section-inset-block-highlight-background-h);
    --color-highlight-background-s: var(--color-section-inset-block-highlight-background-s);
    --color-highlight-background-l: var(--color-section-inset-block-highlight-background-l);

    --color-highlight-background2: var(--color-section-inset-block-highlight-background2);
    --color-highlight-background2-h: var(--color-section-inset-block-highlight-background2-h);
    --color-highlight-background2-s: var(--color-section-inset-block-highlight-background2-s);
    --color-highlight-background2-l: var(--color-section-inset-block-highlight-background2-l);
  `)}
`;

export const blockLayoutInnerProps = <T extends BlockLayoutProps>(props: T): Prettify<Omit<T, 'className'>> => {
  // className is not something we want to propagate down.
  // blocks are only meant to be read below the section layout level.
  return { ..._omit(props, ['className']), blocks: [] };
};
