import { debounce as _debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useBodyMarginTop = (): number => {
  const [marginTop, setMarginTop] = useState(0);

  useEffect(() => {
    const getMarginTop = () => parseInt(window.getComputedStyle(document.body).getPropertyValue('margin-top'), 10);

    setMarginTop(getMarginTop());

    const callback = _debounce(() => {
      setMarginTop(getMarginTop());
    }, 100);

    const observer = new MutationObserver(callback);

    observer.observe(document.body, { attributes: true, attributeFilter: ['style'] });

    return () => {
      observer.disconnect();
    };
  }, [marginTop]);

  return marginTop;
};
