import * as yup from 'yup';

export const yupPrefixedKeySchema = yup
  .string()
  .matches(/^[a-z0-9]+$/)
  .required();

export const yupKeyStringSchema = yup.string().matches(/^[a-z0-9]+_[0-9a-f]{0,32}$/, { excludeEmptyString: true });

export const yupPrefixedKeyStringSchema = (prefix: string): yup.StringSchema => {
  yupPrefixedKeySchema.validateSync(prefix);

  return yup.string().matches(RegExp(`^${prefix}_[0-9a-f]{0,32}$`), { excludeEmptyString: true });
};

export const yupStringMatcherSchema = (name: string, matcher: (value: string) => boolean): yup.StringSchema => {
  return yup.string().test(name, (value) => {
    // return true if value is undefined because yup required() will handle that if needed
    if (value === undefined) {
      return true;
    }
    return matcher(value);
  });
};

/**
 * Validate a String Value
 * Validates an empty string; also validates undefined by setting it to an empty string.
 *
 * Wheras yup.string().required() will not validate an empty string;
 * and yup.string().ensure() will not throw a validation error if the value is not a string,
 * this schema will set a default and require that the type is string.
 *
 * This schema is useful when validating a value as a non-optional string type.
 *
 */
export const yupStringSchema = yup.string().trim().default('').strict(true);
