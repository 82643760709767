import { Alignment, InfoMaxWidth } from '@nucleus/types/web';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Body, Byline, Headline, Label, Labels, Overline } from '../components/Base';
import { InfoButtons } from '../components/InfoButtons';
import { BlockProps } from '../types/component';
import { InfoSizeGroup, InfoSizePartition, SizingByElementAndWidth, SizingConfiguration } from './Base';

export const BlockInsetInfo = (props: BlockProps): JSX.Element | null => {
  if (props.insetBlock === undefined) {
    return null;
  }

  const renderButtons = props.insetBlock.buttons !== undefined && props.insetBlock.buttons.length > 0;
  const renderByline = props.insetBlock.byline !== undefined;
  const renderHeadline = props.insetBlock.headline !== undefined;
  const renderLabels = props.insetBlock.labels !== undefined;
  const renderOverline = props.insetBlock.overline !== undefined;
  const renderBody = props.insetBlock.body !== undefined;

  const renderHeadlineElements =
    renderByline === true || renderHeadline === true || renderLabels === true || renderOverline === true;

  const alignment: Alignment = 'left';
  const width: InfoMaxWidth = 'medium';

  const allSizingData = [
    renderHeadlineElements && SizingByElementAndWidth.Headline[width],
    SizingByElementAndWidth.Body[width],
    renderButtons && SizingByElementAndWidth.Button[width],
  ].filter((sizeData): sizeData is SizingConfiguration => sizeData !== false);

  // Get the max of all sizing config parts.
  const maxSizingConfiguration = allSizingData.reduce((acc, next) => {
    return {
      maxWidthFactor: Math.max(acc.maxWidthFactor, next.maxWidthFactor),
      maxWidthRems: Math.max(acc.maxWidthRems, next.maxWidthRems),
      minWidthRems: Math.max(acc.minWidthRems, next.minWidthRems),
    };
  });

  return (
    <StyledBlockInsetInfo className={classNames([props.className, nucleusClass('block-info')])}>
      <InfoSizePartition sizingConfiguration={maxSizingConfiguration}>
        {renderHeadlineElements && (
          <InfoSizeGroup
            alignment={alignment}
            parentSizingConfiguration={maxSizingConfiguration}
            sizingConfiguration={SizingByElementAndWidth.Headline[width]}
          >
            <Overline nodes={props.insetBlock?.overline} />
            <Headline nodes={props.insetBlock?.headline} />
            <Byline nodes={props.insetBlock?.byline} />
            {props.insetBlock?.labels && (
              <Labels>
                {props.insetBlock?.labels?.map((label, index) => <Label key={index}>{label.title}</Label>)}
              </Labels>
            )}
          </InfoSizeGroup>
        )}

        {renderBody && (
          <InfoSizeGroup
            alignment={alignment}
            parentSizingConfiguration={maxSizingConfiguration}
            sizingConfiguration={SizingByElementAndWidth.Body[width]}
          >
            <Body nodes={props.insetBlock?.body} />
          </InfoSizeGroup>
        )}

        {renderButtons && (
          <InfoSizeGroup
            alignment={alignment}
            parentSizingConfiguration={maxSizingConfiguration}
            sizingConfiguration={SizingByElementAndWidth.Button[width]}
          >
            <InfoButtons
              buttons={props.insetBlock?.buttons}
              buttonsLayout="horizontal"
              buttonsWidth="auto"
              buttonsMaxWidth="medium"
              buttonsAlignment="left"
            />
          </InfoSizeGroup>
        )}
      </InfoSizePartition>
    </StyledBlockInsetInfo>
  );
};

const StyledBlockInsetInfo = styled.div`
  z-index: 1;
`;
