import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCardGrid, StackedGrid } from '../../blocks/BlockCardGrid';
import { BlockCollage } from '../../blocks/BlockCollage';
import { BlockInfo } from '../../blocks/BlockInfo';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { CardBlockLayoutProps } from './CardLayout';

export const CardBlockLayoutCollage = (props: CardBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  return (
    <StyledBlockLayout {...props}>
      <StyledBlockCollage {...innerProps} block={props.blocks[0]} />
      <StyledBlockInfo {...innerProps} block={props.blocks[0]} />
      <StyledBlockCardGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const StyledBlockCardGrid = styled(BlockCardGrid)``;
const StyledBlockCollage = styled(BlockCollage)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)`
  ${StyledBlockInfo} {
    ${SpacerTop} {
      display: none;
    }
  }

  ${StyledBlockCardGrid} {
    position: relative;
    z-index: 1;

    background-color: transparent;
    ${StackedGrid} {
      padding: 0 var(--unit-length) var(--unit-length);
    }
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '35vh';
        case 'large':
          return '80vh';
        default:
          return undefined;
      }
    }};
  }
`;
