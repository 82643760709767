import { SermonTagWeb } from '@nucleus/types/web/sermons/sermonTag';
import { useUser } from '@nucleus/web-theme';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryCollection } from '../queries/collection';

type QueryResult = Extract<Awaited<ReturnType<typeof queryCollection>>, { collection: SermonTagWeb }>;

export const useSermonTag = (engineId: string, slugId: string): UseQueryResult<QueryResult> => {
  const { currentUser, jwtToken } = useUser();

  return useQuery({
    queryKey: ['sermonHub', 'tags', slugId, currentUser?.id],
    queryFn: () => queryCollection(engineId, 'tags', slugId, jwtToken),
    suspense: true,
  });
};
