import {
  ThemeCustomElement as CustomElement,
  ThemeImageElement as ImageElement,
  ThemeLinkElement as LinkElement,
} from '@nucleus/types/web';
import React from 'react';
import { RichTextCustomRichTextComponent, RichTextRegistryProvider } from './RichTextRegistry';

const H1 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <h1>{children}</h1>;
const H2 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <h2>{children}</h2>;
const H3 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <h3>{children}</h3>;
const H4 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <h4>{children}</h4>;
const H5 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <h5>{children}</h5>;
const H6 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <h6>{children}</h6>;

const P1 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <p>{children}</p>;
const P2 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <p>{children}</p>;
const P3 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <p>{children}</p>;
const P4 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <p>{children}</p>;

const L1 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <div>{children}</div>;
const L2 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <div>{children}</div>;
const L3 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <div>{children}</div>;
const L4 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <div>{children}</div>;
const L5 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <div>{children}</div>;
const L6 = ({ children }: { children: React.ReactNode; node: CustomElement }) => <div>{children}</div>;

const UL = ({ children }: { children: React.ReactNode; node: CustomElement }) => <ol>{children}</ol>;
const OL = ({ children }: { children: React.ReactNode; node: CustomElement }) => <ul>{children}</ul>;
const LI = ({ children }: { children: React.ReactNode; node: CustomElement }) => <li>{children}</li>;

const Q1 = (props: { children: React.ReactNode }) => <blockquote>{props.children}</blockquote>;
const Q2 = (props: { children: React.ReactNode }) => <blockquote>{props.children}</blockquote>;

const Span = ({ children }: { children: React.ReactNode; node: CustomElement }) => <span>{children}</span>;

const T1 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const T2 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

const D1 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const D2 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

const N1 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const N2 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const N3 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const N4 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const N5 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

const B1 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const B2 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const B3 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;
const B4 = (props: { children: React.ReactNode }) => <div>{props.children}</div>;

const Image = ({ node }: { children: React.ReactNode; node: ImageElement }) => {
  const size = node.size;

  const srcSet = node.srcSet
    ?.reduce((acc: Array<string>, cur) => {
      const item = [cur.src, cur.dimensions.width + 'w', cur.dimensions.height + 'h']
        .filter((item) => item !== undefined)
        .join(' ');

      acc.push(item);

      return acc;
    }, [])
    .join(', ');

  return <img src={node.src} srcSet={srcSet} width={size?.[0]} height={size?.[1]} />;
};

const Link = ({ children, node }: { children: React.ReactNode; node: LinkElement }) => {
  return <a href={node.href}>{children}</a>;
};

// Text components
type TextProps = Parameters<RichTextCustomRichTextComponent>[0];

const Bold = (props: TextProps) => <b>{props.children}</b>;
const Italic = (props: TextProps) => <i>{props.children}</i>;
const Underline = (props: TextProps) => <u>{props.children}</u>;
const Strike = (props: TextProps) => <s>{props.children}</s>;
const Code = (props: TextProps) => <code>{props.children}</code>;
const Mark = (props: TextProps) => <mark>{props.children}</mark>;

interface RichTextDefaultContextProps {
  children: React.ReactNode;
}
export const RichTextDefaultProvider = (props: RichTextDefaultContextProps): JSX.Element => {
  const DefaultElementRegistry: Required<Parameters<typeof RichTextRegistryProvider>[0]['registerElements']> = {
    headline1: H1,
    headline2: H2,
    headline3: H3,
    headline4: H4,
    headline5: H5,
    headline6: H6,
    paragraph1: P1,
    paragraph2: P2,
    paragraph3: P3,
    paragraph4: P4,
    label1: L1,
    label2: L2,
    label3: L3,
    label4: L4,
    label5: L5,
    label6: L6,
    blockquote1: Q1,
    blockquote2: Q2,
    span: Span,
    title1: T1,
    title2: T2,
    description1: D1,
    description2: D2,
    navigation1: N1,
    navigation2: N2,
    navigation3: N3,
    navigation4: N4,
    navigation5: N5,
    button1: B1,
    button2: B2,
    button3: B3,
    button4: B4,
    // misc
    paragraph: P1, // @deprecated
    image: Image,
    link: Link,
    'bulleted-list': UL,
    'numbered-list': OL,
    'list-item': LI,
  };

  const DefaultTextRegistry: Required<Parameters<typeof RichTextRegistryProvider>[0]['registerText']> = {
    bold: Bold,
    italic: Italic,
    underline: Underline,
    strike: Strike,
    code: Code,
    highlight: Mark,
  };

  return (
    <RichTextRegistryProvider registerElements={DefaultElementRegistry} registerText={DefaultTextRegistry}>
      {props.children}
    </RichTextRegistryProvider>
  );
};
