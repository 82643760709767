import React from 'react';

/** Represents a Share icon, used on buttons on Share buttons */
export const IconShare = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.855 14.855" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeDasharray="0,0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.855"
        d="M3.11 5.018 6.926.928l3.818 4.09M6.927.928v8.937m-6 0v4.062h12V9.865"
      />
    </svg>
  );
};
