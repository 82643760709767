import { SectionConfigBuilder } from '../ThemeConfigBuilder';
import { body, headline, overline } from '../fields/fields';

const textLabel = 'No Results Message';

export default new SectionConfigBuilder()
  .setContext('picker', {
    label: 'Search Results',
    description: 'Display sermons in search results.',
    icon: 'Sermon',
  })
  .setContext('editor', {
    label: 'Search Results Section',
    description: '',
    icon: 'Sermon',
  })
  .addLayout('layout6', (layout) => {
    layout
      .setDescription('Search Results Only')
      .setIcon('SermonLayout6')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body], textLabel);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([overline, headline, body], textLabel);
        block.setMedia(0);
        block.setActions(0);
      });
  })
  .build();
