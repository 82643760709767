import { inverseAspectRatioAsPercent } from '@nucleus/web-theme';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockInfo } from '../../blocks/BlockInfo';
import { BlockMedia } from '../../blocks/BlockMedia';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { InfoBlockLayoutProps } from './InfoLayout';

export const InfoBlockLayoutDouble = (props: InfoBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const blockIndices = [0, 1];

  return (
    <StyledBlockLayout {...props}>
      {blockIndices.map((blockIndex) => (
        <StyledBlockLayoutSideBySide key={blockIndex} {...innerProps}>
          <BlockMedia media={props.blocks[blockIndex]?.mediaItems?.[0]} />
          <BlockInfo {...innerProps} block={props.blocks[blockIndex]} />
        </StyledBlockLayoutSideBySide>
      ))}
    </StyledBlockLayout>
  );
};

const StyledBlockLayoutSideBySide = styled(BlockLayout)``;

const StyledBlockLayout = styled(BlockLayout)`
  flex-direction: row;
  flex-wrap: wrap;

  ${BlockMedia} {
    & > div {
      padding-top: ${inverseAspectRatioAsPercent(2.4, 1)};
    }
  }

  /* media.tabletPortraitAndUp */
  @media (min-width: 600px) {
    flex-wrap: nowrap;

    & ${StyledBlockLayoutSideBySide} {
      width: 50%;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      ${SpacerTop} {
        display: none;
      }

      // vertical separator line between blocks
      &:first-child {
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 1px;
          top: 0;
          width: 1px;
          height: 100%;
          background: var(--color-media-background);
          opacity: 0.7;
        }
      }
    }
  }

  ${StyledBlockLayoutSideBySide} + ${StyledBlockLayoutSideBySide} {
    --color-media-background: var(--color-media-background2);
  }
`;
