import { SermonSearchRequestSectionWeb } from '@nucleus/types/web/sections/SermonSearchRequestSection';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { BlockLayout } from '../../blockLayouts/Base';
import { BlockSermonSearchRequest } from '../../blocks/BlockSermonSearchRequest';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SectionLayout } from '../../sectionLayouts/SectionLayout';

export const SermonSearchRequestSection = (): JSX.Element => {
  const section = useSection<SermonSearchRequestSectionWeb>();

  return (
    <Section className={classNames(nucleusClass('section-search-request'), getSectionClasses(section))}>
      <SectionLayout {...section.payload}>
        <BlockLayout {...section.payload}>
          <BlockSermonSearchRequest {...section.payload} block={section.payload.blocks[0]} />
        </BlockLayout>
      </SectionLayout>
    </Section>
  );
};
