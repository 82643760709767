import { ContentData, ContentType } from '@nucleus/types/web';
import React, { useContext } from 'react';
import { MarkupRegistryContext } from '../markup/MarkupRegistryContext';
import { SectionErrorBoundary } from '../sections/SectionErrorBoundary';
import { SectionProvider } from '../sections/SectionProvider';
import { SectionsContext } from '../sections/SectionsContext';
import { ContentLoaderProps } from './ContentLoader';

export const isSection = (
  data: ContentLoaderProps['data']
): data is Extract<ContentData, { type: typeof ContentType.Section }> => data.type === ContentType.Section;

// const isPendingAuth = (section: SectionData): section is PendingAuthorizationSectionData<> => 'auth' in section;

interface SectionHandlerProps {
  data: Extract<ContentData, { type: typeof ContentType.Section }>;
}
export const ContentSectionLoader = ({ data }: SectionHandlerProps): JSX.Element | null => {
  const { getSection } = useContext(SectionsContext);
  const { getByType } = useContext(MarkupRegistryContext);

  const section = getSection(data.payload.sectionId);

  if (section === undefined) {
    console.error('Section not found', data.payload.sectionId);
    return null;
  }

  // TODO: enable when `PendingAuthorizationSectionData` is figured out
  // if (isPendingAuth(section)) {
  //   return <>NOT AUTHORIZED</>;
  // }

  const MarkupComponent = getByType(section.type);
  if (MarkupComponent === undefined) {
    console.error('Markup Component not found', section.type);
    return null;
  }

  return (
    <SectionErrorBoundary fallbackRender={({ error }) => <SectionErrorFallback error={error} />}>
      <SectionProvider section={section}>
        <MarkupComponent />
      </SectionProvider>
    </SectionErrorBoundary>
  );
};

const SectionErrorFallback = ({ error }: { error: Error }) => {
  const { getByType, sectionRenderErrorData } = useContext(MarkupRegistryContext);

  const MarkupComponent = getByType(sectionRenderErrorData?.type ?? '');

  if (sectionRenderErrorData === undefined || MarkupComponent === undefined) {
    return (
      <div>
        There was an error!
        <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
      </div>
    );
  }

  return (
    <SectionProvider section={sectionRenderErrorData}>
      <MarkupComponent />
    </SectionProvider>
  );
};
