import { ThemeContext, useSiteCssSnippets } from '@nucleus/web-theme';
import { TextGlobalStyle } from '@nucleus/web-theme-elements';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { useEnabledCodeSnippets } from '../hooks/useEnabledCodeSnippets';
import { ColorGlobalStyle } from './Color';
import { GlobalStyle } from './GlobalStyle';

export const ThemeGlobalStyle = (): JSX.Element => {
  const theme = React.useContext(ThemeContext);
  const cssSnippets = useSiteCssSnippets();
  const enabledCssSnippets = useEnabledCodeSnippets(cssSnippets);

  return (
    <>
      <BaseGlobalStyle />
      <ColorGlobalStyle colorPalette={theme.colors.lightMode} />
      <TextGlobalStyle fontPalette={theme.fonts.styles} baseScale={theme.fonts.baseScale} scale={theme.fonts.scale} />
      {enabledCssSnippets.map((snippet) => (
        <GlobalStyle key={snippet.id} content={snippet.content} />
      ))}
    </>
  );
};

const BaseGlobalStyle = createGlobalStyle`
:root {
  --min-unit-length: 12px;
  --max-unit-length: 45px;

  --the-unit-length: 3vw;

  --unit-length: clamp(var(--min-unit-length), var(--the-unit-length), var(--max-unit-length));

  --section-padding-multiplier: 1;
}

html {
  --header-content-height: max(calc(4.6 * var(--min-unit-length)), calc(1.9 * var(--unit-length)));

  box-sizing: border-box;
  font-size: 10px;
  min-width: 320px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#root {
  background: #fff;
}
`;
