import { SectionDataWeb } from '@nucleus/types/web';
import React from 'react';
import { MarkupComponent } from './components/markup/MarkupRegistryContext';
import { MarkupRegistryProvider } from './components/markup/MarkupRegistryProvider';

export const registry = (
  sections: Array<[string, MarkupComponent]>,
  options: { sectionRenderErrorData?: SectionDataWeb } = {}
): {
  // Sections: Array<string>;
  Provider: (props: { children: React.ReactNode }) => JSX.Element;
} => {
  const provider = (props: { children: React.ReactNode }) => {
    return (
      <MarkupRegistryProvider register={sections} sectionRenderErrorData={options.sectionRenderErrorData}>
        {props.children}
      </MarkupRegistryProvider>
    );
  };

  return {
    // Sections: sections.map(([, component]) => component), // TODO: not sure what this is for
    Provider: provider,
  };
};
