import React from 'react';

/** Icon Representing the Sermon List View */
export const IconListView = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <rect width="21" height="9" rx="1" />
        <rect width="21" height="9" y="11" rx="1" />
      </g>
    </svg>
  );
};
