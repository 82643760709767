import { Alignment, Height, InfoMaxWidth, MediaWeb } from '@nucleus/types/web';
import { convertFocalPointToBackgroundPositionCss } from '@nucleus/web-theme';
import { SIZE, booleanPropHelperFactory, media, nucleusClass, positionToFlexStyles } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { InfoButtons } from '../../components/InfoButtons';
import { SectionHeader } from '../../components/SectionHeader';
import { WrappedStyledRichText } from '../../components/WrappedRichText';
import { GalleryPreview } from '../../components/galleries/GalleryPreview';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import {
  GalleryBlockLayoutName,
  GalleryBlockLayoutProps,
  GallerySectionLayoutName,
  GallerySectionLayoutProps,
} from './types';

const DEFAULT_INFO_WIDTH = 'medium';
const DEFAULT_SECTION_HEIGHT = 'medium';
const DEFAULT_BODY_MAX_COLUMNS = 1;

const SectionHeightStyles = {
  [Height.Small]: css`
    min-height: auto;
    --section-inset-padding-vertical-rems: 3;
    --section-inset-padding-horizontal-rems: 6;
    --section-block-padding-vertical-rems: 3;
    --section-block-padding-horizontal-rems: 6;
  `,
  [Height.Medium]: css`
    min-height: auto;
    --section-inset-padding-vertical-rems: 9;
    --section-inset-padding-horizontal-rems: 9;
    --section-block-padding-vertical-rems: 9;
    --section-block-padding-horizontal-rems: 9;
  `,
  [Height.Large]: css`
    min-height: 100vh;
    --section-inset-padding-vertical-rems: 9;
    --section-inset-padding-horizontal-rems: 9;
    --section-block-padding-vertical-rems: 9;
    --section-block-padding-horizontal-rems: 9;
  `,
};

const InfoMaxWidthCssBySize = {
  Half: {
    [InfoMaxWidth.Small]: css`
      max-width: 34rem;
    `,
    [InfoMaxWidth.Medium]: css`
      max-width: 45rem;
    `,
    [InfoMaxWidth.Large]: css`
      max-width: 60rem;
    `,
  },
  Full: {
    [InfoMaxWidth.Small]: css`
      max-width: 68rem;
    `,
    [InfoMaxWidth.Medium]: css`
      max-width: 90rem;
    `,
    [InfoMaxWidth.Large]: css`
      max-width: 120rem;
    `,
  },
};

const BlockInfo = (
  props: GallerySectionLayoutProps & {
    block: GalleryBlockLayoutProps['blocks'][0];
    sectionSize: 'Half' | 'Full';
  }
) => {
  const infoPosition = props.block?.infoPosition ?? props.blockInfoPosition;
  const headlineAlignment = props.block?.headlineAlignment ?? props.blockHeadlineAlignment;
  const headlineMaxWidth = props.block?.headlineMaxWidth ?? props.blockHeadlineMaxWidth ?? DEFAULT_INFO_WIDTH;
  const bodyAlignment = props.block?.bodyAlignment ?? props.blockBodyAlignment;
  const bodyMaxWidth = props.block?.bodyMaxWidth ?? props.blockBodyMaxWidth ?? headlineMaxWidth;
  const buttonsAlignment = props.block?.buttonsAlignment ?? props.blockButtonsAlignment;
  const buttonsMaxWidth = props.block?.buttonsMaxWidth ?? props.blockButtonsMaxWidth ?? headlineMaxWidth;
  const buttonsLayout = props.block?.buttonsLayout ?? props.blockButtonsLayout;
  const buttonsWidth = props.block?.buttonsWidth ?? props.blockButtonsWidth;

  const headlineMaxWidthCss = InfoMaxWidthCssBySize[props.sectionSize][headlineMaxWidth];
  const bodyMaxWidthCss = InfoMaxWidthCssBySize[props.sectionSize][bodyMaxWidth];
  const buttonsMaxWidthCss = InfoMaxWidthCssBySize[props.sectionSize][buttonsMaxWidth];

  const buttons = props.block?.buttons;

  return (
    <BlockContent headlineAlignment={headlineAlignment} bodyAlignment={bodyAlignment} infoPosition={infoPosition}>
      <BlockContentInner>
        {spacing.top}
        <BlockInfoContainer maxWidthCss={headlineMaxWidthCss} alignment={headlineAlignment}>
          <Overline nodes={props.block?.overline} />
          <Headline nodes={props.block?.headline} />
          <Byline nodes={props.block?.byline} />
          {props.block?.labels && (
            <Labels>{props.block?.labels?.map((label, index) => <Label key={index}>{label.title}</Label>)}</Labels>
          )}
        </BlockInfoContainer>
        <BlockInfoContainer maxWidthCss={bodyMaxWidthCss} alignment={bodyAlignment}>
          <Body nodes={props.block?.body} />
        </BlockInfoContainer>
        <BlockInfoContainer maxWidthCss={buttonsMaxWidthCss} alignment={buttonsAlignment}>
          <InfoButtons
            buttons={buttons}
            buttonsLayout={buttonsLayout}
            buttonsWidth={buttonsWidth}
            buttonsMaxWidth={buttonsMaxWidth}
            buttonsAlignment={buttonsAlignment}
          />
        </BlockInfoContainer>
      </BlockContentInner>
    </BlockContent>
  );
};

const FullSectionLayout = (props: GallerySectionLayoutProps): JSX.Element => {
  return (
    <SectionLayout className={props.className} background={props.backgroundMedia} sectionHeight={props.height}>
      <SectionBlocksWrapper>{props.children}</SectionBlocksWrapper>
    </SectionLayout>
  );
};

const InsetSectionLayout = (props: GallerySectionLayoutProps): JSX.Element => {
  return (
    <SectionLayout className={props.className} background={props.backgroundMedia} sectionHeight={props.height}>
      <SpacerTop />
      <InsetSectionBlocksWrapper>{props.children}</InsetSectionBlocksWrapper>
    </SectionLayout>
  );
};

const InsetPlusInfoSectionLayout = (props: GallerySectionLayoutProps): JSX.Element => {
  const renderSectionHeader = props.insetBlock !== undefined;

  return (
    <SectionLayout className={props.className} background={props.backgroundMedia} sectionHeight={props.height}>
      <SpacerTop />
      {renderSectionHeader && <SectionHeader {...props.insetBlock} />}
      <InsetSectionBlocksWrapper>{props.children}</InsetSectionBlocksWrapper>
    </SectionLayout>
  );
};
type GallerySectionLayoutComponent = (props: GallerySectionLayoutProps) => JSX.Element;

export const GallerySectionLayoutComponentMap: Record<GallerySectionLayoutName, GallerySectionLayoutComponent> = {
  Full: FullSectionLayout,
  Inset: InsetSectionLayout,
  InsetPlusInfo: InsetPlusInfoSectionLayout,
};

const HorizontalBlockLayout = (props: GalleryBlockLayoutProps): JSX.Element => {
  const items = props.block?.items;
  const hasItems = items && items.length > 0;
  const isLayoutFlipped = props.blockLayoutVariant === 'variant2';

  return (
    <HorizontalBlock className={props.className} isLayoutFlipped={isLayoutFlipped}>
      {hasItems && (
        <BlockGallery>
          <GalleryPreview.Adaptive id="gallery-id" items={items ?? []} />
        </BlockGallery>
      )}
      <BlockInfo {...props} block={props.block} sectionSize="Half" />
    </HorizontalBlock>
  );
};

const VerticalBlockLayout = (props: GalleryBlockLayoutProps): JSX.Element => {
  const items = props.block?.items;
  const hasItems = items && items.length > 0;
  const isLayoutFlipped = props.blockLayoutVariant === 'variant2';

  return (
    <VerticalBlock className={props.className} isLayoutFlipped={isLayoutFlipped}>
      {hasItems && (
        <BlockGallery>
          <GalleryPreview.Adaptive id="gallery-id" items={items ?? []} />
        </BlockGallery>
      )}
      <BlockInfo {...props} block={props.block} sectionSize="Full" />
    </VerticalBlock>
  );
};

const ContentOnlyBlockLayout = (props: GalleryBlockLayoutProps): JSX.Element => {
  const isLayoutFlipped = props.blockLayoutVariant === 'variant2';
  const bodyMaxColumns = props.block?.bodyMaxColumns ?? props.blockBodyMaxColumns;
  const items = props.block?.items;
  const hasItems = items && items.length > 0;

  return (
    <ContentOnlyBlock className={props.className} isLayoutFlipped={isLayoutFlipped} bodyMaxColumns={bodyMaxColumns}>
      {hasItems && (
        <BlockGallery>
          <GalleryPreview.Adaptive id="gallery-id" items={items ?? []} />
        </BlockGallery>
      )}
    </ContentOnlyBlock>
  );
};

type GalleryBlockLayoutComponent = (props: GalleryBlockLayoutProps) => JSX.Element;

export const GalleryBlockLayoutComponentMap: Record<GalleryBlockLayoutName, GalleryBlockLayoutComponent> = {
  Horizontal: HorizontalBlockLayout,
  Vertical: VerticalBlockLayout,
  Collage: VerticalBlockLayout, // TODO: add collage layout
  ContentOnly: ContentOnlyBlockLayout,
};

// Base Components
const StyledOverline = styled.div``;

const Overline = WrappedStyledRichText(StyledOverline);

const StyledHeadline = styled.div``;

const Headline = WrappedStyledRichText(StyledHeadline);

const StyledByline = styled.div``;

const Byline = WrappedStyledRichText(StyledByline);

const StyledBody = styled.div`
  margin-top: max(2rem, 1.5vw);
`;

const Body = WrappedStyledRichText(StyledBody);

const Labels = styled.div`
  font-size: 0.75em;
  display: flex;
  gap: ${SIZE[1]};
  padding: ${SIZE[1]} 0;
`;

const Label = styled.span`
  padding: 0.2rem;
  background: #f1f1f1; // TODO: theme color
`;

type BlockContentProps = {
  headlineAlignment?: GalleryBlockLayoutProps['blockHeadlineAlignment'];
  bodyAlignment?: GalleryBlockLayoutProps['blockBodyAlignment'];
  infoPosition?: GalleryBlockLayoutProps['blockInfoPosition'];
};

const BlockContent = styled.div<BlockContentProps>`
  flex: 1 1 auto;
  display: flex;
  ${(props) => positionToFlexStyles(props.infoPosition ?? 'center')};
  text-align: ${(props) => props.headlineAlignment ?? 'left'};

  ${StyledOverline} {
    text-align: ${(props) => props.headlineAlignment ?? 'left'};
  }

  ${StyledHeadline} {
    text-align: ${(props) => props.headlineAlignment ?? 'left'};
  }

  ${StyledBody} {
    text-align: ${(props) => props.bodyAlignment ?? 'left'};
  }

  ${InfoButtons} {
  }

  padding: calc(var(--section-block-padding-vertical-rems) * 1rem * 0.5)
    calc(var(--section-block-padding-horizontal-rems) * 1rem * 0.5);

  ${media.tabletLandscapeAndUp`
    padding: calc(var(--section-block-padding-vertical-rems) * 1rem * 0.666666666666667) calc(var(--section-block-padding-horizontal-rems) * 1rem);
  `}

  ${media.smallDesktopAndUp`
    padding: calc(var(--section-block-padding-vertical-rems) * 1rem) calc(var(--section-block-padding-horizontal-rems) * 1rem);
  `}
`;

const BlockContentInner = styled.div`
  display: inline-grid;
  grid-template-columns: minmax(0, max-content);
`;

interface BlockInfoContainerProps {
  maxWidthCss?: ReturnType<typeof css>;
  alignment?: Alignment;
  children?: React.ReactNode;
}

const BlockInfoContainerInner = styled.div<BlockInfoContainerProps>``;

const BlockInfoContainerOuter = styled.div<BlockInfoContainerProps>`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  ${(props) => positionToFlexStyles(props.alignment ?? 'left', true)};

  ${BlockInfoContainerInner} {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    ${(props) => props.maxWidthCss};
    ${(props) => positionToFlexStyles(props.alignment ?? 'left', true)};
  }

  &:first-child > ${BlockInfoContainerInner} > :first-child {
    margin-top: 0;
  }

  &:first-child > ${BlockInfoContainerInner} > :first-child {
    margin-bottom: 0;
  }
`;

const BlockInfoContainer = (props: BlockInfoContainerProps) => {
  return (
    <BlockInfoContainerOuter maxWidthCss={props.maxWidthCss} alignment={props.alignment}>
      <BlockInfoContainerInner>{props.children}</BlockInfoContainerInner>
    </BlockInfoContainerOuter>
  );
};

const BlockGallery = styled.div.attrs({
  className: nucleusClass('gallery-block'),
})``;

const BaseBlockStyles = css`
  background-color: var(--color-section-background);
  color: var(--color-section-text);
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${media.tabletLandscapeAndUp`
    flex-wrap: nowrap;
  `}
`;

const isLayoutFlipped = booleanPropHelperFactory('isLayoutFlipped');

type BlockProps = {
  isLayoutFlipped?: boolean;
  bodyMaxColumns?: number;
};

// Block Styles
const HorizontalBlock = styled.div<BlockProps>`
  ${BaseBlockStyles}

  ${BlockContent} {
    width: 100%;
    flex: 1 1 auto;
    order: 2;
  }

  ${BlockGallery} {
    width: 100%;
    flex: 0 1 auto;
    order: 1;
  }

  ${media.tabletLandscapeAndUp`
    ${BlockContent} {
      width: 50%;
      order: 2;
    }

    ${BlockGallery} {
      width: 50%;
      order: 1;
    }
  `}

  ${isLayoutFlipped(css`
    ${BlockContent} {
      flex: 1 1 auto;
      order: 2;
    }

    ${BlockGallery} {
      flex: 0 1 auto;
      order: 1;
    }

    ${media.tabletLandscapeAndUp`
      ${BlockContent} {
        order: 1;
      }

      ${BlockGallery} {
        order: 2;
      }
    `}
  `)}
`;

const VerticalBlock = styled.div<BlockProps>`
  ${BaseBlockStyles}

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${BlockContent} {
    order: 2;
  }

  ${BlockGallery} {
    order: 1;
  }

  ${isLayoutFlipped(css`
    ${BlockContent} {
      order: 1;
    }

    ${BlockGallery} {
      order: 2;
    }
  `)}
`;

const ContentOnlyBlock = styled.div<BlockProps>`
  ${BaseBlockStyles}

  ${StyledBody} {
    column-count: ${(props) => props.bodyMaxColumns ?? DEFAULT_BODY_MAX_COLUMNS};
    column-width: 34rem;
    column-gap: 5rem;
  }
`;

const SectionBlocksWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

const InsetSectionBlocksWrapper = styled(SectionBlocksWrapper)`
  padding: calc(var(--section-inset-padding-vertical-rems) * 1rem * 0.4)
    calc(var(--section-inset-padding-horizontal-rems) * 1rem * 0.4);

  ${media.tabletLandscapeAndUp`
    padding: calc(var(--section-inset-padding-vertical-rems) * 1rem * 0.666666666666667) calc(var(--section-inset-padding-horizontal-rems) * 1rem);
  `}

  ${media.smallDesktopAndUp`
    padding: calc(var(--section-inset-padding-vertical-rems) * 1rem) calc(var(--section-inset-padding-horizontal-rems) * 1rem);
  `}
`;

type SectionLayoutProps = {
  background?: MediaWeb;
  sectionHeight?: Height;
};
const SectionLayout = styled.div<SectionLayoutProps>`
  color: ${(props) => (props.background?.image !== undefined ? '#fff' : '#000')};
  background-color: ${(props) => props.background?.color?.hex ?? 'var(--color-section-background)'};

  ${(props) =>
    props.background?.image &&
    css<SectionLayoutProps>`
      background-image: url(${(props) => props.background?.image?.src});
      background-size: ${(props) => props.background?.image?.scale ?? 'cover'};
      background-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.background?.image?.focalPoint)};
      background-repeat: ${(props) => props.background?.image?.tile ?? 'no-repeat'};
    `}

  position: relative;
  display: flex;
  flex-direction: column;

  ${(props) => SectionHeightStyles[props.sectionHeight ?? DEFAULT_SECTION_HEIGHT]}

  & > * {
    z-index: 1;
    position: relative;
  }

  // backdrop
  ${(props) =>
    props.background?.image?.src !== undefined &&
    css`
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 0;
      }
    `}
`;
