import React, { useEffect, useRef } from 'react';

type Props = {
  innerHTML: string;
};

export const HTMLElement = ({ innerHTML }: Props): React.ReactNode => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parentElement = ref.current;
    if (parentElement !== null && innerHTML !== undefined) {
      while (parentElement.firstChild) {
        parentElement.removeChild(parentElement.firstChild);
      }
      // https://dev.to/christo_pr/render-dangerous-content-with-react-2j7j
      const parsedHTML = document.createRange().createContextualFragment(innerHTML);
      parentElement.appendChild(parsedHTML);
    }
  }, []);

  return <div ref={ref} />;
};
