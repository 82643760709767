export const Namespace = 'site';
export const NamespaceSeparator = '/';

export const SiteBondsCategory = 'PageEntity';
export const KEY_PREFIX_ACTIONITEM = 'actionitem';
export const KEY_PREFIX_CODESNIPPET = 'codesnippet';
export const KEY_PREFIX_COLLECTION = 'collection';
export const KEY_PREFIX_REDIRECT = 'redirect';

export const HeaderStackName = 'Header';
export const FooterStackName = 'Footer';

export const WebsiteSetupChecklistItems = Object.freeze([
  'checkedDesign',
  'checkedHomepage',
  'checkedNavigation',
  'checkedPrivacyPages',
  'checkedCustomPage',
  'checkGoingLiveInfo',
]);
