import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

export interface MenuListContext {
  setChildMenuListIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Context = React.createContext<MenuListContext>(null!);
Context.displayName = 'MenuListContext';

export const useMenuListContext = (): MenuListContext => React.useContext(Context);

export interface MenuListProps extends React.HTMLAttributes<HTMLUListElement> {
  /**
   * Indicates whether the MenuList is visible.
   */
  isVisible?: boolean;
}

/**
 * The MenuList component is used to render a <ul> list of menu items. It can manage the visibility of child elements
 * and applies animations for sliding and fading in/out.
 *
 * Intended to be used with <MenuItem> for automatic nested menu management
 *
 * @param props - The props for the MenuList component
 * @returns A JSX element representing the MenuList.
 */
export const MenuList = ({ isVisible = true, ...props }: MenuListProps): JSX.Element => {
  const [childMenuListIsVisible, setChildMenuListIsVisible] = React.useState(false);

  const context: MenuListContext = {
    setChildMenuListIsVisible: setChildMenuListIsVisible,
  };

  const currentPosition = childMenuListIsVisible ? 'left' : isVisible ? 'center' : 'right';

  return (
    <Context.Provider value={context}>
      <List {...props} as={animated.ul} style={{ ...props.style, ...useAnimatedPosition(currentPosition) }}>
        {props.children}
      </List>
    </Context.Provider>
  );
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 3px;
  list-style: none;
  margin: 0;
  min-width: 20rem;
  opacity: 1;
  padding: 0;
  transition: left 200ms ease-in-out;
  transition: opacity 0.05s ease-out;
`;

type Position = 'left' | 'center' | 'right';

/** Slide left coming in, and fade right going out */
const useAnimatedPosition = (position: Position) => {
  const next = React.useCallback((position: Position) => {
    switch (position) {
      case 'left': {
        return {
          x: '-100%',
          opacity: 1,
          display: 'none',
        };
      }

      case 'center': {
        return {
          x: '0%',
          opacity: 1,
          display: 'flex',
        };
      }

      case 'right':
      default: {
        return {
          x: '100%',
          opacity: 0,
          display: 'none',
        };
      }
    }
  }, []);

  return useSpring({
    to: next(position),
    config: {
      max: 0.5,
      tension: 1000,
      friction: 55,
    },
  });
};
