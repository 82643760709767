import { Site } from '@nucleus/types/web/site';
import { createContext } from 'react';
import { DataResourceProviderProps } from '../data/dataResourceContext';

export type ApplicationContextValue = {
  DataResourceProvider: (props: DataResourceProviderProps) => JSX.Element;
  getSite: () => Site;
};

export const ApplicationContext = createContext<ApplicationContextValue>({} as ApplicationContextValue);
