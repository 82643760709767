import { Alignment, Position } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCover } from '../../blocks/BlockCover';
import { SectionBackground } from '../../components/Background';
import { Overline } from '../../components/Base';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { InfoBlockLayoutProps, getPositionRow } from './InfoLayout';

export const InfoBlockLayoutCover = (props: InfoBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const row = getPositionRow(props.blockInfoPosition);

  const renderBackground = props.backgroundMedia !== undefined;

  return (
    <StyledBlockLayout {...props} bodyAlignment={props.blocks[0]?.bodyAlignment ?? props.blockBodyAlignment} row={row}>
      {renderBackground && <SectionBackground background={props.backgroundMedia} />}
      <SpacerTop />
      <StyledInfoContainer>
        <BlockCover {...innerProps} block={props.blocks[0]} />
        <SpacerTop />
      </StyledInfoContainer>
    </StyledBlockLayout>
  );
};

const StyledInfoContainer = styled.div``;

const StyledBlockLayout = styled(BlockLayout)<{ bodyAlignment?: Alignment; row: Position }>`
  ${StyledInfoContainer} {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    padding-top: ${(props) => (props.row === Position.Center ? 'calc(var(--unit-length) * 1.5)' : undefined)};

    ${SpacerTop} {
      display: none;
      @media (min-width: 1020px) {
        display: ${(props) => (props.row === Position.Center ? 'block' : undefined)};
      }
    }
  }

  ${Overline} {
    text-align: ${(props) => props.bodyAlignment ?? 'left'};
  }
`;
