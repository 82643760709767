import { ThemeContext } from '@nucleus/web-theme';
import React from 'react';
import { get as _get } from 'lodash';
import { ButtonConfig, ButtonPaletteConfig, ButtonPaletteKey } from '@nucleus/types/web';

export const useButtonConfig = (buttonStyle: keyof ButtonPaletteConfig['styles']): ButtonConfig | undefined => {
  const theme = React.useContext(ThemeContext);
  return _get(theme, `buttons.styles.${buttonStyle}`);
};

export const withButtonConfig = <T extends React.FunctionComponent>(
  WrappedComponent: T,
  style: ButtonPaletteKey
): T => {
  return Object.assign(
    (props: any) => {
      return <WrappedComponent {...props} {...useButtonConfig(style)} />;
    },
    { displayName: 'withButtonConfig' }
  ) as T;
};
