import React, { useContext } from 'react';
import { ApplicationContext } from '../application/ApplicationContext';
import { SiteContext, SiteContextValue } from './SiteContext';
import { RichTextParameterContext } from '@nucleus/web-theme-elements';

interface SiteProviderProps {
  children: React.ReactNode;
}
export const SiteProvider = (props: SiteProviderProps): JSX.Element => {
  const { getSite } = useContext(ApplicationContext);

  // could be used to refresh site data after initial load
  const site = getSite();

  const context: SiteContextValue = {
    getSite: getSite,
  };

  return (
    <RichTextParameterContext parameters={{ ...site.parameters }}>
      <SiteContext.Provider value={context}>{props.children}</SiteContext.Provider>
    </RichTextParameterContext>
  );
};
