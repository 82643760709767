import React from 'react';

type Props = { color?: string } & React.SVGAttributes<SVGSVGElement>;

/** This is the newer, fatter angle icon, used as a dropdown indicator on buttons */
export const IconAngleDown = ({ color, ...props }: Props): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" />
        <g transform="rotate(90 12 12)" stroke={color ?? 'currentColor'} strokeLinecap="round" strokeWidth="3">
          <path d="M11.224 8.297l2.845 3.57M11.224 15.569l2.845-3.57" />
        </g>
      </g>
    </svg>
  );
};
