import {
  ThemeCustomElement as CustomElement,
  ThemeCustomRichText as CustomRichText,
  ThemeRichTextElement as RichTextElement,
} from '@nucleus/types/web';
import React, { useContext } from 'react';

export type RichTextElementComponent<T extends RichTextElement> = (props: {
  children: React.ReactNode;
  node: T;
}) => JSX.Element | null;

export type RichTextElementComponentMap<T extends RichTextElement = RichTextElement> = {
  [P in T['type']]: Extract<T, { type: P }> extends never
    ? RichTextElementComponent<CustomElement>
    : RichTextElementComponent<Extract<T, { type: P }>>;
};

export type RichTextCustomRichTextComponent = (props: { children: React.ReactNode }) => JSX.Element | null;

export type RichTextCustomRichTextComponentMap = {
  [p in keyof Required<Omit<CustomRichText, 'text'>>]: RichTextCustomRichTextComponent;
};

interface RichTextRegistryController {
  getElementMap: () => RichTextElementComponentMap;

  getElementByType: (key: keyof RichTextElementComponentMap) => RichTextElementComponent<any>;

  getTextMap: () => RichTextCustomRichTextComponentMap;

  getTextByType: (key: keyof RichTextCustomRichTextComponentMap) => RichTextCustomRichTextComponentMap[typeof key];
}

const RichTextRegistryContext = React.createContext({} as unknown as RichTextRegistryController);

interface RichTextRegistryProviderProps {
  children: React.ReactNode;
  registerElements: Partial<RichTextElementComponentMap>;
  registerText: Partial<RichTextCustomRichTextComponentMap>;
}
export const RichTextRegistryProvider = (props: RichTextRegistryProviderProps): JSX.Element => {
  return (
    <RichTextRegistryContext.Provider value={useControllerValue(props.registerElements, props.registerText)}>
      {props.children}
    </RichTextRegistryContext.Provider>
  );
};

export const useRichTextRegistryProvider = (): RichTextRegistryController => {
  const controller = useContext(RichTextRegistryContext);
  return controller;
};

const useControllerValue = (
  registerElements: RichTextRegistryProviderProps['registerElements'],
  registerText: RichTextRegistryProviderProps['registerText']
): RichTextRegistryController => {
  const { getElementMap, getTextMap } = useRichTextRegistryProvider();

  const elementMap = {
    ...(getElementMap !== undefined ? getElementMap() : {}),
    ...registerElements,
  } as RichTextElementComponentMap;

  const textMap = {
    ...(getTextMap !== undefined ? getTextMap() : {}),
    ...registerText,
  } as RichTextCustomRichTextComponentMap;

  return {
    getElementMap: () => elementMap,

    getElementByType: (key: keyof RichTextElementComponentMap) => elementMap[key],

    getTextMap: () => textMap,

    getTextByType: (key: keyof RichTextCustomRichTextComponentMap) => textMap[key],
  };
};
