import React from 'react';
import { PostHubContext, PostHubContextValue } from './PostHubContext';

interface PostHubProviderProps {
  children: React.ReactNode;
  hubId: string;
}
export const PostHubProvider = (props: PostHubProviderProps): JSX.Element => {
  const context: PostHubContextValue = {
    hubId: props.hubId,
  };

  return <PostHubContext.Provider value={context}>{props.children}</PostHubContext.Provider>;
};
