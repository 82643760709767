import React from 'react';
import styled from 'styled-components';
import { Thumbnail } from './Thumbnail';
import { DurationBadge } from './DurationBadge';
import { ImageWeb } from '@nucleus/types/media/image';
import { formatSrcSet } from '../lib/media';
import { Link, useNavigate } from 'react-router-dom';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    MediaListItem?: {
      color?: string;
      background?: string;
      hover?: {
        background?: string;
        shadow?: string;
      };
    };
  }
}

interface Props {
  actions?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  duration?: React.ReactNode;
  image?: ImageWeb;
  style?: React.CSSProperties;
  to: string;
}

export const MediaListItem = (props: Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Card className={props.className} style={props.style} onClick={(e) => !e.defaultPrevented && navigate(props.to)}>
      <Media>
        <Link to={props.to}>
          <ThumbnailContainer>
            <Thumbnail
              src={props.image?.src}
              srcSet={formatSrcSet(props.image?.srcSet)}
              blurHash={props.image?.blurHash}
            />
            <Button />
            {props.duration && <DurationBadge>{props.duration}</DurationBadge>}
          </ThumbnailContainer>
        </Link>
      </Media>
      <Meta>
        {props.children}
        <Actions onClick={(e) => e.stopPropagation()}>{props.actions}</Actions>
      </Meta>
    </Card>
  );
};

const Card = styled.div`
  background: ${({ theme }) => theme._sermonThemeElements.MediaListItem?.background};
  color: ${({ theme }) => theme._sermonThemeElements.MediaListItem?.color};
  border-radius: 10px;
  min-height: 210px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  gap: 50px;
  position: relative;
  cursor: pointer;

  transition: all 200ms ease-in-out;

  :hover {
    background: ${({ theme }) => theme._sermonThemeElements.MediaListItem?.hover?.background};
    box-shadow: 0px 12px 36px ${({ theme }) => theme._sermonThemeElements.MediaListItem?.hover?.shadow};
  }
`;

const Media = styled.div`
  flex: 0 0 320px;
  width: 320px;
  cursor: default;
`;

const Meta = styled.div`
  flex: 1 1 200px;
  padding-right: 50px;
`;

const Button = styled.button`
  display: none;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${Button} {
    position: absolute;
  }

  ${DurationBadge} {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
  }
`;

const Actions = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;
