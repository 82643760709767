import { Alignment, Position } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCardGrid, StackedGrid } from '../../blocks/BlockCardGrid';
import { BlockCover } from '../../blocks/BlockCover';
import { SectionBackground } from '../../components/Background';
import { Overline } from '../../components/Base';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { CardBlockLayoutProps, getPositionRow } from './CardLayout';

export const CardBlockLayoutCover = (props: CardBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const row = getPositionRow(props.blockInfoPosition);

  const renderBackground = props.backgroundMedia !== undefined;

  return (
    <StyledBlockLayout {...props} bodyAlignment={props.blocks[0]?.bodyAlignment ?? props.blockBodyAlignment} row={row}>
      {renderBackground && <SectionBackground background={props.backgroundMedia} />}
      <SpacerTop />
      <StyledBlockCover {...innerProps} block={props.blocks[0]} />
      <StyledSpacerBottom />
      <StyledBlockCardGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const StyledSpacerBottom = styled(SpacerTop)``;
const StyledBlockCardGrid = styled(BlockCardGrid)``;
const StyledBlockCover = styled(BlockCover)``;

const StyledBlockLayout = styled(BlockLayout)<{ bodyAlignment?: Alignment; row: Position }>`
  ${StyledBlockCardGrid} {
    position: relative;
    z-index: 1;

    background-color: transparent;
    ${StackedGrid} {
      padding: var(--unit-length);
    }
  }

  ${StyledSpacerBottom} {
    display: ${(props) => (props.row === Position.Center ? 'block' : 'none')};
  }

  ${StyledBlockCover} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '60vh';
        case 'large':
          return '80vh';
        default:
          return undefined;
      }
    }};
  }

  ${Overline} {
    text-align: ${(props) => props.bodyAlignment ?? 'left'};
  }
`;
