import { ContentData, ContentType } from '@nucleus/types/web';
import React, { createContext, useContext } from 'react';
import { compositeString } from '../../lib/string';
import { SectionsProvider } from '../sections/SectionsProvider';
import { StacksContext } from '../stacks/StacksContext';
import { ContentLoader, ContentLoaderProps } from './ContentLoader';

const StackedContext = createContext<Array<string>>([]);

type StackContent = Extract<ContentData, { type: typeof ContentType.Stack }>;
export const isStack = (data: ContentLoaderProps['data']): data is StackContent => data.type === ContentType.Stack;

interface StackHandlerProps {
  data: Extract<ContentData, { type: typeof ContentType.Stack }>;
}

export const ContentStackLoader = ({ data }: StackHandlerProps): JSX.Element | null => {
  const { getStack } = useContext(StacksContext);
  const seen = useContext(StackedContext) ?? [];

  const stackId = data.payload.stackId;
  const found = seen.find((seen) => seen === stackId);

  if (found !== undefined) {
    console.error('section already seen recursive issue', data);
    return null;
  }

  const stack = getStack(stackId);
  if (stack === undefined) {
    console.error('stack not found', stackId);
    return null;
  }

  const content = stack.content['main'] || []; // TODO: stacks probably shouldn't include a Record

  return (
    <StackedContext.Provider value={[...seen, stackId]}>
      <SectionsProvider sections={stack.sections}>
        {content.map((content) => (
          <ContentLoader key={compositeString([data.id, stack.id, content.id])} data={content} />
        ))}
      </SectionsProvider>
    </StackedContext.Provider>
  );
};
