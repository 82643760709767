import { ThemeLifeV1Button } from '@nucleus/web-theme-elements';
import React from 'react';
import { withButtonConfig } from '../hooks/useButtonConfig';
import { withForwardedAs } from './withForwardedAs';

type ButtonsProps = {
  [K in keyof typeof ThemeLifeV1Button]: React.ComponentProps<(typeof ThemeLifeV1Button)[K]>;
};

export const ButtonPrimary = withForwardedAs(withButtonConfig(ThemeLifeV1Button.Primary, 'button1'));
export const ButtonSecondary = withForwardedAs(withButtonConfig(ThemeLifeV1Button.Secondary, 'button2'));
export const ButtonTertiary = withForwardedAs(withButtonConfig(ThemeLifeV1Button.Tertiary, 'button3'));
export const ButtonTextLink = withForwardedAs(withButtonConfig(ThemeLifeV1Button.Alternate, 'button4'));
export const ButtonSecondaryAlt = withForwardedAs(ThemeLifeV1Button.SecondaryAlt);
export const ButtonNavPrimary = withForwardedAs(withButtonConfig(ThemeLifeV1Button.NavPrimary, 'button1'));
export const ButtonNavSecondary = withForwardedAs(withButtonConfig(ThemeLifeV1Button.NavSecondary, 'button1'));
export const ButtonNavTertiary = withForwardedAs(withButtonConfig(ThemeLifeV1Button.NavTertiary, 'button1'));

type SectionButtonProps = ButtonsProps[keyof ButtonsProps] & {
  displayAs?: keyof typeof ButtonStyleToComponent;
};

export const SectionButton = ({ displayAs = 'style1', ...props }: SectionButtonProps): React.ReactElement => {
  const ButtonComponent = ButtonStyleToComponent[displayAs];
  return React.createElement(ButtonComponent, props);
};

const ButtonStyleToComponent = {
  style1: ButtonPrimary,
  style2: ButtonSecondary,
  style3: ButtonTertiary,
  style4: ButtonTextLink,
};
