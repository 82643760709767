import {
  Join,
  Menu,
  MenuItem,
  MenuItemButton,
  MenuItemButtonProps,
  ReactComponentsTheme,
  lengthOfRichText,
  usePopoverState,
} from '@nucleus/react-components';
import {
  BibleReference,
  Button,
  DropdownButton,
  IconShare,
  IconTrashCan,
  IconX,
  InfoCard,
  InfoItem,
  InfoList,
  MediaToggle,
  NavItem,
  NotesCard,
  NotesController,
  NotesEditor,
  NotesToolbar,
  Player,
  SermonDate,
  SermonMediaBlockController,
  SermonSpeakers,
  SermonStructuredData,
  TabBar,
  TabPanel,
  Theater,
  useLocalization,
  useNotesController,
  useSermonMediaBlockController,
  useSermonTag,
  useSpeaker,
} from '@nucleus/sermon-theme-elements';
import { SermonAudioMediaItemWeb, SermonMediaItemWeb } from '@nucleus/sermons/types/Sermon';
import { slugifyString } from '@nucleus/src-platform/data/text';
import { objectKeys } from '@nucleus/src/lib/object';
import { SermonMediaBlockWeb } from '@nucleus/types/web/sections/SermonMediaSection';
import { SermonWeb } from '@nucleus/types/web/sermons/sermon';
import { ErrorBoundary } from '@nucleus/web-theme';
import { Text, buildTransparentColorFromCssVariable, nucleusClass } from '@nucleus/web-theme-elements';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { DefaultTheme, ThemeProvider } from 'styled-components';
import { Body } from '../components/Base';
import { InfoButtons } from '../components/InfoButtons';
import { SpacerTop } from '../sectionLayouts/Spacers';
import { BlockProps } from '../types/component';

type BlockSermonProps = Extract<BlockProps, { block?: SermonMediaBlockWeb; sectionType?: 'sermonMedia' }>;

export const BlockSermonMedia = (props: BlockSermonProps): JSX.Element => {
  return (
    <>
      <SermonStructuredData sermon={props.block?.sermon} />
      <SermonMediaBlockController sermon={props.block?.sermon}>
        <PlayerBlock {...props} />
        <InfoBlockPadding>
          <InfoBlock {...props} />
        </InfoBlockPadding>
      </SermonMediaBlockController>
    </>
  );
};

const InfoBlockPadding = styled.div`
  padding-top: max(calc(var(--unit-length) * 2), 40px);
  padding-left: calc(var(--unit-length) * 1);
  padding-right: calc(var(--unit-length) * 1);
  padding-bottom: calc(var(--unit-length) * 1);
`;

const PlayerBlock = (props: BlockSermonProps) => {
  const { currentMedia } = useSermonMediaBlockController();

  return (
    <Theater className={nucleusClass('media-theater')} spacer={<SpacerTop />}>
      <StagePadding>
        <PlayerSize>
          <Player
            key={currentMedia?.id}
            className={nucleusClass('media-player')}
            url={getMediaItemUrl(currentMedia)}
            thumbnail={currentMedia?.artwork ?? props.block?.sermon?.mainImage?.image}
          />
        </PlayerSize>
      </StagePadding>
    </Theater>
  );
};

const StagePadding = styled.div`
  padding: 0 var(--unit-length);
  @media (min-width: 600px) {
    padding: 0 calc(var(--unit-length) * 4);
  }

  @media (min-width: 900px) {
    padding: 0 calc(var(--unit-length) * 5);
  }

  @media (min-width: 1200px) {
    padding: 0 calc(var(--unit-length) * 7);
  }
`;

const PlayerSize = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const getMediaItemUrl = (mediaItem?: SermonMediaItemWeb) => {
  if (isSermonAudioMediaItem(mediaItem)) {
    return mediaItem.item?.src;
  }
  return mediaItem?.href;
};

const isSermonAudioMediaItem = (item?: SermonMediaItemWeb): item is SermonAudioMediaItemWeb =>
  item?.type === 'file/audio';

const InfoBlock = (props: BlockSermonProps) => {
  return (
    <ColumnDiv>
      <Grid>
        <ToolbarGridItem>
          <MediaToolbar />
        </ToolbarGridItem>
        <InfoGridItem>
          <SermonMetaData {...props} />
          <InfoButtons
            buttons={props.block?.sermon?.buttons}
            buttonsAlignment={props.block?.buttonsAlignment}
            buttonsLayout={props.block?.buttonsLayout}
            buttonsMaxWidth={props.block?.buttonsMaxWidth}
            buttonsWidth={props.block?.buttonsWidth ?? 'stretch'}
          />
        </InfoGridItem>
        <NotesGridItem>
          <Notes sermon={props.block?.sermon} />
        </NotesGridItem>
      </Grid>
    </ColumnDiv>
  );
};

const ToolbarGridItem = styled.div``;
const InfoGridItem = styled.div``;
const NotesGridItem = styled.div``;

const SermonMetaData = (props: BlockSermonProps) => {
  return (
    <Flex className={nucleusClass('sermon-metadata')}>
      <div>
        <Text.H5 className={nucleusClass('sermon-title')} style={{ margin: 0, maxWidth: '90%' }}>
          {props.block?.sermon?.title}
        </Text.H5>
        <Text.L4 className={nucleusClass('sermon-date')}>
          <Join separator=" • ">
            <SermonDate sermon={props.block?.sermon} />
            <SermonSpeakers sermon={props.block?.sermon} />
          </Join>
        </Text.L4>
      </div>
      <ErrorBoundary>
        <SermonInfo {...props} />
      </ErrorBoundary>
    </Flex>
  );
};

const ColumnDiv = styled.div`
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  row-gap: 30px;
  width: 100%;
  margin-left: 0;
  margin-right: auto;

  @media (min-width: 900px) {
    grid-template-columns: 44% 1fr;
    grid-template-rows: auto 1fr;

    column-gap: 20px;
    row-gap: 24px;
    width: calc(100% - 20px);

    ${ToolbarGridItem} {
      order: 2;
    }

    ${InfoGridItem} {
      order: 1;
      grid-row: span 2;
    }

    ${NotesGridItem} {
      order: 3;
    }
  }
`;

const SermonInfo = (props: BlockSermonProps) => {
  const [isAvailable, availableMap] = useAvailableInfo(props);
  const localize = useLocalization();

  const initialState = objectKeys(availableMap).find((key) => availableMap[key] === true);

  const [state, setState] = useState<string | undefined>(initialState);

  if (state === undefined) {
    return null;
  }

  return (
    <Container>
      <TabBar className={nucleusClass('sermon-metadata-tab-bar')} value={state} onChange={setState}>
        {isAvailable('info') && <NavItem id="info">Info</NavItem>}
        {isAvailable('scripture') && <NavItem id="scripture">{localize('scripture', 'Scripture')}</NavItem>}
        {isAvailable('transcript') && <NavItem id="transcript">Transcript</NavItem>}
        {isAvailable('speaker') && <NavItem id="speaker">{localize('speaker', 'Speaker')}</NavItem>}
        {isAvailable('resources') && <NavItem id="resources">Resources</NavItem>}
        {isAvailable('tags') && <NavItem id="tags">{localize('topic', 'Topics')}</NavItem>}
      </TabBar>
      {isAvailable('info') && (
        <TabPanel id="info" value={state}>
          <InfoCard className={nucleusClass('sermon-description')}>
            <Body className="theme-text-paragraph3" nodes={props.block?.sermon?.body} />
          </InfoCard>
        </TabPanel>
      )}
      {isAvailable('scripture') && (
        <TabPanel id="scripture" value={state}>
          <Scripture sermon={props.block?.sermon} />
        </TabPanel>
      )}
      {isAvailable('transcript') && (
        <TabPanel id="transcript" value={state}>
          <InfoCard className={nucleusClass('sermon-transcript')}>
            <Body className="theme-text-paragraph3" nodes={props.block?.sermon?.transcript} />
          </InfoCard>
        </TabPanel>
      )}
      {isAvailable('speaker') && (
        <TabPanel id="speaker" value={state}>
          <Speakers sermon={props.block?.sermon} />
        </TabPanel>
      )}
      {isAvailable('resources') && (
        <TabPanel id="resources" value={state}>
          <InfoList className={nucleusClass('resource-list')}>
            {props.block?.sermon?.attachments?.map((attachment) => (
              <InfoItem key={attachment.id}>
                <Text.P3>
                  <a href={attachment.destination}>{attachment.label}</a>
                </Text.P3>
              </InfoItem>
            ))}
          </InfoList>
        </TabPanel>
      )}
      {isAvailable('tags') && (
        <TabPanel id="tags" value={state}>
          <Tags sermon={props.block?.sermon} />
        </TabPanel>
      )}
    </Container>
  );
};

const useAvailableInfo = (props: BlockSermonProps) => {
  const availableMap = {
    info: props.block?.sermon?.body !== undefined && lengthOfRichText(props.block?.sermon.body) > 0,
    scripture: props.block?.sermon?.references !== undefined && props.block?.sermon?.references.length > 0,
    speaker: props.block?.sermon?.speakers !== undefined && props.block?.sermon?.speakers.length > 0,
    resources: props.block?.sermon?.attachments !== undefined && props.block?.sermon.attachments.length > 0,
    tags: props.block?.sermon?.tags !== undefined && props.block?.sermon?.tags.length > 0,
    transcript: props.block?.sermon?.transcript !== undefined && props.block.sermon.transcript.length > 0,
  } satisfies Record<string, boolean>;

  const isAvailable = (id: keyof typeof availableMap): boolean => availableMap[id] ?? false;

  return [isAvailable, availableMap] as const;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Scripture = (props: { sermon?: SermonWeb }) => {
  if (props.sermon === undefined) {
    return null;
  }
  return (
    <InfoList className={nucleusClass('scripture-list')}>
      {props.sermon.references.map((reference) => (
        <InfoItem key={reference.id}>
          <Text.P3>
            <BibleReference reference={reference} />
          </Text.P3>
        </InfoItem>
      ))}
    </InfoList>
  );
};

const Speakers = (props: { sermon?: SermonWeb }) => {
  if (props.sermon === undefined) {
    return null;
  }
  return (
    <InfoList className={nucleusClass('speaker-list')}>
      {props.sermon?.speakers.map((id) => (
        <ErrorBoundary key={id}>
          <React.Suspense>
            <Speaker id={id} sermon={props.sermon} />
          </React.Suspense>
        </ErrorBoundary>
      ))}
    </InfoList>
  );
};

const Speaker = ({ sermon, id }: { sermon: SermonWeb; id: string }) => {
  const { data, isLoading, isError } = useSpeaker(sermon.engineId, id);
  const localize = useLocalization();

  if (isLoading || isError) {
    return null;
  }

  const speaker = data?.collection;

  const slug = [slugifyString(localize('speakers', 'speakers')), speaker.slug].join('/');

  return (
    <InfoItem>
      <Text.P3>
        <Link to={slug}>{speaker.displayName}</Link>
      </Text.P3>
    </InfoItem>
  );
};

const Tags = (props: { sermon?: SermonWeb }) => {
  if (props.sermon === undefined) {
    return null;
  }

  return (
    <InfoList className={nucleusClass('tag-list')}>
      {props.sermon.tags.map((id) => (
        <ErrorBoundary key={id}>
          <React.Suspense>
            <Tag id={id} sermon={props.sermon} />
          </React.Suspense>
        </ErrorBoundary>
      ))}
    </InfoList>
  );
};

const Tag = ({ sermon, id }: { sermon: SermonWeb; id: string }) => {
  const { data, isLoading, isError } = useSermonTag(sermon.engineId, id);
  const localize = useLocalization();

  if (isLoading || isError) {
    return null;
  }

  const tag = data?.collection;

  const slug = [slugifyString(localize('topics', 'topics')), tag.slug].join('/');

  return (
    <InfoItem>
      <Text.P3>
        <Link to={slug}>{tag.title}</Link>
      </Text.P3>
    </InfoItem>
  );
};

const MediaToolbar = () => {
  const { availableMedia, currentMedia, onMediaChange } = useSermonMediaBlockController();
  return (
    <ToolBar>
      <MediaToggle
        className={nucleusClass('media-toggle')}
        availableMedia={availableMedia}
        value={currentMedia?.type}
        onChange={onMediaChange}
      />
    </ToolBar>
  );
};

const ToolBar = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 12px;

  @media (min-width: 900px) {
    justify-content: flex-end;
  }
`;

const Notes = (props: { sermon?: SermonWeb }) => {
  const clientRender = useClientRender();

  if (clientRender === false) {
    return null;
  }

  return (
    <ErrorBoundary>
      <NotesController sermonId={props.sermon?.id} template={props.sermon?.notes?.[0]?.body}>
        <NotesWrapper sermon={props.sermon} />
      </NotesController>
    </ErrorBoundary>
  );
};

const useClientRender = () => {
  const [state, setState] = useState(false);
  React.useEffect(() => {
    setState(true);
  }, []);
  return state;
};

const NotesWrapper = (props: { sermon?: SermonWeb }) => {
  const { isEnabled } = useNotesController();

  if (!isEnabled) {
    return null;
  }

  return (
    <NotesCard>
      <NotesHeader sermon={props.sermon} />
      <NotesContent />
    </NotesCard>
  );
};

const NotesHeader = (props: { sermon?: SermonWeb }) => {
  const { isFocused, isModified, focusEditor } = useNotesController();

  return (
    <NotesToolbar
      left={<Text.H5 style={{ margin: '0' }}>Notes</Text.H5>}
      right={
        <>
          {isModified && (
            <PrimaryButtonTheme>
              <NotesActionsButton sermon={props.sermon} />
            </PrimaryButtonTheme>
          )}
          {isFocused || isModified ? (
            isModified ? (
              <NotesDeleteDropdownButton />
            ) : (
              <NotesBlurButton />
            )
          ) : (
            <PrimaryButtonTheme>
              <Button onClick={focusEditor}>
                <span style={{ padding: '0 38px' }}>Take Notes</span>
              </Button>
            </PrimaryButtonTheme>
          )}
        </>
      }
    />
  );
};

const NotesActionsButton = (props: { sermon?: SermonWeb }) => {
  const { onCopy, onDownload, onMailTo, onShare } = useNotesController();

  const [handleCopy, isCopied] = useThrottledCallbackWithState(onCopy);

  return (
    <DropdownButton label="Save my Notes">
      <MenuItem>
        <MenuItemButton onClick={() => onMailTo(`My Notes from: ${props.sermon?.title}`)}>
          Email to myself
        </MenuItemButton>
      </MenuItem>
      <MenuItem>
        <MenuItemButton onClick={() => onDownload(`My Notes from: ${props.sermon?.title}`)}>
          Download Notes
        </MenuItemButton>
      </MenuItem>
      <MenuItem>
        <MenuItemButton onClick={handleCopy}>{isCopied ? 'Copied!' : 'Copy all Note Text'}</MenuItemButton>
      </MenuItem>
      {navigator.share !== undefined && (
        <MenuItem>
          <MenuItemButton
            onClick={onShare}
            right={<IconShare style={{ margin: '0 2px 0 12px' }} width="16" height="17" />}
          >
            Sharing Options
          </MenuItemButton>
        </MenuItem>
      )}
    </DropdownButton>
  );
};

const NotesContent = () => {
  const { editorProps } = useNotesController();
  return <NotesEditor {...editorProps} />;
};

const NotesDeleteDropdownButton = () => {
  const { popoverProps, triggerProps } = usePopoverState();
  const { onDelete } = useNotesController();

  return (
    <>
      <Button style={{ width: '50px' }} {...triggerProps}>
        <IconX width="17px" height="17px" />
      </Button>
      <Menu {...popoverProps}>
        <MenuItem>
          <div className="theme-text-button-4" style={{ maxWidth: '212px', padding: '12px' }}>
            Are you sure you want to discard your notes?
          </div>
        </MenuItem>
        <MenuItem>
          <MenuItemButtonWithBackground onClick={onDelete} right={<IconTrashCan width="16" height="21" />}>
            Delete Now
          </MenuItemButtonWithBackground>
        </MenuItem>
      </Menu>
    </>
  );
};

const MenuItemButtonWithBackground = (props: MenuItemButtonProps) => {
  const themeCallback = (theme: { _reactComponents: ReactComponentsTheme }) => ({
    ...theme,
    _reactComponents: {
      ...theme._reactComponents,
      MenuItemButton: {
        ...theme._reactComponents.MenuItemButton,
        background: buildTransparentColorFromCssVariable('--color-dark', 0.05),
      },
    } satisfies ReactComponentsTheme,
  });

  return (
    <ThemeProvider theme={themeCallback}>
      <MenuItemButton {...props} />
    </ThemeProvider>
  );
};

const NotesBlurButton = () => {
  const { blurEditor } = useNotesController();

  return (
    <Button style={{ width: '50px' }} onClick={blurEditor}>
      <IconX width="13.24px" height="13.24px" />
    </Button>
  );
};

const useThrottledCallbackWithState = (func: () => void) => {
  const [isThrottled, setIsThrottled] = React.useState(false);

  const throttledCallback = () => {
    if (isThrottled) {
      return;
    }
    func();
    setIsThrottled(true);
    setTimeout(() => setIsThrottled(false), 1000);
  };

  return [throttledCallback, isThrottled] as const;
};

const PrimaryButtonTheme = (props: { children?: React.ReactNode }) => {
  const themeCallback = (theme: DefaultTheme): DefaultTheme => ({
    ...theme,
    _reactComponents: {
      ...theme._reactComponents,
      MenuButton: {
        background: buildTransparentColorFromCssVariable('--color-button-primary-text', 0.25),
        foreground: `var(--color-button-primary-text)`,
        color: `var(--color-button-primary-background)`,
        hover: {
          background: buildTransparentColorFromCssVariable('--color-button-primary-text', 0.3),
        },
      },
    },
    _sermonThemeElements: {
      ...theme._sermonThemeElements,
      Button: {
        ...theme._sermonThemeElements.Button,
        background: `var(--color-button-primary-background)`,
        color: `var(--color-button-primary-text)`,
        hover: {
          background: `var(--color-button-primary-background-hover)`,
        },
      },
    },
  });

  return <ThemeProvider theme={themeCallback}>{props.children}</ThemeProvider>;
};
