import {
  MediaGrid,
  MediaList,
  MediaListToolbar,
  SermonBlockController,
  ViewToggle,
  useSermonBlockController,
  useSermonSearchController,
} from '@nucleus/sermon-theme-elements';
import { SectionTypeEnum, SermonBlockWeb } from '@nucleus/types/web';
import { SermonSearchResultsBlockWeb } from '@nucleus/types/web/sections/SermonSearchResultsSection';
import { Text, nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import { merge as _merge, omit as _omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { SectionButton } from '../components/Button';
import { LoadingSpinnerBlock } from '../components/LoadingSpinner';
import { SermonSearchUrlController } from '../controllers/SermonSearchUrlController';
import { BlockProps } from '../types/component';
import { BlockInfo } from './BlockInfo';
import { BlockSermon, GridViewItem, ListViewItem, SermonBlockShareMenu } from './BlockSermon';

export type BlockSermonSearchRequestProps = Extract<
  BlockProps,
  { block?: SermonSearchResultsBlockWeb; sectionType?: 'sermonSearchResults' }
>;
type BlockSermonProps = Extract<BlockProps, { block?: SermonBlockWeb; sectionType?: 'sermon' }>;

export const BlockSermonSearchResults = (props: BlockSermonSearchRequestProps): JSX.Element => {
  return (
    <SermonSearchUrlController engineId={props.block?.engineId}>
      <SermonsBlock {...props} />
      <FallbackNoResults {...props} />
    </SermonSearchUrlController>
  );
};

const SermonsBlock = (props: BlockSermonSearchRequestProps) => {
  const { searchQuery, status } = useSermonSearchController();

  if (searchQuery === '') {
    return null;
  }

  const renderLoading = status === 'loading';
  const renderResults = status === 'success';

  return (
    <div className={classNames([props.className, nucleusClass('block-sermons-search-results')])}>
      <SermonBlockController {...props.block}>
        <React.Suspense>
          <ColumnDiv>
            <ViewWrapper {...props}>
              {renderLoading && <LoadingSpinnerBlock />}
              {renderResults && <SwitchSermonListLayout />}
              <NoResults {...props} />
            </ViewWrapper>
          </ColumnDiv>
        </React.Suspense>
      </SermonBlockController>
    </div>
  );
};

const NoResults = (props: BlockSermonSearchRequestProps): JSX.Element | null => {
  const { total, status } = useSermonSearchController();

  if (status === 'loading') {
    return null;
  }

  if (total > 0) {
    return null;
  }

  return <BlockInfo {...props} blockHeadlineMaxWidth="small" />;
};

const FallbackNoResults = (props: BlockSermonSearchRequestProps): JSX.Element | null => {
  const { total } = useSermonSearchController();

  if (total > 0) {
    return null;
  }

  return <BlockSermon {...useRecentSermonsProps(props)} />;
};

const SwitchSermonListLayout = () => {
  const { currentLayout } = useSermonBlockController();

  switch (currentLayout) {
    case 'list':
      return <ListView />;
    case 'grid':
    default:
      return <GridView />;
  }
};

const isListOrGrid = (value: string): value is 'list' | 'grid' => ['list', 'grid'].includes(value);

interface ViewWrapperProps extends BlockSermonSearchRequestProps {
  children: React.ReactNode;
}
const ViewWrapper = (props: ViewWrapperProps) => {
  const { currentLayout } = useSermonBlockController();
  const { hasNextPage, fetchNextPage } = useSermonSearchController();

  const layout = isListOrGrid(currentLayout) ? currentLayout : 'grid';

  return (
    <>
      <SermonListViewToolbar currentLayout={layout} />
      {props.children}
      {hasNextPage && (
        <SectionButton style={{ width: '100%' }} onClick={fetchNextPage}>
          Load More
        </SectionButton>
      )}
    </>
  );
};

const ListView = () => {
  const { sermons, getSermonPath } = useSermonSearchController();

  return (
    <MediaList className={nucleusClass('media-list')}>
      {sermons.map((sermon) => (
        <ListViewItem key={sermon.id} sermon={sermon} getSermonPath={getSermonPath} />
      ))}
    </MediaList>
  );
};

const GridView = () => {
  const { sermons, getSermonPath } = useSermonSearchController();

  return (
    <MediaGrid style={{ marginBottom: '36px' }}>
      {sermons.map((sermon) => (
        <GridViewItem key={sermon.id} sermon={sermon} getSermonPath={getSermonPath} />
      ))}
    </MediaGrid>
  );
};

type ToolbarProps = Parameters<typeof MediaListToolbar>[0];

const SermonListViewToolbar = styled((props: ToolbarProps & { currentLayout: 'grid' | 'list' }) => {
  const { sermons } = useSermonSearchController();
  const { onLayoutChange } = useSermonBlockController();

  const renderShareMenu = sermons.length > 0;

  return (
    <MediaListToolbar
      {...props}
      left={
        <>
          <ViewToggle value={props.currentLayout} onChange={onLayoutChange} />
          <ResultsOutput />
        </>
      }
      right={<>{renderShareMenu && <SermonBlockShareMenu />}</>}
    />
  );
})``;

const ResultsOutput = (): JSX.Element => {
  const { total } = useSermonSearchController();

  const text = total === 0 ? 'None' : total;

  return (
    <Text.L3 style={{ margin: 0 }}>
      <strong>Results:</strong> {text}
    </Text.L3>
  );
};

const ColumnDiv = styled.div`
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`;

const useRecentSermonsProps = (props: BlockSermonSearchRequestProps): BlockSermonProps => {
  const { limit } = useSermonSearchController();
  const base = _omit(props, 'block', 'sectionType');

  const block: SermonBlockWeb = {
    isSermonListNavVisible: false,
    engineId: props.block?.engineId,
    dataSource: {
      isLoadMoreEnabled: false,
      itemType: 'sermon',
      limit: limit,
      order: 'desc',
      orderBy: 'date',
      type: 'dynamic',
    },
    title: 'Recent Media',
  };

  return _merge({ sectionType: SectionTypeEnum.Sermon }, base, {
    block: block,
  }) as BlockSermonProps;
};
