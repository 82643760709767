import Joi from '@hapi/joi';
import { safeToArray } from './shape';

interface NameParts {
  firstName?: string;
  lastName?: string;
}

export const nameSchema = Joi.string().trim().min(1);

type Name = string | undefined | Array<Name>;

export const formatName = (nameOrParts: Name): string => {
  return safeToArray(nameOrParts)
    .filter((item): item is string => typeof item === 'string')
    .join(' ')
    .split(' ')
    .map((part) => part.trim())
    .filter((part) => part.length > 0)
    .join(' ');
};

export const nameToParts = (nameInput: Name): NameParts => {
  const name = formatName(nameInput);
  if (name === '') {
    return {};
  }

  const parts = name.split(' ');
  if (parts.length === 1) {
    return {
      firstName: name,
    };
  }

  return {
    lastName: parts.pop(),
    firstName: parts.join(' '),
  };
};
