import { usePolymorphicButtonProps } from '@nucleus/react-components';
import React from 'react';
import styled from 'styled-components';
import { useLocalization } from '../hooks/useLocalization';
import { IconMagnifyingGlass } from '../icons/IconMagnifyingGlass';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';
import { ButtonProps } from './Button';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    SearchButton?: {
      className?: string;
      color?: string;
      background?: string;
      fontSize?: string;
      lineHeight?: string;
      hover?: {
        color?: string;
        background?: string;
      };
    };
  }
}

interface SearchButtonProps extends ButtonProps {
  enableIcon?: boolean;
}
export const SearchButton = ({ enableIcon, ...props }: SearchButtonProps): JSX.Element => {
  const localize = useLocalization();

  return (
    <StyledButton {...usePolymorphicButtonProps(props)}>
      <Content>
        {enableIcon === true && <IconMagnifyingGlass width="12.16px" height="13px" />}
        <span>{localize('search', 'Search')}</span>
      </Content>
    </StyledButton>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 18px;
`;

const StyledButton = styled.button.attrs((props) => ({
  className: props.theme._sermonThemeElements.SearchButton?.className,
}))`
  color: ${({ theme }) => theme._sermonThemeElements.SearchButton?.color};
  background: ${({ theme }) => theme._sermonThemeElements.SearchButton?.background};
  border: none;
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    pill: '25px',
    rounded: '10px',
  })};
  font-size: ${({ theme }) => theme._sermonThemeElements.SearchButton?.fontSize};
  line-height: ${({ theme }) => theme._sermonThemeElements.SearchButton?.lineHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  user-select: none;
  cursor: pointer;
  height: 50px;
  margin: 0;
  padding: 0;
  text-decoration: none;

  :hover {
    color: ${({ theme }) => theme._sermonThemeElements.SearchButton?.hover?.color};
    background: ${({ theme }) => theme._sermonThemeElements.SearchButton?.hover?.background};
  }

  :active:not([disabled]) {
    transform: scale(0.96);
  }
`;
