import React from 'react';

/** Represents a profile with headphones, used on Sermon Media player actions toolbar */
export const IconListeningMusic = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20" {...props}>
      <g fill="currentColor" fillRule="nonzero">
        <path d="M10.486 13.083H6.514c-3.14 0-5.687 2.401-5.687 5.363 0 .859.738 1.554 1.648 1.554h12.048c.91 0 1.647-.696 1.647-1.554.002-2.962-2.544-5.363-5.684-5.363ZM12.347 7.505c0-2.372-1.724-3.794-3.852-3.794-2.119 0-3.843 1.422-3.843 3.794 0 2.362 1.724 4.784 3.843 4.784 2.128 0 3.852-2.422 3.852-4.784Z" />
        <path d="M15.925 6.594C15.688 2.921 12.453 0 8.5 0 4.544 0 1.31 2.924 1.074 6.6.422 7.1 0 7.852 0 8.695c0 1.423.764 2.616 2.273 2.718h.063c.224 0 .869-.076 1.03-.229a.802.802 0 0 0 .288-.618V6.832a.817.817 0 0 0-.288-.627.937.937 0 0 0-.529-.203C3.347 3.49 5.69 1.585 8.5 1.585s5.153 1.906 5.662 4.418a.918.918 0 0 0-.52.202.82.82 0 0 0-.288.627v3.734c0 .237.098.457.287.618a.915.915 0 0 0 .611.229h.063C15.824 11.312 17 10.118 17 8.695c0-.846-.422-1.6-1.075-2.1Z" />
      </g>
    </svg>
  );
};
