import { createContext } from '@nucleus/react-components';
import React from 'react';

interface SermonHubController {
  engineId: string;
}

const [SermonHubProvider, useSermonHub] = createContext<SermonHubController>({
  name: 'SermonHub',
});

export { useSermonHub };

interface SermonHubControllerProps extends useControllerProps {
  children: React.ReactNode;
}
export const SermonHubController = ({ children, ...props }: SermonHubControllerProps): JSX.Element => {
  return <SermonHubProvider value={useController(props)}>{children}</SermonHubProvider>;
};

interface useControllerProps {
  engineId: string;
}
const useController = (props: useControllerProps): SermonHubController => {
  return {
    engineId: props.engineId,
  };
};
