import { NameAttribute } from '@nucleus/types/user/attribute';
import { ThemeParameterElement as ParameterElement } from '@nucleus/types/web';
import { useUser } from '@nucleus/web-theme';
import { useRichTextParameterContext } from '@nucleus/web-theme-elements';

export const useRichTextParameters = (): { [Type in ParameterElement['parameter']]?: string } => {
  const { parameters } = useRichTextParameterContext();
  const { currentUser } = useUser();

  if (currentUser === undefined) {
    return parameters;
  }

  const name = currentUser.attributes.find((attribute): attribute is NameAttribute => attribute.type === 'name');

  if (name === undefined) {
    return parameters;
  }

  return {
    ...parameters,
    firstName: (name.value.first_name ?? '').trim(),
    lastName: (name.value.last_name ?? '').trim(),
    name: [name.value.first_name, name.value.last_name]
      .filter(Boolean)
      .map((string) => string?.trim())
      .join(' '),
  };
};
