import React from 'react';
import ReactPlayer from 'react-player';
import { AudioPlayer } from './AudioPlayer';

export const AUDIO_EXTENSIONS = /\.(m4a|m4b|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/i;

export class ReactAudioPlayer extends ReactPlayer {
  static displayName = 'AudioPlayer';
  static canPlay(url: string): boolean {
    return AUDIO_EXTENSIONS.test(url);
  }

  seekTo(amount: number, type?: 'seconds' | 'fraction' | undefined): void {
    throw new Error('Not implemented');
  }
  getCurrentTime(): number {
    throw new Error('Not implemented');
  }
  getSecondsLoaded(): number {
    throw new Error('Not implemented');
  }
  getDuration(): number {
    throw new Error('Not implemented');
  }
  getInternalPlayer(key?: string): Record<string, any> {
    throw new Error('Not implemented');
  }
  showPreview(): void {
    throw new Error('Not implemented');
  }

  render(): JSX.Element {
    return <AudioPlayer src={this.props.url} autoPlay={this.props.playing} thumbnail={this.props.thumbnail} />;
  }
}
