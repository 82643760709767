import { CalendarEvent } from '@nucleus/calendar';
import { createContext } from '@nucleus/react-components';
import { CalendarDisplayData, PostalAddress, ThemeRichTextNodeArray } from '@nucleus/types/web';
import { useFontContext } from '@nucleus/web-theme';
import {
  buildTransparentColorFromCssVariable,
  CalendarIntervalMode,
  DateApi,
  DateRange,
  IanaTimezoneName,
  Iso8601,
  media,
  nucleusClass,
  Text,
  useCalendarEvents,
  useIcsEventDownload,
} from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { isToday, isTomorrow, subSeconds } from 'date-fns';
import { upperFirst as _upperFirst } from 'lodash';
import React, { MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { recurringToReadable } from '../utils/calendar';
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryAlt } from './Button';
import { IconAngleLeft, IconAngleRight, IconCalendarAdd, IconXThin } from './Icons';
import { LoadingSpinner, LoadingSpinnerInline } from './LoadingSpinner';
import { WrappedStyledRichText } from './WrappedRichText';

interface CalendarContext {
  displayData: Array<CalendarDisplayData>;
  expandAll: boolean;
  localTimezone: IanaTimezoneName;
  setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
  subscribeUrl?: string;
  resetTruncate: () => void;
}

const [CalendarContextProvider, useCalendarContext] = createContext<CalendarContext>({});

export type CalendarProps = {
  apiEndpoint: string;
  className?: string;
  displayData?: Array<CalendarDisplayData>;
  defaultLimit?: number;
  subscribeUrl?: string;
  sectionId: string;
  initialIntervalMode?: CalendarIntervalMode;
};

export const Calendar = ({
  className,
  displayData = [], // TODO: use section defaults as fallback
  defaultLimit = 0,
  apiEndpoint,
  subscribeUrl,
  sectionId,
  initialIntervalMode = 'week',
}: CalendarProps): JSX.Element => {
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [truncate, setTruncate] = useState<number>(defaultLimit);

  const { loadFont } = useFontContext();
  loadFont({
    family: 'DM Sans',
    variant: '400normal',
    source: 'google',
  });

  const calendarEvents = useCalendarEvents({
    apiEndpoint: apiEndpoint,
    initialIntervalMode: initialIntervalMode,
  });

  const handleShowMoreClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setTruncate(0);
  };

  const resetTruncate = () => {
    setTruncate(defaultLimit);
  };

  const handleChangeIntervalMode = (intervalMode: CalendarIntervalMode) => {
    resetTruncate();
    calendarEvents.changeIntervalMode(intervalMode);
  };

  const { events, currentRange } = calendarEvents;

  const renderLoading = calendarEvents.isLoading === true;
  const renderError = renderLoading === false && calendarEvents.error !== undefined;

  const renderBlocked = renderLoading !== false || renderError !== false;
  const renderEmpty = renderBlocked === false && events.length === 0;
  const renderEvents = renderBlocked === false && events.length > 0;
  const renderShowMore = truncate > 0 && events.length > truncate;
  const renderCollapse = truncate === 0 && defaultLimit !== 0 && events.length > defaultLimit;

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone as IanaTimezoneName;
  const titleFromIntervalMode = [_upperFirst(calendarEvents.intervalMode), 'View'].join(' ');

  const context: CalendarContext = {
    displayData: displayData,
    expandAll: expandAll,
    localTimezone: localTimezone,
    setExpandAll: setExpandAll,
    resetTruncate: resetTruncate,
    subscribeUrl: subscribeUrl,
  };

  return (
    <CalendarContextProvider value={context}>
      <CalendarContainer className={classNames(nucleusClass('calendar'), className)}>
        <CalendarHeader
          title={titleFromIntervalMode}
          currentRange={currentRange}
          onPrev={calendarEvents.loadPrevEvents}
          onNext={calendarEvents.loadNextEvents}
          onChangeIntervalMode={handleChangeIntervalMode}
          sectionId={sectionId}
        />
        {renderLoading && <LoadingCalendar />}
        {renderError && <ErrorCalendar />}
        {renderEmpty && (
          <NoEvents
            onGoToDate={calendarEvents.jumpToDate}
            intervalMode={calendarEvents.intervalMode}
            nextEvent={calendarEvents.nextEvent}
          />
        )}
        {renderEvents && (
          <>
            <Accordion>
              {events.slice(0, truncate > 0 ? truncate : events.length).map((event) => (
                <EventItem key={event.eventId + event.start} event={event} />
              ))}
            </Accordion>
            {calendarEvents.isRefreshing === true && (
              <RefreshingCalendarContainer>
                <LoadingSpinnerInline width="24" strokeWidth="4" />
                <RefreshingCalendarText>Checking for new events...</RefreshingCalendarText>
              </RefreshingCalendarContainer>
            )}
            {renderShowMore && (
              <TruncateActions>
                <ButtonPrimary widthMode="full" onClick={handleShowMoreClick}>
                  Show all this {calendarEvents.intervalMode}’s events ({events.length - truncate}&nbsp;more)
                </ButtonPrimary>
              </TruncateActions>
            )}
            {renderCollapse && (
              <TruncateActions>
                <ButtonPrimary widthMode="full" onClick={resetTruncate}>
                  Collapse this {calendarEvents.intervalMode}’s events
                </ButtonPrimary>
              </TruncateActions>
            )}
          </>
        )}
      </CalendarContainer>
    </CalendarContextProvider>
  );
};

interface EventItemContext {
  endDate: Date;
  event: CalendarEvent;
  isMultiDayEvent: boolean;
  startDate: Date;
  timezoneCode: string | undefined;
}
const [CalendarEventContextProvider, useCalendarEventContext] = createContext<EventItemContext>({
  name: 'CalendarEvent',
});

const AttachmentsDisplay = (): JSX.Element | null => {
  const { event } = useCalendarEventContext();

  if (event.attachments === undefined) {
    return null;
  }

  return (
    <DetailRow>
      <dt>Attachments:</dt>
      <dd>
        {event.attachments.map((attachment, index) => (
          <>
            {index !== 0 && <br />}
            <a key={attachment.url} href={attachment.url} target="_blank" rel="noopener noreferrer">
              {attachment.filename ?? attachment.url}
            </a>
          </>
        ))}
      </dd>
    </DetailRow>
  );
};

const DescriptionDisplay = (): JSX.Element | null => {
  const { event } = useCalendarEventContext();

  if (event.description === undefined) {
    return null;
  }

  return <Description nodes={event.description as ThemeRichTextNodeArray} />;
};

const DateDisplay = (): JSX.Element | null => {
  const { localTimezone } = useCalendarContext();
  const { event, endDate, isMultiDayEvent, startDate, timezoneCode } = useCalendarEventContext();

  const renderTimezone = localTimezone !== event.timezone;

  let format = 'h:mmaaa';

  if (isMultiDayEvent === true) {
    format = "MMMM d, y 'at' h:mmaaa";
  }

  if (event.allDay === true) {
    format = 'MMMM d, y';
  }

  const start = DateApi.format(startDate, format);
  const end = DateApi.format(endDate, format);

  if (isMultiDayEvent === true) {
    return (
      <DetailRowGroup>
        <DetailRow>
          <dt>Start Date:</dt>
          <dd>
            {start}
            {renderTimezone && ` (${timezoneCode})`}
          </dd>
        </DetailRow>
        <DetailRow>
          <dt>End Date:</dt>
          <dd>
            {end}
            {renderTimezone && ` (${timezoneCode})`}
          </dd>
        </DetailRow>
      </DetailRowGroup>
    );
  }

  const renderTime = event.allDay === false;

  const renderEnd = start !== end;

  return (
    <DetailRowGroup>
      <DetailRow>
        <dt>Date:</dt>
        <dd>{DateApi.format(startDate, 'MMMM d, y')}</dd>
      </DetailRow>
      {renderTime && (
        <DetailRow>
          <dt>Time:</dt>
          <dd>
            {start}
            {renderEnd && ` - ${end}`}
            {renderTimezone && ` (${timezoneCode})`}
          </dd>
        </DetailRow>
      )}
    </DetailRowGroup>
  );
};

const LocationDisplay = (): JSX.Element | null => {
  const { event } = useCalendarEventContext();

  if (event.location === undefined) {
    return null;
  }

  return (
    <DetailRow>
      <dt>Location:</dt>
      <dd>{event.location.locationType === 'richtext' && <LocationRichText nodes={event.location.nodes as any} />}</dd>
    </DetailRow>
  );
};

const RecurrenceDisplay = (): JSX.Element | null => {
  const { event } = useCalendarEventContext();

  if (event.recurring === undefined) {
    return null;
  }

  return (
    <DetailRow>
      <dt>Recurring:</dt>
      <dd>{recurringToReadable(event.recurring)}</dd>
    </DetailRow>
  );
};

const EventDisplayMap: Partial<Record<CalendarDisplayData, () => JSX.Element | null>> = {
  attachments: AttachmentsDisplay,
  date: DateDisplay,
  description: DescriptionDisplay,
  location: LocationDisplay,
  recurrence: RecurrenceDisplay,
};

interface EventItemProps {
  event: CalendarEvent;
}
const EventItem = ({ event }: EventItemProps) => {
  const { localTimezone, displayData } = useCalendarContext();

  const startDate = DateApi.isoToDate(event.start as Iso8601);
  const endDate = DateApi.isoToDate(event.end as Iso8601);
  const isMultiDayEvent = DateApi.isSameDay(startDate, subSeconds(endDate, 1)) !== true;
  const timezoneCode =
    localTimezone !== undefined && localTimezone !== event.timezone
      ? DateApi.getTimezoneCode((event.timezone ?? 'UTC') as IanaTimezoneName)
      : undefined;

  const [handleDownloadClick] = useIcsEventDownload(event);

  const context: EventItemContext = {
    endDate: endDate,
    event: event,
    isMultiDayEvent: isMultiDayEvent,
    startDate: startDate,
    timezoneCode: timezoneCode,
  };

  return (
    <CalendarEventContextProvider value={context}>
      <Accordion.Item
        header={
          <TitleContainer>
            <DateSquare date={startDate} />
            <Title>
              <Headline>{event.title}</Headline>
              <HeaderSubHeading />
            </Title>
          </TitleContainer>
        }
      >
        <Details>
          <dl>
            {displayData.map((displayItem): JSX.Element | null => {
              const DisplayComponent = EventDisplayMap[displayItem];
              if (DisplayComponent === undefined) {
                return null;
              }
              return <DisplayComponent key={displayItem} />;
            })}
          </dl>
        </Details>
        <Buttons>
          <SubscribeButton onClick={handleDownloadClick} />
        </Buttons>
      </Accordion.Item>
    </CalendarEventContextProvider>
  );
};

const HeaderSubHeading = () => {
  const { localTimezone } = useCalendarContext();
  const { event, startDate, endDate, isMultiDayEvent, timezoneCode } = useCalendarEventContext();

  let shorthand;
  if (isTomorrow(startDate) === true) {
    shorthand = 'Tomorrow';
  } else if (isToday(startDate) === true) {
    shorthand = 'Today';
  }

  const renderShorthand = shorthand !== undefined;
  const renderTimezone = localTimezone !== event.timezone;

  let format = 'h:mmaaa';

  if (isMultiDayEvent === true) {
    format = "MMMM d, y 'at' h:mmaaa";
  }

  if (event.allDay === true) {
    format = 'MMMM d, y';
  }

  const start = DateApi.format(startDate, format);
  const end = DateApi.format(endDate, format);

  const hideEnd = event.allDay === true || start === end;
  const renderEnd = hideEnd === false;

  return (
    <Byline>
      {renderShorthand && (
        <>
          <b>{shorthand}</b> @
        </>
      )}{' '}
      {start}
      {renderEnd && ` - ${end}`}
      {renderTimezone && ` (${timezoneCode})`}
    </Byline>
  );
};

const RefreshingCalendarContainer = styled.div`
  position: relative;
  color: var(--color-section-text);
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 1.8rem;
`;

const RefreshingCalendarText = styled(Text.L6)`
  font-weight: 500;
  margin: 0;
`;

const EmptyCalendarContainer = styled.div`
  position: relative;
  color: var(--color-section-text);
  background-color: ${buildTransparentColorFromCssVariable('--color-section-text', 0.1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4em 0;
  margin: calc(var(--unit-length) * 0.5) 0;
  border-radius: 1rem;
`;

const EmptyCalendarText = styled(Text.P3)`
  font-weight: 500;
  margin: 0 0 0.5em;
`;

const ErrorCalendar = (): JSX.Element => {
  return (
    <EmptyCalendarContainer>
      <EmptyCalendarText>Error loading... please try again.</EmptyCalendarText>
    </EmptyCalendarContainer>
  );
};

type NoEventsProps = {
  intervalMode: CalendarIntervalMode;
  nextEvent?: CalendarEvent;
  onGoToDate: (date: Date) => void;
};
const NoEvents = (props: NoEventsProps) => {
  const nextEvent = props.nextEvent;

  if (nextEvent === undefined) {
    return <EmptyCalendar {...props} />;
  }

  return <NextEvent {...props} nextEvent={nextEvent} />;
};

const EmptyCalendar = (props: NoEventsProps) => {
  return (
    <EmptyCalendarContainer>
      <EmptyCalendarText>No events this {props.intervalMode}.</EmptyCalendarText>
    </EmptyCalendarContainer>
  );
};

const NextEvent = (props: Required<NoEventsProps>) => {
  const startDate = DateApi.isoToDate(props.nextEvent.start as Iso8601);

  const handleJumpToNextEventClick = (e: MouseEvent) => {
    e.stopPropagation();
    props.onGoToDate(startDate);
  };

  return (
    <EmptyCalendarContainer>
      <EmptyCalendarText>
        No events this {props.intervalMode}. <br /> Next one is on {DateApi.format(startDate, 'MMMM d, y')}
      </EmptyCalendarText>
      <StyledButtonContainer>
        <ButtonPrimary size="medium" onClick={handleJumpToNextEventClick}>
          Jump to next event
        </ButtonPrimary>
      </StyledButtonContainer>
    </EmptyCalendarContainer>
  );
};

const StyledButtonContainer = styled.div`
  margin-top: 1rem;
`;

const LoadingCalendar = () => {
  return (
    <EmptyCalendarContainer>
      <LoadingSpinner />
    </EmptyCalendarContainer>
  );
};

const DateSquareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid var(--color-section-text);
  border-radius: 0.744rem;
  width: 5.2rem;
  height: 5.2rem;
  margin-top: 0.5rem;
`;

const DateSquareMonth = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1em;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding-top: 0.3rem;
`;

const DateSquareDay = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 1em;
  letter-spacing: 0;
  text-align: center;
  margin: 0;
  padding-top: 0.2rem;
`;

const DateSquare = ({ date }: { date: Date }) => {
  return (
    <DateSquareContainer>
      <DateSquareMonth>{DateApi.format(date, 'MMM')}</DateSquareMonth>
      <DateSquareDay>{DateApi.format(date, 'd')}</DateSquareDay>
    </DateSquareContainer>
  );
};

const Headline = styled(Text.H6)`
  line-height: 1.2em;
`;

const Byline = styled(Text.P4)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDescription = styled(Text.P4)`
  margin: 0 0 2em 0;
  overflow-wrap: anywhere;
`;

const Description = WrappedStyledRichText(StyledDescription);

const StyledLocation = styled(Text.P4)``;

const LocationRichText = WrappedStyledRichText(StyledLocation);

const Details = styled(Text.P4)`
  && {
    margin: 0.5em 0;
  }

  dl {
    list-style: none;
    padding: 0;
  }
`;

const DetailRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin: 0.8em 0;

  dt {
    font-weight: bold;
    flex: 0 1 auto;
  }

  dd {
    flex: 1 1 auto;
  }
`;

const DetailRowGroup = styled.div`
  ${DetailRow} {
    &:first-child {
      margin-bottom: 0;
    }
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  padding: 1rem 0 0;
`;

const CalendarContainer = styled.div`
  font-family: 'DM Sans', sans-serif;
`;

const TruncateActions = styled.div``;

type AccordionItemProps = {
  header?: ReactNode;
  children?: ReactNode;
};

const AccordionItem = (props: AccordionItemProps) => {
  const { expandAll } = useCalendarContext();

  const [isOpen, setIsOpen] = useState(expandAll);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen !== expandAll) {
      toggle();
    }
  }, [expandAll]);

  const click = (e: MouseEvent) => {
    e.preventDefault();
    toggle();
  };

  // if open recalculate height on resize
  useEffect(() => {
    if (ref.current === null || isOpen === false) {
      setHeight(0);
      return;
    }

    setHeight(ref.current.offsetHeight);

    const handleResize = () => {
      if (ref.current === null) {
        return;
      }
      setHeight(ref.current.offsetHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen, ref.current]);

  return (
    <StyledAccordionItem isOpen={isOpen}>
      <Header onClick={(e) => click(e)}>
        {props.header}
        <HeaderRight>
          <ToggleButton />
        </HeaderRight>
      </Header>
      <Body style={{ height: height }}>
        <div ref={ref}>{props.children}</div>
      </Body>
    </StyledAccordionItem>
  );
};

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, max-content) minmax(0, 1fr);
  gap: 2.2rem;
  padding-top: 0.2rem;
`;

const Title = styled.div`
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const HeaderRight = styled.div`
  justify-self: end;
  margin-top: 0.5rem;
`;

const ToggleIcon = styled(IconXThin)`
  display: block;
  transition: all 200ms linear;
  transform-origin: center;
  transform: rotate(45deg);
  color: var(--color-section-text);

  && {
    width: 3rem;
    height: 3rem;
  }
`;

const ToggleButton = styled(ButtonSecondaryAlt).attrs({
  shape: 'square',
  icon: <ToggleIcon />,
  size: 'medium',
})`
  --color-button-secondary-alt-background-h: var(--color-section-text-h);
  --color-button-secondary-alt-background-S: var(--color-section-text-s);
  --color-button-secondary-alt-background-l: var(--color-section-text-l);
  --color-button-secondary-alt-text: var(--color-section-text);
`;

const StyledIconCalendarAdd = styled(IconCalendarAdd)`
  && {
    width: 3rem;
    height: 3rem;
  }
`;

const SubscribeButton = styled(ButtonSecondaryAlt).attrs({
  shape: 'square',
  icon: <StyledIconCalendarAdd />,
  size: 'medium',
})`
  --color-button-secondary-alt-background-h: var(--color-section-text-h);
  --color-button-secondary-alt-background-S: var(--color-section-text-s);
  --color-button-secondary-alt-background-l: var(--color-section-text-l);
  --color-button-secondary-alt-text: var(--color-section-text);
`;

type StyledAccordionItemProps = {
  isOpen: boolean;
};

const StyledAccordionItem = styled.li<StyledAccordionItemProps>`
  --border: 2px solid ${buildTransparentColorFromCssVariable('--color-section-text', 0.5)};
  background-color: transparent;
  border-top: var(--border);
  border-bottom: var(--border);
  transition: background-color 250ms ease-out;
  text-align: left;
  padding: 0;

  & + & {
    border-top: none; // one line between items
  }

  ${ToggleButton} {
    ${ToggleIcon} {
      transform: ${(props) => (props.isOpen ? 'rotate(0deg)' : 'rotate(45deg)')};
    }

    ${(props) =>
      !props.isOpen &&
      css`
        & > div {
          background: transparent;
        }
      `}
  }
`;

const Header = styled.a`
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(auto, max-content);
  gap: 2.2rem;
  color: var(--color-section-text);
  padding: 1.5rem 0.5rem;
  text-decoration: none !important;
  cursor: pointer;
`;

const Body = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 200ms ease-in-out;
  color: var(--color-section-text);

  & > div {
    padding: 0 0.5rem 2.4rem;
  }

  ${media.tabletLandscapeAndUp`
    max-width: 83%;
  `};
`;

const StyledAccordion = styled.ul`
  padding: 0 0.6rem;
  list-style: none;
  margin: calc(var(--unit-length) * 0.5) 0;
  width: 100%;
  max-width: 100%;

  ${media.tabletLandscapeAndUp`
    margin-left: auto;
    margin-right: auto;
  `};
`;

const Accordion = Object.assign(StyledAccordion, {
  Item: AccordionItem,
});

// TODO: implement when location has a postal address
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FormattedStreetAddress = ({ address }: { address: PostalAddress }) => {
  if (address === undefined) {
    return null;
  }
  const { streetAddress, streetAddress2, city, state, postalCode, country } = address;

  return (
    <div>
      {streetAddress && (
        <div>
          {streetAddress}
          {streetAddress2 && `, ${streetAddress2}`}
        </div>
      )}
      {(city || state || postalCode) && (
        <div>{`${city || ''}${city && state ? ', ' : ''}${state || ''}${state && postalCode ? ' ' : ''}${
          postalCode || ''
        }`}</div>
      )}
      {country && <div>{country}</div>}
    </div>
  );
};

type CalendarHeaderProps = {
  currentRange: DateRange;
  title?: string;
  onPrev?: () => void;
  onNext?: () => void;
  onChangeIntervalMode?: (mode: CalendarIntervalMode) => void;
  sectionId: string;
};

const CalendarHeader = ({
  currentRange,
  title,
  onPrev = () => {},
  onNext = () => {},
  onChangeIntervalMode = () => {},
  sectionId,
}: CalendarHeaderProps) => {
  const { expandAll, setExpandAll, subscribeUrl, resetTruncate } = useCalendarContext();

  const [isCopied, setIsCopied] = useState(false);

  const getShareLink = () => {
    const sectionAnchor = `#${sectionId}`;
    try {
      return [window.location.origin, window.location.pathname, sectionAnchor].join('');
    } catch (error) {
      return sectionAnchor;
    }
  };

  const hasShare = typeof window !== 'undefined' && window.navigator?.share !== undefined;

  const handleShare = () => {
    copy(getShareLink());
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const handleShareSheet = () => {
    if (hasShare === true) {
      window.navigator.share({ url: getShareLink() });
    }
  };

  const handlePrevClick = (e: React.MouseEvent) => {
    e.preventDefault();
    resetTruncate();
    onPrev();
  };
  const handleNextClick = (e: React.MouseEvent) => {
    e.preventDefault();
    resetTruncate();
    onNext();
  };

  const showSubscribeButton = subscribeUrl !== undefined;

  const expandAllEvents = useCallback(() => {
    setExpandAll((prev) => !prev);
  }, [expandAll, setExpandAll]);

  const expandAllEventsLabel = expandAll ? 'Collapse all Events' : 'Expand all Events';

  const start = DateApi.format(currentRange.start, 'MMMM d, y');
  const end = DateApi.format(DateApi.subMilliseconds(currentRange.end, 1), 'MMMM d, y');

  const renderEnd = start !== end;

  return (
    <CalendarHeaderWrapper>
      <CalendarHeaderContainer>
        <CalendarHeaderLeft>
          <StyledButtonPrimary
            size="medium"
            dropdownItems={[
              { title: expandAllEventsLabel, onClick: expandAllEvents },
              {
                title: 'Change Date Range',
                onClick: () => {},
                dropdownItems: [
                  { title: 'Day', onClick: () => onChangeIntervalMode('day') },
                  { title: 'Week', onClick: () => onChangeIntervalMode('week') },
                  { title: 'Month', onClick: () => onChangeIntervalMode('month') },
                ],
              },
              { title: 'Subscribe to Calendar', href: subscribeUrl, show: showSubscribeButton },
              {
                title: isCopied ? 'Copied!' : 'Share Calendar',
                onClick: handleShare,
                keepOpen: true,
              },
              {
                title: 'More Share Options',
                onClick: handleShareSheet,
                keepOpen: true,
                visible: hasShare,
              },
            ]}
          >
            Actions
          </StyledButtonPrimary>
        </CalendarHeaderLeft>
        <CalendarHeaderCenter>
          {title && <CalendarTitle>{title}</CalendarTitle>}
          <CalendarDateRange>
            <NoWrapSpan>{start}</NoWrapSpan>
            {renderEnd && (
              <>
                {' '}
                - <NoWrapSpan>{end}</NoWrapSpan>
              </>
            )}
          </CalendarDateRange>
        </CalendarHeaderCenter>
        <CalendarHeaderRight>
          <PrevNextButtons>
            <ButtonSecondary shape="square" size="medium" icon={<IconAngleLeft />} onClick={handlePrevClick} />
            <ButtonPrimary shape="square" size="medium" icon={<IconAngleRight />} onClick={handleNextClick} />
          </PrevNextButtons>
        </CalendarHeaderRight>
      </CalendarHeaderContainer>
    </CalendarHeaderWrapper>
  );
};

const StyledButtonPrimary = styled(ButtonPrimary)`
  font-size: calc(var(--label4-font-size) * var(--global-font-factor));
`;

export const CalendarHeaderWrapper = styled.div`
  background: var(--color-section-background);
  border-radius: 1rem;
`;

export const CalendarHeaderContainer = styled.div`
  padding: 1.8rem;
  background: ${buildTransparentColorFromCssVariable('--color-section-text', 0.1)};
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1.8rem;
  border-radius: 1rem;
  display: grid;
  gap: 2.2rem;
  grid-template-columns: minmax(0, 1fr) minmax(auto, max-content);
  grid-template-areas:
    'center center'
    'left right';

  ${media.tabletPortraitAndUp`
    grid-template-areas: 'left center right';
    grid-template-columns: 1fr auto 1fr;
  `}
`;

const CalendarHeaderLeft = styled.div`
  grid-area: left;
`;

const CalendarHeaderRight = styled.div`
  grid-area: right;
`;

const CalendarHeaderCenter = styled.div`
  grid-area: center;
  text-align: center;
  align-self: center;
  color: var(--color-section-text);
`;

const CalendarTitle = styled.div.attrs({ className: Text.ClassName['calendar-header-label'] })`
  opacity: 0.5;
`;

const CalendarDateRange = styled.div.attrs({ className: Text.ClassName['calendar-header-label'] })``;

const PrevNextButtons = styled.div`
  display: flex;
  gap: 1.2rem;
  justify-content: right;
`;

const NoWrapSpan = styled.span`
  white-space: nowrap;
`;
