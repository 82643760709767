import { stringifyRichText } from '@nucleus/react-components';
import { SermonWeb } from '@nucleus/types/web/sermons/sermon';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { VideoObject, WithContext } from 'schema-dts';
import { SermonEmbedVideoMediaItemWeb } from '../../../sermons/types/Sermon';

interface Props {
  sermon?: SermonWeb;
}

export const SermonStructuredData = ({ sermon }: Props): JSX.Element | null => {
  if (sermon === undefined) {
    return null;
  }

  const mediaItems = Object.values(sermon.mediaItems);

  const embedMedia = mediaItems.find((media): media is SermonEmbedVideoMediaItemWeb => media.type === 'embed/video');

  // For now, only support embedMedia. We don't have enough metadata to support livestream videos. Missing start and end datetime.
  if (embedMedia === undefined) {
    return null;
  }

  return (
    // https://developers.google.com/search/docs/appearance/structured-data/video
    <JsonLd<WithContext<VideoObject>>
      data={{
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: sermon.title,
        thumbnailUrl: sermon.mainImage?.image?.src,
        uploadDate: sermon.dateTimeIso,
        description: sermon.body !== undefined ? stringifyRichText(sermon.body) : undefined,
        embedUrl: embedMedia.href,
      }}
    />
  );
};

const JsonLd = <T,>({ data }: { data: T }): JSX.Element => {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(data)}</script>
    </Helmet>
  );
};
