import { MediaWeb } from '@nucleus/types/web';
import { nucleusClass } from '@nucleus/web-theme-elements';
import { MediaQuery } from '@nucleus/web-theme-elements';
import { inverseAspectRatioAsPercentFromImage } from '@nucleus/web-theme/src/lib/utilities';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { MediaItemBackground } from '../components/Background';

export type MediaProps = MediaWeb;

const MediaShapes = ['square', 'rounded', 'circle', 'original'] as const;
export type MediaShape = typeof MediaShapes[number];

interface MediaContainerProps {
  $image?: MediaProps;
  $shape: MediaShape;
  isSticky?: boolean;
}

const MEDIA_SHAPE = {
  circle: {
    borderRadius: '50%',
    height: 0,
    paddingBottom: '100%',
  },
  square: {
    height: 0,
    paddingBottom: '100%',
  },
  rounded: {
    borderRadius: '2.4rem',
  },
  original: {},
};

const MediaContainer = styled.div<MediaContainerProps>((props) => {
  return {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    ...(MEDIA_SHAPE[props.$shape] ?? {}),
    [MediaQuery.smallDesktopAndUp]: {
      ...(props.isSticky === true ? { maxHeight: '100vh', position: 'sticky', top: 0 } : {}),
    },
  };
});

interface MediaInnerProps {
  $image?: MediaProps;
}

const MediaInner = styled.div<MediaInnerProps>((props) => {
  return {
    width: '100%',
    paddingTop: inverseAspectRatioAsPercentFromImage(props.$image?.image),
    position: 'relative',
    minHeight: '100%',
  };
});

interface BlockMediaProps {
  media?: MediaProps;
  shape?: MediaShape;
  className?: string;
  content?: ReactNode;
  isSticky?: boolean;
}

export const BlockMedia = styled(({ media, content, shape = 'original', ...props }: BlockMediaProps) => {
  return (
    <MediaContainer {...props} className={classNames([props.className, nucleusClass('block-media')])} $shape={shape}>
      <MediaInner $image={media}>
        <MediaItemBackground background={media} />
        {content && <MediaContentOverlay>{content}</MediaContentOverlay>}
      </MediaInner>
    </MediaContainer>
  );
})``;

const MediaContentOverlay = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
`;
