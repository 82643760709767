import { History } from 'history';
import { partial as _partial, pick as _pick } from 'lodash';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type URLSearchObject = {
  [param: string]: string | Array<string>;
};
type UpdateFunction<T extends URLSearchInput<T>> = (arg: T | undefined) => void;
type SearchParamActions<T extends URLSearchInput<T>> = {
  push: UpdateFunction<T>;
  replace: UpdateFunction<T>;
};

export const objectFromUrlSearchParams = (urlSearchParams: URLSearchParams): URLSearchObject => {
  return Array.from(urlSearchParams).reduce((acc: URLSearchObject, [key, value]: [string, string]) => {
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);

    const newValue = [acc[decodedKey], decodedValue].filter((value) => value !== undefined && value.length > 0).flat(1);

    return {
      ...acc,
      [decodedKey]: newValue.length > 1 ? newValue : newValue[0],
    };
  }, {});
};

export type URLSearchValue = string | boolean | number | undefined;
export type URLSearchInput<T> = { [P in keyof T]: URLSearchValue | URLSearchValue[] };

export const objectToQueryString = <T>(object: URLSearchInput<T>): string => {
  return Object.entries(object)
    .filter(([key]) => encodeURIComponent(key) !== '')
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const values = Array.isArray(value) ? value : [value];

      return values
        .filter((value) => value !== undefined && value !== '')
        .map((value) => `${encodedKey}=${encodeURIComponent(value as string)}`)
        .join('&');
    })
    .filter((value) => value.length > 0)
    .join('&');
};

export interface UseSearchParamsOptions {
  include?: Array<string>;
  exclude?: Array<string>;
}

export type UseSearchParamsReturn<T extends URLSearchInput<T> = { [key: string]: URLSearchValue | URLSearchValue[] }> =
  [URLSearchObject, SearchParamActions<T>];

export const useSearchParams = <T extends URLSearchInput<T> = { [key: string]: URLSearchValue | URLSearchValue[] }>(
  options: UseSearchParamsOptions = {}
): UseSearchParamsReturn<T> => {
  const navigate = useNavigate();
  const location = useLocation();

  const urlSearchparams = new URLSearchParams(location.search);
  const params = objectFromUrlSearchParams(urlSearchparams);

  const keys = Object.keys(params);
  const excludedKeys = options.exclude ?? [];
  const includedKeys = options.include ?? keys;
  const filteredKeys = includedKeys.filter((key) => excludedKeys.includes(key) === false);
  const filteredParams = _pick(params, filteredKeys);

  const setParams = (historyFunction: History['push'] | History['replace'], newParams: T | undefined): void => {
    if (newParams === undefined) {
      // Clear params.
      historyFunction({ pathname: location.pathname });
      return;
    }

    const queryString = objectToQueryString({
      ...params,
      ...newParams,
    });
    historyFunction({ pathname: location.pathname, search: queryString });
  };

  return useMemo(() => {
    return [
      filteredParams,
      {
        push: _partial(setParams, (path) => navigate(path, { replace: false })),
        replace: _partial(setParams, (path) => navigate(path, { replace: true })),
      },
    ];
    // If the search string hasn't changed, the result won't change either.
  }, [location.search]);
};
