interface Range {
  start: number;
  end: number;
}

export interface InterpolateLinearOptions {
  from: Range;
  to: Range;
}

export const interpolateLinear = (value: number, ranges: InterpolateLinearOptions, clampToRange = true): number => {
  const inputDelta = ranges.from.end - ranges.from.start;
  const outputDelta = ranges.to.end - ranges.to.start;

  const output = ((value - ranges.from.start) / inputDelta) * outputDelta + ranges.to.start;

  if (clampToRange === true) {
    return clamp(output, Math.min(ranges.to.start, ranges.to.end), Math.max(ranges.to.start, ranges.to.end));
  }

  return output;
};

export const clamp = (value: number, lowerBound: number, upperBound: number): number => {
  if (lowerBound > upperBound) {
    throw new Error('Invalid bounds.');
  }

  return Math.min(Math.max(value, lowerBound), upperBound);
};
