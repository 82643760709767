import { QueryWebEndpoint } from '@nucleus/web-hosting';
import axios from 'axios';

const getBaseUrl = (isUserAuthenticated: boolean): string => {
  return isUserAuthenticated ? '/_api/private/sermon-hub' : '/_api/public/sermon-hub';
};

export const querySearch = async (
  engineId: string | undefined,
  jwtToken?: string,
  params?: QueryWebEndpoint['queryStringParameters']
): Promise<QueryWebEndpoint['responseBody']> => {
  const isUserAuthenticated = jwtToken !== undefined;

  const response = await axios.request<QueryWebEndpoint['responseBody']>({
    url: `${getBaseUrl(isUserAuthenticated)}/${engineId}/sermons/actions/search`,
    params: params,
    headers: {
      Authorization: jwtToken,
    },
  });
  return response.data;
};
