import { ThemeColorPaletteBuilder } from '../ThemeConfigBuilder';
import { serviceName } from '../constants';

const builder = new ThemeColorPaletteBuilder({
  id: `${serviceName}_lifesABeach`,
  title: 'Life’s a Beach 🏄🏼‍♂️',
  description:
    'Like a perfect summertime trip to the beach, these colors reflect shades of cool ocean waves and light yellow sunshine.',
  lightMode: {
    color1: '#2B81AB',
    color2: '#FEE59A',
    color3: '#00527A',
    color4: '#D6EBF5',
    color5: '#085278',
    color6: '#F5DA8A',
    colorLight: '#EAF5FB',
    colorDark: '#004466',
  },
});

export default builder.build();
