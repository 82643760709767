const DEFAULT_SEPARATOR = '#';

const compositor = (values: Array<string | number | symbol | Date | undefined>, separator: string): string =>
  values.map((value) => (value instanceof Date ? value.getTime() : value)).join(separator);

/**
 * createCompositeStringFromObjectFactory will create an composite key based on the keys of the object passed in
 * @param keys key to pick from the object
 * @param separator what the keys will be joined on
 */
export const createCompositeStringFromObjectFactory = <
  T extends Record<string, string | number | symbol | Date | undefined>,
>(
  keys: Array<keyof T>,
  separator = DEFAULT_SEPARATOR
): ((model: T) => string) => {
  return (model: T): string => {
    const values = keys.map((key) => model[key]);
    return compositor(values, separator);
  };
};

/**
 * createCompositeString will create a key of values joined on a separate
 */
export const createCompositeString = (
  values: Array<string | number | symbol | Date | undefined>,
  separator = DEFAULT_SEPARATOR
): string => compositor(values, separator);

/**
 * getCompositeStringValues will separate a composite string on the separator
 */
export const getCompositeStringValues = (compositeKey: string, separator = DEFAULT_SEPARATOR): string[] =>
  compositeKey.split(separator);

/**
 * xmlSafeText will replace &, <, and > with their xml safe counterparts
 */
export const xmlSafeText = (text: string): string => {
  return text.replace(/&amp;/g, '&').replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
};
