import { BlockLayout, GallerySectionWeb, SectionLayout } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { GalleryBlockLayoutComponentMap, GallerySectionLayoutComponentMap } from './GalleryLayout';
import { GalleryBlockLayoutName, GallerySectionLayoutName } from './types';

const GallerySectionLayoutNameById: Record<SectionLayout, GallerySectionLayoutName> = {
  full: 'Full',
  constrained: 'Inset',
  info: 'InsetPlusInfo',
} as const;

const BlockLayoutNameById: Record<BlockLayout, GalleryBlockLayoutName> = {
  layout1: 'Horizontal',
  layout2: 'Vertical',
  layout3: 'Collage',
  layout4: 'Horizontal',
  layout5: 'Horizontal',
  layout6: 'ContentOnly',
} as const;

export const GallerySection = (): JSX.Element => {
  const section = useSection<GallerySectionWeb>();

  const SectionLayoutComponent =
    GallerySectionLayoutComponentMap[GallerySectionLayoutNameById[section.payload.layout]] ??
    GallerySectionLayoutComponentMap['Full'];

  return (
    <Section className={classNames(nucleusClass('section-gallery'), getSectionClasses(section))}>
      <SectionLayoutComponent {...section.payload}>
        {section.payload.blocks?.map((block, index) => {
          const BlockLayoutComponent =
            GalleryBlockLayoutComponentMap[BlockLayoutNameById[section.payload.blockLayout]] ??
            GalleryBlockLayoutComponentMap['Horizontal'];
          return <BlockLayoutComponent key={index} {...section.payload} block={block} />;
        })}
      </SectionLayoutComponent>
    </Section>
  );
};
