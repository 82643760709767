import { formatDateRelative } from '@nucleus/lib-datetime';
import { SermonIndexDocumentWeb } from '@nucleus/sermons/types/Sermon';
import { SermonWeb } from '@nucleus/types/web/sermons/sermon';
import { format, parseISO } from 'date-fns';

export const formatSermonDate = (sermon?: SermonWeb | SermonIndexDocumentWeb, relative?: boolean): string => {
  if (sermon?.date === undefined) {
    return '';
  }

  // Sermon dates have a time component that doesn't matter
  const [date] = sermon.date.split('Z');

  if (relative) {
    return formatDateRelative(date);
  }

  return format(parseISO(date), 'MMMM d, yyyy');
};
