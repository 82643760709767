import React from 'react';
import { Node } from 'slate';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import { withPlaceholder } from '../utils/withPlaceholder';

declare module '@nucleus/sermon-theme-elements' {
  interface NotesEditorElements {
    paragraph?: {
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
      letterSpacing?: string;
    };
  }
}

export const ParagraphElement = ({ attributes, children, element }: RenderElementProps): JSX.Element => {
  return (
    <P
      contentEditable={element.editable}
      placeholder={element.placeholder}
      $showPlaceholder={Node.string(element) === ''}
      {...attributes}
    >
      {children}
    </P>
  );
};

const P = withPlaceholder(styled.p.attrs(({ theme }) => ({
  className: theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.className,
}))`
  font-size: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.fontSize};
  color: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.color};
  line-height: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.lineHeight};
  letter-spacing: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.paragraph?.letterSpacing};
  margin: 0;
  margin-bottom: 15px;
`);
