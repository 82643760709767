import { PolymorphicButtonProps, usePolymorphicButtonProps } from '@nucleus/react-components';
import React from 'react';
import styled from 'styled-components';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    Button?: {
      className?: string;
      color?: string;
      background?: string;
      fontSize?: string;
      lineHeight?: string;
      hover?: {
        color?: string;
        background?: string;
      };
    };
  }
}

export interface ButtonProps extends PolymorphicButtonProps {
  children?: React.ReactNode;
}

export const Button = ({ children, ...props }: ButtonProps): JSX.Element => {
  return <StyledButton {...usePolymorphicButtonProps(props)}>{children}</StyledButton>;
};

const StyledButton = styled.button.attrs((props) => ({
  className: props.theme._sermonThemeElements.Button?.className,
}))`
  color: ${({ theme }) => theme._sermonThemeElements.Button?.color};
  background: ${({ theme }) => theme._sermonThemeElements.Button?.background};
  border: none;
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    pill: '25px',
    rounded: '10px',
  })};
  font-size: ${({ theme }) => theme._sermonThemeElements.Button?.fontSize};
  line-height: ${({ theme }) => theme._sermonThemeElements.Button?.lineHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  user-select: none;
  cursor: pointer;
  height: 50px;
  margin: 0;
  padding: 0;
  text-decoration: none;

  :hover {
    color: ${({ theme }) => theme._sermonThemeElements.Button?.hover?.color};
    background: ${({ theme }) => theme._sermonThemeElements.Button?.hover?.background};
  }

  :active:not([disabled]) {
    transform: scale(0.96);
  }
`;
