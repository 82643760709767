import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { querySermons } from '../queries/sermon';
import { ListSermonsWebEndpoint } from '@nucleus/web-hosting';
import { useUser } from '@nucleus/web-theme';

type QueryResult = Awaited<ReturnType<typeof querySermons>>;

export const useSermons = (
  engineId?: string,
  params?: ListSermonsWebEndpoint['queryStringParameters']
): UseInfiniteQueryResult<QueryResult> => {
  const { currentUser, jwtToken } = useUser();

  return useInfiniteQuery({
    queryKey: ['sermonHub', 'sermon', params, currentUser?.id],
    queryFn: ({ pageParam }) => querySermons(engineId, jwtToken, { ...params, cursor: pageParam }),
    suspense: true,
    getNextPageParam: (lastPage) => lastPage.cursor,
  });
};
