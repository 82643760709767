import { Descendant, Node, Element } from 'slate';
import { CustomEditor } from '../types/custom-types';

export const withToPlainText = (editor: CustomEditor): CustomEditor => {
  editor.toPlainText = () => {
    return toPlainText(editor, editor.children);
  };

  return editor;
};

const toPlainText = (editor: CustomEditor, nodes: Descendant[]): string => {
  let plainText = '';

  nodes.forEach((node) => {
    if (Element.isElement(node) && editor.isBlock(node)) {
      plainText += toPlainText(editor, node.children!);
    } else {
      plainText += Node.string(node) + '\n\n';
    }
  });

  return plainText;
};
