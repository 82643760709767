import { PublishableEngine, PublishableEngineSchema } from '@nucleus/publishable';
import {
  PublishableEntitySharingMetadataApiSchema,
  PublishableEntitySharingMetadataSchema,
} from '@nucleus/publishable/types/PublishableRevision';
import { Image, ImageWeb } from '@nucleus/types/media/image';
import { SharingMetadataEntityItem, SharingMetadataEntityItemApi } from '@nucleus/types/web/sharing';
import * as yup from 'yup';

export const enum SiteCategory {
  Web = 'Web',
  Sermons = 'Sermons',
  Posts = 'Posts',
}

export enum SitemapSource {
  Navigation = 'navigation',
  None = 'none',
}

export type SiteSourceMetadata =
  | {
      source: SitemapSource.None;
    }
  | {
      source: SitemapSource.Navigation;
      navigationId: string;
    };

export interface SiteEngine extends PublishableEngine {
  category: SiteCategory;
  activeTheme: string;
  collectionsOrderedSetId?: string;
  title?: string;
  sharingMetadata?: {
    entity?: SharingMetadataEntityItem;
  };
  icon?: SiteIcon;
  sitemap?: SiteSourceMetadata;
}

export interface SiteEngineApi extends PublishableEngine {
  category: SiteCategory;
  activeTheme: string;
  collectionsOrderedSetId?: string;
  title?: string;
  sharingMetadata?: {
    entity?: SharingMetadataEntityItemApi;
  };
  icon?: SiteIconWeb;
  sitemap?: {
    source: SitemapSource;
    navigationId?: string;
  };
}

const imageSchema = yup.object({
  fileId: yup.string(),
});

const sitemapSchema = yup.object({
  source: yup.mixed().oneOf([SitemapSource.None, SitemapSource.Navigation]).default(SitemapSource.Navigation),
  navigationId: yup
    .mixed()
    .when('source', {
      is: SitemapSource.Navigation,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    })
    .default(undefined),
});

export const SiteEngineSchema: yup.SchemaOf<Omit<SiteEngine, 'category'> & { category: string }> =
  PublishableEngineSchema.concat(
    yup.object({
      category: yup
        .string()
        .oneOf<SiteCategory>([SiteCategory.Web, SiteCategory.Sermons, SiteCategory.Posts])
        .required(),
      activeTheme: yup.string(),
      collectionsOrderedSetId: yup.string(),
      title: yup.string(),
      sharingMetadata: yup
        .object({
          entity: PublishableEntitySharingMetadataSchema.default(undefined),
        })
        .default(undefined),
      icon: yup.object({
        favicon: imageSchema.default(undefined),
        touchicon: imageSchema.default(undefined),
      }),
      sitemap: sitemapSchema.default(undefined),
    })
  );

export const SiteEngineApiSchema: yup.SchemaOf<Omit<SiteEngineApi, 'category'> & { category: string }> =
  SiteEngineSchema.concat(
    yup.object({
      sharingMetadata: yup
        .object({
          entity: PublishableEntitySharingMetadataApiSchema.default(undefined),
        })
        .default(undefined),
      icon: yup.object({
        favicon: imageSchema.default(undefined),
        touchicon: imageSchema.default(undefined),
      }),
    })
  );

interface SiteIcon {
  favicon?: Image;
  touchicon?: Image;
}

export interface SiteIconWeb {
  favicon?: ImageWeb;
  touchicon?: ImageWeb;
}
