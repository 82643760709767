import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { getCollections } from '../../query/collections';
import { Renderer } from '../content/ContentRenderer';
import { PostHubContext } from '../hubContext/PostHubContext';

export const Collections = (): JSX.Element => {
  const { hubId } = useContext(PostHubContext);
  const { isLoading, error, data } = useQuery({
    queryKey: ['postHub', hubId, 'collections'],
    queryFn: () => getCollections(hubId),
    suspense: true,
  });

  return <Renderer isLoading={isLoading} error={error} page={data?.page} />;
};
