import { createContext } from '@nucleus/react-components';
import React, { useCallback, useContext, useMemo } from 'react';

interface RichTextParameterContext {
  parameters: Record<string, string>;
  getParameter: (name: string) => string | undefined;
}

const [RichTextParameterProvider, , Context] = createContext<RichTextParameterContext>({
  name: 'RichTextParameter',
});

export const useRichTextParameterContext = (): RichTextParameterContext => {
  const foundContext = useContext(Context);
  if (foundContext === null) {
    return {
      parameters: {},
      getParameter: () => undefined,
    };
  }

  return foundContext;
};

interface RichTextParameterContextProps extends UseControllerProps {
  children: React.ReactNode;
}
export const RichTextParameterContext = ({ children, ...props }: RichTextParameterContextProps): JSX.Element => {
  return <RichTextParameterProvider value={useController(props)}>{children}</RichTextParameterProvider>;
};

interface UseControllerProps {
  inheritParameters?: boolean;
  parameters?: Record<string, string>;
}
const useController = ({ inheritParameters = true, parameters = {} }: UseControllerProps): RichTextParameterContext => {
  const parentContext = useRichTextParameterContext();

  const internalParameters = useMemo(() => {
    if (!inheritParameters) {
      return parameters;
    }

    return { ...parentContext.parameters, ...parameters };
  }, [inheritParameters, parameters]);

  const getParameter = useCallback(
    (name: string): string | undefined => {
      return parameters[name];
    },
    [parameters]
  );

  return {
    parameters: internalParameters,
    getParameter: getParameter,
  };
};
