import { flow as _flow } from 'lodash';
import { useMemo } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import { CustomEditor, Plugin } from '../types/custom-types';

export const useCustomEditor = (plugins: Plugin[] = []): CustomEditor => {
  return useMemo<CustomEditor>(() => _flow(withHistory, withReact, ...plugins)(createEditor()), []);
};
