import { isNonNullable } from '@nucleus/lib-shape';
import {
  Alignment,
  CardBlockWeb,
  CardItemEnabledKey,
  CardItemWeb,
  ListItemContentAreaName,
  MediaWeb,
  Position,
  SectionAction,
} from '@nucleus/types/web';
import { inverseAspectRatioAsPercent } from '@nucleus/web-theme';
import {
  booleanPropHelperFactory,
  conditionalHelperFactory,
  media,
  nucleusClass,
  positionToFlexStyles,
  SIZE,
} from '@nucleus/web-theme-elements';
import { inverseAspectRatioAsPercentFromImage } from '@nucleus/web-theme/src/lib/utilities';
import classNames from 'classnames';
import { get as _get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CardBackground, CardMediaItemBackground } from '../components/Background';
import { Body, Byline, Headline, Label, Labels, Overline } from '../components/Base';
import { InfoButtons } from '../components/InfoButtons';
import { SnapSwipe } from '../components/SnapSwipe';
import { useActionProps } from '../hooks/useActionProps';
import { BlockProps } from '../types/component';
import { buildThemeColorsVariationClassName } from '../utils/styles';

type BlockCardGridProps = Extract<BlockProps, { block?: CardBlockWeb; sectionType?: 'card' }>;

export const BlockCardGrid = (props: BlockCardGridProps): JSX.Element => {
  const items = (props.block?.items ?? []).filter(isNonNullable);

  return (
    <CardGrid
      className={classNames([
        props.className,
        buildThemeColorsVariationClassName(props.block?.itemColorPaletteVariation),
        nucleusClass('block-cards'),
      ])}
    >
      <CardItems
        gridTypeDesktop={props.block?.gridTypeDesktop}
        gridTypeMobile={props.block?.gridTypeMobile}
        gridAlignment={props.block?.gridAlignment}
        items={items}
        itemEnabledInfo={props.block?.itemEnabledInfo ?? {}}
        itemAlignment={props.block?.itemAlignment}
        itemInfoPosition={props.block?.itemInfoPosition}
        itemMediaDisplayType={props.block?.itemMediaDisplayType}
        itemBackgroundMedia={props.block?.itemBackgroundMedia}
        itemSpacing={props.block?.itemSpacing}
      />
    </CardGrid>
  );
};

type CardItemProps = {
  item: CardItemWeb;
  enabledInfo: CardBlockWeb['itemEnabledInfo'];
  alignment?: Alignment;
  backgroundMedia?: CardBlockWeb['itemBackgroundMedia'];
  infoPosition?: Position;
  composition?: Array<CardItemEnabledKey>;
  mediaDisplayType?: string;
  mediaShape?: MediaShape;
  mediaAspectRatio?: [number, number];
  mediaPosition?: 'top' | 'bottom' | 'behind';
  onClick?: (e: React.MouseEvent) => void;
  overlays?: Array<React.ReactNode>;
  shadow?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const CardItem = styled(({ mediaDisplayType = 'inline', ...props }: CardItemProps): JSX.Element | null => {
  const item = props.item;

  let mediaAspectRatio = props.mediaAspectRatio;
  if (mediaDisplayType === 'inline') {
    mediaAspectRatio = [2, 1]; // set default aspect ratio for inline media per theme design
  }

  const media = item.mediaItems?.[0];

  // apply first button destination as card item link
  const firstButton = _get(item.buttons, 0);
  const cardItemLinkProps = getCardItemLinkProps(firstButton);

  // only render enabled content
  const composition = props.composition?.filter((fieldName) => {
    const isEnabled = props.enabledInfo?.[fieldName];
    return isEnabled === true;
  });

  const renderMedia = media !== undefined && composition?.includes('mediaItems') === true;
  const renderCardBackground = renderMedia !== true || mediaDisplayType !== 'background';

  return (
    <CardItemWrapper>
      <CardItemContainer
        className={classNames([props.className, nucleusClass('card-item')])}
        style={props.style}
        onClick={props.onClick}
        $alignment={props.alignment}
        $mediaDisplayType={mediaDisplayType}
        $shadow={props.shadow}
        {...cardItemLinkProps}
      >
        {renderCardBackground && <CardBackground background={props.backgroundMedia} />}
        <CardItemInner>
          {renderMedia && (
            <CardItemMedia
              className={nucleusClass('card-item-media')}
              media={media}
              shape={props.mediaShape}
              aspectRatio={mediaAspectRatio}
            />
          )}
          <CardItemContentArea
            alignment={props.alignment}
            className={nucleusClass('card-item-content')}
            contentName="below"
            composition={composition}
            infoPosition={props.infoPosition}
            item={item}
          />
        </CardItemInner>
      </CardItemContainer>
      {props.overlays}
    </CardItemWrapper>
  );
})``;

const CardGrid = styled.div``;

const areItemsInset = booleanPropHelperFactory('areItemsInset');

type GridContainerProps = {
  gridGap?: number;
  gridColumnsMax?: number;
  gridAlignment?: Alignment;
  areItemsInset?: boolean;
};

const GridContainer = styled.div<GridContainerProps>`
  --grid-gap: ${(props) => (props.gridGap !== undefined && props.gridGap > 0 ? props.gridGap + 'rem' : '1px')};
  --grid-column-count: ${(props) => props.gridColumnsMax ?? 4};
  --grid-item--min-width: 30rem;
  --grid-padding: ${(props) =>
    props.gridGap !== undefined && props.gridGap > 0 ? 'calc(var(--grid-gap) * 3)' : '1px'};

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-gap));
  --grid-item--max-width: min(36rem, calc((100% - var(--total-gap-width)) / var(--grid-column-count)));

  display: flex;
  flex-direction: row;
  ${(props) => positionToFlexStyles(props.gridAlignment ?? 'left')};
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
    min-width: var(--grid-item--min-width);
    max-width: var(--grid-item--max-width);
  }

  grid-gap: var(--grid-gap);

  ${areItemsInset(
    css`
      background-color: transparent;
    `,
    css`
      background-color: var(--color-media-background);
    `
  )}
`;

type GridSwipeContainerProps = {
  gridGap?: number;
  gridColumnsMax?: number;
  areItemsInset?: boolean;
};

const GridSwipeContainer = styled.div<GridSwipeContainerProps>`
  --grid-gap: ${(props) => (props.gridGap !== undefined && props.gridGap > 0 ? props.gridGap + 'rem' : '1px')};
  --grid-padding: ${(props) =>
    props.gridGap !== undefined && props.gridGap > 0 ? 'calc(var(--grid-gap) * 3)' : '1px'};
  --grid-column-count: ${(props) => props.gridColumnsMax ?? 4};
  --grid-item--min-width: 30rem;
  --grid-item--max-width: 36rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  ${areItemsInset(
    css`
      --color-snap-swipe-content-background: transparent;
    `,
    css`
      --color-snap-swipe-content-background: var(--color-media-background);
    `
  )}
`;

const StyledSnapSwipe = styled(SnapSwipe)`
  ${CardItem} {
    min-width: var(--grid-item--min-width);
    max-width: var(--grid-item--max-width);
    width: calc(100vw / (var(--grid-column-count) + 1));
  }
`;

const StackedGridComponent = ({
  className,
  items = [],
  gridGap,
  gridColumnsMax = 3,
  gridAlignment,
  itemEnabledInfo,
  itemAlignment,
  itemComposition,
  itemInfoPosition,
  itemMediaDisplayType,
  itemBackgroundMedia,
  areItemsInset,
}: GridProps): JSX.Element | null => {
  if (items.length === 0) {
    return null;
  }

  return (
    <GridContainer
      className={className}
      gridColumnsMax={gridColumnsMax}
      gridGap={gridGap}
      gridAlignment={gridAlignment}
      areItemsInset={areItemsInset}
    >
      {items.map((item) => {
        return (
          <CardItem
            key={item.id}
            item={item}
            alignment={itemAlignment}
            enabledInfo={itemEnabledInfo}
            composition={itemComposition}
            infoPosition={itemInfoPosition}
            mediaDisplayType={itemMediaDisplayType}
            backgroundMedia={itemBackgroundMedia}
            shadow={gridGap !== undefined && gridGap !== 0}
          />
        );
      })}
    </GridContainer>
  );
};

export const StackedGrid = styled(StackedGridComponent)``;

const SwipeGridComponent = ({
  className,
  items = [],
  gridGap,
  itemEnabledInfo,
  itemAlignment,
  itemComposition,
  itemInfoPosition,
  itemMediaDisplayType,
  itemBackgroundMedia,
  areItemsInset,
}: GridProps): JSX.Element | null => {
  if (items.length === 0) {
    return null;
  }

  return (
    <GridSwipeContainer className={className} gridGap={gridGap} areItemsInset={areItemsInset}>
      <StyledSnapSwipe
        items={items.map((item) => {
          return (
            <CardItem
              key={item.id}
              item={item}
              alignment={itemAlignment}
              enabledInfo={itemEnabledInfo}
              composition={itemComposition}
              infoPosition={itemInfoPosition}
              mediaDisplayType={itemMediaDisplayType}
              backgroundMedia={itemBackgroundMedia}
              shadow={gridGap !== undefined && gridGap !== 0}
            />
          );
        })}
        gridGap={gridGap}
      />
    </GridSwipeContainer>
  );
};

export const SwipeGrid = styled(SwipeGridComponent)``;

type GridProps = BlockCardGridProps['block'] & {
  className?: string;
  gridGap?: number;
  gridColumnsMax?: number;
  gridAlignment?: Alignment;
  itemComposition: Array<CardItemEnabledKey>;
  areItemsInset?: boolean;
};

const ITEM_SPACING_INSET_REM = 2;

const CardItemsContainerMobile = styled.div`
  display: block;

  ${media.tabletLandscapeAndUp`
    display: none;
  `}
`;

const CardItemsContainerDesktop = styled.div`
  ${media.tabletPortraitAndDown`
    ${CardItemsContainerMobile} + & {
      display: none;
    }
  `}
`;

const CardItemsContainer = styled.div``;

const CardItems = (props: NonNullable<BlockCardGridProps['block']>): JSX.Element => {
  const areItemsInset = props.itemSpacing === 'constrained';
  const gridGap = areItemsInset === true ? ITEM_SPACING_INSET_REM : 0;
  const gridColumnsMax = 4; // TODO: adjust based on number of items?
  const itemComposition: Array<CardItemEnabledKey> = ['mediaItems', 'overline', 'headline', 'body', 'buttons']; // used to arrange content
  const renderMobileGrid = props.gridTypeMobile !== props.gridTypeDesktop;

  return (
    <CardItemsContainer>
      {renderMobileGrid && (
        <CardItemsContainerMobile>
          {props.gridTypeMobile === 'swipe' ? (
            <SwipeGrid {...props} gridGap={gridGap} itemComposition={itemComposition} areItemsInset={areItemsInset} />
          ) : (
            <StackedGrid
              {...props}
              gridGap={gridGap}
              gridColumnsMax={gridColumnsMax}
              gridAlignment={props.gridAlignment}
              itemComposition={itemComposition}
              areItemsInset={areItemsInset}
            />
          )}
        </CardItemsContainerMobile>
      )}
      <CardItemsContainerDesktop>
        {props.gridTypeDesktop === 'swipe' ? (
          <SwipeGrid {...props} gridGap={gridGap} itemComposition={itemComposition} areItemsInset={areItemsInset} />
        ) : (
          <StackedGrid
            {...props}
            gridGap={gridGap}
            gridColumnsMax={gridColumnsMax}
            gridAlignment={props.gridAlignment}
            itemComposition={itemComposition}
            areItemsInset={areItemsInset}
          />
        )}
      </CardItemsContainerDesktop>
    </CardItemsContainer>
  );
};

type MediaProps = MediaWeb;

const MediaShapes = ['square', 'rounded', 'circle', 'original'] as const;
type MediaShape = (typeof MediaShapes)[number];

interface MediaContainerProps {
  $image?: MediaProps;
  $shape: MediaShape;
  isSticky?: boolean;
}

const MEDIA_SHAPE = {
  circle: {
    borderRadius: '50%',
    height: 0,
    paddingBottom: '100%',
  },
  square: {
    height: 0,
    paddingBottom: '100%',
  },
  rounded: {
    borderRadius: '2.4rem',
  },
  original: {},
};

const MediaContainer = styled.div<MediaContainerProps>((props) => {
  return {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    '--color-media-background': 'var(--color-dark)', // override media background color
    ...(MEDIA_SHAPE[props.$shape] ?? {}),
  };
});

interface MediaInnerProps {
  $image?: MediaProps;
  $aspectRatio?: [number, number];
}

const MediaInner = styled.div<MediaInnerProps>((props) => {
  let aspectRatioPercentage = inverseAspectRatioAsPercentFromImage(props.$image?.image);
  if (props.$aspectRatio) {
    aspectRatioPercentage = inverseAspectRatioAsPercent(props.$aspectRatio[0], props.$aspectRatio[1]);
  }

  return {
    width: '100%',
    paddingTop: aspectRatioPercentage,
    position: 'relative',
    minHeight: '100%',
  };
});

interface CardItemMediaProps {
  media?: MediaProps;
  shape?: MediaShape;
  aspectRatio?: [number, number];
  className?: string;
}

const CardItemMedia = styled(({ media, shape, aspectRatio, ...props }: CardItemMediaProps) => {
  return (
    <MediaContainer {...props} $image={media} $shape={shape ?? 'original'}>
      <MediaInner $image={media} $aspectRatio={aspectRatio}>
        <CardMediaItemBackground background={media} />
      </MediaInner>
    </MediaContainer>
  );
})``;

const getCardItemLinkProps = (action?: SectionAction): React.ComponentProps<typeof CardItemContainer> => {
  if (action === undefined) {
    return {};
  }

  const props: React.ComponentProps<typeof CardItemContainer> = useActionProps(action);

  if (props.to !== undefined) {
    props.as = Link;
  }

  if (props.href !== undefined) {
    props.as = 'a';
  }

  return props;
};

const ContentOverline = (props: { item: CardItemWeb }) => <CardItemOverline nodes={props.item.overline} />;

const ContentHeadline = (props: { item: CardItemWeb }) => <CardItemHeadline nodes={props.item.headline} />;

const ContentBody = (props: { item: CardItemWeb }) => <CardItemBody nodes={props.item.body} />;

const ContentLabels = (props: { item: CardItemWeb }) => {
  if (props.item.labels === undefined) {
    return null;
  }
  return (
    <CardItemLabels>
      {props.item.labels.map((label, index) => (
        <CardItemLabel key={index}>{label.title}</CardItemLabel>
      ))}
    </CardItemLabels>
  );
};

const ContentButtons = (props: { item: CardItemWeb }) => {
  if (props.item.buttons === undefined || props.item.buttons.length === 0) {
    return null;
  }

  const hasTitle = (action: SectionAction) => action.title !== undefined && action.title.trim().length > 1;
  const isActionable = (action: SectionAction) => hasTitle(action) || action.type !== 'none';

  const filteredButtons = props.item.buttons.filter(isActionable);

  if (filteredButtons.length < 1) {
    return null;
  }

  return (
    <ButtonStackPosition>
      <InfoButtons
        buttons={filteredButtons}
        buttonsLayout="vertical"
        buttonsWidth="stretch"
        buttonsMaxWidth="large"
        buttonsAlignment="center"
      />
    </ButtonStackPosition>
  );
};

const ContentByline = (props: { item: CardItemWeb }) => <CardItemByline nodes={props.item.byline} />;

const FIELD_NAME_TO_COMPONENT = {
  overline: ContentOverline,
  headline: ContentHeadline,
  body: ContentBody,
  labels: ContentLabels,
  byline: ContentByline,
  buttons: ContentButtons,
};

type CardItemContentAreaProps = {
  alignment?: Alignment;
  item: CardItemWeb;
  className?: string;
  contentName: ListItemContentAreaName;
  composition?: Array<CardItemEnabledKey>;
  infoPosition?: Position;
};

const CardItemContentArea = (props: CardItemContentAreaProps) => {
  if (props.composition === undefined || props.composition.length === 0) {
    return null;
  }

  const contentAreaFields = props.composition;

  if (contentAreaFields === undefined) {
    return null;
  }

  const textContentFields = contentAreaFields.filter((field) => field !== 'buttons');
  const buttonContentField = contentAreaFields.filter((field) => field === 'buttons');

  const textContent = textContentFields.map((fieldName: CardItemEnabledKey) => {
    const ContentComponent = FIELD_NAME_TO_COMPONENT[fieldName as keyof typeof FIELD_NAME_TO_COMPONENT];
    if (ContentComponent === undefined) {
      return null;
    }
    return <ContentComponent key={fieldName} item={props.item} />;
  });
  let hasButtons = false;
  const buttons = buttonContentField.map((fieldName: CardItemEnabledKey) => {
    const ContentComponent = FIELD_NAME_TO_COMPONENT[fieldName as keyof typeof FIELD_NAME_TO_COMPONENT];
    if (ContentComponent === undefined) {
      return null;
    }
    hasButtons = true;
    return <ContentComponent key={fieldName} item={props.item} />;
  });

  return (
    <CardItemContent className={props.className}>
      <TextMask hasButtons={hasButtons}>
        <InfoPosition hasButtons={hasButtons} position={props.infoPosition}>
          <CardItemTextContent alignment={props.alignment}>{textContent}</CardItemTextContent>
        </InfoPosition>
      </TextMask>
      {buttons}
    </CardItemContent>
  );
};

const hasBackgroundMedia = conditionalHelperFactory((props: any) => props.$mediaDisplayType === 'background');
const hasShadow = booleanPropHelperFactory('$shadow');

const CardItemOverline = styled(Overline)`
  & > * {
    min-height: 1.5em;
  }
`;

const CardItemHeadline = styled(Headline)`
  & > * {
    max-width: 11em;
  }
`;

const CardItemByline = styled(Byline)``;

const CardItemBody = styled(Body)`
  flex: 1 1 auto;

  & > * {
    max-width: 18em;
  }
`;

const CardItemLabels = styled(Labels)`
  display: inline-flex;
  padding: 0;
`;

const CardItemLabel = styled(Label)``;

const CardItemContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 0;

  // Increase spacing at top if first child on item
  &:first-child {
    padding-top: ${SIZE[8]};

    ${CardItemOverline} {
      min-height: 3em;
    }
  }
`;

const CardItemTextContent = styled.div<{ alignment?: Alignment }>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  max-height: 100%;

  margin-left: ${(props) => (props.alignment === 'left' ? '0' : 'auto')};
  margin-right: ${(props) => (props.alignment === 'right' ? '0' : 'auto')};

  & ${CardItemOverline}, ${CardItemHeadline}, ${CardItemByline}, ${CardItemBody}, ${CardItemLabels} {
    & > :first-child {
      margin-bottom: 0.5em;
    }
  }

  & > div:first-child > :first-child {
    margin-top: 0;
  }

  & > div:last-child > :last-child {
    margin-bottom: 0;
  }
`;

type CardItemContainerProps = {
  className?: string;
  $alignment?: Alignment;
  onClick?: (e: React.MouseEvent) => void;
  $mediaDisplayType?: string;
  $shadow?: boolean;
};

const CardItemInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardItemWrapper = styled.div`
  width: 100%;
`;

const CardItemContainer = styled.div<CardItemContainerProps>`
  display: block;
  position: relative;
  width: 100%;
  padding-top: ${inverseAspectRatioAsPercent(6, 9)};
  background: var(--color-section-background);
  cursor: pointer;

  ${hasBackgroundMedia(
    css`
      color: var(--color-light);
    `,
    css`
      color: var(--color-section-text-body);
    `
  )}

  ${hasShadow(css`
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.18); // TODO: ensure some level of contrast with background
  `)}

  ${CardItemMedia} {
    flex: 0 0 auto;

    ${hasBackgroundMedia(css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    `)};
  }

  ${CardItemInner} {
    ${(props) =>
      props.$alignment === 'center' &&
      css`
        text-align: center;
        align-items: center;

        ${CardItemLabels} {
          justify-content: center;
        }
      `};
    ${(props) =>
      props.$alignment === 'right' &&
      css`
        text-align: right;
        align-items: flex-end;

        ${CardItemLabels} {
          justify-content: flex-end;
        }
      `};
  }
`;

const ButtonStackPosition = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: ${SIZE[4]};
  padding-top: 0;

  & > * {
    margin: 0;
  }
`;

const InfoPosition = styled.div<{ position?: Position; hasButtons: boolean }>`
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  ${(props) => positionToFlexStyles(props.position ?? 'center')};
  padding: ${SIZE[4]};

  ${(props) =>
    props.hasButtons &&
    css`
      padding-bottom: ${2.4 * 2 + 6.8 + 'rem'}; // padding + button height
    `}
`;

const TextMask = styled.div<{ hasButtons?: boolean }>`
  height: 100%;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to top, transparent 0rem, black 5rem, black 100%);
  mask-image: linear-gradient(to top, transparent 0rem, black 5rem, black 100%);

  ${(props) =>
    props.hasButtons &&
    css`
      -webkit-mask-image: linear-gradient(to top, transparent 8rem, black 13rem, black 100%);
      mask-image: linear-gradient(to top, transparent 8rem, black 13rem, black 100%);
    `}
`;
