import { SearchInput } from '@nucleus/sermon-theme-elements';
import { SermonSearchRequestBlockWeb } from '@nucleus/types/web/sections/SermonSearchRequestSection';
import React from 'react';
import { SermonSearchUrlController } from '../controllers/SermonSearchUrlController';
import { BlockProps } from '../types/component';
import { BlockInfo } from './BlockInfo';

type BlockSermonSearchRequestProps = Extract<
  BlockProps,
  { block?: SermonSearchRequestBlockWeb; sectionType?: 'sermonSearchRequest' }
>;

export const BlockSermonSearchRequest = ({ block, ...props }: BlockSermonSearchRequestProps): JSX.Element => {
  return (
    <SermonSearchUrlController {...props} engineId={block?.engineId}>
      <BlockInfo {...props} block={block}>
        <SearchInput />
      </BlockInfo>
    </SermonSearchUrlController>
  );
};
