import { Position } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCardGrid, StackedGrid } from '../../blocks/BlockCardGrid';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { CardBlockLayoutProps, getPositionRow } from './CardLayout';

export const CardBlockLayoutContentOnly = (props: CardBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const row = getPositionRow(props.blockInfoPosition);

  return (
    <StyledBlockLayout {...props} row={row}>
      <SpacerTop />
      <StyledContainer>
        <StyledBlockCardGrid {...innerProps} block={props.blocks[0]} />
        <StyledSpacerBottom />
      </StyledContainer>
    </StyledBlockLayout>
  );
};

const StyledBlockCardGrid = styled(BlockCardGrid)``;
const StyledSpacerBottom = styled(SpacerTop)``;
const StyledContainer = styled.div``;

const StyledBlockLayout = styled(BlockLayout)<{ row: Position }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledBlockCardGrid} {
    position: relative;
    z-index: 1;

    background-color: transparent;
    ${StackedGrid} {
      padding: var(--unit-length);
    }
  }

  ${StyledContainer} {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    justify-content: ${(props) => {
      switch (props.row) {
        case Position.Top:
          return 'flex-start';
        case Position.Center:
          return 'center';
        case Position.Bottom:
          return 'flex-end';
        default:
          return;
      }
    }};

    ${StyledSpacerBottom} {
      display: ${(props) => (props.row === Position.Center ? 'block' : 'none')};
      display: ${(props) => (props.height === 'small' ? 'none' : undefined)};
    }
  }
`;
