import React from 'react';
import styled from 'styled-components';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    NotesToolbar?: {
      padding?: string;
    };
  }
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const NotesToolbar = (props: Props): JSX.Element => {
  const { left, right, children, ...otherProps } = props;

  return (
    <ToolBar {...otherProps}>
      {left && <FlexLeft>{left}</FlexLeft>}
      {children}
      {right && <FlexRight>{right}</FlexRight>}
    </ToolBar>
  );
};

const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme._sermonThemeElements.NotesToolbar?.padding};
`;

const FlexLeft = styled.div`
  flex: 0 1 content;
  width: 0px;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const FlexRight = styled.div`
  flex: 0 0 content;
  display: flex;
  gap: 12px;
  align-items: center;
`;
