import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCollage } from '../../blocks/BlockCollage';
import { BlockInfo } from '../../blocks/BlockInfo';
import { InfoBlockLayoutProps } from './InfoLayout';

export const InfoBlockLayoutCollage = (props: InfoBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const mediaOnTheBottom = props.blockLayoutVariant === 'variant2';

  return (
    <StyledBlockLayout {...props} mediaOnTheBottom={mediaOnTheBottom}>
      <StyledBlockCollage {...innerProps} block={props.blocks[0]} />
      <StyledBlockInfo {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const mediaOnTheBottom = booleanPropHelperFactory('mediaOnTheBottom');

const StyledBlockCollage = styled(BlockCollage)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)<{ mediaOnTheBottom: boolean }>`
  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '60vh';
        case 'large':
          return '80vh';
        default:
          return undefined;
      }
    }};
  }

  ${mediaOnTheBottom(css`
    ${StyledBlockCollage} {
      order: 2;
    }

    ${StyledBlockInfo} {
      order: 1;
    }
  `)}
`;
