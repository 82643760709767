import { slugifyString } from '@nucleus/src-platform/data/text';
import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { getCollectionTypeFromId } from '../lib/collection';
import { useLocalization } from './useLocalization';

type Collection = { id: string; slug: string };

export const useCollectionPath = (basePath: string): (<T extends Collection>(collection: T | undefined) => string) => {
  const localize = useLocalization();

  return useCallback(
    <T extends Collection>(collection: T | undefined) => {
      if (collection === undefined) {
        return basePath;
      }
      return generatePath(':basePath/:collectionCategory/:slug', {
        basePath: basePath,
        collectionCategory: getCollectionCategoryPath(collection, localize),
        slug: collection.slug,
      });
    },
    [basePath]
  );
};

// Fixme: this will need to change with localization
const getCollectionCategoryPath = (collection: Collection, localize: (key: string, fallback: string) => string) => {
  const TypePathMap: Record<string, string> = {
    playlist: localize('playlists', 'playlists'),
    tag: localize('topics', 'topics'),
    speaker: localize('speakers', 'speakers'),
    book: localize('scripture', 'bible'),
  };
  const type = getCollectionTypeFromId(collection.id);

  if (!type) {
    return '';
  }

  return slugifyString(TypePathMap[type]);
};
