import Joi from '@hapi/joi';
import { EmailShape } from '@nucleus/src/email/service/send';
import { isValid } from './shape';

// Do not try to "fix" this regex until you've read and understand this page!!
// https://www.regular-expressions.info/email.html
const EMAIL_REGEX_STRING = '[A-Z0-9_%+-]+(?:\\.[A-Z0-9_%+-]+)*@[A-Z0-9-]+(?:\\.[A-Z0-9-]+)*\\.[A-Z]{2,}';
const ONLY_EMAIL_REGEX = new RegExp(`^${EMAIL_REGEX_STRING}$`, 'i');
export const EMAIL_REGEX = new RegExp(EMAIL_REGEX_STRING, 'i');

export const emailSchema = Joi.string().regex(ONLY_EMAIL_REGEX).min(5).lowercase().label('email address');

export function isValidEmail(address: unknown): address is string {
  return isValid(address, emailSchema.required());
}

interface EmailParts {
  name?: string;
  address: string;
}

/**
 * Name Address Spec
 * https://www.rfc-editor.org/rfc/rfc2822
 */
export const emailStringToParts = (nameAddressSpec: string): EmailParts => {
  if (isValidEmail(nameAddressSpec)) {
    return {
      address: nameAddressSpec,
    };
  }

  const emailMatch = nameAddressSpec.match(EMAIL_REGEX);

  if (emailMatch === null) {
    throw new Error('Value does not contain a valid email address substring');
  }

  const name = nameAddressSpec
    .replace(emailMatch[0], '')
    .replace(/^"+/, '')
    .replace(/[ ><"]+$/, '')
    .trim();

  return {
    address: emailMatch[0],
    name: name !== '' ? name : undefined,
  };
};

export const emailPartsToDestination = (parts: EmailParts): EmailShape => {
  if (parts.name !== undefined && parts.name.trim() !== '') {
    return [parts.name, parts.address];
  }
  return parts.address;
};

export const emailStringToDestination = (nameAddressSpec: string): EmailShape => {
  return emailPartsToDestination(emailStringToParts(nameAddressSpec));
};
