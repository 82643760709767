import styled from 'styled-components';
import React from 'react';
import { Button, ButtonProps } from './Button';
import { IconShare } from '../icons/IconShare';

export const ShareButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button {...props}>
      <Content>
        <IconShare width="17px" height="18px" />
        <span>Share</span>
      </Content>
    </Button>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 0 18px;
`;
