import React from 'react';

/** Icon for drawing a circle play button on a Player Thumbnail element */
export const IconCirclePlay = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106 106" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(3 3)">
        <circle cx="50" cy="50" r="50" fill="#000" fillOpacity=".3" stroke="#FFF" strokeWidth="6" />
        <path
          fill="#FFF"
          d="M74.175 51.883 36.87 70.535a2.105 2.105 0 0 1-3.046-1.883V31.348a2.105 2.105 0 0 1 3.046-1.883l37.305 18.652a2.105 2.105 0 0 1 0 3.766Z"
        />
      </g>
    </svg>
  );
};
