import React from 'react';

/** Icon representing a search button action */
export const IconMagnifyingGlass = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.71 14.653" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2"
        d="M6.316 11.632A5.316 5.316 0 1 0 6.316 1a5.316 5.316 0 0 0 0 10.632Zm3.865-.529L13 13.95"
      />
    </svg>
  );
};
