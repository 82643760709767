import { CalendarEvent } from '@nucleus/calendar/types/calendar';
import { objectToQueryString } from '@nucleus/lib-shape';
import { readCalendarWebEndpoint } from '@nucleus/web-hosting';
import { DateApi, DateRange, Iso8601 } from '@nucleus/web-theme-elements';
import { getAuthState } from '@nucleus/web-theme/src/lib/auth';
import { fetchWithAuth } from '@nucleus/web-theme/src/lib/fetch';
import { useEffect, useMemo, useState } from 'react';

export type CalendarIntervalMode = 'day' | 'week' | 'month';

type UseCalendarParams = {
  apiEndpoint: string;
  initialRange?: DateRange;
  initialIntervalMode?: CalendarIntervalMode;
};

type UseCalendarReturn = {
  isLoading: boolean;
  isRefreshing: boolean; // used to indicate that we are loading more events in the background
  error?: string;
  currentRange: DateRange;
  jumpToDate: (date: Date) => void;
  intervalMode: CalendarIntervalMode;
  events: Array<CalendarEvent>;
  nextEvent?: CalendarEvent;
  loadEvents: (range: DateRange) => void;
  loadNextEvents: () => void;
  loadPrevEvents: () => void;
  changeIntervalMode: (mode: CalendarIntervalMode) => void;
};

const initialCacheState = {
  loading: true,
  refreshing: false,
  events: [],
};

const rangeHandlersMap = {
  day: {
    start: DateApi.startOfDay,
    end: (date: Date | number) => DateApi.addDays(DateApi.startOfDay(date), 1),
    increment: (date: Date | number) => DateApi.addDays(date, 1),
    decrement: (date: Date | number) => DateApi.subDays(date, 1),
  },
  week: {
    start: DateApi.startOfWeek,
    end: (date: Date | number) => DateApi.addWeeks(DateApi.startOfWeek(date), 1),
    increment: (date: Date | number) => DateApi.addWeeks(date, 1),
    decrement: (date: Date | number) => DateApi.subWeeks(date, 1),
  },
  month: {
    start: DateApi.startOfMonth,
    end: (date: Date | number) => DateApi.addMonths(DateApi.startOfMonth(date), 1),
    increment: (date: Date | number) => DateApi.addMonths(date, 1),
    decrement: (date: Date | number) => DateApi.subMonths(date, 1),
  },
};

export const useCalendarEvents = ({
  apiEndpoint,
  initialRange: initialRangeProps,
  initialIntervalMode = 'week',
}: UseCalendarParams): UseCalendarReturn => {
  const initialRange = useMemo(() => {
    const fallbackHandlers = rangeHandlersMap[initialIntervalMode];
    return {
      start: initialRangeProps?.start ?? fallbackHandlers.start(new Date()),
      end: initialRangeProps?.end ?? fallbackHandlers.end(new Date()),
    };
  }, [initialRangeProps]);

  const [eventCache, setEventCache] = useState<{
    [key: string]: {
      events: CalendarEvent[];
      loading: boolean;
      nextEvent?: CalendarEvent;
      refreshing: boolean;
      error?: string;
    };
  }>({});

  const [intervalMode, setIntervalMode] = useState<CalendarIntervalMode>(initialIntervalMode);
  const [currentRange, setCurrentRange] = useState<DateRange>(initialRange);

  const getApiUrl = (range: DateRange): string => {
    const params = {
      start: DateApi.dateToIso(range.start),
      end: DateApi.dateToIso(range.end),
    };
    return `${apiEndpoint}?${objectToQueryString(params)}`;
  };

  const loadEvents = async (range: DateRange) => {
    const url = getApiUrl(range);

    const cached = eventCache[url] ?? initialCacheState;

    const loading = cached?.loading ?? true;
    const refreshing = loading === false ?? cached?.refreshing ?? false;

    setEventCache((state) => ({
      ...state,
      [url]: { ...cached, loading: loading, refreshing: refreshing },
    }));

    try {
      const data = await fetchCalendarEvents(url);
      setEventCache((state) => ({
        ...state,
        [url]: { events: data.events, nextEvent: data.nextEvent, loading: false, refreshing: false },
      }));
    } catch (error) {
      setEventCache((state) => ({
        ...state,
        [url]: { ...cached, loading: false, refreshing: false, error: error.message },
      }));
    }
  };

  const jumpToDate = (date: Date) => {
    const rangeHandlers = rangeHandlersMap[intervalMode];
    const newStart = rangeHandlers.start(date);
    const newEnd = rangeHandlers.end(date);
    const newRange = { start: newStart, end: newEnd };
    setCurrentRange(newRange);
  };

  const loadNextEvents = async () => {
    const rangeHandlers = rangeHandlersMap[intervalMode];
    const newStart = currentRange.end;
    const newEnd = rangeHandlers.increment(newStart);
    const newRange = { start: newStart, end: newEnd };
    setCurrentRange(newRange);
  };

  const loadPrevEvents = async () => {
    const rangeHandlers = rangeHandlersMap[intervalMode];
    const newEnd = currentRange.start;
    const newStart = rangeHandlers.decrement(newEnd);
    const newRange = { start: newStart, end: newEnd };
    setCurrentRange(newRange);
  };

  const changeIntervalMode = (newIntervalMode: CalendarIntervalMode) => {
    const fallbackHandlers = rangeHandlersMap[newIntervalMode];
    const firstEvent = events.at(0);

    const todayInRange =
      DateApi.isAfter(new Date(), currentRange.start) && DateApi.isBefore(new Date(), currentRange.end);
    const nonRangeStart =
      firstEvent !== undefined ? DateApi.isoToDate(firstEvent.start as Iso8601) : currentRange.start;
    const startingDate = todayInRange === true ? new Date() : nonRangeStart;

    const newStart = fallbackHandlers.start(startingDate);
    const newEnd = fallbackHandlers.end(startingDate);
    const newRange = { start: newStart, end: newEnd };
    setCurrentRange(newRange);
    setIntervalMode(newIntervalMode);
  };

  useEffect(() => {
    loadEvents(currentRange);
  }, [currentRange]);

  const currentState = eventCache[getApiUrl(currentRange)] ?? initialCacheState;

  const isLoading = currentState.loading;
  const isRefreshing = currentState.refreshing;
  const error = currentState.error;
  const events = currentState.events ?? [];
  const nextEvent = currentState.nextEvent;

  return {
    changeIntervalMode: changeIntervalMode,
    currentRange: currentRange,
    error: error,
    events: events,
    intervalMode: intervalMode,
    isLoading: isLoading,
    isRefreshing: isRefreshing,
    jumpToDate: jumpToDate,
    loadEvents: loadEvents,
    loadNextEvents: loadNextEvents,
    loadPrevEvents: loadPrevEvents,
    nextEvent: nextEvent,
  };
};

// Example API function for fetching calendar events
const fetchCalendarEvents = async (url: string): Promise<readCalendarWebEndpoint['responseBody']> => {
  const authState = await getAuthState();
  const fetcher = authState === 'authenticated' ? fetchWithAuth : fetch;

  const response = await fetcher(url);
  if (response.status >= 300) {
    throw new Error(`Failed to fetch calendar events: ${response.status}`);
  }

  const data = await response.json();
  return data as readCalendarWebEndpoint['responseBody'];
};
