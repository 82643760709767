import { SectionDataWeb } from '@nucleus/types/web';
import React from 'react';
import { SectionContext, SectionContextValue } from './SectionContext';

export interface SectionProviderProps {
  children: React.ReactNode;
  section: SectionDataWeb;
}

export const SectionProvider = (props: SectionProviderProps): JSX.Element => {
  const context: SectionContextValue = {
    section: props.section,
  };

  return <SectionContext.Provider value={context}>{props.children}</SectionContext.Provider>;
};
