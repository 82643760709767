import { Link } from 'react-router-dom';
import { DefaultTheme, StyledComponentProps } from 'styled-components';

export type PolymorphicButtonProps = {
  to?: string;
  href?: string;
} & {
  as?: string | React.ComponentType<any>;
  forwardedAs?: string | React.ComponentType<any>;
  // eslint-disable-next-line @typescript-eslint/ban-types
} & StyledComponentProps<'button', DefaultTheme, {}, never>;

/** Implements the polymorphic "as" prop, switching between button, a, and Link elements based on props */
export const usePolymorphicButtonProps = <T extends PolymorphicButtonProps>(props: T): T => {
  if (props.to !== undefined) {
    return {
      as: Link,
      ...props,
    };
  }

  if (props.href !== undefined) {
    return {
      as: 'a',
      ...props,
    };
  }

  return {
    ...props,
  };
};
