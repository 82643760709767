import styled from 'styled-components';
import React from 'react';
import { Button, ButtonProps } from './Button';
import { IconMagnifyingGlass } from '../icons/IconMagnifyingGlass';
import { useLocalization } from '../hooks/useLocalization';

export const SearchNavButton = (props: ButtonProps): JSX.Element => {
  const localize = useLocalization();

  return (
    <Button {...props}>
      <Content>
        <IconMagnifyingGlass width="12.16px" height="13px" />
        <StyledText>{localize('search', 'Search')}</StyledText>
      </Content>
    </Button>
  );
};

const StyledText = styled.span``;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 18px;

  ${StyledText} {
    display: none;
  }

  @media (min-width: 600px) {
    ${StyledText} {
      display: inline-block;
    }
  }
`;
