import { truncate as _truncate } from 'lodash';
import { Descendant, Element, Text } from 'slate';

export const traverse = <T extends Descendant>(nodes: T[], visit: (node: T) => T): T[] => {
  return nodes.map((node) => {
    let newNode = visit(node);

    if (Element.isElement(newNode)) {
      const newChildren = traverse(newNode.children as any, visit);
      newNode = { ...newNode, children: newChildren };
    }

    return newNode;
  });
};

export const lengthOfRichText = <T extends Descendant>(nodes: T[]): number => {
  let count = 0;

  traverse(nodes, (node) => {
    if (Text.isText(node)) {
      count += node.text.length;
    }
    return node;
  });

  return count;
};

export const truncateRichText = <T extends Descendant>(nodes: T[], limit: number): T[] => {
  let count = 0;

  const truncate = (nodes: T[]): T[] => {
    return nodes.reduce((acc, node) => {
      if (limit - count <= 0) {
        return acc;
      }

      if (Text.isText(node)) {
        const truncatedText = _truncate(node.text, { length: limit - count });
        const truncatedNode: Text = { ...node, text: truncatedText };
        count += truncatedText.length;
        return acc.concat(truncatedNode as T);
      }

      if (Element.isElement(node)) {
        const truncatedChildren = truncate(node.children as any);
        const truncatedNode: Element = { ...node, children: truncatedChildren };
        return acc.concat(truncatedNode as T);
      }

      return acc.concat(node);
    }, [] as T[]);
  };

  return truncate(nodes);
};

export const stringifyRichText = <T extends Descendant>(nodes: T[]): string => {
  return nodes.map((node) => (Element.isElement(node) ? stringifyElement(node) : stringifyLeaf(node as Text))).join('');
};

const stringifyElement = <T extends Element>(element: T): string => {
  switch (element.type) {
    case 'parameter':
      return element.fallback ?? ' ';
    default:
      return stringifyRichText(element.children);
  }
};

const stringifyLeaf = <T extends Text>(leaf: T): string => (leaf.text === '' ? ' ' : leaf.text);
