import { createPrefixedKey, isPrefixedKey } from '@nucleus/lib-shape';
import { PublishableEntity, PublishableEntitySchema } from '../../types/PublishableEntity';
import {
  RepositoryHelperOptions,
  RepositoryHelperOptionsWithoutType,
  RepositoryHelperOptionsWithType,
} from '../../types/repository';
import { publishableEngineRepositoryHelpers } from './publishableEngineRepositoryHelpers';

export const ProtectedEntityKeys = [
  'id',
  'engineId',
  'ownerId',
  'createdAt',
  'createdBy',
  'archivedAt',
  'archivedBy',
  'updatedAt',
  'updatedBy',
] as const;
export type ProtectedEntityKeys = typeof ProtectedEntityKeys[number];

function publishableEntityRepositoryHelpersFunction<T extends PublishableEntity>(
  options: RepositoryHelperOptionsWithType
): {
  entityType: RepositoryHelperOptionsWithType['entityType'];
  isPrefixedKey: (key: any) => key is T['id'];
  isType: (element: any) => element is T;
  key: 'metadata';
  namespace: RepositoryHelperOptionsWithType['namespace'];
  createPrefixedKey: () => T['id'];
  schema: typeof PublishableEntitySchema;
};

function publishableEntityRepositoryHelpersFunction(options: RepositoryHelperOptionsWithoutType): {
  key: 'metadata';
  namespace: RepositoryHelperOptionsWithoutType['namespace'];
};

function publishableEntityRepositoryHelpersFunction<T extends PublishableEntity>(
  options: RepositoryHelperOptions
): any {
  // constants
  const baseReturn = {
    key: 'metadata',
    namespace: options.namespace,
  } as const;

  if (!('entityType' in options)) {
    return baseReturn;
  }

  // Helpers
  const isPrefixedEntityKey = (key: any): key is T['id'] => isPrefixedKey(options.entityType, key);
  const prefixedEntityKey = (): T['id'] => createPrefixedKey(options.entityType);

  const isEntityType = (element: any): element is T => entitySchema.required().isValidSync(element);

  const engineHelpers = publishableEngineRepositoryHelpers(options);

  const entitySchema = PublishableEntitySchema.shape({
    id: PublishableEntitySchema.fields.id.test(
      'is this entity type',
      `\${path}: \${value} is not a "${options.entityType}" entity`,
      (value) => isPrefixedEntityKey(value)
    ),
    engineId: engineHelpers.schema.fields.id,
  });

  return {
    ...baseReturn,
    entityType: options.entityType,
    isPrefixedKey: isPrefixedEntityKey,
    isType: isEntityType,
    createPrefixedKey: prefixedEntityKey,
    schema: entitySchema,
  } as const;
}

export const publishableEntityRepositoryHelpers = publishableEntityRepositoryHelpersFunction;
