import React from 'react';
import styled, { StyledComponent } from 'styled-components';

export const withForwardedAs = <T extends StyledComponent<any, any>>(WrappedComponent: T): T => {
  return Object.assign(
    styled(({ as, ...props }) => {
      return <WrappedComponent forwardedAs={as} {...props} />;
    })``,
    { displayName: 'withForwardedAs' }
  ) as T;
};
