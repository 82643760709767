import { getKeyPrefix } from '@nucleus/lib-shape';
import { SermonReferenceKeyPrefix, SermonSpeakerKeyPrefix, SermonTagEntityPrefix } from '@nucleus/sermons';

export type CollectionType = 'playlist' | 'tag' | 'speaker' | 'book';

export const getCollectionTypeFromId = (id?: string): CollectionType | undefined => {
  if (id === undefined) {
    return;
  }

  const PrefixPathMap: Record<string, CollectionType> = {
    playlist: 'playlist',
    [SermonTagEntityPrefix]: 'tag',
    [SermonSpeakerKeyPrefix]: 'speaker',
    [SermonReferenceKeyPrefix]: 'book',
  };

  if (isNaN(Number(id)) === false) {
    return PrefixPathMap[SermonReferenceKeyPrefix];
  }

  const prefix = getKeyPrefix(id);

  if (!prefix) {
    return;
  }

  return PrefixPathMap[prefix];
};
