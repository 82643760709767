import * as yup from 'yup';

const mediaFileSchema = yup.object().shape({
  fileId: yup.string().required(),
});

const baseSermonMediaItemSchema = yup.object().shape({
  id: yup.string().required(),
  artwork: yup.object(),
  gathering: yup.object(),
  primary: yup.boolean().required(),
  privacy: yup.object().required(),
  title: yup.string(),
  type: yup.mixed().oneOf(['file/audio', 'embed/video', 'liveStream/link']).required(),
});

export const sermonAudioMediaItemSchema = baseSermonMediaItemSchema.shape({
  type: yup.string().equals(['file/audio']).required(),
  caption: mediaFileSchema.default(undefined),
  description: yup.array(),
  item: mediaFileSchema,
  podcastArtwork: yup.object(),
  podcastChannels: yup.array().of(yup.string()),
});

export const sermonEmbedVideoMediaItemSchema = baseSermonMediaItemSchema.shape({
  type: yup.string().equals(['embed/video']).required(),
  href: yup.string().url().required(),
});

export const sermonLiveStreamLinkMediaItemSchema = baseSermonMediaItemSchema.shape({
  type: yup.string().equals(['liveStream/link']).required(),
  href: yup.string().url().required(),
  label: yup.string(),
});
