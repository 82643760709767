import { objectKeys } from '@nucleus/src/lib/object';
import { FontPalette } from '@nucleus/types/web/style';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { ServerFontContext } from './ServerFont';

type fontStatus = 'loading' | 'loaded' | 'error' | 'pending';

interface FontDefinition {
  family: string;
  variant: string;
  source: string;
  status: fontStatus;
}

type FontDefinitions = Record<string, FontDefinition>;

type WebFontConfig = Record<
  string,
  {
    families: string[];
  }
>;

export interface FontContextValue {
  loadFont: (font: Omit<FontDefinition, 'status'>) => void;
}

export const FontContext = createContext<FontContextValue>({} as FontContextValue);

export const useFontContext = (): FontContextValue => {
  const providedContext = useContext(FontContext);
  if (providedContext === null) {
    throw new Error(`hook must be used within a FontProvider`);
  }
  return providedContext;
};

interface FontProviderProps {
  children: React.ReactNode;
}

export const FontProvider = (props: FontProviderProps): JSX.Element => {
  return <FontContext.Provider value={useFontContextValue()}>{props.children}</FontContext.Provider>;
};

const generateFontKey = ({ family, variant, source }: Omit<FontDefinition, 'status'>) =>
  [source, family, variant].join('.');

const getPaletteDefinitions = (fontPallet?: FontPalette): FontDefinitions => {
  if (fontPallet === undefined) {
    return {};
  }

  return objectKeys(fontPallet).reduce((acc: FontDefinitions, font) => {
    const config = fontPallet[font];
    const variant = (config.fontWeight ?? '400') + (config.fontStyle ?? 'normal');
    const definition: FontDefinition = {
      family: config.fontFamily,
      variant: variant,
      source: config.source,
      status: 'pending',
    };

    const key = generateFontKey(definition);
    if (acc[key] === undefined) {
      acc[key] = definition;
    }

    return acc;
  }, {});
};

const getConfigFromDefinition = (definitions: FontDefinitions) =>
  objectKeys(definitions).reduce((acc: WebFontConfig, definitionKey) => {
    const definition = definitions[definitionKey];
    if (acc[definition.source] === undefined) {
      acc[definition.source] = { families: [] };
    }
    acc[definition.source].families.push([definition.family, definition.variant].join(':'));
    return acc;
  }, {});

const useFontContextValue = (): FontContextValue => {
  const serverContext = useContext(ServerFontContext);
  const lookConfig = useContext(ThemeContext);

  const fontPaletteFontConfigs = lookConfig?.fonts?.styles;

  const [definitions, setDefinitions] = useState<FontDefinitions>(getPaletteDefinitions(fontPaletteFontConfigs));

  if (serverContext !== null) {
    serverContext.setDefinitions(getConfigFromDefinition(definitions));
  }

  useEffect(() => {
    // NOTE: webfontloader has reference to window on import so we can't import it on the server
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const WebFontLoader = require('webfontloader');

    const filteredDefinitions = objectKeys(definitions).reduce((acc: FontDefinitions, key) => {
      if (definitions[key].status === 'pending') {
        acc[key] = definitions[key];
      }
      return acc;
    }, {});
    if (objectKeys(filteredDefinitions).length === 0) {
      return;
    }

    const setDefinitionStatus = (status: fontStatus) => () =>
      setDefinitions((currentDefinitions: FontDefinitions) =>
        objectKeys(filteredDefinitions).reduce((acc, key) => {
          if (acc[key] !== undefined) {
            acc[key].status = status;
          }
          return acc;
        }, currentDefinitions)
      );

    WebFontLoader.load({
      timeout: 3000,
      ...getConfigFromDefinition(filteredDefinitions),
      loading: setDefinitionStatus('loading'),
      active: setDefinitionStatus('loaded'),
      inactive: setDefinitionStatus('error'),
    });
  }, [definitions]);

  const loadFont = useCallback(
    (font: Omit<FontDefinition, 'status'>) => {
      const key = generateFontKey(font);
      if (definitions[key] !== undefined) {
        return;
      }

      setDefinitions((currentDefinitions) => {
        const newDefinitions = { ...currentDefinitions };
        newDefinitions[key] = { ...font, status: 'pending' };
        return newDefinitions;
      });
    },
    [definitions]
  );

  return {
    loadFont: loadFont,
  };
};
