import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockEmbedGrid } from '../../blocks/BlockEmbedGrid';
import { EmbedBlockLayoutProps } from './EmbedLayout';
import { EmbedItemPadding } from '@nucleus/types/web';

export const EmbedBlockLayoutContentOnly = (props: EmbedBlockLayoutProps): JSX.Element => {
  const itemPadding = props.blocks?.[0].itemPadding;

  const innerProps = blockLayoutInnerProps(props);

  return (
    <StyledBlockLayout itemPadding={itemPadding} {...props}>
      <StyledBlockEmbedGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const StyledBlockEmbedGrid = styled(BlockEmbedGrid)``;

const StyledBlockLayout = styled(BlockLayout)<{
  itemPadding?: EmbedItemPadding;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  ${StyledBlockEmbedGrid} {
    background: var(--color-media-background);
    padding: ${({ itemPadding }) => (itemPadding === 'none' ? undefined : `calc(var(--unit-length) * 0.5)`)};
  }
`;
