import { ImageWeb } from '@nucleus/types/media/image';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IconCirclePlay } from '../icons/IconCirclePlay';
import { formatSrcSet } from '../lib/media';
import { Thumbnail } from './Thumbnail';

interface Props {
  children?: React.ReactNode;
  className?: string;
  image?: ImageWeb;
  style?: React.CSSProperties;
  to: string;
}

export const MediaExpandedListItem = (props: Props): JSX.Element => {
  return (
    <Container className={props.className} style={props.style}>
      <Media>
        <Link to={props.to}>
          <ThumbnailContainer>
            <Thumbnail
              src={props.image?.src}
              srcSet={formatSrcSet(props.image?.srcSet)}
              blurHash={props.image?.blurHash}
            />
            <LinearGradient />
            <StyledIcon />
          </ThumbnailContainer>
        </Link>
      </Media>
      <Meta>
        <div>{props.children}</div>
      </Meta>
    </Container>
  );
};

const LinearGradient = styled.div`
  position: absolute;
  inset: 0;

  pointer-events: none;
  background-image: linear-gradient(0deg, #000000, transparent);

  opacity: 0;
  transition: opacity 900ms cubic-bezier(0.23, 1, 0.32, 1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Media = styled.div`
  flex: 2 1 500px;
  max-width: 860px;

  border-radius: 10px;
  box-shadow: 24px 24px 52px 0 rgba(0, 0, 0, 0.24);
`;

const ThumbnailContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  overflow: hidden;

  :hover {
    ${LinearGradient} {
      opacity: 0.9;
    }
  }
`;

const StyledIcon = styled(IconCirclePlay)`
  width: 14%;
  position: absolute;

  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  :hover {
    transform-origin: center;
    transform: scale(1.1);
  }
`;

const Meta = styled.div`
  flex: 1 2 300px;
  max-width: 860px;
  padding-left: 4.6%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

MediaExpandedListItem.Media = Media;
MediaExpandedListItem.Meta = Meta;
