import { ImageWeb } from '@nucleus/types/media/image';
import { convertFocalPointToBackgroundPositionCss } from '@nucleus/web-theme';
import { media } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { ButtonTertiary } from '../Button';
import { GalleryOverlay, GalleryItem as TGalleryItem } from '../overlays/GalleryOverlay';
import { useNavigateToOverlay } from '../overlays/Overlay';

const GalleryContainer = styled.div``;

const GalleryInner = styled.div``;

const GalleryGrid = styled.div``;

const GalleryButtonOverlay = styled.div`
  display: flex;
  position: absolute;
  width: 100%;

  bottom: 3.6rem;
  right: 0;
  justify-content: center;

  ${media.tabletPortraitAndUp`
    bottom: 4rem;
    right: 9.5rem;
    justify-content: flex-end;
  `}
`;

const GalleryButton = styled(ButtonTertiary)`
  min-width: 26rem;
`;

const GalleryItemImage = styled.img<Partial<ImageWeb>>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.focalPoint)};
`;

const GalleryItemBlurHash = styled.div<Partial<ImageWeb>>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-image: filter(url(${(props) => props.blurHash}), blur(40px));
  background-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.focalPoint)};
`;

const GalleryItemContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
`;

interface GalleryItemProps extends TGalleryItem {
  className?: string;
  style?: React.CSSProperties;
  overlays?: React.ReactNode;
}

const GalleryItem = styled(({ className, style, image, overlays }: GalleryItemProps) => {
  const renderOverlays = overlays !== undefined;
  return (
    <GalleryItemContainer className={className} style={style}>
      <GalleryItemBlurHash focalPoint={image.focalPoint} blurHash={image.blurHash} />
      <GalleryItemImage focalPoint={image.focalPoint} src={image.src} />
      {renderOverlays && overlays}
    </GalleryItemContainer>
  );
})``;

export interface GalleryProps {
  id: string;
  items: Array<TGalleryItem>;
}

const Strict = (props: GalleryProps): JSX.Element => {
  const items = props.items?.slice(0, 8) ?? [];

  return (
    <StrictGalleryContainer>
      <GalleryInner>
        <GalleryGrid>
          {items.map((item, index) => (
            <GalleryItem {...item} key={index} />
          ))}
        </GalleryGrid>
      </GalleryInner>
    </StrictGalleryContainer>
  );
};

const StrictGalleryContainer = styled(GalleryContainer)`
  overflow-y: hidden;
  position: relative;
  max-height: calc(-144px + 100vh);
  min-height: 300px;

  ${GalleryInner} {
    width: 100%;
    max-height: 100%;
    padding-top: 100%;

    ${media.tabletPortraitAndUp`
      padding-top: 50%;
    `}
  }

  ${GalleryGrid} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, 1fr);

    ${media.tabletPortraitAndUp`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `}
  }
`;

const Adaptive = (props: GalleryProps): JSX.Element => {
  const items = props.items.slice(0, 5);

  const navigateToOverlay = useNavigateToOverlay();

  const handleClick = (e: React.MouseEvent, itemId: string) => {
    e.stopPropagation();
    const overlayId = `${props.id}-${itemId}`;
    navigateToOverlay(overlayId, { overlayPath: 'gallery' });
  };

  const firstItemId = items[0]?.id;

  return (
    <AdaptiveGalleryContainer count={items.length}>
      <GalleryInner>
        <GalleryGrid>
          {items.slice(0, 6).map((item, index) => (
            <GalleryItem key={index} {...item} />
          ))}
        </GalleryGrid>
      </GalleryInner>
      <GalleryButtonOverlay>
        <GalleryButton onClick={(e: React.MouseEvent) => handleClick(e, firstItemId)}>View Full Gallery</GalleryButton>
      </GalleryButtonOverlay>
      <GalleryOverlay id={`${props.id}-${props.items[0].id}`} items={props.items} />
    </AdaptiveGalleryContainer>
  );
};

const GRID_BY_COUNT: Record<number, any> = {
  1: css`
    grid-template-areas:
      'photo1 photo1'
      'photo1 photo1';

    ${media.tabletPortraitAndUp`
      grid-template-areas:
        'photo1 photo1'
        'photo1 photo1';
    `}
  `,
  2: css`
    grid-template-areas:
      'photo1 photo1'
      'photo1 photo1'
      'photo2 photo2';

    ${media.tabletPortraitAndUp`
      grid-template-areas:
        'photo1 photo1 photo2'
        'photo1 photo1 photo2';
  `}
  `,
  3: css`
    grid-template-areas:
      'photo1 photo1'
      'photo1 photo1'
      'photo2 photo3'
      'photo4 photo5';

    ${media.tabletPortraitAndUp`
      grid-template-areas:
        'photo1 photo1 photo2 photo3'
        'photo1 photo1 photo2 photo3';
  `}
  `,
  4: css`
    grid-template-areas:
      'photo1 photo1'
      'photo1 photo1'
      'photo2 photo3'
      'photo2 photo4';

    ${media.tabletPortraitAndUp`
      grid-template-areas:
        'photo1 photo1 photo2 photo3'
        'photo1 photo1 photo2 photo4';
  `}
  `,
  5: css`
    grid-template-areas:
      'photo1 photo1'
      'photo1 photo1'
      'photo2 photo3'
      'photo4 photo5';

    ${media.tabletPortraitAndUp`
      grid-template-areas:
        'photo1 photo1 photo2 photo4'
        'photo1 photo1 photo3 photo5';
    `}
  `,
};

const AdaptiveGalleryContainer = styled(GalleryContainer)<{ count: number }>`
  overflow-y: hidden;
  position: relative;
  max-height: calc(-144px + 100vh);
  min-height: 300px;

  /* ${media.desktopAndUp`
    max-height: calc(-64px + 60vh);
  `} */

  ${GalleryInner} {
    width: 100%;
    max-height: 100%;
    padding-top: 100%;

    ${media.tabletPortraitAndUp`
      padding-top: 50%;
    `}
  }

  ${GalleryGrid} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    ${(props) => GRID_BY_COUNT[Math.min(5, props.count) || 1]}

    & :nth-child(1) {
      grid-area: photo1;
    }

    & :nth-child(2) {
      grid-area: photo2;
    }

    & :nth-child(3) {
      grid-area: photo3;
    }

    & :nth-child(4) {
      grid-area: photo4;
    }

    & :nth-child(5) {
      grid-area: photo5;
    }
  }
`;

export const GalleryPreview = {
  Strict: Strict,
  Adaptive: Adaptive,
};
