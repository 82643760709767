import { BaseBlockWeb, InfoButtonsLayout, InfoButtonsWidth } from '@nucleus/types/web';
import { nucleusClass } from '@nucleus/web-theme-elements';
import { SIZE, positionToFlexStyles } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import { useActionProps } from '../hooks/useActionProps';
import { SectionButton } from './Button';

const ButtonStack = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  width: var(--button-width);
  min-width: var(--button-min-width);
  gap: ${SIZE[2]};
`;

const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--button-min-width), var(--button-max-width)));
  width: 100%;
  min-width: var(--button-min-width);
  gap: ${SIZE[2]};

  & > * {
    width: var(--button-width)
    flex-basis: var(--button-min-width);
  }
`;

const ButtonsContainer = styled.div<InfoButtonsProps>`
  display: flex;
  margin-top: clamp(3rem, 4vw, 3.6rem);
  text-align: ${(props) => props.buttonsAlignment ?? 'left'};
  ${(props) => positionToFlexStyles(props.buttonsAlignment ?? 'left')};

  width: 100%;

  --button-width: auto;
  --button-min-width: 24rem;
  --button-max-width: max-content;

  ${(props) =>
    props.buttonsWidth === InfoButtonsWidth.Stretch &&
    css`
      --button-width: 100%;
      --button-max-width: 1fr;
    `};

  ${ButtonRow} {
    ${(props) => positionToFlexStyles(props.buttonsAlignment ?? 'left')};
  }
`;

const ButtonsLayoutDefault = InfoButtonsLayout.Horizontal;
const ButtonsLayoutComponentMap = {
  [InfoButtonsLayout.Horizontal]: ButtonRow,
  [InfoButtonsLayout.Vertical]: ButtonStack,
};

function getButtonsLayoutComponent(buttonsLayout: BaseBlockWeb['buttonsLayout']) {
  const component = ButtonsLayoutComponentMap[buttonsLayout as InfoButtonsLayout];
  return component ?? ButtonsLayoutComponentMap[ButtonsLayoutDefault];
}

interface InfoButtonsProps {
  buttons?: BaseBlockWeb['buttons'];
  buttonsLayout?: BaseBlockWeb['buttonsLayout'];
  buttonsAlignment?: BaseBlockWeb['buttonsAlignment'];
  buttonsWidth?: BaseBlockWeb['buttonsWidth'];
  buttonsMaxWidth?: BaseBlockWeb['buttonsMaxWidth'];
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const InfoButtons = styled((props: InfoButtonsProps): JSX.Element | null => {
  if (props.buttons === undefined || !Array.isArray(props.buttons) || props.buttons.length === 0) {
    return null;
  }

  const ButtonsLayoutComponent = getButtonsLayoutComponent(props.buttonsLayout);

  return (
    <ButtonsContainer
      className={classNames([props.className, nucleusClass('buttons')])}
      style={props.style}
      buttonsWidth={props.buttonsWidth}
      buttonsMaxWidth={props.buttonsMaxWidth}
      buttonsAlignment={props.buttonsAlignment}
    >
      <ButtonsLayoutComponent>
        {props.buttons?.map((button) => (
          <SectionButton key={button.id} {...useActionProps(button)}>
            {button.title}
          </SectionButton>
        ))}
      </ButtonsLayoutComponent>
    </ButtonsContainer>
  );
})``;
