import { BibleReferenceWeb } from '@nucleus/types/bible';
import React from 'react';
import { formatBibleReference, getBibleReferenceUrl } from '../lib/bible';

interface Props {
  reference?: BibleReferenceWeb;
}

/** Formats a Bible reference and links to the Book page */
export const BibleReference = (props: Props): JSX.Element => {
  const url = getBibleReferenceUrl(props.reference);

  if (url !== undefined) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {formatBibleReference(props.reference)}
      </a>
    );
  }

  return <>{formatBibleReference(props.reference)}</>;
};
