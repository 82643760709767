import { isValid, parseISO } from 'date-fns';

export function parseIsoToDate(value: Date | string): Date;
export function parseIsoToDate(value: Date | string | undefined): Date | undefined;
export function parseIsoToDate(value: Date | string | undefined): Date | undefined {
  if (value === undefined) {
    return value;
  }
  if (value instanceof Date) {
    return value;
  }

  const date = parseISO(value);
  if (isValid(date) !== true) {
    throw new Error(`"${value}" is not a valid ISO8601 date string`);
  }

  return date;
}

export const formatDateToIso = (date: Date): string => {
  return date.toISOString();
};
