import styled from 'styled-components';
import React from 'react';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    NotesCard?: {
      background?: string;
      color?: string;
      padding?: string;
    };
  }
}

interface Props {
  children?: React.ReactNode;
}

export const NotesCard = ({ children, ...props }: Props): JSX.Element => {
  return <Card {...props}>{children}</Card>;
};

const Card = styled.div`
  background: ${({ theme }) => theme._sermonThemeElements.NotesCard?.background};
  color: ${({ theme }) => theme._sermonThemeElements.NotesCard?.color};
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '12px',
    pill: '25px',
  })};
  padding: ${({ theme }) => theme._sermonThemeElements.NotesCard?.padding};
`;
