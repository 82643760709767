import { ContentData } from '@nucleus/types/web';
import React from 'react';
import { ContentHubLoader, isHub } from './ContentHubLoader';
import { ContentSectionLoader, isSection } from './ContentSectionLoader';
import { ContentStackLoader, isStack } from './ContentStackLoader';

export interface ContentLoaderProps {
  data: ContentData;
}
export const ContentLoader = ({ data }: ContentLoaderProps): JSX.Element => {
  if (isStack(data)) {
    return <ContentStackLoader data={data} />;
  }

  if (isHub(data)) {
    return <ContentHubLoader data={data} />;
  }

  if (isSection(data)) {
    return <ContentSectionLoader data={data} />;
  }

  return <pre>No Handler Found</pre>;
};
