import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockEmbedGrid } from '../../blocks/BlockEmbedGrid';
import { BlockInfo } from '../../blocks/BlockInfo';
import { Body } from '../../components/Base';
import { EmbedBlockLayoutProps } from './EmbedLayout';

export const EmbedBlockLayoutLongText = (props: EmbedBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const bodyMaxColumns = props.blocks[0]?.bodyMaxColumns ?? props.blockBodyMaxColumns;

  return (
    <StyledBlockLayout {...props} bodyMaxColumns={bodyMaxColumns}>
      <StyledBlockInfo {...innerProps} block={props.blocks[0]}>
        <StyledBlockEmbedGrid {...innerProps} block={props.blocks[0]} />
      </StyledBlockInfo>
    </StyledBlockLayout>
  );
};

const FallbackMaxColumns = 1;

const StyledBlockEmbedGrid = styled(BlockEmbedGrid)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)<{ bodyMaxColumns?: number }>`
  ${StyledBlockEmbedGrid} {
    padding-top: max(
      calc(var(--min-unit-length) * 3.5),
      calc(var(--section-padding-multiplier) * var(--unit-length) * 1.5)
    );
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '60vh';
        case 'large':
          return '80vh';
        default:
          return undefined;
      }
    }};
  }

  ${Body} {
    column-count: ${(props) => props.bodyMaxColumns ?? FallbackMaxColumns};
    column-width: 30rem;
    column-gap: 5rem;
  }
`;
