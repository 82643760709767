import { VerseId } from '@nucleus/types/bible';
import { VerseReference } from '../types/verse';

export const bibleVerseIdRegex = /^(\d{2})(\d{3})(\d{3})$/;

/**
 * Parse a VerseId into its constituent parts.
 * @param verseId The VerseId to parse.
 * @returns An object containing the book, chapter, and verse parts.
 */
export const parseBibleVerseId = (verseId: VerseId): VerseReference => {
  const match = verseId.match(bibleVerseIdRegex);

  if (!match) {
    throw new Error('Invalid VerseId format');
  }

  const [, bookId, chapter, verse] = match;

  return {
    bookId: bookId,
    chapter: Number(chapter),
    verse: Number(verse),
  };
};
