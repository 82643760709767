import { objectKeys } from '@nucleus/src/lib/object';
import { BrandIdentityWeb } from '@nucleus/types/web/branding';
import { Text, nucleusClass } from '@nucleus/web-theme-elements';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  brandIdentity: BrandIdentityWeb;
  onClick?: () => void;
}

export const Branding = (props: Props): JSX.Element => {
  return (
    <Container className={nucleusClass('branding')}>
      <StyledLink to="/" onClick={props.onClick}>
        <BrandIdentity {...props} />
      </StyledLink>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  max-width: 100%;
  pointer-events: auto;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const BrandIdentity = (props: Props) => {
  const key = objectKeys(props.brandIdentity)[0];

  switch (key) {
    case 'text':
      return <BrandingText {...props} />;
    case 'logo':
    default:
      return <BrandingLogo {...props} />;
  }
};

const BrandingText = (props: Props) => {
  return (
    <TextContainer>
      <StyledChurchName>{props.brandIdentity.text ?? ''}</StyledChurchName>
    </TextContainer>
  );
};

const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledChurchName = styled.span.attrs({ className: Text.ClassName['footer-church-name-label'] })``;

const BrandingLogo = (props: Props) => {
  const lightLogoImage = props.brandIdentity.logo?.light?.image;
  const darkLogoImage = props.brandIdentity.logo?.dark?.image;

  if (lightLogoImage === undefined && darkLogoImage === undefined) {
    return null;
  }

  return (
    <LogoContainer>
      {lightLogoImage !== undefined && <img className="logo-light" src={lightLogoImage?.src} />}
      {darkLogoImage !== undefined && <img className="logo-dark" src={darkLogoImage?.src} />}
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  svg,
  img {
    height: 100%;
    object-fit: contain;
    object-position: left;
    position: absolute;
    top: 0;
  }

  img:first-of-type {
    position: static;
  }

  --logo-light-min: min(var(--color-logo-l), var(--color-dark-l));
  --logo-light-max: max(var(--color-logo-l), var(--color-dark-l));
  --logo-contrast: min(calc((var(--logo-light-max) - var(--logo-light-min)) * 9999999), 1);

  .logo-light {
    opacity: var(--logo-contrast);
  }

  .logo-dark {
    opacity: calc((var(--logo-contrast) * -1) + 1);
  }
`;
