import React from 'react';
import { ThemeProvider } from 'styled-components';
import { SermonThemeElementsTheme } from '@nucleus/sermon-theme-elements';

interface Props {
  children?: React.ReactNode;
  theme: SermonThemeElementsTheme;
}

export const SermonThemeElementsThemeProvider = (props: Props): JSX.Element => {
  return <ThemeProvider theme={{ _sermonThemeElements: props.theme }}>{props.children}</ThemeProvider>;
};
