import { CodeSnippet, CodeSnippetWeb, CodeSnippetWebKeys } from '@nucleus/sites/src/types/CodeSnippet';
import { pick as _pick } from 'lodash';
import { useContext } from 'react';
import { SiteContext } from '../../webTheme';

const useSiteCodeSnippets = (): CodeSnippetWeb[] => {
  const { getSite } = useContext(SiteContext);
  if (getSite === undefined) {
    return [];
  }
  const site = getSite();
  const codeSnippets = (site?.codeSnippets ?? [])
    .filter(isEnabled)
    .map((snippet) => _pick(snippet, CodeSnippetWebKeys));

  return codeSnippets;
};

export const useSiteCssSnippets = (): CodeSnippetWeb[] => {
  const codeSnippets = useSiteCodeSnippets();
  const cssSnippets = codeSnippets.filter((snippet) => snippet.language === 'css');

  return cssSnippets;
};

export const useSiteJavascriptSnippets = (): CodeSnippetWeb[] => {
  const codeSnippets = useSiteCodeSnippets();
  const jsSnippets = codeSnippets.filter((snippet) => snippet.language === 'javascript');

  return jsSnippets;
};

export const useSiteHtmlSnippets = (): CodeSnippetWeb[] => {
  const codeSnippets = useSiteCodeSnippets();
  const htmlSnippets = codeSnippets.filter((snippet) => snippet.language === 'html');

  return htmlSnippets;
};

function isEnabled(snippet: CodeSnippet): boolean {
  return snippet.enabled === true;
}
