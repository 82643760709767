// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Position } from '@nucleus/types/web/position';
import { css, DefaultTheme, FlattenInterpolation, ThemedCssFunction, ThemedStyledProps } from 'styled-components';

type BreakpointConfig = {
  name: string;
  min?: number;
  max?: number;
  unit?: string;
  type?: string;
};

const BREAKPOINTS: BreakpointConfig[] = [
  { name: 'tabletPortrait', min: 600, max: 799 },
  { name: 'tabletLandscape', min: 800, max: 949 },
  { name: 'smallDesktop', min: 1020, max: 1399 },
  { name: 'desktop', min: 1400, max: 1599 },
];

function generateMediaQueryMin(min: number, unit = 'px', type = '') {
  return `@media ${type} (min-width: ${min + unit})`;
}

function generateMediaQueryMax(max: number, unit = 'px', type = '') {
  return `@media ${type} (max-width: ${max + unit})`;
}

function generateMediaQueryBetween(min: number, max: number, unit = 'px', type = '') {
  return `@media ${type} (min-width: ${min + unit}) and (max-width: ${max + unit})`;
}

function generateMediaQueryCssFunction(mediaQuery = '') {
  return (...args) => css`
    ${mediaQuery} {
      ${css(...args)};
    }
  `;
}

function getMediaBreakpointNames() {
  const namesWithAndUp = BREAKPOINTS.map(({ name }) => `${name}AndUp`);
  const namesWithAndDown = BREAKPOINTS.map(({ name }) => `${name}AndDown`);
  return [...namesWithAndUp, ...namesWithAndDown] as const;
}
const MediaBreakpointNames = getMediaBreakpointNames();
type MediaBreakpointName = typeof MediaBreakpointNames[number];

export const MediaQuery: Record<MediaBreakpointName, string> = BREAKPOINTS.reduce(
  (acc, { name, min, max, unit, type }) => {
    if (min !== undefined) {
      acc[`${name}AndUp`] = generateMediaQueryMin(min, unit, type);
    }
    if (max !== undefined) {
      acc[`${name}AndDown`] = generateMediaQueryMax(max, unit, type);
    }
    if (min !== undefined && max !== undefined) {
      acc[`${name}Only`] = generateMediaQueryBetween(min, max, unit, type);
    }
    return acc;
  },
  {}
);

export const media: Record<MediaBreakpointName, ThemedCssFunction<DefaultTheme>> = Object.entries(MediaQuery).reduce(
  (acc, [key, value]) => {
    acc[key] = (...args) => generateMediaQueryCssFunction(value)(...args);
    return acc;
  },
  {}
);

const BASE_FONT_SIZE = 10;
const BASE_SIZE_UNIT = 6;
const SIZE_MULTIPLIERS = [0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 22];

export const toRem = (value: number): number => value / BASE_FONT_SIZE;
const toEm = (value: number, fontSize: number) => value / fontSize;

export const remToPixelNum = (value: string): number =>
  parseFloat(value) * parseInt(getComputedStyle(document.documentElement).fontSize);
export const remToPixel = (value: string): string => remToPixelNum(value) + 'px';

const sizes: Array<string> = [];
SIZE_MULTIPLIERS.forEach((multiplier) => (sizes[multiplier] = `${toRem(BASE_SIZE_UNIT * multiplier)}rem`));

export const SIZE = Object.freeze(sizes);

export const font = (
  fontSize: number,
  fontWeight?: keyof typeof WEIGHTS,
  lineHeight?: number,
  letterSpacing?: number
): ReturnType<ThemedCssFunction<DefaultTheme>> => css`
  font-size: ${fontSize ? toRem(fontSize) + 'rem' : '1em'};
  font-weight: ${fontWeight ? WEIGHTS[fontWeight] : undefined};
  letter-spacing: ${letterSpacing ? toEm(letterSpacing, fontSize) + 'em' : undefined};
  line-height: ${lineHeight ? toEm(lineHeight, fontSize) + 'em' : '1em'};
`;

const WEIGHTS = {
  ExtraLight: 100,
  UltraLight: 100,
  Light: 200,
  Thin: 200,
  Book: 300,
  Demi: 300,
  Normal: 400,
  Regular: 400,
  Medium: 500,
  Semibold: 600,
  Demibold: 600,
  Bold: 700,
  Black: 800,
  ExtraBold: 800,
  Heavy: 800,
  ExtraBlack: 900,
  Fat: 900,
  Poster: 900,
  UltraBlack: 900,
};

export const colorStyles = (ColorObj: any): ReturnType<ThemedCssFunction<DefaultTheme>> => css`
  ${Object.keys(ColorObj).map(
    (key) => css`
      ${key === 'Default' ? '' : ':' + key + '{'}
      color: ${ColorObj[key].Foreground};
      background-color: ${ColorObj[key].Background};
      ${key === 'Default' ? '' : '}'}
    `
  )}
`;

/**
 * Builds a styled helper function that returns css if truthy prop[key] is truthy
 * @param {*} key [string]
 * @returns [function]
 */
export const booleanPropHelperFactory =
  <K extends string>(key: K) =>
  <P extends { [A in K]?: boolean }>(
    rules: FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>,
    fallbackRules?: FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>>
  ): FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>> =>
    css<P>`
      ${(props) => {
        if (props[key]) {
          return rules;
        } else {
          return fallbackRules;
        }
      }}
    `;

/**
 * Builds a styled helper function that return css if the result of the function argument is truthy
 * @param {*} key [string]
 * @returns [function]
 */
export const conditionalHelperFactory =
  <P extends Record<string, any>>(func: (props: P) => boolean) =>
  (
    rules: FlattenInterpolation<ThemedStyledProps<any, DefaultTheme>>,
    fallbackRules?: FlattenInterpolation<ThemedStyledProps<any, DefaultTheme>>
  ): FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>> =>
    css<P>`
      ${(props) => {
        if (func(props)) {
          return rules;
        } else {
          return fallbackRules;
        }
      }}
    `;

export const STANDARD_SPRING_TRANSITION = {
  type: 'spring',
  delay: 0,
  stiffness: 1000,
  damping: 55,
  mass: 0.5,
};

export const positionToFlexStyles = (position?: Position, isFlexColumn = false): any => {
  if (!position) {
    return;
  }
  const yAxisProperty = isFlexColumn ? 'justify-content' : 'align-items';
  const xAxisProperty = isFlexColumn ? 'align-items' : 'justify-content';
  const xSelfProperty = 'justify-self';

  switch (position) {
    case 'top':
      return css`
        ${yAxisProperty}: flex-start;
        ${xAxisProperty}: center;
        ${xSelfProperty}: center;
      `;
    case 'top-left':
      return css`
        ${yAxisProperty}: flex-start;
        ${xAxisProperty}: flex-start;
        ${xSelfProperty}: flex-start;
      `;
    case 'top-right':
      return css`
        ${yAxisProperty}: flex-start;
        ${xAxisProperty}: flex-end;
        ${xSelfProperty}: flex-end;
      `;
    case 'bottom':
      return css`
        ${yAxisProperty}: flex-end;
        ${xAxisProperty}: center;
        ${xSelfProperty}: center;
      `;
    case 'bottom-left':
      return css`
        ${yAxisProperty}: flex-end;
        ${xAxisProperty}: flex-start;
        ${xSelfProperty}: flex-start;
      `;
    case 'bottom-right':
      return css`
        ${yAxisProperty}: flex-end;
        ${xAxisProperty}: flex-end;
        ${xSelfProperty}: flex-end;
      `;
    case 'left':
      return css`
        ${yAxisProperty}: center;
        ${xAxisProperty}: flex-start;
        ${xSelfProperty}: flex-start;
      `;
    case 'right':
      return css`
        ${yAxisProperty}: center;
        ${xAxisProperty}: flex-end;
        ${xSelfProperty}: flex-end;
      `;
    case 'center':
    default:
      return css`
        ${yAxisProperty}: center;
        ${xAxisProperty}: center;
        ${xSelfProperty}: center;
      `;
  }
};

export const positionToPaddingStyles = (position?: Position): ReturnType<typeof css> => {
  if (position === undefined) {
    return;
  }

  const minSmall = 2;
  const minMedium = 3;
  const minLarge = 3.5;

  const valueSmall = 1;
  const valueMedium = 1.5;
  const valueLarge = 2.25;

  let minMultipleTop = minLarge;
  let theMultipleTop = valueMedium;
  let minMultipleRight = minSmall;
  let theMultipleRight = valueSmall;
  let minMultipleBottom = minLarge;
  let theMultipleBottom = valueMedium;
  let minMultipleLeft = minSmall;
  let theMultipleLeft = valueSmall;

  switch (position) {
    case 'top':
      minMultipleTop = minSmall;
      theMultipleTop = valueSmall;
      minMultipleRight = minSmall;
      theMultipleRight = valueSmall;
      minMultipleBottom = minMedium;
      theMultipleBottom = valueLarge;
      minMultipleLeft = minSmall;
      theMultipleLeft = valueSmall;
      break;
    case 'top-left':
      minMultipleTop = minSmall;
      theMultipleTop = valueSmall;
      minMultipleRight = minMedium;
      theMultipleRight = valueLarge;
      minMultipleBottom = minMedium;
      theMultipleBottom = valueLarge;
      minMultipleLeft = minSmall;
      theMultipleLeft = valueSmall;
      break;
    case 'top-right':
      minMultipleTop = minSmall;
      theMultipleTop = valueSmall;
      minMultipleRight = minSmall;
      theMultipleRight = valueSmall;
      minMultipleBottom = minMedium;
      theMultipleBottom = valueLarge;
      minMultipleLeft = minMedium;
      theMultipleLeft = valueLarge;
      break;
    case 'bottom':
      minMultipleTop = minMedium;
      theMultipleTop = valueLarge;
      minMultipleRight = minSmall;
      theMultipleRight = valueSmall;
      minMultipleBottom = minSmall;
      theMultipleBottom = valueSmall;
      minMultipleLeft = minSmall;
      theMultipleLeft = valueSmall;
      break;
    case 'bottom-left':
      minMultipleTop = minMedium;
      theMultipleTop = valueLarge;
      minMultipleRight = minMedium;
      theMultipleRight = valueLarge;
      minMultipleBottom = minSmall;
      theMultipleBottom = valueSmall;
      minMultipleLeft = minSmall;
      theMultipleLeft = valueSmall;
      break;
    case 'bottom-right':
      minMultipleTop = minMedium;
      theMultipleTop = valueLarge;
      minMultipleRight = minSmall;
      theMultipleRight = valueSmall;
      minMultipleBottom = minSmall;
      theMultipleBottom = valueSmall;
      minMultipleLeft = minMedium;
      theMultipleLeft = valueLarge;
      break;
    case 'left':
      minMultipleTop = minLarge;
      theMultipleTop = valueMedium;
      minMultipleRight = minMedium;
      theMultipleRight = valueLarge;
      minMultipleBottom = minLarge;
      theMultipleBottom = valueMedium;
      minMultipleLeft = minSmall;
      theMultipleLeft = valueSmall;
      break;
    case 'right':
      minMultipleTop = minLarge;
      theMultipleTop = valueMedium;
      minMultipleRight = minSmall;
      theMultipleRight = valueSmall;
      minMultipleBottom = minLarge;
      theMultipleBottom = valueMedium;
      minMultipleLeft = minMedium;
      theMultipleLeft = valueLarge;
      break;
    case 'center':
    default:
      minMultipleTop = minLarge;
      theMultipleTop = valueMedium;
      minMultipleRight = minSmall;
      theMultipleRight = valueSmall;
      minMultipleBottom = minLarge;
      theMultipleBottom = valueMedium;
      minMultipleLeft = minSmall;
      theMultipleLeft = valueSmall;
      break;
  }

  return css`
    padding-top: max(
      calc(var(--min-unit-length) * ${minMultipleTop}),
      calc(var(--section-padding-multiplier) * var(--unit-length) * ${theMultipleTop})
    );
    padding-right: max(
      calc(var(--min-unit-length) * ${minMultipleRight}),
      calc(var(--section-padding-multiplier) * var(--unit-length) * ${theMultipleRight})
    );
    padding-bottom: max(
      calc(var(--min-unit-length) * ${minMultipleBottom}),
      calc(var(--section-padding-multiplier) * var(--unit-length) * ${theMultipleBottom})
    );
    padding-left: max(
      calc(var(--min-unit-length) * ${minMultipleLeft}),
      calc(var(--section-padding-multiplier) * var(--unit-length) * ${theMultipleLeft})
    );
  `;
};
