import { useEventListener } from '@nucleus/react-components/src/hooks/useEventListener';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';

const getAuthenticatedUser = async (): Promise<CognitoUser | undefined> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    return;
  }
};

export const useAuthentication = (): [boolean, CognitoUser | undefined] => {
  const [user, setUser] = useState<CognitoUser | undefined>();

  const getCurrentUser = useCallback(async () => {
    setUser(await getAuthenticatedUser());
  }, []);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    const hubListener = Hub.listen('auth', () => {
      getCurrentUser();
    });
    return () => hubListener();
  }, [getCurrentUser]);

  useEventListener('NucleusUserSignedIn', () => getCurrentUser());
  useEventListener('NucleusUserSignedOut', () => getCurrentUser());

  const isAuthenticated = user !== undefined;

  return [isAuthenticated, user];
};

export const useAuthBroadcast = (): void => {
  const [isAuthenticated] = useAuthentication();

  useEffect(() => {
    if (isAuthenticated === false) {
      window.dispatchEvent(new CustomEvent('NucleusWebSignedOut'));
    }
  }, [isAuthenticated]);
};
