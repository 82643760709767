import { Page } from '@nucleus/types/web';
import axios from 'axios';

export const getCollections = async (hubId: string): Promise<{ page: Page }> => {
  const response = await axios.get<{ page: Page }>(`/_api/post_hub/${hubId}/collections`);

  return response.data;
};

export const getCollection = async (hubId: string, collection: string): Promise<{ page: Page }> => {
  const response = await axios.get<{ page: Page }>(`/_api/post_hub/${hubId}/collections/${collection}`);

  return response.data;
};
