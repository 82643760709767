import { sleep } from '@nucleus/lib-function';
import React from 'react';

const stack: null[] = [];

export const useHideLauncherEffect = (hideInlineBanners?: boolean): void => {
  React.useEffect(() => {
    let unmounted = false;

    stack.push(null);

    /**
     * Fixme: This is a hack that will work for now. Later we want to revisit this and make it more event based.
     * This works without flashing, because we are waiting for the hide() method to become defined. The hide()
     * method is registered in the Launcher Embedded Controller, before the Springboard has even fetched the config.
     */
    (async function () {
      while (window.NucleusLauncher?.hide === undefined) {
        if (unmounted) {
          return;
        }
        await sleep(100);
      }
      if (unmounted) {
        return;
      }
      window.NucleusLauncher?.hide?.();
      if (hideInlineBanners === true) {
        window.NucleusLauncher?.hideInlineBanners?.();
      }
    })();

    return () => {
      unmounted = true;
      stack.pop();

      if (stack.length === 0) {
        window.NucleusLauncher?.show?.();
      }
    };
  }, []);
};
