import { isNotEmpty } from '@nucleus/lib-shape';
import util from 'util';

export * from './color';
export * from './date';
export * from './email';
export * from './encoding';
export * from './key';
export * from './name';
export * from './shape';

export function deepFilterNotEmpty(data) {
  return deepFilter(data, isNotEmpty);
}

export function filterNotEmpty(data) {
  return data.filter(isNotEmpty);
}

export function deepFilterNotUndefined(data) {
  return deepFilter(data, notUndefined);
}

export function filterNotUndefined(data) {
  return data.filter(notUndefined);
}

function notUndefined(value) {
  return value !== undefined;
}

export function readable(obj) {
  if (typeof obj === 'string') {
    return obj;
  }

  try {
    return JSON.stringify(obj, null, 2);
  } catch (err) {
    return util.inspect(obj);
  }
}

const nullValues = Object.freeze(['', null, undefined]);

// Take a document and return false if any keys are undefined, null, '' or {}, [].
export function hasAllKeys(obj, keys) {
  if (typeof obj !== 'object') {
    throw new Error(`${obj} is not an object`);
  }

  return keys.every((key) => {
    const value = obj[key];

    if (nullValues.includes(value)) {
      return false;
    }

    if (typeof value === 'object' && Object.keys(value).length < 1) {
      return false;
    }

    return true;
  });
}

// 'deep-filter' and dependencies after here.
// TODO: Move them somewhere else.
function isObject(val) {
  return val != null && typeof val === 'object' && Array.isArray(val) === false;
}

function isObjectObject(o) {
  return isObject(o) === true && Object.prototype.toString.call(o) === '[object Object]';
}

function isPlainObject(o) {
  var ctor, prot;

  if (isObjectObject(o) === false) {
    return false;
  }

  // If has modified constructor
  ctor = o.constructor;
  if (typeof ctor !== 'function') {
    return false;
  }

  // If has modified prototype
  prot = ctor.prototype;
  if (isObjectObject(prot) === false) {
    return false;
  }

  // If constructor does not have an Object-specific method
  if (prot.hasOwnProperty('isPrototypeOf') === false) {
    return false;
  }

  // Most likely a plain Object
  return true;
}

function deepFilter(value, fn) {
  if (Array.isArray(value)) {
    return filterArray(value, fn);
  } else if (isPlainObject(value)) {
    return filterObject(value, fn);
  }

  return value;
}

function filterObject(obj, fn) {
  var newObj = {};
  var key;
  var value;

  for (key in obj) {
    value = deepFilter(obj[key], fn);

    if (fn.call(obj, value, key, obj)) {
      if (value !== obj[key] && !isCollection(value)) {
        value = obj[key];
      }

      newObj[key] = value;
    }
  }

  return newObj;
}

function filterArray(array, fn) {
  var filtered = [];

  array.forEach(function (value, index, array) {
    value = deepFilter(value, fn);

    if (fn.call(array, value, index, array)) {
      if (value !== array[index] && !isCollection(value)) {
        value = array[index];
      }

      filtered.push(value);
    }
  });

  return filtered;
}

function isCollection(value) {
  return Array.isArray(value) || isPlainObject(value);
}
