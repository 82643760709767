import { nucleusClass } from '@nucleus/web-theme-elements';
import { booleanPropHelperFactory, SIZE, Text } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';

export type TabNavItem = {
  label: string;
  to: string;
  disabled?: boolean;
};

export type TabNavItems = Array<TabNavItem>;

export type TabNavProps = {
  style?: React.CSSProperties;
  className?: string;
  items?: TabNavItems;
  activeItem?: string;
  isSticky?: boolean;
  stickyOffset?: string | number;
  onItemClick?: (to: string) => void;
};

export const TabNav = styled(
  ({ items, isSticky = true, stickyOffset = 0, onItemClick = () => {}, ...props }: TabNavProps): JSX.Element => {
    const handleTabClick = (e: React.MouseEvent<HTMLLIElement>, to: string) => {
      e.preventDefault();
      onItemClick(to);
    };

    const activeItem = props.activeItem ?? items?.[0]?.to;

    return (
      <TabNavPosition
        {...props}
        className={classNames([props.className, nucleusClass('tab-nav')])}
        isSticky={isSticky}
        stickyOffset={stickyOffset}
      >
        <TabsContainer>
          <Tabs>
            {items?.map(({ label, to, disabled }) => (
              <Tab key={to} onClick={(e) => handleTabClick(e, to)} disabled={disabled} isActive={activeItem === to}>
                {label}
              </Tab>
            ))}
          </Tabs>
        </TabsContainer>
      </TabNavPosition>
    );
  }
)``;

const isSticky = booleanPropHelperFactory('isSticky');
const isActive = booleanPropHelperFactory('isActive');

const Tab = styled.li<{ disabled?: boolean; isActive?: boolean }>`
  opacity: 0.5;
  text-decoration: none;
  color: var(--color-navigation-text);
  display: flex;
  align-items: center;
  border: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  ${isActive(css`
    opacity: 1;
    text-decoration: underline;
  `)}
`;

const Tabs = styled.ul.attrs({
  className: Text.ClassName['main-nav-item-label'],
})`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: ${SIZE[10]};
  list-style: none;
  padding: 0 ${SIZE[2]} 0 0;
  margin: 0;
  user-select: none;
  gap: 0.5em;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const TabsContainer = styled.div``;

interface TabNavPositionProps {
  isSticky: boolean;
  stickyOffset: string | number;
}

const TabNavPosition = styled.div<TabNavPositionProps>`
  ${isSticky(css<TabNavPositionProps>`
    position: sticky;
    top: ${(props) => props.stickyOffset};
  `)}
`;
