import { InfoSectionWeb } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SectionLayout } from '../../sectionLayouts/SectionLayout';
import { InfoBlockLayoutCollage } from './InfoBlockLayoutCollage';
import { InfoBlockLayoutCover } from './InfoBlockLayoutCover';
import { InfoBlockLayoutDouble } from './InfoBlockLayoutDouble';
import { InfoBlockLayoutHorizontal } from './InfoBlockLayoutHorizontal';
import { InfoBlockLayoutLongText } from './InfoBlockLayoutLongText';
import { InfoBlockLayoutVertical } from './InfoBlockLayoutVertical';
import { InfoBlockLayoutProps } from './InfoLayout';

export const InfoSection = (): JSX.Element => {
  const section = useSection<InfoSectionWeb>();

  // Background logic HACK: Clean up the maps so this can be less convoluted.
  const isLayout6 = section.payload.blockLayout === 'layout6';
  const isLayoutFull = section.payload.layout === 'full' || section.payload.layout === undefined;

  const layoutProps = { ...section.payload };
  if (isLayoutFull === true && isLayout6 !== true) {
    delete layoutProps.backgroundMedia;
  }

  const blockProps = { ...section.payload };
  if (isLayoutFull !== true) {
    delete blockProps.backgroundMedia;
  }
  // HACK: END

  return (
    <Section className={classNames(nucleusClass('section-info'), getSectionClasses(section))}>
      <SectionLayout {...section.payload}>
        <BlockLayout {...blockProps} />
      </SectionLayout>
    </Section>
  );
};

const BlockLayout = (props: InfoBlockLayoutProps) => {
  switch (props.blockLayout) {
    case 'layout1':
      return <InfoBlockLayoutHorizontal {...props} />;
    case 'layout2':
      return <InfoBlockLayoutVertical {...props} />;
    case 'layout3':
      return <InfoBlockLayoutCollage {...props} />;
    case 'layout4':
      return <InfoBlockLayoutLongText {...props} />;
    case 'layout5':
      return <InfoBlockLayoutCover {...props} />;
    case 'layout6':
      return <InfoBlockLayoutDouble {...props} />;
    default:
      return <InfoBlockLayoutHorizontal {...props} />;
  }
};
