import { truncate as _truncate } from 'lodash';
import { lengthOfRichText, truncateRichText } from '@nucleus/react-components';
import { ThemeRichTextNodeArray } from '@nucleus/types/web';
import React, { useState } from 'react';
import styled from 'styled-components';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    InfoCard?: {
      background?: string;
      buttonClassName?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
    };
  }
}

interface Props {
  children: React.ReactElement<{ nodes?: string | ThemeRichTextNodeArray }>;
  className?: string;
  limit?: number;
  style?: React.CSSProperties;
}

export const InfoCard = ({ children, limit = 220, ...props }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const length = getLengthOfChildren(children);

  if (length < limit) {
    return (
      <Card {...props}>
        <Content>{children}</Content>
      </Card>
    );
  }

  return (
    <Card {...props}>
      <Content>
        {React.Children.map(children, (child) => {
          if (typeof child.props.nodes === 'undefined') {
            return;
          }

          if (typeof child.props.nodes === 'string') {
            return isExpanded ? child.props.nodes : _truncate(child.props.nodes, { length: limit });
          }

          return React.cloneElement(child, {
            nodes: isExpanded ? child.props.nodes : truncateRichText(child.props.nodes, limit),
          });
        })}
      </Content>
      <Button onClick={() => setIsExpanded((prev) => !prev)}>{isExpanded ? 'Show Less' : 'Show More'}</Button>
    </Card>
  );
};

const getLengthOfChildren = (children: React.ReactElement<{ nodes?: string | ThemeRichTextNodeArray }>): number => {
  let count = 0;

  React.Children.forEach(children, (child) => {
    if (typeof child.props.nodes === 'undefined') {
      return;
    }

    if (typeof child.props.nodes === 'string') {
      count += child.props.nodes.length;
    }

    if (Array.isArray(child.props.nodes)) {
      count += lengthOfRichText(child.props.nodes);
    }
  });

  return count;
};

const Card = styled.div`
  background: ${({ theme }) => theme._sermonThemeElements.InfoCard?.background};
  color: ${({ theme }) => theme._sermonThemeElements.InfoCard?.color};
  font-size: ${({ theme }) => theme._sermonThemeElements.InfoCard?.fontSize};
  line-height: ${({ theme }) => theme._sermonThemeElements.InfoCard?.lineHeight};
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '12px',
    pill: '25px',
  })};
  padding: 24px 48px 24px 24px;
`;

const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = styled.button.attrs((props) => ({
  className: props.theme._sermonThemeElements.InfoCard?.buttonClassName,
}))`
  background: none;
  color: inherit;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  margin-top: 12px;
  text-decoration: underline;
`;
