import { interpolateLinear } from '@nucleus/lib-number';
import { Fade } from '@nucleus/types/media/blending';
import { FocalPoint } from '@nucleus/types/media/image';
import { css } from 'styled-components';

// FIXME: This function is duplicated from packages/webTheme/src/lib/utilities.ts
// We can't import it from there because it causes a build error in the dashboard
// At some point we should figure out why that is and fix it, and/or move these
// all the utility functions here or to a separate shared package.
export function convertFocalPointToBackgroundPositionCss(focalPoint?: FocalPoint): string {
  if (focalPoint === undefined) {
    return 'center';
  }
  let { x, y } = focalPoint;
  // check for undefined values and set fallbacks
  if (x === undefined) {
    x = 0.5;
  }
  if (y === undefined) {
    y = 0.5;
  }
  // if x and y are both between 0 and 1, return the percentages
  if (x >= 0 && y >= 0 && x <= 1 && y <= 1) {
    return `${x * 100}% ${y * 100}%`;
  }
  return 'center';
}

const FadeToDegrees: Record<Fade, number> = {
  none: 0,
  top: 0,
  right: 90,
  bottom: 180,
  left: 270,
};

type Css = ReturnType<typeof css>;

export function buildFadeCss(color: string | undefined, fadeDirection: Fade | undefined, fadeIntensity = 0.5): Css {
  const { opacity, background } = buildFadeCssObject(color, fadeDirection, fadeIntensity);

  return css`
    opacity: ${opacity};
    background: ${background};
  `;
}

export function buildFadeCssObject(
  color: string | undefined,
  fadeDirection: Fade | undefined,
  fadeIntensity = 0.5
): { opacity: number; background: string } {
  if (color === undefined || fadeDirection === undefined || fadeDirection === 'none') {
    return { opacity: 0, background: 'transparent' };
  }

  const degrees = FadeToDegrees[fadeDirection];
  const [opacity, fadeStart, fadeEnd] = calculateFadeProperties(fadeIntensity);

  return {
    opacity: opacity / 100,
    background: `linear-gradient(${degrees}deg, transparent ${fadeStart}%, ${color} ${fadeEnd}%)`,
  };
}

export function calculateFadeProperties(fadeIntensity: number): [number, number, number] {
  const opacity = interpolateLinear(fadeIntensity, {
    from: { start: 0, end: 0.5 },
    to: { start: 0, end: 100 },
  });
  const fadeStart = interpolateLinear(fadeIntensity, {
    from: { start: 0.5, end: 1 },
    to: { start: 0, end: -100 },
  });
  const fadeEnd = interpolateLinear(fadeIntensity, {
    from: { start: 0, end: 1 },
    to: { start: 130, end: 70 },
  });

  return [opacity, fadeStart, fadeEnd];
}
