import { ThemeFieldConfigBuilder } from '../ThemeConfigBuilder';

export const overline = new ThemeFieldConfigBuilder('overline')
  .setStyles(['label1', 'label2', 'label3', 'label4', 'label5', 'label6'])
  .build();

export const headline = new ThemeFieldConfigBuilder('headline')
  .setStyles(['headline1', 'headline2', 'headline3', 'headline4', 'headline5', 'headline6'])
  .build();

export const byline = new ThemeFieldConfigBuilder('byline')
  .setStyles(['label1', 'label2', 'label3', 'label4', 'label5', 'label6'])
  .build();

export const body = new ThemeFieldConfigBuilder('body')
  .setStyles(['paragraph1', 'paragraph2', 'paragraph3', 'paragraph4'])
  .build();
