import { Alignment, Position } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCover } from '../../blocks/BlockCover';
import { blockInfoHasContent } from '../../blocks/BlockInfo';
import { BlockSermon } from '../../blocks/BlockSermon';
import { SectionBackground } from '../../components/Background';
import { Overline } from '../../components/Base';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { SermonBlockLayoutProps, getPositionRow } from './SermonLayout';
import { BlockLayoutProps } from '../../types/component';
import { css } from 'styled-components';

export const SermonBlockLayoutCover = (props: SermonBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const row = getPositionRow(props.blockInfoPosition);

  const renderBackground = props.backgroundMedia !== undefined;
  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout
      {...props}
      bodyAlignment={props.blocks[0]?.bodyAlignment ?? props.blockBodyAlignment}
      row={row}
      renderInfo={renderInfo}
    >
      {renderBackground && <SectionBackground background={props.backgroundMedia} />}
      <SpacerTop />
      <StyledBlockCover {...innerProps} block={props.blocks[0]} />
      <StyledSpacerBottom />
      <StyledBlockSermon {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const SermonHeightMap = {
  small: 1,
  medium: 2,
  large: 3,
};

const getSermonPadding = (props: BlockLayoutProps) => {
  const defaultHeight = 'small';
  const multiplier = SermonHeightMap[props.height ?? defaultHeight];

  return css`
    padding: calc(var(--unit-length) * ${multiplier}) var(--unit-length);
  `;
};

const StyledSpacerBottom = styled(SpacerTop)``;
const StyledBlockSermon = styled(BlockSermon)``;
const StyledBlockCover = styled(BlockCover)``;

const StyledBlockLayout = styled(BlockLayout)<{ bodyAlignment?: Alignment; row: Position; renderInfo: boolean }>`
  ${StyledSpacerBottom} {
    display: ${(props) => (props.row === Position.Center ? 'block' : 'none')};
  }

  ${StyledBlockCover} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${Overline} {
    text-align: ${(props) => props.bodyAlignment ?? 'left'};
  }

  ${StyledBlockSermon} {
    ${getSermonPadding}
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};

    > div {
      max-width: min(75%, 1600px);
    }
  }
`;
