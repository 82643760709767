import { getKeyPrefix, isNotEmpty } from '@nucleus/lib-shape';
import {
  Join,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemButton,
  MenuList,
  Select,
  usePopoverState,
  useScrollNav,
} from '@nucleus/react-components';
import {
  CircleProfileImage,
  CollectionList,
  CollectionListController,
  CollectionListItem,
  CollectionListItemHeader,
  HorizontalRule,
  IconPlaylist,
  MediaExpandedList,
  MediaExpandedListItem,
  MediaGrid,
  MediaGridItem,
  MediaGridItemBadge,
  MediaList,
  MediaListButton,
  MediaListItem,
  MediaListToolbar,
  MediaStrip,
  SearchNavButton,
  SermonBlockController,
  SermonDate,
  SermonHubNav,
  SermonListController,
  SermonSpeakers,
  ShareButton,
  ViewToggle,
  getCollectionTypeFromId,
  isDynamicSermonListDataSource,
  useCollection,
  useCollectionDataSourceParams,
  useCollectionListController,
  useCollectionPath,
  useCollectionTerm,
  useLocalization,
  useSermonBlockController,
  useSermonBlockDataSourceCollectionListParams,
  useSermonBlockDataSourceSermonListParams,
  useSermonHubPath,
  useSermonListController,
} from '@nucleus/sermon-theme-elements/';
import { SermonIndexDocumentWeb } from '@nucleus/sermons/types/Sermon';
import { slugifyString } from '@nucleus/src-platform/data/text';
import { SermonBlockWeb, ThemeRichTextNodeArray } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { Text, formatSrcSet, nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { truncate as _truncate } from 'lodash';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonPrimary, ButtonSecondary, SectionButton } from '../components/Button';
import { IconAngleLeft, IconAngleRight } from '../components/Icons';
import { getWindow } from '../lib/dom';
import { BlockProps } from '../types/component';
import { BlockInfo } from './BlockInfo';

type BlockSermonProps = Extract<BlockProps, { block?: SermonBlockWeb; sectionType?: 'sermon' }>;

export const BlockSermon = (props: BlockSermonProps): JSX.Element => {
  return (
    <div className={classNames([props.className, nucleusClass('block-sermons')])}>
      {props.block?.dataSource !== undefined && <SwitchBlockType {...props} />}
    </div>
  );
};

const SwitchBlockType = (props: BlockSermonProps) => {
  switch (props.block?.dataSource?.itemType) {
    case 'playlist':
    case 'speaker':
    case 'bibleReference':
    case 'tag':
      return <CollectionsBlock {...props} />;
    case 'sermon':
    default:
      return <SermonsBlock {...props} />;
  }
};

const CollectionsBlock = (props: BlockSermonProps) => {
  return (
    <React.Suspense>
      <CollectionListController
        engineId={props.block?.engineId}
        basePath={useSermonHubPath(props.block?.engineId)}
        {...useSermonBlockDataSourceCollectionListParams(props.block)}
      >
        <ColumnDiv>
          <CollectionsListLayout {...props} />
        </ColumnDiv>
      </CollectionListController>
    </React.Suspense>
  );
};

const CollectionsListLayout = (props: BlockSermonProps) => {
  const { collections, collectionType, getCollectionPath, hasNextPage, fetchNextPage } = useCollectionListController();

  const enabledLoadMore = props.block?.dataSource?.isLoadMoreEnabled !== false;

  return (
    <>
      <BlockTitle title={props.block?.title} />
      {props.block?.isSermonHubNavVisible && <CollectionListToolbar />}
      {collections.length > 0 ? (
        <>
          <CollectionList className={nucleusClass('collection-list')}>
            {collections.map((collection) => (
              <React.Suspense key={collection.id}>
                <SermonListController
                  engineId={props.block?.engineId}
                  basePath={getCollectionPath(collection)}
                  params={useCollectionDataSourceParams(collection.id)}
                >
                  <CollectionItem collection={collection} />
                </SermonListController>
              </React.Suspense>
            ))}
          </CollectionList>
          {enabledLoadMore && hasNextPage && (
            <SectionButton style={{ width: '100%' }} onClick={fetchNextPage}>
              Load More
            </SectionButton>
          )}
        </>
      ) : (
        <EmptyBlock body={getEmptyCollectionListBody(collectionType)} />
      )}
    </>
  );
};

const getEmptyCollectionListBody = (
  collectionType?: 'playlists' | 'tags' | 'speakers' | 'books'
): ThemeRichTextNodeArray => {
  switch (collectionType) {
    case 'playlists':
      return [
        { text: 'Items show here when they’ve been added to a ' },
        { type: 'parameter', parameter: 'language:playlist', fallback: '', children: [{ text: '' }] },
      ];
    case 'speakers':
      return [
        { text: `Items show here when they're associated with a ` },
        { type: 'parameter', parameter: 'language:speaker', fallback: '', children: [{ text: '' }] },
      ];
    case 'books':
      return [
        { text: 'Items show here when they’re associated with ' },
        { type: 'parameter', parameter: 'language:scripture', fallback: '', children: [{ text: '' }] },
      ];
    case 'tags':
      return [
        { type: 'parameter', parameter: 'language:topics', fallback: '', children: [{ text: '' }] },
        { text: ' show here when they’re associated with a media item' },
      ];
    default:
      return [{ text: '' }];
  }
};

interface CollectionItemProps {
  collection: any;
}

const CollectionItem = (props: CollectionItemProps) => {
  const { sermons, getSermonPath } = useSermonListController();
  const { ref, prev, next } = useScrollNav();

  return (
    <CollectionListItem className={nucleusClass('collection-item')}>
      {getKeyPrefix(props.collection.id) === 'speaker' ? (
        <SpeakerItemHeader {...props} />
      ) : (
        <PlaylistItemHeader {...props} />
      )}
      <MediaStrip className={nucleusClass('media-filmstrip')} flexBasis="min(100%, 312px)" ref={ref}>
        {sermons.map((sermon) => (
          <MediaGridItem
            key={sermon.id}
            className={nucleusClass('media-item')}
            image={sermon.mainImage?.image}
            actions={<ActionsMenu title={sermon.title ?? sermon.slug} to={getSermonPath(sermon)} />}
            hoverButton={<ButtonPrimary to={getSermonPath(sermon)}>View Media</ButtonPrimary>}
            to={getSermonPath(sermon)}
          >
            <ClampedTitle2>{sermon.title}</ClampedTitle2>
            <ClampedL6>
              <Byline sermon={sermon} />
            </ClampedL6>
          </MediaGridItem>
        ))}
      </MediaStrip>
      <ButtonGroup>
        <PrevButton onClick={prev} />
        <NextButton onClick={next} />
      </ButtonGroup>
    </CollectionListItem>
  );
};

const PlaylistItemHeader = (props: CollectionItemProps) => {
  const { getCollectionPath } = useCollectionListController();
  return (
    <CollectionListItemHeader
      left={<StyledIconPlaylist height="30px" width="30px" />}
      right={<ViewCollectionButton {...props} />}
    >
      <ClampedH5 style={{ margin: 0 }}>
        <Link style={{ textDecoration: 'none' }} to={getCollectionPath(props.collection)}>
          {props.collection.title ?? props.collection.label}
        </Link>
      </ClampedH5>
    </CollectionListItemHeader>
  );
};

const SpeakerItemHeader = (props: CollectionItemProps) => {
  const { getCollectionPath } = useCollectionListController();
  const profileImage = props.collection.headshot?.image;

  return (
    <CollectionListItemHeader
      left={
        <>
          {profileImage?.src && (
            <CircleProfileImage
              style={{ height: '50px', width: '50px' }}
              src={profileImage?.src}
              srcSet={formatSrcSet(profileImage?.srcSet)}
              blurHash={profileImage?.blurHash}
            />
          )}
        </>
      }
      right={<ViewCollectionButton {...props} />}
    >
      <ClampedL2 style={{ margin: 0 }}>
        <Link style={{ textDecoration: 'none' }} to={getCollectionPath(props.collection)}>
          {props.collection.displayName}
        </Link>
        <ClampedL6>{props.collection.title}</ClampedL6>
      </ClampedL2>
    </CollectionListItemHeader>
  );
};

const ViewCollectionButton = (props: CollectionItemProps) => {
  const { getCollectionPath } = useCollectionListController();
  return (
    <MediaListButton to={getCollectionPath(props.collection)}>
      View {useCollectionTerm(props.collection)}
    </MediaListButton>
  );
};

const StyledIconPlaylist = styled(IconPlaylist)`
  display: block;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 30px;
`;

function withIcon<P>(Component: React.FunctionComponent<P>, icon: JSX.Element) {
  return function WithIcon(props: P) {
    return <Component shape="square" size="medium" icon={icon} {...props} />;
  };
}

const PrevButton = withIcon(ButtonSecondary, <IconAngleLeft />);
const NextButton = withIcon(ButtonPrimary, <IconAngleRight />);

const SermonsBlock = (props: BlockSermonProps) => {
  return (
    <SermonBlockController {...props.block}>
      <React.Suspense>
        <SermonListController
          engineId={props.block?.engineId}
          basePath={useSermonsBlockBasePath(props)}
          currentlyPlayingSermonId={props.block?.dataSource?.currentlyPlayingSermonId}
          params={useSermonBlockDataSourceSermonListParams(props.block)}
          isLoadMoreEnabled={props.block?.dataSource?.isLoadMoreEnabled}
        >
          <ColumnDiv>
            <SwitchSermonListLayout {...props} />
          </ColumnDiv>
        </SermonListController>
      </React.Suspense>
    </SermonBlockController>
  );
};

const useSermonsBlockBasePath = (props: BlockSermonProps) => {
  const collectionType = getCollectionTypeFromId(props.block?.dataSource?.sourceId)!;
  const { data } = useCollection(props.block?.engineId, `${collectionType}s`, props.block?.dataSource?.sourceId);
  const getCollectionPath = useCollectionPath(useSermonHubPath(props.block?.engineId));
  return getCollectionPath(data?.collection);
};

const SwitchSermonListLayout = (props: BlockSermonProps) => {
  const { currentLayout } = useSermonBlockController();

  switch (currentLayout) {
    case 'expanded':
      return <ExpandedView {...props} />;
    case 'filmstrip':
      return <FilmstripView {...props} />;
    case 'list':
      return <ListView {...props} />;
    case 'grid':
    default:
      return <GridView {...props} />;
  }
};

const ExpandedView = (props: BlockSermonProps) => {
  const { sermons, getSermonPath, hasNextPage, fetchNextPage } = useSermonListController();

  const enabledLoadMore = props.block?.dataSource?.isLoadMoreEnabled !== false;

  return (
    <ExpandedSection>
      <BlockTitle title={props.block?.title} />
      <MediaExpandedList className={nucleusClass('media-expanded-list')}>
        {sermons.map((sermon, index) => (
          <MediaExpandedListItem
            key={sermon.id}
            className={nucleusClass('media-item')}
            image={sermon.mainImage?.image}
            to={getSermonPath(sermon)}
          >
            {index === 0 && props.block?.items?.[0]?.overline && (
              <ClampedL5 style={{ marginTop: '1.7em' }}>{props.block?.items?.[0]?.overline}</ClampedL5>
            )}
            <ClampedH5 style={{ maxWidth: '95%', marginTop: props.block?.items?.[0]?.overline ? '0.6em' : undefined }}>
              {sermon.title}
            </ClampedH5>
            <ClampedP4 style={{ maxWidth: '74%', marginTop: '0.5em' }}>
              {_truncate(sermon.description, { length: 120 })}
            </ClampedP4>
            <ClampedL6 style={{ maxWidth: '60%' }}>
              <Byline sermon={sermon} />
            </ClampedL6>
            <div style={{ maxWidth: '216px', marginTop: '1.8em' }}>
              <ButtonPrimary to={getSermonPath(sermon)}>View Media</ButtonPrimary>
            </div>
          </MediaExpandedListItem>
        ))}
      </MediaExpandedList>
      {enabledLoadMore && hasNextPage && (
        <SectionButton style={{ width: '100%' }} onClick={fetchNextPage}>
          Load More
        </SectionButton>
      )}
    </ExpandedSection>
  );
};

const ColumnDiv = styled.div`
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
`;

const ExpandedSection = styled.div`
  padding: calc(var(--unit-length) * 1) calc(var(--unit-length) * 1);
  /* padding: calc(var(--unit-length) * 1) 0;

  @media (min-width: 600px) {
    padding: calc(var(--unit-length) * 1) calc(var(--unit-length) * 3);
  } */
`;

const FilmstripView = (props: BlockSermonProps) => {
  const { currentlyPlayingSermon, sermons, getSermonPath } = useSermonListController();
  const { ref, prev, next } = useScrollNav();

  const currentlyPlayingIndex = sermons.findIndex((sermon) => sermon.id === currentlyPlayingSermon?.id);

  useScrollToCurrentlyPlaying(ref, currentlyPlayingIndex);

  return (
    <>
      <BlockTitle
        title={props.block?.title}
        subtitle={
          isDynamicSermonListDataSource(props.block?.dataSource) && props.block?.dataSource?.sourceId !== undefined ? (
            <FilmstripSubtitle {...props} />
          ) : undefined
        }
      />
      <div>
        <MediaStrip
          className={nucleusClass('media-filmstrip')}
          flexBasis="min(100%, max(calc(33% - 6px), 300px))"
          ref={ref}
        >
          {sermons.map((sermon, index) => (
            <MediaGridItem
              key={sermon.id}
              className={nucleusClass('media-item')}
              isCurrentlyPlaying={index === currentlyPlayingIndex}
              image={sermon.mainImage?.image}
              actions={<ActionsMenu title={sermon.title ?? sermon.slug} to={getSermonPath(sermon)} />}
              hoverButton={<ButtonPrimary to={getSermonPath(sermon)}>View Media</ButtonPrimary>}
              currentlyPlayingButton={<MediaGridItemBadge>Currently Playing</MediaGridItemBadge>}
              to={getSermonPath(sermon)}
            >
              <ClampedTitle2>{sermon.title}</ClampedTitle2>
              <ClampedL6>
                <Byline sermon={sermon} />
              </ClampedL6>
            </MediaGridItem>
          ))}
        </MediaStrip>
        <ButtonGroup>
          <PrevButton onClick={prev} />
          <NextButton onClick={next} />
        </ButtonGroup>
      </div>
    </>
  );
};

const useScrollToCurrentlyPlaying = (ref: React.RefObject<HTMLDivElement>, currentIndex: number) => {
  useEffect(() => {
    if (!ref.current || currentIndex < 0) {
      return;
    }

    const nextIndex = Math.min(Math.max(0, currentIndex - 1), ref.current.children.length);

    const nextItem = ref.current.children[nextIndex] as HTMLElement | undefined;

    if (!nextItem) {
      return;
    }

    ref.current.scrollTo({
      left: nextItem.offsetLeft,
    });
  }, []);
};

const FilmstripSubtitle = (props: BlockSermonProps) => {
  const collectionType = getCollectionTypeFromId(props.block?.dataSource?.sourceId)!;
  const { data } = useCollection(props.block?.engineId, `${collectionType}s`, props.block?.dataSource?.sourceId);
  const getCollectionPath = useCollectionPath(useSermonHubPath(props.block?.engineId));

  if (data === undefined) {
    return null;
  }

  const PrefixTermMap: Record<string, string> = {
    playlist: 'Playlist',
    tag: 'Tag',
    speaker: 'Speaker',
    book: 'Book',
  };

  const term = PrefixTermMap[collectionType];
  const title = [data?.collection?.displayName, data?.collection?.title].filter(isNotEmpty).at(0);

  return (
    <>
      <span style={{ opacity: 0.65 }}>From this {term}: </span>
      <Link to={getCollectionPath(data?.collection)}>{title}</Link>
    </>
  );
};

const ListView = (props: BlockSermonProps) => {
  const { sermons, getSermonPath, hasNextPage, fetchNextPage } = useSermonListController();

  const renderListNavToolbar = props.block?.isSermonHubNavVisible === true;
  const renderListViewToolbar = renderListNavToolbar !== true && props.block?.isSermonListNavVisible !== false;

  const renderLoadMore = props.block?.dataSource?.isLoadMoreEnabled !== false && hasNextPage === true;

  return (
    <>
      <BlockTitle title={props.block?.title} />
      {renderListNavToolbar && <SermonListNavToolbar />}
      {renderListViewToolbar && <SermonListViewToolbar currentLayout="list" />}
      <MediaList className={nucleusClass('media-list')}>
        {sermons.map((sermon) => (
          <ListViewItem key={sermon.id} sermon={sermon} getSermonPath={getSermonPath} />
        ))}
      </MediaList>
      {renderLoadMore && (
        <SectionButton style={{ width: '100%' }} onClick={fetchNextPage}>
          Load More
        </SectionButton>
      )}
    </>
  );
};

interface ListViewItemProps {
  sermon: SermonIndexDocumentWeb;
  getSermonPath: (sermon: SermonIndexDocumentWeb) => string;
}
export const ListViewItem = ({ sermon, getSermonPath }: ListViewItemProps): JSX.Element => {
  return (
    <MediaListItem
      key={sermon.id}
      className={nucleusClass('media-item')}
      actions={<ActionsMenu to={getSermonPath(sermon)} />}
      image={sermon.mainImage?.image}
      to={getSermonPath(sermon)}
    >
      <ClampedH5 style={{ margin: 0 }}>{sermon.title}</ClampedH5>
      <ClampedP3 style={{ marginTop: '12px' }}>
        <Byline sermon={sermon} />
      </ClampedP3>
    </MediaListItem>
  );
};

const GridView = (props: BlockSermonProps) => {
  const { sermons, getSermonPath, hasNextPage, fetchNextPage } = useSermonListController();

  const renderListNav = props.block?.isSermonHubNavVisible === true;
  const renderListView = renderListNav !== true && props.block?.isSermonListNavVisible !== false;

  const renderLoadMore = props.block?.dataSource?.isLoadMoreEnabled !== false && hasNextPage === true;

  return (
    <>
      <BlockTitle title={props.block?.title} />
      {renderListNav && <SermonListNavToolbar />}
      {renderListView && <SermonListViewToolbar currentLayout="grid" />}
      <MediaGrid className={nucleusClass('media-grid')} style={{ marginBottom: '36px' }}>
        {sermons.map((sermon) => (
          <GridViewItem key={sermon.id} sermon={sermon} getSermonPath={getSermonPath} />
        ))}
      </MediaGrid>
      {renderLoadMore && (
        <SectionButton style={{ width: '100%' }} onClick={fetchNextPage}>
          Load More
        </SectionButton>
      )}
    </>
  );
};

export const GridViewItem = ({
  sermon,
  getSermonPath,
}: {
  sermon: SermonIndexDocumentWeb;
  getSermonPath: (sermon: SermonIndexDocumentWeb) => string;
}): JSX.Element => {
  return (
    <MediaGridItem
      key={sermon.id}
      className={nucleusClass('media-item')}
      image={sermon.mainImage?.image}
      actions={<ActionsMenu title={sermon.title ?? sermon.slug} to={getSermonPath(sermon)} />}
      hoverButton={<ButtonPrimary to={getSermonPath(sermon)}>View Media</ButtonPrimary>}
      to={getSermonPath(sermon)}
    >
      <ClampedTitle2>{sermon.title}</ClampedTitle2>
      <ClampedL6>
        <Byline sermon={sermon} />
      </ClampedL6>
    </MediaGridItem>
  );
};

const BlockTitle = ({ title, subtitle }: { title?: React.ReactNode; subtitle?: React.ReactNode }) => {
  const localize = useLocalization();

  if (title === undefined) {
    return null;
  }

  if (typeof title === 'string') {
    title = localize(title.toLocaleLowerCase(), title);
  }

  if (subtitle !== undefined) {
    return (
      <div style={{ marginBottom: '24px' }}>
        <HorizontalRule>
          <ClampedH5 style={{ margin: 0 }}>{title}</ClampedH5>
        </HorizontalRule>
        <ClampedL2 style={{ margin: '6px 0 0 0' }}>{subtitle}</ClampedL2>
      </div>
    );
  }

  return (
    <HorizontalRule style={{ marginBottom: '24px', marginLeft: '24px', marginRight: '24px' }}>
      <ClampedH5 style={{ margin: 0 }}>{title}</ClampedH5>
    </HorizontalRule>
  );
};

const EmptyBlock = ({
  headline = [{ text: 'Nothing here right now…' }],
  body = [{ text: 'Items shows here when they’ve been added to a Playlist' }],
}: {
  headline?: ThemeRichTextNodeArray;
  body?: ThemeRichTextNodeArray;
}) => {
  const props: BlockProps = {
    blockHeadlineMaxWidth: 'small',
    block: {
      headline: [
        {
          type: 'headline6',
          children: headline,
        },
      ],
      body: [
        {
          type: 'paragraph3',
          children: body,
        },
      ],
    },
  };
  return <BlockInfo {...props} />;
};

const ActionsMenu = (props: { title?: string; to: string }) => {
  const { popoverProps, triggerProps } = usePopoverState();

  const url = `${getWindow()?.location.origin}${props.to}`;

  return (
    <>
      <MenuButton {...triggerProps} />
      <Menu {...popoverProps}>
        <ViewItem to={props.to}>View Media</ViewItem>
        <CopyLinkItem url={url}>Copy Link</CopyLinkItem>
        <ShareItem title={props.title ?? ''} to={props.to}>
          Sharing Options
        </ShareItem>
      </Menu>
    </>
  );
};

export const SermonBlockShareMenu = (): JSX.Element => {
  const { popoverProps, triggerProps } = usePopoverState();

  const shareUrl = `${getWindow()?.location.href}#${useSection().id}`;

  return (
    <>
      <ShareButton {...triggerProps} />
      <Menu {...popoverProps}>
        <CopyLinkItem url={shareUrl}>Copy Link</CopyLinkItem>
        <ShareItem title={document.title ?? ''} to={shareUrl}>
          Sharing Options
        </ShareItem>
      </Menu>
    </>
  );
};

const ViewItem = (props: { children?: React.ReactNode; to: string }) => {
  return (
    <MenuItem>
      <Link to={props.to} style={{ textDecoration: 'none' }}>
        <MenuItemButton>{props.children}</MenuItemButton>
      </Link>
    </MenuItem>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AddToWatchItem = (props: { children?: React.ReactNode }) => {
  // Todo: Fetch the watch lists
  const items = ['My List', 'Shared List'];
  return (
    <MenuItem>
      <MenuItemButton>{props.children}</MenuItemButton>
      {items.length > 0 && (
        <MenuList>
          {items.map((item) => (
            <MenuItem key={item}>
              <MenuItemButton>{item}</MenuItemButton>
            </MenuItem>
          ))}
        </MenuList>
      )}
    </MenuItem>
  );
};

const CopyLinkItem = (props: { children?: React.ReactNode; url: string }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopyLink = () => {
    copy(props.url);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <MenuItem>
      <MenuItemButton onClick={handleCopyLink}>{isCopied === true ? 'Copied!' : props.children}</MenuItemButton>
    </MenuItem>
  );
};

const ShareItem = (props: { children?: React.ReactNode; title: string; to: string }) => {
  const handleShareSheet = () => {
    const shareLink = `${window.location.origin}${window.location.pathname}/${props.to}`;
    if (navigator.share) {
      navigator.share({
        text: props.title,
        url: shareLink,
      });
    }
  };

  if (navigator.share === undefined) {
    return null;
  }

  return (
    <MenuItem>
      <MenuItemButton onClick={handleShareSheet}>{props.children}</MenuItemButton>
    </MenuItem>
  );
};

const SortSelect = styled((props) => {
  const { availableToOrderBy, order, setOrder, orderBy, setOrderBy } = useSermonListController();

  const value = [orderBy, order].join(':');

  const handleChange = (value: string) => {
    const [orderBy, order] = value.split(':') as any;
    setOrderBy(orderBy);
    setOrder(order);
  };

  const isAvailable = (key: any) => availableToOrderBy.includes(key);

  if (availableToOrderBy.length < 1) {
    return null;
  }

  return (
    <Select {...props} label="Sort" value={value} onChange={handleChange}>
      {isAvailable('playlist') && <option value="playlist:desc">Playlist</option>}
      {isAvailable('date') && <option value="date:desc">Latest</option>}
      {isAvailable('date') && <option value="date:asc">Oldest</option>}
    </Select>
  );
})``;

type ToolbarProps = Parameters<typeof MediaListToolbar>[0];

const SermonListNavToolbar = styled((props: ToolbarProps) => {
  const localize = useLocalization();

  return (
    <MediaListToolbar
      {...props}
      left={<SermonHubNav />}
      right={
        <>
          <SortSelect />
          <SearchNavButton to={slugifyString(localize('search', 'search'))} />
        </>
      }
    />
  );
})`
  ${SortSelect} .SelectValue {
    display: none;
    @media screen and (min-width: 600px) {
      display: initial;
    }
  }
`;

const SermonListViewToolbar = styled((props: ToolbarProps & { currentLayout: 'grid' | 'list' }) => {
  const { onLayoutChange } = useSermonBlockController();

  return (
    <MediaListToolbar
      {...props}
      left={
        <>
          <ViewToggle value={props.currentLayout} onChange={onLayoutChange} />
          <SortSelect />
        </>
      }
      right={
        <>
          <SermonBlockShareMenu />
        </>
      }
    />
  );
})``;

const CollectionListToolbar = () => {
  return <MediaListToolbar left={<SermonHubNav />} right={<SearchNavButton to="search" />} />;
};

interface BylineProps {
  sermon: SermonIndexDocumentWeb;
}

export const Byline = (props: BylineProps): JSX.Element => {
  return (
    <Join separator=" • ">
      <SermonSpeakers sermon={props.sermon} />
      <SermonDate sermon={props.sermon} relative />
    </Join>
  );
};

const clampFontFactory = (min: string, cssVariable: string, max: string) =>
  `clamp(${min}, var(${cssVariable}) * var(--global-font-factor), ${max})`;

const ClampedTitle2 = styled.div.attrs({ className: 'theme-text-title2' })`
  font-size: ${clampFontFactory('14px', '--title2-font-size', '24px')};
`;

const ClampedH5 = styled(Text.H5)`
  font-size: ${clampFontFactory('30px', '--headline5-font-size', '46px')};
`;

const ClampedL2 = styled(Text.L2)`
  font-size: ${clampFontFactory('19px', '--label2-font-size', '29px')};
`;

const ClampedL5 = styled(Text.L5)`
  font-size: ${clampFontFactory('12px', '--label5-font-size', '19px')};
`;

const ClampedL6 = styled(Text.L6)`
  font-size: ${clampFontFactory('12px', '--label6-font-size', '19px')};
`;

const ClampedP3 = styled(Text.P3)`
  font-size: ${clampFontFactory('17px', '--paragraph3-font-size', '27px')};
`;

const ClampedP4 = styled(Text.P4)`
  font-size: ${clampFontFactory('12px', '--paragraph4-font-size', '19px')};
`;
