import React from 'react';
import styled from 'styled-components';
import { Popover, PopoverProps } from './Popover';
import { MenuList } from './MenuList';
import { uuid4InHex } from '@nucleus/lib-shape';

declare module '@nucleus/react-components' {
  interface ReactComponentsTheme {
    Menu?: {
      color?: string;
      background?: string;
      boxShadow?: string;
    };
  }
}

export interface MenuContext {
  panelId: string;
}

const Context = React.createContext<MenuContext>(null!);
Context.displayName = 'MenuContext';

export const useMenuContext = (): MenuContext => React.useContext(Context);

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The element based on which the menu is positioned.
   */
  anchorEl?: PopoverProps['anchorEl'];
  anchorOrigin?: PopoverProps['anchorOrigin'];
  isOpen?: PopoverProps['isOpen'];
  onClose?: () => void;
  transformOrigin?: PopoverProps['transformOrigin'];
}

/**
 * The Menu component is used for creating menus that can be opened and positioned relative to a specified element.
 * It uses the <Popover> component for relative positioning
 *
 * See `usePopoverState` for connecting to a trigger element
 *
 * @param props - The props for the Menu component
 * @returns A JSX element representing the menu.
 */
export const Menu = ({ anchorEl, anchorOrigin, isOpen, onClose, transformOrigin, ...props }: Props): JSX.Element => {
  const context: MenuContext = React.useMemo(() => ({ panelId: props.id ?? uuid4InHex() }), [props.id]);

  return (
    <Context.Provider value={context}>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Panel {...props} id={context.panelId}>
          <MenuList>{props.children}</MenuList>
        </Panel>
      </Popover>
    </Context.Provider>
  );
};

Menu.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const Panel = styled.div`
  background: ${({ theme }) => theme._reactComponents.Menu?.background};
  color: ${({ theme }) => theme._reactComponents.Menu?.color};
  box-shadow: ${({ theme }) => theme._reactComponents.Menu?.boxShadow};
  border-radius: 12px;
  padding: 6px;
  position: relative;
  overflow: hidden;
`;
