import React from 'react';

/** Represents a Playlist icon used on the Collection Block title */
export const IconPlaylist = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeWidth="3"
          d="M12.8 1.5h10.4c4 0 5.5.4 7 1.2 1.3.7 2.4 1.8 3.1 3.1.8 1.5 1.2 3 1.2 7v10.4c0 4-.4 5.5-1.2 7a7.6 7.6 0 0 1-3.1 3.1c-1.5.8-3 1.2-7 1.2H12.8c-4 0-5.5-.4-7-1.2a7.6 7.6 0 0 1-3.1-3.1c-.8-1.5-1.2-3-1.2-7V12.8c0-4 .4-5.5 1.2-7a7.6 7.6 0 0 1 3.1-3.1c1.5-.8 3-1.2 7-1.2Z"
        />
        <g fill="currentColor" transform="translate(10 11)">
          <rect width="10.7" height="3.5" x="5.3" rx="1.3" />
          <rect width="3.6" height="3.5" rx="1.3" />
          <rect width="3.6" height="3.5" y="5.3" rx="1.3" />
          <rect width="10.7" height="3.5" x="5.3" y="5.3" rx="1.3" />
          <rect width="3.6" height="3.5" y="10.5" rx="1.3" />
          <rect width="10.7" height="3.5" x="5.3" y="10.5" rx="1.3" />
        </g>
      </g>
    </svg>
  );
};
