import React, { useImperativeHandle } from 'react';
import { Descendant, Selection } from 'slate';
import { Slate } from 'slate-react';
import { useCustomEditor } from '../hooks/useCustomEditor';
import { CustomEditor as Editor, Plugin } from '../types/custom-types';

interface Props {
  children: React.ReactNode;
  onChange?: ((value: Descendant[]) => void) | undefined;
  onSelectionChange?: ((selection: Selection) => void) | undefined;
  onValueChange?: ((value: Descendant[]) => void) | undefined;
  plugins?: Plugin[];
  initialValue: Descendant[];
}

export const CustomEditor = React.forwardRef<Editor, Props>(({ plugins, ...props }, ref): JSX.Element => {
  const editor = useCustomEditor(plugins);
  useImperativeHandle(ref, () => editor, [editor]);
  return <Slate editor={editor} {...props} />;
});

CustomEditor.displayName = 'CustomEditor';
