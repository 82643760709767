import { FontPalette, FontPaletteKey, FontPaletteKeys } from '@nucleus/types/web';
import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { objectToCssClass, objectToCssRules } from './src/utils/styles';
import { font, media } from './style-utils';

export const TextGlobalStyle = ({
  fontPalette,
  baseScale,
  scale,
}: {
  fontPalette: FontPalette;
  baseScale?: number;
  scale?: number;
}): JSX.Element => {
  const baseTypographyCssVariables = generateCssVariablesFromFontPalette(fontPalette);
  return (
    <GlobalStyle
      cssVariables={{ ...baseTypographyCssVariables, ...themeTextCssVariables }}
      cssClasses={themeTextCssClasses}
      fontBaseScale={baseScale}
      fontScale={scale}
    />
  );
};

const palettePrefix = (key: string) => `palette-${key}`;

interface GlobalStyleProps {
  cssVariables: Record<string, string | number>;
  cssClasses: Record<string, Record<string, string | number>>;
  fontBaseScale?: number;
  fontScale?: number;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    :root {
      /* text css variables */
      --root-font-value: 10; /* REMS are base10 :) */

      /* Known constant that is the middle of the global font size slider. */
      --global-font-base: ${(props) => props.fontBaseScale ?? 16};

      /* this comes from the DB */
      --global-font-config: ${(props) => props.fontScale ?? props.fontBaseScale};

      /* This is used to SCALE the size of the various fonts */
      --global-font-factor: calc(var(--global-font-config) / var(--global-font-base));

      ${(props) => objectToCssRules(props.cssVariables)}
    }

    html {
      font-size: calc(var(--root-font-value) * 1px);
    }

    ${(props) => buildThemeTextCss(props.cssClasses)}

`;

const ThemeTextKey = {
  /** @deprecated */
  HeadlineOne: 'headlineOne',
  /** @deprecated */
  HeadlineTwo: 'headlineTwo',
  /** @deprecated */
  HeadlineThree: 'headlineThree',
  /** @deprecated */
  HeadlineFour: 'headlineFour',
  /** @deprecated */
  HeadlineFive: 'headlineFive',
  /** @deprecated */
  HeadlineSix: 'headlineSix',

  Headline1: 'headline1',
  Headline2: 'headline2',
  Headline3: 'headline3',
  Headline4: 'headline4',
  Headline5: 'headline5',
  Headline6: 'headline6',
  Paragraph1: 'paragraph1',
  Paragraph2: 'paragraph2',
  Paragraph3: 'paragraph3',
  Paragraph4: 'paragraph4',
  Title1: 'title1',
  Title2: 'title2',
  Description1: 'description1',
  Description2: 'description2',
  Blockquote1: 'blockquote1',
  Blockquote2: 'blockquote2',
  Label1: 'label1',
  Label2: 'label2',
  Label3: 'label3',
  Label4: 'label4',
  Label5: 'label5',
  Label6: 'label6',
  Nav1: 'navigation1',
  Nav2: 'navigation2',
  Nav3: 'navigation3',
  Nav4: 'navigation4',
  Nav5: 'navigation5',
  SectionHeaderHeadline: 'section-header-headline',
  SectionHeaderBody: 'section-header-body',
  SectionHeaderLabel: 'section-header-label',
  SectionHeaderByline: 'section-header-byline',
  SectionHeadline: 'section-headline',
  SectionBody: 'section-body',
  SectionLabel: 'section-label',
  SectionByline: 'section-byline',
  CardHeadline: 'card-headline',
  CardBody: 'card-body',
  CardLabel: 'card-label',
  CardByline: 'card-byline',
  MainNavItemLabel: 'main-nav-item-label',
  MainNavSubitemLabel: 'main-nav-subitem-label',
  MainNavToggleLabel: 'main-nav-toggle-label',
  FooterBody: 'footer-body',
  FooterChurchNameLabel: 'footer-church-name-label',
  FooterNavItemLabel: 'footer-nav-item-label',
  FooterNavSubitemLabel: 'footer-nav-subitem-label',
  Button1: 'button-1',
  Button2: 'button-2',
  Button3: 'button-3',
  Button4: 'button-4',
  CalendarHeaderLabel: 'calendar-header-label',
} as const;

const ThemeTextKeys = Object.values(ThemeTextKey) as ThemeTextKey[];

type ThemeTextKey = (typeof ThemeTextKey)[keyof typeof ThemeTextKey];

type ThemeTextOrFontPaletteKey = ThemeTextKey | FontPaletteKey;

interface ThemeTextValue {
  base: FontPaletteKey;
  margin?: [string] | [string, string] | [string, string, string] | [string, string, string, string];
}

type ThemeTextConfig = Record<ThemeTextKey, ThemeTextValue>;

const THEME_TEXT_CONFIG: ThemeTextConfig = {
  /** @deprecated */
  ['headlineOne']: {
    base: 'headline1',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  /** @deprecated */
  ['headlineTwo']: {
    base: 'headline2',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  /** @deprecated */
  ['headlineThree']: {
    base: 'headline3',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  /** @deprecated */
  ['headlineFour']: {
    base: 'headline4',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  /** @deprecated */
  ['headlineFive']: {
    base: 'headline5',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  /** @deprecated */
  ['headlineSix']: {
    base: 'headline6',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },

  ['headline1']: {
    base: 'headline1',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  ['headline2']: {
    base: 'headline2',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  ['headline3']: {
    base: 'headline3',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  ['headline4']: {
    base: 'headline4',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  ['headline5']: {
    base: 'headline5',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  ['headline6']: {
    base: 'headline6',
    margin: ['clamp(3rem, 0.5em, 5vw)', '0', '0', '0'],
  },
  ['paragraph1']: {
    base: 'paragraph1',
  },
  ['paragraph2']: {
    base: 'paragraph2',
  },
  ['paragraph3']: {
    base: 'paragraph3',
  },
  ['paragraph4']: {
    base: 'paragraph4',
  },
  ['title1']: {
    base: 'title1',
  },
  ['title2']: {
    base: 'title2',
  },
  ['description1']: {
    base: 'description1',
  },
  ['description2']: {
    base: 'description2',
  },
  ['blockquote1']: {
    base: 'blockquote1',
  },
  ['blockquote2']: {
    base: 'blockquote2',
  },
  ['label1']: {
    base: 'label1',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  ['label2']: {
    base: 'label2',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  ['label3']: {
    base: 'label3',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  ['label4']: {
    base: 'label4',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  ['label5']: {
    base: 'label5',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  ['label6']: {
    base: 'label6',
    margin: ['min(0.5em, 2vw)', '0', '0', '0'],
  },
  ['navigation1']: {
    base: 'navigation1',
  },
  ['navigation2']: {
    base: 'navigation2',
  },
  ['navigation3']: {
    base: 'navigation3',
  },
  ['navigation4']: {
    base: 'navigation4',
  },
  ['navigation5']: {
    base: 'navigation5',
  },
  ['section-header-headline']: {
    base: 'headline2',
  },
  ['section-header-body']: {
    base: 'paragraph1',
  },
  ['section-header-label']: {
    base: 'label1',
  },
  ['section-header-byline']: {
    base: 'label1',
  },
  ['section-headline']: {
    base: 'headline3',
  },
  ['section-body']: {
    base: 'paragraph2',
  },
  ['section-label']: {
    base: 'label2',
  },
  ['section-byline']: {
    base: 'label4',
  },
  ['card-headline']: {
    base: 'headline6',
  },
  ['card-body']: {
    base: 'paragraph4',
  },
  ['card-label']: {
    base: 'label6',
  },
  ['card-byline']: {
    base: 'label6',
  },
  ['main-nav-item-label']: {
    base: 'navigation1',
  },
  ['main-nav-subitem-label']: {
    base: 'navigation2',
  },
  ['main-nav-toggle-label']: {
    base: 'navigation5',
  },
  ['footer-body']: {
    base: 'paragraph3',
  },
  ['footer-church-name-label']: {
    base: 'headline6',
  },
  ['footer-nav-item-label']: {
    base: 'navigation3',
  },
  ['footer-nav-subitem-label']: {
    base: 'navigation4',
  },
  ['button-1']: {
    base: 'button1',
  },
  ['button-2']: {
    base: 'button2',
  },
  ['button-3']: {
    base: 'button3',
  },
  ['button-4']: {
    base: 'button4',
  },
  ['calendar-header-label']: {
    base: 'label4',
  },
};

const THEME_TEXT_CLASS_PREFIX = 'theme-text-';
const themeTextCssClasses = generateCssForTextByKey(ThemeTextKeys);
const themeTextCssVariables = generateCssVariablesFromThemeTextConfig(THEME_TEXT_CONFIG);

function getTextTransformFromCapitalization(
  capitalization: 'none' | 'everyWord' | 'firstWord' | 'lowercase' | 'uppercase'
) {
  switch (capitalization) {
    case 'everyWord':
      return 'capitalize';
    case 'firstWord':
      return 'lowercase';
    case 'lowercase':
      return 'lowercase';
    case 'uppercase':
      return 'uppercase';
    case 'none':
    default:
      return 'none';
  }
}

function generateCssVariablesFromFontPalette(fontPalette: FontPalette): Record<string, string | number> {
  // Magic numbers (for now) to be replaced soon.
  const windowMinWidth = 320; // Based on the min width of the body.
  const windowMaxWidth = 1500;
  const sizeScaleUpperBound = 140;
  const sizeScaleLowerBound = 12;
  const responsiveScaleUpperBound = 76;
  const responsiveScaleLowerBound = 12;
  const upperSizeRange = sizeScaleUpperBound - sizeScaleLowerBound;
  const responsiveSizeRange = responsiveScaleUpperBound - responsiveScaleLowerBound;
  const rangeScaleFactor = responsiveSizeRange / upperSizeRange;

  return Object.entries(fontPalette).reduce((acc, [key, value]) => {
    const responsiveFontSizePx =
      rangeScaleFactor * (value.fontSizePx - responsiveScaleLowerBound) + responsiveScaleLowerBound;

    const slope = (value.fontSizePx - responsiveFontSizePx) / (windowMaxWidth - windowMinWidth);

    const fontSizeCalc = `clamp(${responsiveFontSizePx}px, calc((${slope} * (100vw - ${windowMinWidth}px)) + ${responsiveFontSizePx}px), 1000rem)`;

    return {
      ...acc,
      [`--${palettePrefix(key)}-font-size`]: fontSizeCalc,
      [`--${palettePrefix(key)}-line-height`]: value.lineHeightEm,
      [`--${palettePrefix(key)}-letter-spacing`]: value.letterSpacingEm,
      [`--${palettePrefix(key)}-font-family`]: `"${value.fontFamily}"`,
      [`--${palettePrefix(key)}-font-weight`]: value.fontWeight,
      [`--${palettePrefix(key)}-font-style`]: value.fontStyle,
      [`--${palettePrefix(key)}-text-transform`]: getTextTransformFromCapitalization(value.capitalization),
    };
  }, {});
}

function generateCssVariablesFromThemeTextConfig(themeTextConfig: ThemeTextConfig) {
  return Object.entries(themeTextConfig).reduce<Record<string, string>>((acc, [key, value]) => {
    const result = {
      ...acc,
      [`--${key}-font-size`]: `var(--${palettePrefix(value.base)}-font-size)`,
      [`--${key}-line-height`]: `var(--${palettePrefix(value.base)}-line-height)`,
      [`--${key}-letter-spacing`]: `var(--${palettePrefix(value.base)}-letter-spacing)`,
      [`--${key}-font-family`]: `var(--${palettePrefix(value.base)}-font-family)`,
      [`--${key}-font-weight`]: `var(--${palettePrefix(value.base)}-font-weight)`,
      [`--${key}-font-style`]: `var(--${palettePrefix(value.base)}-font-style)`,
      [`--${key}-text-transform`]: `var(--${palettePrefix(value.base)}-text-transform)`,
    };

    if (value.margin !== undefined) {
      result[`--${key}-font-margin`] = `${value.margin.join(' ')}`;
    }

    return result;
  }, {});
}

function generateCssForTextByKey(typographyKeys: ThemeTextOrFontPaletteKey[]) {
  return typographyKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ['margin']: `var(--${key}-font-margin)`,
        ['font-size']: `calc(var(--${key}-font-size) * var(--global-font-factor))`,
        ['line-height']: `calc(var(--${key}-line-height) * 1em)`,
        ['letter-spacing']: `calc(var(--${key}-letter-spacing) * 1em)`,
        ['font-family']: `var(--${key}-font-family)`,
        ['font-weight']: `var(--${key}-font-weight)`,
        ['font-style']: `var(--${key}-font-style)`,
        ['text-transform']: `var(--${key}-text-transform)`,
      },
    }),
    {} as Record<ThemeTextKey | FontPaletteKey, Record<string, string | number>>
  );
}

function buildThemeTextCss(themeCssClasses: ReturnType<typeof generateCssForTextByKey>): ReturnType<typeof css> {
  const styles = css`
    /* output theme css classes */
    ${Object.entries(themeCssClasses)
      .map(([key, value]) => objectToCssClass(`${THEME_TEXT_CLASS_PREFIX}${key}`, value))
      .join('')}
  `;

  return styles;
}

function generateTextClassName(name: ThemeTextOrFontPaletteKey): string {
  return THEME_TEXT_CLASS_PREFIX + name;
}

const TextClassNameMap = [...ThemeTextKeys, ...FontPaletteKeys].reduce(
  (acc, name) => ({
    ...acc,
    [name]: generateTextClassName(name),
  }),
  {} as { [key in ThemeTextOrFontPaletteKey]: string }
);

const BaseLinkStyles = css`
  color: inherit;
  text-decoration: underline;
`;
const BaseTextStyles = css`
  a {
    ${BaseLinkStyles}
  }
`;
const BaseHeadingStyles = css`
  ${BaseTextStyles}
`;
const BaseParagraphStyles = css`
  ${BaseTextStyles}
`;
const BaseLabelStyles = css`
  ${BaseTextStyles}
`;
const BaseDisplayStyles = css`
  ${BaseTextStyles}
`;
const BaseBlockquoteStyles = css`
  ${BaseTextStyles}
`;
const BaseCodeStyles = css`
  ${BaseTextStyles}
`;

type TextStyles = {
  [key: string]: any;
};

const ThemeTextStyleNames = Object.keys(THEME_TEXT_CONFIG) as Array<keyof typeof THEME_TEXT_CONFIG>;

const TextStyles: TextStyles = {
  // Heading
  H1: css`
    ${BaseHeadingStyles}
    ${objectToCssRules(themeTextCssClasses.headline1)}
  `,
  H2: css`
    ${BaseHeadingStyles}
    ${objectToCssRules(themeTextCssClasses.headline2)}
  `,
  H3: css`
    ${BaseHeadingStyles}
    ${objectToCssRules(themeTextCssClasses.headline3)}
  `,
  H4: css`
    ${BaseHeadingStyles}
    ${objectToCssRules(themeTextCssClasses.headline4)}
  `,
  H5: css`
    ${BaseHeadingStyles}
    ${objectToCssRules(themeTextCssClasses.headline5)}
  `,
  H6: css`
    ${BaseHeadingStyles}
    ${objectToCssRules(themeTextCssClasses.headline6)}
  `,
  // Paragraph
  P1: css`
    ${BaseParagraphStyles}
    ${objectToCssRules(themeTextCssClasses.paragraph1)}
  `,
  P2: css`
    ${BaseParagraphStyles}
    ${objectToCssRules(themeTextCssClasses.paragraph2)}
  `,
  P3: css`
    ${BaseParagraphStyles}
    ${objectToCssRules(themeTextCssClasses.paragraph3)}
  `,
  P4: css`
    ${BaseParagraphStyles}
    ${objectToCssRules(themeTextCssClasses.paragraph4)}
  `,
  P5: css`
    ${BaseParagraphStyles}
    ${objectToCssRules(themeTextCssClasses.description1)}
  `,
  P6: css`
    ${BaseParagraphStyles}
    ${objectToCssRules(themeTextCssClasses.description2)}
  `,
  // Label
  L1: css`
    ${BaseLabelStyles}
    ${objectToCssRules(themeTextCssClasses.label1)}
  `,
  L2: css`
    ${BaseLabelStyles}
    ${objectToCssRules(themeTextCssClasses.label2)}
  `,
  L3: css`
    ${BaseLabelStyles}
    ${objectToCssRules(themeTextCssClasses.label3)}
  `,
  L4: css`
    ${BaseLabelStyles}
    ${objectToCssRules(themeTextCssClasses.label4)}
  `,
  L5: css`
    ${BaseLabelStyles}
    ${objectToCssRules(themeTextCssClasses.label5)}
  `,
  L6: css`
    ${BaseLabelStyles}
    ${objectToCssRules(themeTextCssClasses.label6)}
  `,
  // Link
  A1: css`
    ${BaseTextStyles}
    ${BaseLinkStyles}
  `,
  A2: css`
    ${BaseTextStyles}
    ${BaseLinkStyles}
  `,
  A3: css`
    ${BaseTextStyles}
    ${BaseLinkStyles}
  `,
  A4: css`
    ${BaseTextStyles}
    ${BaseLinkStyles}
  `,
  A5: css`
    ${BaseTextStyles}
    ${BaseLinkStyles}
  `,
  A6: css`
    ${BaseTextStyles}
    ${BaseLinkStyles}
  `,
  // Display
  D1: css`
    ${BaseTextStyles}
    ${BaseDisplayStyles}
  `,
  D2: css`
    ${BaseTextStyles}
    ${BaseDisplayStyles}
  `,
  // Blockquote
  Q1: css`
    ${BaseTextStyles}
    ${BaseBlockquoteStyles}
    ${font(32, 'Regular', 50, -0.5)}
    font-style: italic;
    ${media.desktopAndUp`${font(42, 'Regular', 60, -0.5)}`}

    /* blockquote styles */
    position: relative;
    padding-left: 2.4rem;
    /* line down left side */
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0.4rem;
      height: 100%;
      background: #000000;
    }
  `,
  Q2: css`
    ${BaseTextStyles}
    ${BaseBlockquoteStyles}
    ${font(30, 'Regular', 42, -1)}
    font-style: italic;
    ${media.desktopAndUp`${font(30, 'Regular', 42, -1)}`}

    /* blockquote styles */
    padding: 2.4rem;
    background: #fafafa;
    border-radius: 3px;
  `,
  // Code
  C1: css`
    ${BaseTextStyles}
    ${BaseCodeStyles}
  `,
  C2: css`
    ${BaseTextStyles}
    ${BaseCodeStyles}
  `,
  // Separator
  S1: css`
    display: block;
    &:after {
      display: inline-block;
      width: 12rem;
      height: 0.4rem;
      background: rgba(0, 0, 0, 1);
    }
  `,
  S2: css``,
  // Theme Elements
  ...ThemeTextStyleNames.reduce(
    (acc, name) => ({
      ...acc,
      [name]: css`
        ${objectToCssRules(themeTextCssClasses[name])}
      `,
    }),
    {}
  ),
};

const H1 = styled.h1.attrs({ className: TextClassNameMap['headline1'] })`
  ${TextStyles.H1}
`;
const H2 = styled.h2.attrs({ className: TextClassNameMap['headline2'] })`
  ${TextStyles.H2}
`;
const H3 = styled.h3.attrs({ className: TextClassNameMap['headline3'] })`
  ${TextStyles.H3}
`;
const H4 = styled.h4.attrs({ className: TextClassNameMap['headline4'] })`
  ${TextStyles.H4}
`;
const H5 = styled.h5.attrs({ className: TextClassNameMap['headline5'] })`
  ${TextStyles.H5}
`;
const H6 = styled.h6.attrs({ className: TextClassNameMap['headline6'] })`
  ${TextStyles.H6}
`;
const P1 = styled.p.attrs({ className: TextClassNameMap['paragraph1'] })`
  ${TextStyles.P1}
`;
const P2 = styled.p.attrs({ className: TextClassNameMap['paragraph2'] })`
  ${TextStyles.P2}
`;
const P3 = styled.p.attrs({ className: TextClassNameMap['paragraph3'] })`
  ${TextStyles.P3}
`;
const P4 = styled.p.attrs({ className: TextClassNameMap['paragraph4'] })`
  ${TextStyles.P4}
`;
const P5 = styled.p.attrs({ className: TextClassNameMap['description1'] })`
  ${TextStyles.P5}
`;
const P6 = styled.p.attrs({ className: TextClassNameMap['description2'] })`
  ${TextStyles.P6}
`;
const L1 = styled.div.attrs({ className: TextClassNameMap['label1'] })`
  ${TextStyles.L1}
`;
const L2 = styled.div.attrs({ className: TextClassNameMap['label2'] })`
  ${TextStyles.L2}
`;
const L3 = styled.div.attrs({ className: TextClassNameMap['label3'] })`
  ${TextStyles.L3}
`;
const L4 = styled.div.attrs({ className: TextClassNameMap['label4'] })`
  ${TextStyles.L4}
`;
const L5 = styled.div.attrs({ className: TextClassNameMap['label5'] })`
  ${TextStyles.L5}
`;
const L6 = styled.div.attrs({ className: TextClassNameMap['label6'] })`
  ${TextStyles.L6}
`;
const A1 = styled.a`
  ${TextStyles.A1}
`;
const A2 = styled.a`
  ${TextStyles.A2}
`;
const A3 = styled.a`
  ${TextStyles.A3}
`;
const A4 = styled.a`
  ${TextStyles.A4}
`;
const A5 = styled.a`
  ${TextStyles.A5}
`;
const A6 = styled.a`
  ${TextStyles.A6}
`;
const D1 = styled.div`
  ${TextStyles.D1}
`;
const D2 = styled.div`
  ${TextStyles.D2}
`;
const Q1 = styled.div`
  ${TextStyles.Q1}
`;
const Q2 = styled.div`
  ${TextStyles.Q2}
`;
const C1 = styled.div`
  ${TextStyles.C1}
`;
const C2 = styled.div`
  ${TextStyles.C2}
`;

export const Text = {
  ClassName: TextClassNameMap,
  Styles: TextStyles,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P1,
  P2,
  P3,
  P4,
  P5,
  P6,
  L1,
  L2,
  L3,
  L4,
  L5,
  L6,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  D1,
  D2,
  Q1,
  Q2,
  C1,
  C2,
};
