import { ErrorBoundary } from '@nucleus/web-theme';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React from 'react';
import { PostHubProvider } from './hubContext/PostHubProvider';
import { PostRoutes } from './PostRoutes';

interface PostHubProps {
  engineId: string;
}

export const PostHub = (props: PostHubProps): JSX.Element => {
  return (
    <PostHubProvider hubId={props.engineId}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                There was an error! <a onClick={() => resetErrorBoundary()}>Try again</a>
                <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
              </div>
            )}
          >
            <PostRoutes />
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </PostHubProvider>
  );
};
