import { NavigationAction } from '@nucleus/sites';
import {
  Contact,
  ContactType,
  EmailAddressContact,
  PhoneNumberContact,
  PostalAddressContact,
  SocialMediaLinkContact,
  UrlContact,
} from '@nucleus/types/contact';
import { FooterPayloadWeb } from '@nucleus/types/web';
import { NavigationHosted, NavigationItemHosted } from '@nucleus/web-hosting';
import { Text, media, nucleusClass } from '@nucleus/web-theme-elements';
import { upperFirst as _upperFirst } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Branding } from '../../components/Branding';
import { InfoButtons } from '../../components/InfoButtons';
import { WrappedStyledRichText } from '../../components/WrappedRichText';
import { IconSocialMap } from '../../components/theme-icons';
import { SpacerTop } from '../../sectionLayouts/Spacers';

export interface FooterSectionProps extends FooterPayloadWeb {
  className?: string;
  navigation?: NavigationHosted;
}

export const FooterLayout = (props: FooterSectionProps): JSX.Element => {
  return (
    <SectionLayout className={props.className}>
      <Footer {...props} />
    </SectionLayout>
  );
};

const SectionLayout = styled.div`
  overflow: hidden;
  position: relative;
`;

const VerticalLine = styled.div`
  // vertical separator line between footer columns
  display: none;
  position: absolute;
  left: 50%;
  top: 0;
  width: 1px;
  height: 100%;
  background: var(--color-media-background);
  opacity: 0.7;

  ${media.smallDesktopAndUp`
    display: block;
  `}
`;

const HorizontalLine = styled.div`
  // horizontal separator line above footer for contrast
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background: var(--color-media-background);
  opacity: 0.7;
`;

const FooterLeft = styled.div`
  display: flex;
`;

const FooterLeftInner = styled.div`
  max-width: 32rem;
  display: flex;
  flex-direction: column;

  ${media.tabletPortraitAndUp`
    max-width: 46.5rem;
  `}
`;

const FooterRight = styled.div`
  display: flex;
`;

const FooterRightInner = styled.div`
  width: 100%;
  max-width: 50rem;
`;

const FooterInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4.5rem;
  row-gap: 6rem;

  ${FooterRight} {
    width: 100%;
    flex: 1 1 auto;
  }

  ${media.tabletPortraitAndUp`
    flex-wrap: nowrap;
    column-gap: 18rem;
    row-gap: 6rem;

    ${FooterLeft} {
      width: 100%;
      flex: 1 1 auto;
    }

    ${FooterRight} {
      width: 100%;
      justify-content: center;
      flex: 1 1 auto;
    }
  `}
`;

const GridGap = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `calc(${gap} * 0.66)`};

  ${media.smallDesktopAndUp<{ gap: string }>`
    gap: ${({ gap }) => gap};
  `}
`;

const FooterContainer = styled.div<{ contentAlignment: 'left' | 'center' }>`
  display: flex;
  flex-direction: column;
  gap: 7rem;
  background-color: var(--color-section-background);
  color: var(--color-section-text);
  padding: 4rem 3rem;

  ${media.smallDesktopAndUp`
    padding: 9rem 9rem;
    gap: 9rem;
  `}

  ${(props) => {
    if (props.contentAlignment === 'left') {
      return css`
        ${FooterLeftInner} {
          align-items: flex-start;
          text-align: left;
        }
        ${FooterBrandingContainer} {
          align-items: flex-start;
        }
        ${FooterSocialIcons} {
          justify-content: flex-start;
        }
      `;
    }

    return css`
      align-items: center;
      ${FooterLeftInner}, ${GridGap} {
        width: 100%;
        align-items: center;
        text-align: center;
      }
      ${FooterBrandingContainer} {
        align-items: center;
      }
      ${FooterSocialIcons} {
        justify-content: center;
      }
    `;
  }}
`;

const StyledFooterText = styled.div.attrs({
  className: Text.ClassName['footer-body'],
})`
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const StyledFooterIconText = styled.div.attrs({
  className: Text.ClassName['footer-body'],
})`
  a {
    text-decoration: underline;
    color: inherit;
  }
  padding-top: 0.9rem;
  padding-right: 1rem;
`;

const FooterText = WrappedStyledRichText(StyledFooterText);

const FooterButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 32rem;

  ${media.smallDesktopAndUp`
    gap: 2rem;
  `}
`;

const FooterSocialIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
`;

const FooterSocialIconLink = styled.a`
  color: inherit;
  width: 4.6rem;
  height: 4.6rem;
`;

const StyledFooterLegalText = styled.div`
  opacity: 0.5;

  ${media.smallDesktopAndUp`
    margin-top: 0;
  `}

  a {
    color: inherit;
  }
`;
const FooterLegalText = WrappedStyledRichText(StyledFooterLegalText);

const FooterPoweredByLink = () => (
  <StyledFooterLegalText>
    <Text.P4>
      <a href="https://www.nucleus.church" target="_blank" rel="noreferrer">
        Powered by Nucleus
      </a>
    </Text.P4>
  </StyledFooterLegalText>
);

const FooterNavList = styled.div`
  padding: 0;
  list-style: none;
  width: 100%;

  --grid-gap: clamp(30px, var(--the-unit-length), 80px);

  --grid-row-gap: var(--grid-gap);
  --grid-column-gap: var(--grid-gap);
  --grid-column-count: 2;
  --grid-item--min-width: min(15rem, 100%);
  --grid-padding: 0 0 2.4rem 0;

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-column-gap));
  --grid-item--max-width: min(calc((100% - var(--total-gap-width)) / var(--grid-column-count)), 250px);

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), var(--grid-item--max-width))
  );
  column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap);
  padding: var(--grid-padding);
`;

const StyledFooterItemWrapper = styled.div``;

const FooterNavItem = styled.div.attrs({
  className: Text.ClassName['footer-nav-item-label'],
})`
  color: var(--color-section-text);
  text-decoration: none;

  :hover {
    ${(props) => {
      if ('to' in props || 'href' in props) {
        return css`
          text-decoration: underline;
        `;
      }
    }}
  }
`;

const FooterNavSubList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;

  ${media.smallDesktopAndUp`
    margin-top: 2.5rem;
  `}
`;

const FooterNavSubItem = styled.div.attrs({
  className: Text.ClassName['footer-nav-subitem-label'],
})`
  text-decoration: underline;
  --webkit-font-smoothing: antialiased;
  color: inherit;
`;

const FooterBrandingContainer = styled.div`
  --header-content-height: max(calc(4.5 * var(--min-unit-length)), calc(1.6 * var(--unit-length)));
  height: var(--header-content-height);

  display: flex;
  flex-direction: column;
`;

interface FooterItemProps {
  children: React.ReactNode;
  item: NavigationItemHosted;
  isSubItem?: boolean;
}
const FooterItem = ({ children, item, ...props }: FooterItemProps): JSX.Element => {
  let BaseComponent = FooterNavItem;
  if (props.isSubItem) {
    BaseComponent = FooterNavSubItem;
  }

  const options: Parameters<typeof FooterNavItem | typeof FooterNavSubItem>[0] = {};
  if (item.type === NavigationAction.Page) {
    options.as = Link;
    options.to = item.payload.destination;
  } else if (item.type !== NavigationAction.None) {
    options.as = 'a';
    options.href = item.payload.destination;
  }

  if (item.payload.openInNewTab === true) {
    options.target = '_blank';
  }

  return <BaseComponent {...options}>{children}</BaseComponent>;
};

function isEmailContact(contact: Contact): contact is EmailAddressContact {
  return contact.contactType === ContactType.EmailAddress;
}

function isPhoneContact(contact: Contact): contact is PhoneNumberContact {
  return contact.contactType === ContactType.PhoneNumber;
}

function isSocialContact(contact: Contact): contact is SocialMediaLinkContact {
  return contact.contactType === ContactType.SocialMediaLink;
}

function isPostalAddressContact(contact: Contact): contact is PostalAddressContact {
  return contact.contactType === ContactType.PostalAddress;
}

function isUrlContact(contact: Contact): contact is UrlContact {
  return contact.contactType === ContactType.Url;
}

const FormattedStreetAddressContact = ({ contact }: { contact: PostalAddressContact }) => {
  const { streetAddress, streetAddress2, city, state, postalCode, country } = contact;

  return (
    <div>
      {streetAddress && (
        <div>
          {streetAddress}
          {streetAddress2 && `, ${streetAddress2}`}
        </div>
      )}
      {(city || state || postalCode) && (
        <div>{`${city || ''}${city && state ? ', ' : ''}${state || ''}${state && postalCode ? ' ' : ''}${
          postalCode || ''
        }`}</div>
      )}
      {country && <div>{country}</div>}
    </div>
  );
};

function formatPhoneNumberContact(contact: PhoneNumberContact) {
  const { phoneNumber, countryCode, extension } = contact;
  let formattedPhone = phoneNumber;
  // Add the country code to the beginning of the formatted phone number if it exists
  if (countryCode) {
    formattedPhone = `+${countryCode} ${formattedPhone}`;
  }
  // Add the extension to the end of the formatted phone number if it exists
  if (extension) {
    formattedPhone += ` x${extension}`;
  }
  return formattedPhone;
}

function formatPhoneNumberContactHref(contact: PhoneNumberContact) {
  const { phoneNumber, countryCode, extension } = contact;
  return `tel:${countryCode ?? ''}${phoneNumber}${extension ? ';ext=' + extension : ''}`;
}

const PhoneNumber = styled.div``;

const PhoneNumberLink = styled.a`
  text-decoration: underline;
  color: inherit;
`;

const EmailAddress = styled.div``;

const EmailAddressLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Url = styled.div``;

const UrlLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Footer = (props: FooterSectionProps): JSX.Element => {
  const contacts = props.blocks[0].contacts ?? [];
  const emailContacts = contacts.filter(isEmailContact);
  const phoneContacts = contacts.filter(isPhoneContact);
  const socialContacts = contacts.filter(isSocialContact);
  const postalAddressContacts = contacts.filter(isPostalAddressContact);
  const urlContacts = contacts.filter(isUrlContact);

  const contactTypes = contacts.reduce((acc, contact) => {
    if (acc.includes(contact.contactType) !== true) {
      acc.push(contact.contactType);
    }
    return acc;
  }, [] as ContactType[]);

  const renderNavigation = props.navigation?.items !== undefined && props.navigation.items.length > 0;
  const footerContentAlignment = renderNavigation === true ? 'left' : 'center';
  const renderLabels = props.blocks[0].contactDisplaySettings?.displayContactLabels === true;
  const renderSocialAsText = props.blocks[0].contactDisplaySettings?.displaySocialMediaAs === 'text';

  return (
    <FooterContainer className={nucleusClass('block-footer')} contentAlignment={footerContentAlignment}>
      <HorizontalLine />
      <SpacerTop />
      <FooterInner>
        <FooterLeft className={nucleusClass('footer-left')}>
          <FooterLeftInner>
            <GridGap gap="8rem">
              <GridGap gap="3.5rem">
                <FooterBrandingContainer>
                  <Branding brandIdentity={props.blocks[0].brandIdentity ?? {}} />
                </FooterBrandingContainer>
                <GridGap gap="3rem">
                  <FooterText nodes={props.blocks[0].body} />
                  {props.blocks[0].buttons && (
                    <FooterButtons>
                      <InfoButtons style={{ marginTop: 0 }} buttons={props.blocks[0].buttons} />
                    </FooterButtons>
                  )}
                </GridGap>
              </GridGap>
              <GridGap gap="3.5rem">
                <GridGap gap="2.5rem">
                  {contactTypes.map((contactType) => {
                    switch (contactType) {
                      case ContactType.EmailAddress:
                        return (
                          <StyledFooterText key={contactType}>
                            {emailContacts?.map((contact) => (
                              <EmailAddress key={contact.id}>
                                {renderLabels && `${contact.label}: `}
                                <EmailAddressLink href={`mailto:${contact.emailAddress}`}>
                                  {contact.emailAddress}
                                </EmailAddressLink>
                              </EmailAddress>
                            ))}
                          </StyledFooterText>
                        );
                      case ContactType.PhoneNumber:
                        return (
                          <StyledFooterText key={contactType}>
                            {phoneContacts?.map((contact) => (
                              <PhoneNumber key={contact.id}>
                                {renderLabels && `${contact.label}: `}
                                <PhoneNumberLink href={formatPhoneNumberContactHref(contact)}>
                                  {formatPhoneNumberContact(contact)}
                                </PhoneNumberLink>
                              </PhoneNumber>
                            ))}
                          </StyledFooterText>
                        );
                      case ContactType.PostalAddress:
                        return (
                          <StyledFooterText key={contactType}>
                            <GridGap gap="2.5rem">
                              {postalAddressContacts?.map((contact) => (
                                <React.Fragment key={contact.id}>
                                  {renderLabels && `${contact.label}: `}
                                  <FormattedStreetAddressContact contact={contact} />
                                </React.Fragment>
                              ))}
                            </GridGap>
                          </StyledFooterText>
                        );
                      case ContactType.Url:
                        return (
                          <StyledFooterText key={contactType}>
                            {urlContacts?.map((contact) => (
                              <Url key={contact.id}>
                                {renderLabels && `${contact.label}: `}
                                <UrlLink href={contact.url} target="_blank" rel="noopener noreferrer">
                                  {contact.url}
                                </UrlLink>
                              </Url>
                            ))}
                          </StyledFooterText>
                        );

                      case ContactType.SocialMediaLink:
                        if (renderSocialAsText === true) {
                          return (
                            <StyledFooterText key={contactType}>
                              {socialContacts?.map((contact) => (
                                <Url key={contact.id}>
                                  <UrlLink href={contact.url} target="_blank" rel="noopener noreferrer">
                                    {renderLabels === true ? contact.label : _upperFirst(contact.platform)}
                                  </UrlLink>
                                </Url>
                              ))}
                            </StyledFooterText>
                          );
                        } else {
                          return (
                            <FooterSocialIcons
                              key={contactType}
                              style={{ marginTop: '2rem', gap: renderLabels ? '1.5rem' : undefined }}
                            >
                              {socialContacts?.map((contact) => (
                                <React.Fragment key={contact.id}>
                                  <FooterSocialIconLink
                                    title={contact.label}
                                    href={contact.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {IconSocialMap[contact.platform]}
                                  </FooterSocialIconLink>
                                  {renderLabels && (
                                    <StyledFooterIconText key={contactType}>{contact.label} </StyledFooterIconText>
                                  )}
                                </React.Fragment>
                              ))}
                            </FooterSocialIcons>
                          );
                        }
                      default:
                        return null;
                    }
                  })}
                </GridGap>
              </GridGap>
            </GridGap>
          </FooterLeftInner>
        </FooterLeft>

        {renderNavigation && (
          <>
            <VerticalLine />
            <FooterRight className={nucleusClass('footer-right')}>
              <FooterRightInner>
                <FooterNavList>
                  {props.navigation?.items?.map((item) => (
                    <StyledFooterItemWrapper key={item.id}>
                      <FooterItem item={item}>{item.title}</FooterItem>
                      {item.items && (
                        <FooterNavSubList>
                          {item.items.map((subItem) => (
                            <FooterItem key={subItem.id} item={subItem} isSubItem={true}>
                              {subItem.title}
                            </FooterItem>
                          ))}
                        </FooterNavSubList>
                      )}
                    </StyledFooterItemWrapper>
                  ))}
                </FooterNavList>
              </FooterRightInner>
            </FooterRight>
          </>
        )}
      </FooterInner>
      <FooterLeftInner>
        <GridGap gap="3.5rem">
          <FooterLegalText nodes={props.blocks[0].legal} />
          <FooterPoweredByLink />
        </GridGap>
      </FooterLeftInner>
    </FooterContainer>
  );
};
