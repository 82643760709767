import { SermonIndexDocumentWeb } from '@nucleus/sermons/types/Sermon';
import { SermonWeb } from '@nucleus/types/web/sermons/sermon';
import React from 'react';
import { formatSermonDate } from '../lib/sermon';

interface Props {
  sermon?: SermonWeb | SermonIndexDocumentWeb;
  relative?: boolean;
}

/**
 * Renders a Sermon's date as 'MMMM d, yyyy', or optionally as relative time ago
 */
export const SermonDate = ({ sermon, relative }: Props): JSX.Element | null => {
  if (sermon === undefined) {
    return null;
  }

  return <>{formatSermonDate(sermon, relative)}</>;
};
