export type URLSearchValue = string | boolean | number | undefined;
export type URLSearchInput<T> = { [P in keyof T]: URLSearchValue | URLSearchValue[] };

export const objectToQueryString = <T>(object: URLSearchInput<T>): string =>
  Object.entries(object)
    .filter(([key]) => encodeURIComponent(key) !== '')
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const values = Array.isArray(value) ? value : [value];

      return values
        .filter((value) => value !== undefined && value !== '')
        .map((value) => `${encodedKey}=${encodeURIComponent(value as string)}`)
        .join('&');
    })
    .filter((value) => value.length > 0)
    .join('&');
