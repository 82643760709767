import { keyBy as _keyBy } from 'lodash';
import {
  BibleBook,
  BibleVersion,
  VerseReference,
  bibleVersions,
  getBibleBookById,
  parseBibleVerseId,
} from '@nucleus/bible';
import { BibleReferenceWeb } from '@nucleus/types/bible';

export const formatBibleReference = (reference?: BibleReferenceWeb): string => {
  if (reference === undefined) {
    return '';
  }

  try {
    const startVerse = parseBibleVerseId(reference.startVerseId);
    const endVerse = parseBibleVerseId(reference.endVerseId);

    return formatVerseReference(startVerse, endVerse, reference.version);
  } catch (error) {
    return '';
  }
};

export const formatVerseReference = (
  startVerse: VerseReference,
  endVerse: VerseReference,
  versionId: string
): string => {
  const book = getBibleBookById(startVerse.bookId)?.label ?? '';
  const version = getBibleVersionById(versionId)?.abbreviation ?? '';

  // Note: We don't permit users to create a range across books
  // Note: We don't permit users to enter verse numbers when a chapter range is entered

  if (startVerse.chapter !== endVerse.chapter) {
    // Genesis 1-2 (ESV)
    return `${book} ${startVerse.chapter}-${endVerse.chapter} (${version})`;
  }

  if (startVerse.verse === endVerse.verse) {
    // Genesis 1:1 (ESV)
    return `${book} ${startVerse.chapter}:${startVerse.verse} (${version})`;
  }

  if (startVerse.verse === 0) {
    // Genesis 1 (ESV)
    return `${book} ${startVerse.chapter} (${version})`;
  }

  // Genesis 1:1-10 (ESV)
  return `${book} ${startVerse.chapter}:${startVerse.verse}-${endVerse.verse} (${version})`;
};

export const getBibleBookByReference = (reference?: BibleReferenceWeb): BibleBook | undefined => {
  if (reference === undefined) {
    return;
  }

  try {
    const startVerse = parseBibleVerseId(reference.startVerseId);
    return getBibleBookById(startVerse.bookId);
  } catch (error) {
    return;
  }
};

const bibleVersionsById = _keyBy(bibleVersions, 'id');

export const getBibleVersionById = (id: string): BibleVersion | undefined => bibleVersionsById[id];

/**
 * Get a URL to bible.com or undefined
 * @param reference
 * @returns string | undefined
 */
export const getBibleReferenceUrl = (reference?: BibleReferenceWeb): string | undefined => {
  if (reference === undefined) {
    return;
  }

  try {
    const startVerse = parseBibleVerseId(reference.startVerseId);
    const endVerse = parseBibleVerseId(reference.endVerseId);

    return getBibleComVerseUrl(startVerse, endVerse, reference.version);
  } catch (error) {
    //
  }
};

/**
 * Format a Bible Verse as a url to bible.com
 * @param startVerse
 * @param endVerse
 * @param versionId
 * @returns string | undefined
 */
const getBibleComVerseUrl = (
  startVerse: VerseReference,
  endVerse: VerseReference,
  versionId: string
): string | undefined => {
  // Here is an example url:  https://www.bible.com/bible/12/GEN.1.2,5-12.ASV
  // https://www.bible.com/bible/[VERSION_ID]/[BOOK_ABBREVIATION].[CHAPTER].[VERSES (optional)].[VERSION_ABBREVIATION (optional)]

  const baseUrl = `https://www.bible.com/bible`;

  const book = getBibleBookById(startVerse.bookId);
  const version = getBibleVersionById(versionId);

  if (book === undefined || version === undefined) {
    return;
  }

  // Note: We don't permit users to create a range across books
  // Note: We don't permit users to enter verse numbers when a chapter range is entered

  // Genesis 1-2 (ESV)
  // Bible.com does not support chapter ranges, we can send the user to first chapter
  if (startVerse.chapter !== endVerse.chapter) {
    return `${baseUrl}/${version.youVersionId}/${book.abbreviation}.${startVerse.chapter}.${version.abbreviation}`;
  }

  // Genesis 1:1 (ESV)
  if (startVerse.verse === endVerse.verse) {
    return `${baseUrl}/${version.youVersionId}/${book.abbreviation}.${startVerse.chapter}.${startVerse.verse}.${version.abbreviation}`;
  }

  // Genesis 1 (ESV)
  if (startVerse.verse === 0) {
    return `${baseUrl}/${version.youVersionId}/${book.abbreviation}.${startVerse.chapter}.${version.abbreviation}`;
  }

  // Genesis 1:1-10 (ESV)
  return `${baseUrl}/${version.youVersionId}/${book.abbreviation}.${startVerse.chapter}.${startVerse.verse}-${endVerse.verse}.${version.abbreviation}`;
};
