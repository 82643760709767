import { RouteObject, useRoutes } from 'react-router-dom';
import React, { Suspense } from 'react';
import { HomeLayout } from './home/HomeLayout';
import { Collection } from './collection/Collection';
import { Collections } from './collection/Collections';
import { Post } from './post/Post';

export interface RouteItem {
  Component: () => JSX.Element;
  path: string;
  items?: Array<RouteItem>;
}

const HubStructure: Array<RouteItem> = [
  {
    path: '/',
    Component: HomeLayout,
  },
  {
    path: '/collections',
    Component: Collections,
  },
  {
    path: '/collections/:collection',
    Component: Collection,
  },
  {
    path: '/collections/:collection/:post',
    Component: Post,
  },
];

// TODO: use theme's loading components
const LoadingFallback = (props: { path: string }): JSX.Element => <h2>Loading {props.path}...</h2>;

export const PostRoutes = (): JSX.Element | null => {
  const createRoute = ({ path, Component, ...rest }: RouteItem): RouteObject => ({
    path: path,
    element: (
      <Suspense fallback={<LoadingFallback path={path} />}>
        <Component key={path} />
      </Suspense>
    ),
    children: rest.items?.map((item) => createRoute(item)),
  });

  let element = useRoutes(HubStructure.map((item) => createRoute(item)));

  return element;
};
