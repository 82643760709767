import Joi from '@hapi/joi';
import { safeToArray as _safeToArray } from '@nucleus/lib-shape';

interface options extends Joi.ValidationOptions {
  extraErrorAttributes?: any;
}

export function validateOrThrow<T>(value: T, schema: Joi.AnySchema, options: options = {}): T {
  const { extraErrorAttributes, ...validateOptions } = options;
  const validated = Joi.validate<T>(value, schema, validateOptions);
  if (validated.error) {
    if (extraErrorAttributes !== undefined) {
      Object.assign(validated.error, extraErrorAttributes);
    }
    throw validated.error;
  }
  return validated.value;
}

export function isValid(value: any, schema: Joi.AnySchema, options?: Joi.ValidationOptions): boolean {
  const validated = Joi.validate(value, schema, options);
  if (validated.error) {
    return false;
  }
  return true;
}

/** @deprecated use import { safeToArray } from '@nucleus/lib-shape'; instead */
export const safeToArray = _safeToArray;
