import React from 'react';
import styled from 'styled-components';

export const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 100%;
  }
`;

export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

const SVG = styled(({ style, className, ...props }: SVGProps): JSX.Element => {
  return (
    <IconContainer style={style} className={className}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        {props.children}
      </svg>
    </IconContainer>
  );
})``;

export const IconX = (props: any): JSX.Element => (
  <SVG {...props} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" />
      <path
        d="m4.648 4.648 14.704 14.704M19.352 4.648 4.648 19.352"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SVG>
);

export const IconAngleLeft = (props: any): JSX.Element => (
  <SVG {...props} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" />
      <path
        d="M14.176 19.353 6.824 12l7.352-7.353"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </SVG>
);

export const IconAngleRight = (props: any): JSX.Element => (
  <SVG {...props} viewBox="0 0 24 24">
    <g transform="rotate(-180 12 12)" fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" />
      <path
        d="M14.176 19.353 6.824 12l7.352-7.353"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </SVG>
);

export const IconAngleDown = (props: any): JSX.Element => (
  <SVG {...props} viewBox="0 0 24 24">
    <g transform="rotate(-90 12 12)" fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" />
      <path
        d="M14.176 19.353 6.824 12l7.352-7.353"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </SVG>
);

export const IconAngleUp = (props: any): JSX.Element => (
  <SVG {...props} viewBox="0 0 24 24">
    <g transform="rotate(-270 12 12)" fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" />
      <path
        d="M14.176 19.353 6.824 12l7.352-7.353"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </SVG>
);

export const IconShare = (props: any): JSX.Element => (
  <SVG {...props} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" />
      <path
        d="M6.853 8.691 12 3.176l5.147 5.515M12 3.176V15.31m-8.088 0v5.515h16.176v-5.515"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </SVG>
);

export const IconXThin = (props: any): JSX.Element => (
  <SVG {...props} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 6 12 12m0-12L6 18"
      />
    </g>
  </SVG>
);

export const IconCalendarAdd = (props: any): JSX.Element => (
  <SVG {...props} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M18.965 11.111a1 1 0 0 1 1 1V19a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1v-6.889a1 1 0 0 1 1-1h14Zm-11 2.056a.5.5 0 0 0-.5.5v1.5h-1.5a.5.5 0 1 0 0 1h1.5v1.5a.5.5 0 0 0 1 0v-1.5h1.5a.5.5 0 0 0 0-1h-1.5v-1.5a.5.5 0 0 0-.5-.5ZM15.521 4c.982 0 1.778.796 1.778 1.778v.444h.889c.932 0 1.697.719 1.772 1.632l.005.146c0 .982-.795 1.778-1.777 1.778H5.743a1.778 1.778 0 1 1 0-3.556h.888l.001-.444a1.778 1.778 0 1 1 3.556 0v.444h3.554l.001-.444c0-.982.796-1.778 1.778-1.778Z"
      />
    </g>
  </SVG>
);

export const IconHamburgerMenu = (props: any): JSX.Element => {
  return (
    <SVG {...props} viewBox="0 0 25.118 17">
      <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth="3">
        <path d="M1.617 8.5h22M1.5 15.5h22M1.5 1.5h22" />
      </g>
    </SVG>
  );
};

export const Icon = {
  Container: IconContainer,
  AngleLeft: IconAngleLeft,
  AngleRight: IconAngleRight,
  AngleDown: IconAngleDown,
  AngleUp: IconAngleUp,
  Share: IconShare,
  X: IconX,
  XThin: IconXThin,
  CalendarAdd: IconCalendarAdd,
  HamburgerMenu: IconHamburgerMenu,
};
