import React, { useEffect } from 'react';

export function useOnOutsideClick(refs: Array<React.RefObject<any>>, callback?: () => void, condition = true): void {
  useEffect(() => {
    if (refs === undefined || !condition) {
      return;
    }
    function handleClickOutside(e: MouseEvent) {
      const clickedInside = refs.reduce((acc, ref) => {
        let element = ref.current;
        if (typeof element === 'string') {
          element = document.getElementById(element);
        }
        return acc === true || (element && element.contains(e.target));
      }, false);

      if (clickedInside !== true && typeof callback === 'function') {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, callback, condition]);
}
