import { CustomRichText, ImageElement, RichTextNode, RichTextNodeArray } from '@nucleus/types/richText';
import { escape as _escape, isPlainObject as _isPlainObject } from 'lodash';

const decorateText = (node: CustomRichText): string => {
  let string = _escape(node.text);

  if (node.italic) {
    string = `<em>${string}</em>`;
  }

  if (node.underline) {
    string = `<u>${string}</u>`;
  }

  if (node.bold) {
    string = `<strong>${string}</strong>`;
  }

  if (node.code) {
    string = `<pre>${string}</pre>`;
  }

  if (node.strike) {
    string = `<del>${string}</del>`;
  }

  return string;
};

const buildImageNode = (node: ImageElement): string => {
  let image = `<img src="${node.src}" />`;

  if (node.href !== undefined) {
    image = `<a href="${node.href}">${image}</a>`;
  }

  return image;
};

const serializeNode = (node: RichTextNode): string => {
  if (_isPlainObject(node) && 'text' in node && typeof node.text === 'string') {
    if (node.text === '\n') {
      return '<br />';
    }

    return decorateText(node);
  }

  const children = 'children' in node ? node.children.map((n) => serializeNode(n)).join('') : '';

  if (!('type' in node)) {
    return children;
  }

  switch (node.type) {
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'span':
      return `<span>${children}</span>`;
    case 'code':
      return `<pre>${children}</pre>`;
    case 'div':
      return `<div>${children}</div>`;
    case 'block-quote':
      return `<blockquote>${children}</blockquote>`;
    case 'heading-one':
      return `<h1>${children}</h1>`;
    case 'heading-two':
      return `<h2>${children}</h2>`;
    case 'heading-three':
      return `<h3>${children}</h3>`;
    case 'heading-four':
      return `<h4>${children}</h4>`;
    case 'heading-five':
      return `<h5>${children}</h5>`;
    case 'heading-six':
      return `<h6>${children}</h6>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'link':
      return `<a href="${_escape(node.href)}">${children}</a>`;
    case 'image':
      return buildImageNode(node);
    default:
      return children;
  }
};

export const serializeRichtextToHtml = (nodes: RichTextNodeArray): string => {
  return nodes.map((node) => serializeNode(node)).join('');
};
