import * as yup from 'yup';

const slugSchema = yup.string().trim().min(0).max(2048);

export const singleSlugSchema = slugSchema.matches(/^[a-z\d]([a-z\d_-]*[a-z\d])*$/, {
  excludeEmptyString: true,
  message:
    'Slug must start with a letter or digit and may only contain lowercase letters, digits, hyphens, and underscores.',
});

export const multiSlugSchema = slugSchema.matches(/^[a-z\d]+[a-z\d_-]*(\/[a-z\d_-]*[a-z\d])*$/, {
  excludeEmptyString: true,
  message:
    'Slug must start with a letter or digit and may only contain lowercase letters, digits, hyphens, underscores, and forward slash.',
});
