import { PolymorphicButtonProps, usePolymorphicButtonProps } from '@nucleus/react-components';
import React from 'react';
import styled from 'styled-components';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    MediaListButton?: {
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
      background?: string;
      hover?: {
        color?: string;
        background?: string;
      };
      active?: {
        color?: string;
        background?: string;
      };
    };
  }
}

interface MediaListButtonProps extends PolymorphicButtonProps {
  children?: React.ReactNode;
}

export const MediaListButton = ({ children, ...props }: MediaListButtonProps): JSX.Element => {
  return <Button {...usePolymorphicButtonProps(props)}>{children}</Button>;
};

const Button = styled.button.attrs((props) => ({
  className: props.theme._sermonThemeElements.MediaListButton?.className,
}))`
  color: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.color};
  background: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.background};
  border: none;
  border-radius: 7px;
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '10px',
    pill: '18px',
  })};
  font-size: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.fontSize};
  line-height: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.lineHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  user-select: none;
  cursor: pointer;
  height: 36px;
  margin: 0;
  padding: 6px 20px;
  text-decoration: none;

  /* Always use the the Hover state on mobile */
  @media (max-width: 600px) {
    color: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.hover?.color};
    background: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.hover?.background};
  }

  :hover {
    color: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.hover?.color};
    background: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.hover?.background};
  }

  :active,
  &.active {
    color: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.active?.color};
    background: ${({ theme }) => theme._sermonThemeElements.MediaListButton?.active?.background};
  }
`;
