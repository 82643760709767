import { CalendarSectionWeb } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SectionLayout } from '../../sectionLayouts/SectionLayout';
import { CalendarBlockLayoutCollage } from './CalendarBlockLayoutCollage';
import { CalendarBlockLayoutContentOnly } from './CalendarBlockLayoutContentOnly';
import { CalendarBlockLayoutCover } from './CalendarBlockLayoutCover';
import { CalendarBlockLayoutHorizontal } from './CalendarBlockLayoutHorizontal';
import { CalendarBlockLayoutLongText } from './CalendarBlockLayoutLongText';
import { CalendarBlockLayoutVertical } from './CalendarBlockLayoutVertical';
import { CalendarBlockLayoutProps } from './CalendarLayout';

export const CalendarSection = (): JSX.Element => {
  const section = useSection<CalendarSectionWeb>();

  const blockProps = { ...section.payload };
  delete blockProps.backgroundMedia;

  return (
    <Section className={classNames(nucleusClass('section-calendar'), getSectionClasses(section))}>
      <SectionLayout {...section.payload}>
        <BlockLayout {...blockProps} />
      </SectionLayout>
    </Section>
  );
};

const BlockLayout = (props: CalendarBlockLayoutProps) => {
  switch (props.blockLayout) {
    case 'layout1':
      return <CalendarBlockLayoutHorizontal {...props} />;
    case 'layout2':
      return <CalendarBlockLayoutVertical {...props} />;
    case 'layout3':
      return <CalendarBlockLayoutCollage {...props} />;
    case 'layout4':
      return <CalendarBlockLayoutLongText {...props} />;
    case 'layout5':
      return <CalendarBlockLayoutCover {...props} />;
    case 'layout6':
      return <CalendarBlockLayoutContentOnly {...props} />;
    default:
      return <CalendarBlockLayoutHorizontal {...props} />;
  }
};
