import { yupKeyStringSchema } from './yupValidation';
import * as yup from 'yup';

// Shape of MediaApi
export const mediaSchema = yup.object({
  useColor: yup.bool(),
  useImage: yup.bool(),
  useVideo: yup.bool(),
  color: yup
    .string()
    .when('useColor', { is: true, then: (schema) => schema.required(), otherwise: (schema) => schema }), // hex string
  image: yup
    .object({
      fileId: yupKeyStringSchema, // should be require when parent object exists
      focalPoint: yup
        .object({
          x: yup.number(), // should be require when parent object exists
          y: yup.number(), // should be require when parent object exists
        })
        .default(undefined)
        .optional(),
      scale: yup
        .string()
        .matches(/(none|cover|contain)/, { excludeEmptyString: true })
        .optional(),
      tile: yup
        .string()
        .matches(/(none|repeat|repeat-x|repeat-y)/, { excludeEmptyString: true })
        .optional(),
    })
    .default(undefined)
    .optional(),
  video: yup
    .object({
      useImage: yup.bool(),
      image: yup
        .object({
          fileId: yupKeyStringSchema, // should be require when parent object exists
          focalPoint: yup
            .object({
              x: yup.number(), // should be require when parent object exists
              y: yup.number(), // should be require when parent object exists
            })
            .default(undefined)
            .optional(),
          scale: yup
            .string()
            .matches(/(none|cover|contain)/, { excludeEmptyString: true })
            .optional(),
          src: yup.string(),
          tile: yup
            .string()
            .matches(/(none|repeat|repeat-x|repeat-y)/, { excludeEmptyString: true })
            .optional(),
        })
        .default(undefined)
        .optional(),
    })
    .default(undefined)
    .optional(),
  blending: yup
    .object({
      opacity: yup.number(),
      blendMode: yup
        .string()
        .matches(
          /(none|multiply|screen|overlay|darken|lighten|color-dodge|color-burn|hard-light|soft-light|difference|exclusion|hue|saturation|color|luminosity)/,
          { excludeEmptyString: true }
        )
        .optional(),
      fade: yup
        .string()
        .matches(/(none|top|bottom|left|right)/, { excludeEmptyString: true })
        .optional(),
      fadeIntensity: yup.number(),
      filter: yup
        .string()
        .matches(/(none|darken|blur|sepia|grayscale|invert|brighten|vibrant|muted|duotone)/, {
          excludeEmptyString: true,
        })
        .optional(),
      texture: yup
        .string()
        .matches(/(none|grid-small|dots-small)/, { excludeEmptyString: true })
        .optional(),
    })
    .default(undefined)
    .optional(),
  presetMediaKey: yup
    .string()
    .matches(
      /(cardBackground|cardMediaFull|cardMediaInline|leaderCardMediaFull|leaderCardMediaInline|listMedia|sectionBackground|sectionMedia)/,
      { excludeEmptyString: true }
    )
    .optional(),
});
