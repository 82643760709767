import React from 'react';

/** Represents radio waves emanating from a broadcast point, used to indicate a Live broadcast on the Sermon Media Actions toolbar   */
export const IconRadioWaves = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 20" {...props}>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M13 9.968c-1.793-.002-3.45.991-4.347 2.602-.21.418-.578.73-1.015.862-.437.13-.907.072-1.302-.164a1.732 1.732 0 0 1-.788-1.089 1.79 1.79 0 0 1 .213-1.344C7.255 8.152 10.014 6.5 13 6.5c2.986 0 5.745 1.652 7.239 4.335.244.4.322.885.213 1.344a1.731 1.731 0 0 1-.788 1.089 1.626 1.626 0 0 1-1.302.164 1.693 1.693 0 0 1-1.015-.862C16.45 10.959 14.793 9.966 13 9.968Zm-9.694.806A10.221 10.221 0 0 1 8.81 4.36a9.975 9.975 0 0 1 8.379 0 10.22 10.22 0 0 1 5.504 6.413 1.685 1.685 0 0 0 2.1 1.155c.43-.129.79-.426 1.003-.826.214-.4.26-.868.134-1.305C24.82 6 22.143 2.875 18.592 1.232a13.311 13.311 0 0 0-11.186 0C3.856 2.875 1.18 5.999.068 9.798a1.715 1.715 0 0 0 1.146 2.122 1.682 1.682 0 0 0 2.092-1.146Zm8.355 8.959a3.494 3.494 0 0 0 3.813-.759A3.498 3.498 0 1 0 9.5 16.5c0 1.415.853 2.693 2.161 3.233Z"
      />
    </svg>
  );
};
