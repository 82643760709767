import { SectionConfigBuilder } from '../ThemeConfigBuilder';
import { body, headline, overline } from '../fields/fields';

export default new SectionConfigBuilder()
  .setContext('picker', {
    label: 'Search Input',
    description: 'Displays input field for sermons search',
    icon: 'Sermon',
  })
  .setContext('editor', {
    label: 'Search Input Section',
    description: '',
    icon: 'Sermon',
  })
  .addLayout('layout1', (layout) => {
    layout
      .setDescription('Search input only')
      .setIcon('SermonLayout6')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      });
  })
  .build();
