import { ThemeLifeV1Icon } from '@nucleus/web-theme-elements';

export const IconX = ThemeLifeV1Icon.X;
export const IconXThin = ThemeLifeV1Icon.XThin;
export const IconAngleLeft = ThemeLifeV1Icon.AngleLeft;
export const IconAngleRight = ThemeLifeV1Icon.AngleRight;
export const IconAngleDown = ThemeLifeV1Icon.AngleDown;
export const IconAngleUp = ThemeLifeV1Icon.AngleUp;
export const IconShare = ThemeLifeV1Icon.Share;
export const IconCalendarAdd = ThemeLifeV1Icon.CalendarAdd;
export const IconHamburgerMenu = ThemeLifeV1Icon.HamburgerMenu;
