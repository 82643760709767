import { LabelPayload, SectionAction, ThemeRichTextNodeArray } from '@nucleus/types/web';
import React from 'react';
import styled from 'styled-components';
import { Body, Byline, Headline, Label, Labels, Overline } from '../components/Base';
import { InfoButtons } from './InfoButtons';

type SectionHeaderProps = {
  overline?: string | ThemeRichTextNodeArray;
  headline?: string | ThemeRichTextNodeArray;
  byline?: string | ThemeRichTextNodeArray;
  body?: string | ThemeRichTextNodeArray;
  labels?: Array<LabelPayload>;
  actions?: Array<SectionAction>;
};

/** @deprecated */
export const SectionHeader = (props: SectionHeaderProps): JSX.Element => {
  const { overline, headline, byline, body, labels, actions } = props;

  return (
    <SectionBlockContent>
      <Overline nodes={overline} />
      <Headline nodes={headline} />
      <Byline nodes={byline} />
      {labels && <Labels>{labels?.map((label, index) => <Label key={index}>{label.title}</Label>)}</Labels>}
      <Body nodes={body} />
      {actions && (
        <InfoButtons
          buttons={actions}
          buttonsLayout="vertical"
          buttonsWidth="auto"
          buttonsMaxWidth="medium"
          buttonsAlignment="left"
        />
      )}
    </SectionBlockContent>
  );
};

const SectionBlockContent = styled.div`
  padding: min(calc(var(--section-inset-padding-vertical-vw) * 1vw), 6rem)
    min(calc(var(--section-inset-padding-horizontal-vw) * 1vw), 6rem);

  padding-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  z-index: 1;

  ${Headline} {
    max-width: 18em;
  }

  ${Body} {
    max-width: 24em;
  }

  ${Labels} {
    font-size: 0.75em;
    display: flex;
  }
`;
