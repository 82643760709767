import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { SiteContext } from '../site/SiteContext';

const LauncherUrl = process.env['REACT_APP_LAUNCHER_URL'];

export const LauncherLoader = (): JSX.Element | null => {
  const { getSite } = useContext(SiteContext);
  const shortcode = getSite().launcher?.shortcode;

  if (shortcode === undefined) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">{`
        window.nucleusLauncherSettings = {
          launcherId: '${shortcode}',
        };
        (function (l, a, u, n, c, h) {
          u = a.createElement('script');
          u.type = 'text/javascript';
          u.async = true;
          u.src = '${LauncherUrl ?? ''}/boot.latest.js';
          n = a.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(u, n);
        })(window, document);
      `}</script>
    </Helmet>
  );
};
