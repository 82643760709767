import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCalendarGrid } from '../../blocks/BlockCalendarGrid';
import { BlockInfo, blockInfoHasContent } from '../../blocks/BlockInfo';
import { Body } from '../../components/Base';
import { CalendarBlockLayoutProps } from './CalendarLayout';

export const CalendarBlockLayoutLongText = (props: CalendarBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const bodyMaxColumns = props.blocks[0]?.bodyMaxColumns ?? props.blockBodyMaxColumns;

  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout {...props} bodyMaxColumns={bodyMaxColumns} renderInfo={renderInfo}>
      <StyledBlockInfo {...innerProps} block={props.blocks[0]} />
      <StyledBlockCalendarGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const FallbackMaxColumns = 1;

const StyledBlockCalendarGrid = styled(BlockCalendarGrid)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)<{
  bodyMaxColumns?: number;
  renderInfo: boolean;
}>`
  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${Body} {
    column-count: ${(props) => props.bodyMaxColumns ?? FallbackMaxColumns};
    column-width: max(calc(var(--unit-length) * 7), 300px);
    column-gap: var(--unit-length);
    width: 100%;
  }

  ${StyledBlockCalendarGrid} {
    padding: var(--unit-length);
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};
  }
`;
