import { SermonIndexDocumentWeb } from '../types/Sermon';

export const ServiceName = 'Sermons';

export const Namespace = 'sermon';

export const SermonBondCategory = 'Sermon';

export const RedirectKeyPrefix = 'sermonredirect';
export const SpeakerKeyPrefix = 'speaker';
export const MediaPrefix = 'sermonmedia';
export const ReferenceKeyPrefix = 'reference';
export const SermonTagPrefix = 'sermontag';
export const AttachmentPrefix = 'sermonattachment';

export const SermonHubConfigSlug = 'sermon_hub_config';

export const RecentSermonsPlaylistSlug = 'system/recent-sermons';

// Since setup checklist depends on migration choice, this array include all possible keys
export const SermonsSetupChecklistItems = Object.freeze([
  'checkedNavigation',
  'checkedPlaylist',
  'checkedPodcast',
  'checkedPodcast',
  'checkedPrepared',
  'checkedSermon',
  'checkedSermonHub',
  'checkedSermonHubPreview',
]);

export const LatestSermonIndexName = 'sermons';
export const PublishedSermonIndexName = 'sermons-published';

export const SermonIndexDocumentWebKeys: Readonly<Array<keyof SermonIndexDocumentWeb>> = Object.freeze([
  'id',
  'date',
  'description',
  'playlistIds',
  'references',
  'slug',
  'speakerIds',
  'speakers',
  'tagIds',
  'title',
  'updatedAt',
]);
