import React from 'react';
import { Node } from 'slate';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import { withPlaceholder } from '../utils/withPlaceholder';

declare module '@nucleus/sermon-theme-elements' {
  interface NotesEditorElements {
    title?: {
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
      letterSpacing?: string;
    };
  }
}

export const TitleElement = ({ attributes, children, element }: RenderElementProps): JSX.Element => {
  return (
    <H2 placeholder={element.placeholder} $showPlaceholder={Node.string(element) === ''} {...attributes}>
      {children}
    </H2>
  );
};

const H2 = withPlaceholder(styled.h2.attrs(({ theme }) => ({
  className: theme._sermonThemeElements.NotesEditor?.elements?.title?.className,
}))`
  font-size: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.title?.fontSize};
  color: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.title?.color};
  line-height: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.title?.lineHeight};
  letter-spacing: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.title?.letterSpacing};
  margin: 0;
  margin-bottom: 15px;
`);
