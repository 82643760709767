import React from 'react';

/** Represents a Play icon "triangle",  used on Sermon Media player actions toolbar */
export const IconPlay = (props: React.SVGAttributes<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" {...props}>
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M1.056 19.763A2 2 0 0 1 0 18.001V1.999A2 2 0 0 1 3.109.336l12 8a2 2 0 0 1 0 3.328l-12 8a2 2 0 0 1-2.053.1Z"
      />
    </svg>
  );
};
