import { inverseAspectRatioAsPercent } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { MediaItemBackground } from '../components/Background';
import { BlockProps } from '../types/component';

export const BlockCollage = (props: BlockProps): JSX.Element => {
  const media1 = props.block?.mediaItems?.[0];
  const media2 = props.block?.mediaItems?.[1];
  const media3 = props.block?.mediaItems?.[2];

  return (
    <Collage className={classNames([props.className, nucleusClass('block-collage')])}>
      <CollageItem>
        <MediaItemBackground background={media1} />
      </CollageItem>
      <CollageItem>
        <MediaItemBackground background={media2} />
      </CollageItem>
      <CollageItem>
        <MediaItemBackground background={media3} />
      </CollageItem>
    </Collage>
  );
};

const Collage = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'photo1 photo2'
    'photo3 photo3';

  & :nth-child(1) {
    grid-area: photo1;
  }

  & :nth-child(2) {
    grid-area: photo2;
    --color-media-background: var(--color-media-background2);
  }

  & :nth-child(3) {
    grid-area: photo3;
    padding-bottom: ${inverseAspectRatioAsPercent(2, 1)};
    --color-media-background: var(--color-media-background3);
  }

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'photo1 photo2 photo3';

    & :nth-child(3) {
      padding-bottom: ${inverseAspectRatioAsPercent(1, 1)};
    }
  }
`;

const CollageItem = styled.div`
  width: 100%;
  padding-bottom: ${inverseAspectRatioAsPercent(1, 1)};
  position: relative;
`;
