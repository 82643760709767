import { ImageWeb } from '@nucleus/types/media/image';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatSrcSet } from '../lib/media';
import { DurationBadge } from './DurationBadge';
import { Thumbnail } from './Thumbnail';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    MediaGridItem?: {
      color?: string;
    };
  }
}

interface Props {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  isCurrentlyPlaying?: boolean;
  duration?: React.ReactNode;
  hoverButton?: React.ReactNode;
  currentlyPlayingButton?: React.ReactNode;
  image?: ImageWeb;
  style?: React.CSSProperties;
  to: string;
}

export const MediaGridItem = (props: Props): JSX.Element => {
  return (
    <Container className={props.className} style={props.style}>
      <Link to={props.to}>
        <ThumbnailContainer>
          <Thumbnail
            src={props.image?.src}
            srcSet={formatSrcSet(props.image?.srcSet)}
            blurHash={props.image?.blurHash}
          />
          {props.isCurrentlyPlaying ? (
            <>
              <SolidColorOverlay />
              {props.currentlyPlayingButton && <ButtonSecondary>{props.currentlyPlayingButton}</ButtonSecondary>}
            </>
          ) : (
            <>
              <LinearGradient />
              {props.hoverButton && <Button>{props.hoverButton}</Button>}
            </>
          )}
        </ThumbnailContainer>
      </Link>
      <Meta>
        {props.children}
        <Actions>{props.actions}</Actions>
      </Meta>
    </Container>
  );
};

const Container = styled.div`
  color: ${({ theme }) => theme._sermonThemeElements.MediaGridItem?.color};
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Meta = styled.div`
  position: relative;
  padding-right: 50px;
`;

const Button = styled.div`
  transform: translateY(50%);
  opacity: 0;

  transition-property: transform, opacity;
  transition-duration: 600ms, 500ms;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1), cubic-bezier(0.19, 1, 0.22, 1);
`;

const ButtonSecondary = styled.div``;

const LinearGradient = styled.div`
  background-image: linear-gradient(0deg, #000000, transparent);
  opacity: 0;

  transition: opacity 900ms cubic-bezier(0.23, 1, 0.32, 1);

  pointer-events: none;
`;

const SolidColorOverlay = styled.div`
  background: #000;
  opacity: 75%;

  pointer-events: none;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  ${Button}, ${ButtonSecondary} {
    position: absolute;
  }

  ${LinearGradient}, ${SolidColorOverlay} {
    position: absolute;
    inset: 0;
  }

  :hover {
    ${Button} {
      transform: translateY(0);
      opacity: 1;
      transition-duration: 600ms, 150ms;
    }

    ${LinearGradient} {
      opacity: 0.9;
    }
  }

  ${DurationBadge} {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
  }
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
`;
