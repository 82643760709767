import { ThemeColorPaletteBuilder } from '../ThemeConfigBuilder';
import { serviceName } from '../constants';

const builder = new ThemeColorPaletteBuilder({
  id: `${serviceName}_stoneycreek `,
  title: 'Stoney Creek 🪨',
  description:
    'Cool, crisp, and calm, this palette is as inviting as bubbling brook. Try it out and customize it to make it your own.',
  lightMode: {
    color1: '#FFFFFF',
    color2: '#EEEEEE',
    color3: '#176FC1',
    color4: '#05142B',
    color5: '#FAFAFA',
    color6: '#176FC1',
    colorLight: '#FFFFFF',
    colorDark: '#05142B',
  },
});

export default builder.build();
