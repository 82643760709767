import { Alignment, InfoButtonsLayout, InfoButtonsWidth, ListItemWeb } from '@nucleus/types/web';
import { inverseAspectRatioAsPercent } from '@nucleus/web-theme';
import { SIZE, Text } from '@nucleus/web-theme-elements';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BlockMedia } from '../blocks/BlockMedia';
import { hasText } from '../lib/richtext';
import { Body, Byline, Headline, Overline } from './Base';
import { ButtonPrimary } from './Button';
import { InfoButtons } from './InfoButtons';

type Props = {
  className?: string;
  itemBodyAlignment?: Alignment;
  itemButtonAlignment?: Alignment;
  itemButtonsLayout?: InfoButtonsLayout;
  itemButtonsWidth?: InfoButtonsWidth;
  itemHeadlineAlignment?: Alignment;
  items: Array<ListItemWeb>;
  truncateAt?: number;
};

export const StandardList = styled(({ className, items = [], truncateAt, ...props }: Props): JSX.Element => {
  const [truncate, setTruncate] = useState<number | undefined>(truncateAt);
  const showMore = truncateAt !== undefined && truncate !== undefined;

  const haveMedia = items.some((item) => item.mediaItems?.[0]?.image !== undefined);

  const handleShowMoreClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setTruncate(undefined);
  };

  return (
    <StandardListContainer className={className}>
      {items.slice(0, truncate ?? items.length).map((item) => {
        const renderMedia = item.mediaItems?.[0]?.image !== undefined;
        const titlePlacement = haveMedia === true ? 'content' : 'header';

        return (
          <ListItem
            key={item.id}
            itemBodyAlignment={props.itemBodyAlignment}
            itemHeadlineAlignment={props.itemHeadlineAlignment}
          >
            <ListItemOuter $collapseRow={renderMedia === false && titlePlacement !== 'header'}>
              <ListItemHeader>
                {renderMedia && <BlockMedia media={item.mediaItems?.[0]} />}
                {titlePlacement === 'header' && (
                  <>
                    {hasText(item.overline) && <ListOverline nodes={item.overline} />}
                    {hasText(item.headline) && <ListHeadline nodes={item.headline} />}
                  </>
                )}
              </ListItemHeader>

              <ListItemInner>
                <ListItemInfo>
                  {titlePlacement === 'content' && (
                    <>
                      {hasText(item.overline) && <ListOverline nodes={item.overline} />}
                      {hasText(item.headline) && <ListHeadline nodes={item.headline} />}
                    </>
                  )}
                  {hasText(item.byline) && <ListByline nodes={item.byline} />}
                  {hasText(item.body) && <ListBody nodes={item.body} />}
                </ListItemInfo>
                <ListItemButtons>
                  <InfoButtons
                    buttonsAlignment={props.itemButtonAlignment}
                    buttonsWidth={props.itemButtonsWidth}
                    buttonsLayout={props.itemButtonsLayout}
                    buttons={item.buttons}
                  />
                </ListItemButtons>
              </ListItemInner>
            </ListItemOuter>
          </ListItem>
        );
      })}

      {truncateAt && (
        <TruncateActions>
          {showMore && (
            <ButtonPrimary widthMode="full" onClick={handleShowMoreClick}>
              Show more
            </ButtonPrimary>
          )}
        </TruncateActions>
      )}
    </StandardListContainer>
  );
})``;

const ListOverline = styled(Overline).attrs({ className: Text.ClassName['label6'] })`
  && {
    margin: 0;
  }
`;

const ListHeadline = styled(Headline).attrs({ className: Text.ClassName['headline5'] })`
  && {
    margin: 0;
    line-height: 1.2em;
  }
`;

const ListByline = styled(Byline).attrs({ className: Text.ClassName['label3'] })`
  && {
    font-weight: bold;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
`;

const ListBody = styled(Body).attrs({ className: Text.ClassName['paragraph3'] })`
  && {
    > :first-child {
      margin-top: 1.2em;
    }

    & > :not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
`;

export const ListItemOuter = styled.div<{ $collapseRow: boolean }>`
  &&& {
    row-gap: ${({ $collapseRow }) => $collapseRow && 0};
  }
`;

export const ListItemInner = styled.div``;

const ListItemHeader = styled.div``;

const ListItemInfo = styled.div``;

const ListItemButtons = styled.div`
  & > div {
    margin: 0;
  }
`;

const ListItem = styled.div<{
  itemBodyAlignment?: Alignment;
  itemHeadlineAlignment?: Alignment;
}>`
  --list-item--column-gap: max(calc(5 * var(--min-unit-length)), calc(2 * var(--unit-length)));

  ${ListItemOuter} {
    display: flex;
    flex-wrap: wrap;
    gap: max(calc(2.25 * var(--min-unit-length)), calc(2 * var(--unit-length))) var(--list-item--column-gap);

    @media (min-width: 600px) {
      flex-wrap: nowrap;
    }
  }

  ${ListItemInner} {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    gap: max(calc(3 * var(--min-unit-length)), calc(1 * var(--unit-length))) var(--list-item--column-gap);
    width: 100%;

    @media (min-width: 1200px) {
      flex-wrap: nowrap;
    }
  }

  ${ListItemHeader} {
    flex: 1 0.25 30rem;
    width: 100%;

    & > :first-child > :first-child {
      margin-top: 0;
    }
  }

  ${BlockMedia} {
    & > div {
      padding-top: ${inverseAspectRatioAsPercent(3, 2)};
    }

    @media (max-width: 600px) {
      max-width: 85%;
    }
  }

  ${ListItemInfo} {
    flex: 1 1 auto;
    width: 100%;

    @media (max-width: 1200px) {
      max-width: 620px;
    }

    @media (max-width: 600px) {
      max-width: 385px;
    }

    & > :first-child > :first-child {
      margin-top: 0;
    }
  }

  ${ListItemButtons} {
    align-self: flex-start;
    flex: 1 0 24rem;
    width: 100%;
  }

  ${ListHeadline}, ${ListOverline}, ${ListByline} {
    text-align: ${({ itemHeadlineAlignment }) => itemHeadlineAlignment};
  }

  ${ListBody} {
    text-align: ${({ itemBodyAlignment }) => itemBodyAlignment};
  }
`;

const StandardListContainer = styled.div`
  color: var(--color-section-text);
  display: flex;
  flex-direction: column;
  gap: max(calc(5 * var(--min-unit-length)), calc(2 * var(--unit-length))) 0;
`;

const TruncateActions = styled.div`
  padding: ${SIZE[4]} 0;
`;
