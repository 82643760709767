import styled from 'styled-components';

export const MediaGrid = styled.div`
  --min-item-size: 250px;
  --column-gap: 18px;
  --row-gap: 36px;
  display: grid;
  grid-template-rows: auto;
  column-gap: var(--column-gap);
  row-gap: var(--row-gap);
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--min-item-size), calc((100% - var(--row-gap)) / 3)), 1fr));
`;
