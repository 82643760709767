import { SectionDataWeb } from '@nucleus/types/web';
import React, { useContext } from 'react';
import { MarkupComponent, MarkupRegistryContext } from './MarkupRegistryContext';

interface MarkupRegistryProviderProps {
  children: React.ReactNode;
  register?: Array<[string, MarkupComponent]>;
  sectionRenderErrorData?: SectionDataWeb;
}
export const MarkupRegistryProvider = ({ register = [], ...props }: MarkupRegistryProviderProps): JSX.Element => {
  let { getComponentMap } = useContext<MarkupRegistryContext>(MarkupRegistryContext);

  let existingComponents;
  if (getComponentMap !== undefined) {
    existingComponents = getComponentMap();
  }

  const markupComponents = new Map(existingComponents);

  register.forEach(([key, component]) => markupComponents.set(key, component));

  const context: MarkupRegistryContext = {
    getComponentMap: () => markupComponents,

    register: (key: string, markupComponent: MarkupComponent) => markupComponents.set(key, markupComponent),

    getByType: (key: string) => markupComponents.get(key),

    sectionRenderErrorData: props.sectionRenderErrorData,
  };

  return <MarkupRegistryContext.Provider value={context}>{props.children}</MarkupRegistryContext.Provider>;
};
