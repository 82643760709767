import { SermonMediaSectionWeb } from '@nucleus/types/web/sections/SermonMediaSection';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { BlockLayout } from '../../blockLayouts/Base';
import { BlockSermonMedia } from '../../blocks/BlockSermonMedia';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SectionLayout } from '../../sectionLayouts/SectionLayout';

export const SermonMediaSection = (): JSX.Element => {
  const section = useSection<SermonMediaSectionWeb>();

  const blockProps = { ...section.payload };
  delete blockProps.backgroundMedia;

  return (
    <Section className={classNames(nucleusClass('section-sermon-media'), getSectionClasses(section))}>
      <SectionLayout {...section.payload} height="small">
        <BlockLayout {...section.payload}>
          <BlockSermonMedia {...section.payload} block={section.payload.blocks[0]} />
        </BlockLayout>
      </SectionLayout>
    </Section>
  );
};
