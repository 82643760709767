import { SermonSearchController, useSermonHubPath } from '@nucleus/sermon-theme-elements';
import { SermonEngine } from '@nucleus/sermons';
import React, { useCallback } from 'react';
import { useSearchParams } from '../hooks/useSearchParams';

export const SearchParam = 'query';

interface SearchUrlControllerProps {
  children: React.ReactNode;
  engineId?: SermonEngine['id'];
}
export const SermonSearchUrlController = ({ children, ...props }: SearchUrlControllerProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams({ include: [SearchParam] });

  const onChange = useCallback((value: string) => {
    setSearchParams.replace({ [SearchParam]: value });
  }, []);

  const value = getSingleValue(searchParams[SearchParam], '');

  return (
    <SermonSearchController
      engineId={props?.engineId ?? ''}
      basePath={useSermonHubPath(props.engineId)}
      value={value}
      onChange={onChange}
    >
      {children}
    </SermonSearchController>
  );
};

const getSingleValue = (value: string | string[], fallback: string): string => {
  if (value === undefined) {
    return fallback;
  }

  return Array.isArray(value) ? value[0] : value;
};
