import { CodeSnippet, CodeSnippetWeb, CodeSnippetWebKeys } from '@nucleus/sites/src/types/CodeSnippet';
import { pick as _pick } from 'lodash';
import { useContext } from 'react';
import { DataResourceContext } from '../components/data/dataResourceContext';

const usePageCodeSnippets = (): CodeSnippetWeb[] => {
  const { page } = useContext(DataResourceContext);
  const codeSnippets = (page?.codeSnippets ?? [])
    .filter(isEnabled)
    .map((snippet) => _pick(snippet, CodeSnippetWebKeys));

  return codeSnippets;
};

export const usePageCssSnippets = (): CodeSnippetWeb[] => {
  const codeSnippets = usePageCodeSnippets();
  const cssSnippets = codeSnippets.filter((snippet) => snippet.language === 'css');

  return cssSnippets;
};

export const usePageJavascriptSnippets = (): CodeSnippetWeb[] => {
  const codeSnippets = usePageCodeSnippets();
  const jsSnippets = codeSnippets.filter((snippet) => snippet.language === 'javascript');

  return jsSnippets;
};

export const usePageHtmlSnippets = (): CodeSnippetWeb[] => {
  const codeSnippets = usePageCodeSnippets();
  const htmlSnippets = codeSnippets.filter((snippet) => snippet.language === 'html');

  return htmlSnippets;
};

function isEnabled(snippet: CodeSnippet): boolean {
  return snippet.enabled === true;
}
