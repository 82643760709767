/* eslint-disable @typescript-eslint/no-empty-interface */
import { useCallback } from 'react';

declare global {
  interface ReactEventMap {}
  interface WindowEventMap extends ReactEventMap {}
}

type ExtractPayload<K extends keyof ReactEventMap> = ReactEventMap[K] extends CustomEvent
  ? ReactEventMap[K]['detail']
  : never;

type EventDispatch<K extends keyof ReactEventMap> = (payload: ExtractPayload<K>) => boolean;

/**
 * Use Window/Global Event Dispatch
 */
export const useEventDispatch = <K extends keyof ReactEventMap>(type: K): EventDispatch<K> => {
  return useCallback((payload) => window.dispatchEvent(new CustomEvent(type, { detail: payload })), [type]);
};
