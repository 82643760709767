import { Overlay, OverlayType } from '@nucleus/types/web/overlay';
import { useSection } from '@nucleus/web-theme';
import React from 'react';
import { LeadersOverlay } from './LeadersOverlay';

export const SectionOverlayRenderer = (): JSX.Element | null => {
  const section = useSection();

  if (section.payload.overlays === undefined) {
    return null;
  }

  return (
    <>
      {Object.values(section.payload.overlays).map((overlay) =>
        React.createElement(OverlayTypeMap[overlay.type], {
          key: overlay.id,
          ...overlay,
        })
      )}
    </>
  );
};

const OverlayTypeMap: Record<OverlayType, React.FunctionComponent<Overlay>> = {
  leader: LeadersOverlay,
};
