import React from 'react';
import styled from 'styled-components';

declare module '@nucleus/react-components' {
  interface ReactComponentsTheme {
    MenuItemButton?: {
      background?: string;
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
    };
  }
}

export interface MenuItemButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

/**
 * The `MenuItemButton` component is used to create a button for menu items with optional left and right content.
 */
export const MenuItemButton = ({ children, left, right, ...props }: MenuItemButtonProps): JSX.Element => {
  return (
    <Button {...props}>
      {left && <Left>{left}</Left>}
      {children}
      {right && <Right>{right}</Right>}
    </Button>
  );
};

const Button = styled.button.attrs((props) => ({
  className: props.theme._reactComponents.MenuItemButton?.className,
}))`
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  font-size: ${({ theme }) => theme._reactComponents.MenuItemButton?.fontSize};
  line-height: ${({ theme }) => theme._reactComponents.MenuItemButton?.lineHeight};
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
  height: 40px;
  text-decoration: none;
  padding: 0 12px;
  margin: 0;

  color: ${({ theme }) => theme._reactComponents.MenuItemButton?.color};
  cursor: pointer;
  transition:
    transform 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.1),
    box-shadow 0.1s ease-out;
  white-space: nowrap;
  border: none;
  outline: none;
  appearance: none;
  width: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme._reactComponents.MenuItemButton?.background ?? 'none'};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 6px;
    background: currentColor;
    opacity: 0;
    transition: opacity 25ms cubic-bezier(0, 0, 1, 1);
  }

  @media (hover: hover) {
    :hover:before {
      opacity: 0.05;
      transition-duration: 0s;
    }
  }

  :active:before {
    opacity: 0.1;
    transition-duration: 0s;
  }

  &.active {
    color: ${({ theme }) => theme._reactComponents.MenuItemButton?.color};
    :before {
      opacity: 0.1;
    }

    @media (hover: hover) {
      :hover:before {
        opacity: 0.15;
        transition-duration: 0s;
      }
    }

    :active:before {
      opacity: 0.2;
      transition-duration: 0s;
    }
  }
`;

const Left = styled.span`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Right = styled.span`
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
