import React from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationContext } from '../application/ApplicationContext';

interface PageLoaderProps {
  pathname?: string;
  children: React.ReactNode;
}
export const PageLoader = ({ pathname, children }: PageLoaderProps): JSX.Element => {
  const { DataResourceProvider } = useContext(ApplicationContext);
  const location = useLocation();

  return (
    <DataResourceProvider key={pathname ?? location.pathname} path={pathname ?? location.pathname}>
      {children}
    </DataResourceProvider>
  );
};
