import React from 'react';
import { useParams } from 'react-router-dom';
import { Renderer } from '../components/content/ContentRenderer';
import { useSermonHub } from '../controllers/SermonHubController';
import { useSermonPage } from '../hooks/useSermonPage';

export const SermonPage = (): JSX.Element => {
  const { '*': slug } = useParams();

  return <SermonPageLoader key={slug} slug={slug} />;
};

const SermonPageLoader = ({ slug }: { slug?: string }) => {
  const { engineId } = useSermonHub();
  const { isLoading, data, error } = useSermonPage(engineId, slug ?? '');

  return <Renderer isLoading={isLoading} page={data?.page} error={error} />;
};
