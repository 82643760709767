import styled, { css } from 'styled-components';

interface Props {
  /** Alignment of children relative to the horizontal rule(s) */
  align?: 'left' | 'center' | 'right';
}

export const HorizontalRule = styled.div<Props>`
  display: flex;
  flex-direction: ${({ align }) => (align === 'right' ? 'row-reverse' : 'row')};
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;

  > * {
    flex: 0 1 max-content;
  }

  ${({ align }) =>
    align === 'center' &&
    css`
      ::before {
        content: '';
        background-color: currentColor;
        flex: 1 1 0px;
        height: 1px;
        width: 100%;
      }
    `}

  ::after {
    content: '';
    background-color: currentColor;
    flex: 1 1 0px;
    height: 1px;
    width: 100%;
  }
`;
