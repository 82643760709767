import { ThemeColorPaletteBuilder } from '../ThemeConfigBuilder';
import { serviceName } from '../constants';

const builder = new ThemeColorPaletteBuilder({
  id: `${serviceName}_pioneertown`,
  title: 'Pioneertown 🏜️',
  description:
    'Somewhere out in the desert, terracotta tones contrast with the sandy shades of the earth, and the rays of sun as it sets. ',
  lightMode: {
    color1: '#F0E6D8',
    color2: '#E5DCD2',
    color3: '#A25339',
    color4: '#FBFAF9',
    color5: '#383838',
    color6: '#BF5636',
    colorLight: '#FBFAF9',
    colorDark: '#242424',
  },
});

export default builder.build();
