import { MediaWeb } from '@nucleus/types/web';
import { BackgroundComposition } from '@nucleus/web-theme-elements';
import React from 'react';

type BackgroundMediaProps = {
  background?: MediaWeb;
  fallbackColor?: string;
};

export const SectionBackground = ({
  background,
  fallbackColor = 'var(--color-section-background)',
}: BackgroundMediaProps): JSX.Element => {
  return <BackgroundComposition background={background} baseClassName="background" fallbackColor={fallbackColor} />;
};

export const MediaItemBackground = ({
  background,
  fallbackColor = 'var(--color-media-background)',
}: BackgroundMediaProps): JSX.Element => {
  return <BackgroundComposition background={background} baseClassName="media" fallbackColor={fallbackColor} />;
};

export const CardBackground = ({
  background,
  fallbackColor = 'var(--color-section-background)',
}: BackgroundMediaProps): JSX.Element => {
  return <BackgroundComposition background={background} baseClassName="background" fallbackColor={fallbackColor} />;
};

export const CardMediaItemBackground = ({
  background,
  fallbackColor = 'var(--color-dark)',
}: BackgroundMediaProps): JSX.Element => {
  return <BackgroundComposition background={background} baseClassName="media" fallbackColor={fallbackColor} />;
};
