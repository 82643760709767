import { MarkupRegistryContext } from '@nucleus/web-theme/src/components/markup/MarkupRegistryContext';
import React, { useContext } from 'react';
import styled from 'styled-components';

export const Loading = (): JSX.Element => {
  const { getByType } = useContext(MarkupRegistryContext);

  const LoadingComponent = getByType('loading');

  if (LoadingComponent === undefined) {
    return <>Loading...</>;
  }

  return (
    <StyledWrapper>
      <LoadingComponent />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100vh;

  --color-loading-spinner: var(--color-dark);

  background: var(--color-light);
`;
