import { SermonBlockWeb } from '@nucleus/types/web';
import invariant from 'invariant';
import React, { createContext, useContext } from 'react';

const Context = createContext<ReturnType<typeof useController>>(null!);
Context.displayName = 'SermonBlockController';

type Props = {
  children: React.ReactNode;
} & Options;

export const SermonBlockController = ({ children, ...props }: Props): JSX.Element => {
  return <Context.Provider value={useController(props)}>{children}</Context.Provider>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSermonBlockController = () => {
  invariant(
    useContext(Context) !== null,
    'useSermonBlockController() may be used only in the context of a <SermonBlockController> object'
  );
  return useContext(Context);
};

interface Options {
  layout?: SermonBlockWeb['layout'];
}

const useController = (options: Options) => {
  const [currentLayout, setCurrentLayout] = React.useState(options.layout ?? 'grid');

  return {
    currentLayout: currentLayout,
    onLayoutChange: setCurrentLayout,
  };
};
