import { PageWeb } from '@nucleus/types/web';
import { SectionsProvider } from '@nucleus/web-theme';
import { ContentLoader } from '@nucleus/web-theme/src/components/content/ContentLoader';
import { SectionErrorBoundary } from '@nucleus/web-theme/src/components/sections/SectionErrorBoundary';
import React from 'react';

interface RendererProps {
  isLoading: boolean;
  error?: any;
  page?: PageWeb;
}
export const Renderer = ({ isLoading, ...props }: RendererProps): JSX.Element => {
  if (isLoading) {
    return <>Loading...</>;
  } // TODO: Use Theme loader

  if (props.error) {
    return <>An error has occurred: {props.error.message ?? 'unknown'}</>;
  } // TODO: Use theme error component

  if (props.page === undefined) {
    return <>Page not provided</>;
  } // TODO: show theme 404 page

  const { sections, content } = props.page;

  return (
    <SectionErrorBoundary fallbackRender={() => <>Error rendering component</>}>
      <SectionsProvider sections={sections}>
        {content.main.map((item) => (
          <ContentLoader key={item.id} data={item} />
        ))}
      </SectionsProvider>
    </SectionErrorBoundary>
  );
};
