import { Position } from '@nucleus/types/web';
import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockSermon } from '../../blocks/BlockSermon';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { BlockLayoutProps } from '../../types/component';
import { SermonBlockLayoutProps, getPositionRow } from './SermonLayout';

export const SermonBlockLayoutContentOnly = (props: SermonBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const row = getPositionRow(props.blockInfoPosition);

  return (
    <StyledBlockLayout {...props} row={row}>
      <SpacerTop />
      <StyledContainer>
        <StyledBlockSermon {...innerProps} block={props.blocks[0]} />
        <StyledSpacerBottom />
      </StyledContainer>
    </StyledBlockLayout>
  );
};

const SermonHeightMap = {
  small: 1,
  medium: 2,
  large: 3,
};

const getSermonPadding = (props: BlockLayoutProps) => {
  const defaultHeight = 'small';
  const multiplier = SermonHeightMap[props.height ?? defaultHeight];

  return css`
    padding: calc(var(--unit-length) * ${multiplier}) var(--unit-length);
  `;
};

const StyledBlockSermon = styled(BlockSermon)``;
const StyledContainer = styled.div``;
const StyledSpacerBottom = styled(SpacerTop)``;

const StyledBlockLayout = styled(BlockLayout)<{ row: Position }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledSpacerBottom} {
    display: ${(props) => (props.row === Position.Center ? 'block' : 'none')};
    display: ${(props) => (props.height === 'small' ? 'none' : undefined)};
  }

  ${StyledBlockSermon} {
    ${getSermonPadding}
  }

  ${StyledContainer} {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    justify-content: ${(props) => {
      switch (props.row) {
        case Position.Top:
          return 'flex-start';
        case Position.Center:
          return 'center';
        case Position.Bottom:
          return 'flex-end';
        default:
          return;
      }
    }};
  }
`;
