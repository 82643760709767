import { useIsomorphicLayoutEffect } from '@nucleus/react-components';
import { SectionAction, SectionActionType } from '@nucleus/types/web';
import { NavigationItemHosted } from '@nucleus/web-hosting';
import { useAuthentication } from '@nucleus/web-theme';
import { SIZE } from '@nucleus/web-theme-elements';
import { Auth } from 'aws-amplify';
import { sum as _sum } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useActionProps } from '../hooks/useActionProps';
import { HeaderBlockLayoutProps } from '../sections/Header/HeaderLayout';
import { ButtonNavPrimary, ButtonNavSecondary } from './Button';
import { IconHamburgerMenu, IconX } from './Icons';

export type NavigationProps = HeaderBlockLayoutProps & { topOffset: number };

export const NavigationTraditional = (props: NavigationProps): JSX.Element | null => {
  if (props.navigation === undefined || props.navigation.items.length < 0) {
    return null;
  }

  return (
    <Nav>
      <ResponsiveList items={props.navigation.items}>
        {props.blocks[0].buttons?.map((action) => (
          <ActionItem key={action.id} action={action} />
        ))}
      </ResponsiveList>
    </Nav>
  );
};

const ResponsiveList = (props: { children: React.ReactNode; items: NavigationItemHosted[] }) => {
  const [maxItems, ref] = useResponsiveNavRef(props.items);

  const showMoreButton = maxItems === Infinity || (maxItems !== 0 && props.items.length > maxItems);
  const showMobileMenu = maxItems === Infinity || maxItems === 0;

  return (
    <UnorderedList ref={ref}>
      {props.items.slice(0, maxItems).map((item) => (
        <NavItem key={item.id} {...item} />
      ))}
      {showMoreButton && (
        <ListItem>
          <ButtonNavSecondary openOnHover dropdownItems={props.items.slice(maxItems).map(getButtonProps)}>
            More
          </ButtonNavSecondary>
        </ListItem>
      )}
      {props.children}
      {showMobileMenu && (
        <ListItem>
          <ButtonNavPrimary
            style={{ width: '5rem' }}
            dropdownItems={props.items.map(getButtonProps)}
            icon={<MenuIndicator />}
          />
        </ListItem>
      )}
    </UnorderedList>
  );
};

const useResponsiveNavRef = (items: NavigationItemHosted[]) => {
  const [maxItems, setMaxItems] = useState(Infinity);
  const elementRef = useRef<HTMLUListElement>(null);
  const childrenWidths = useRef<number[]>([]);

  const measureChildren = useCallback(() => {
    if (elementRef.current === null) {
      return;
    }

    const children = [...elementRef.current.children];

    children.forEach((element, index) => {
      childrenWidths.current[index] = element.getBoundingClientRect().width;
    });
  }, []);

  const calculateMaxItems = useCallback(() => {
    if (elementRef.current === null) {
      return;
    }

    const parentWidth = elementRef.current.getBoundingClientRect().width;
    const itemsWidth = _sum(childrenWidths.current.slice(0, items.length));
    const moreElementWidth = childrenWidths.current[items.length];
    const additionalElementsWidth = _sum(childrenWidths.current.slice(items.length + 1));
    const availableSpace = parentWidth - additionalElementsWidth;

    if (itemsWidth < availableSpace) {
      setMaxItems(items.length);
      return;
    }

    let sum = 0;
    let count = 0;

    for (const width of childrenWidths.current.slice(0, items.length)) {
      sum += width;
      if (sum > availableSpace - moreElementWidth) {
        if (count < items.length) {
          break;
        }
      }
      count++;
    }

    setMaxItems(count);
  }, []);

  useIsomorphicLayoutEffect(() => {
    measureChildren();
    calculateMaxItems();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculateMaxItems);
    return () => window.removeEventListener('resize', calculateMaxItems);
  }, []);

  return [maxItems, elementRef] as const;
};

const NavItem = (props: { style?: React.CSSProperties } & NavigationItemHosted) => {
  return (
    <ListItem style={props.style}>
      <ButtonNavSecondary {...getButtonProps(props)}>{props.title}</ButtonNavSecondary>
    </ListItem>
  );
};

const getButtonProps = (item: NavigationItemHosted): any => {
  const items = item.items !== undefined && item.items.length > 0 ? item.items.map(getButtonProps) : undefined;

  if (item.type === 'page') {
    return {
      forwardedAs: NavLink,
      to: item.payload.destination,
      title: item.title,
      openOnHover: true,
      dropdownItems: items,
    };
  }

  if (item.type === 'none') {
    return {
      title: item.title,
      openOnHover: true,
      dropdownItems: items,
    };
  }

  return {
    forwardedAs: 'a',
    href: item.payload.destination,
    title: item.title,
    target: item.payload.openInNewTab === true ? '_blank' : undefined,
    openOnHover: true,
    dropdownItems: items,
  };
};

const ActionItem = (props: { action: SectionAction }): JSX.Element => {
  const [isLoggedIn] = useAuthentication();

  const handleLogout = useCallback((): void => {
    Auth.signOut();
  }, []);

  if (props.action.type === SectionActionType.SignIn && isLoggedIn === true) {
    return (
      <ListItem>
        <ButtonNavPrimary onClick={handleLogout}>Sign Out</ButtonNavPrimary>
      </ListItem>
    );
  }

  return (
    <ListItem>
      <ButtonNavPrimary {...useActionProps(props.action)}>{props.action.title}</ButtonNavPrimary>
    </ListItem>
  );
};

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: ${SIZE[1]};
  width: 100%;
`;

const UnorderedList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  width: 100%;

  & > * {
    padding-right: ${SIZE[1]};
  }

  & > *:last-child {
    padding-right: 0;
  }
`;

const ListItem = styled.li`
  list-style: none;
  position: relative;
`;

const MenuIndicator = (props: { isOpen?: boolean }) => {
  if (props.isOpen) {
    return <IconX />;
  }
  return <IconHamburgerMenu />;
};
