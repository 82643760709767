import { SermonRevisionWeb } from '@nucleus/sermons';
import { SermonMediaItemWeb } from '@nucleus/sermons/types/Sermon';
import invariant from 'invariant';
import { sortBy as _sortBy } from 'lodash';
import React, { createContext, useContext } from 'react';

const Context = createContext<ReturnType<typeof useController>>(null!);
Context.displayName = 'SermonMediaBlockController';

type Props = {
  children: React.ReactNode;
} & Options;

export const SermonMediaBlockController = ({ children, ...props }: Props): JSX.Element => {
  return <Context.Provider value={useController(props)}>{children}</Context.Provider>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSermonMediaBlockController = () => {
  invariant(
    useContext(Context) !== null,
    'useSermonMediaBlockController() may be used only in the context of a <SermonMediaBlockController> object'
  );
  return useContext(Context);
};

interface Options {
  sermon?: SermonRevisionWeb;
}

const useController = (options: Options) => {
  const sortedMedia = sortMediaItems(Object.values(options.sermon?.mediaItems ?? {}));

  const [currentMediaType, setCurrentMediaType] = React.useState<SermonMediaItemWeb['type']>(sortedMedia[0]?.type);

  return {
    availableMedia: sortedMedia.map((item) => item.type),
    currentMedia: sortedMedia.find((item) => item.type === currentMediaType),
    onMediaChange: setCurrentMediaType,
  };
};

const sortMediaItems = (mediaItems?: SermonMediaItemWeb[]): SermonMediaItemWeb[] => {
  if (mediaItems === undefined) {
    return [];
  }

  const PriorityOrder: Record<SermonMediaItemWeb['type'], number> = {
    'embed/video': 1,
    'liveStream/link': 2,
    'file/audio': 3,
  };

  return _sortBy(mediaItems, (item) => PriorityOrder[item.type]);
};
