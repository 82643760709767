import { Interpolation, InterpolationFunction, StyledProps } from 'styled-components';
import { ReactComponentsThemeCornerStyle } from '../../types/ReactComponentsTheme';

type CornerStyleMap = {
  [P in ReactComponentsThemeCornerStyle]?: Interpolation<StyledProps<unknown>>;
};

type InterPolationFactory = <P = unknown>(map: CornerStyleMap) => InterpolationFunction<StyledProps<P>>;

/**
 * Factory function for generating a styled-components interpolation function
 * based on a provided mapping of ReactComponentsThemeCornerStyle values.
 *
 * @param {CornerStyleMap} map - Mapping of
 * ReactComponentsThemeCornerStyle values to corresponding styled-components interpolations.
 *
 * @returns {InterPolationFactory} An interpolation
 * function that retrieves the appropriate interpolation based on the current theme's
 * SermonThemeElements.Global.cornerStyle value.
 */
export const cornerStyleInterpolationFactory: InterPolationFactory = (map) => {
  return ({ theme }) => {
    return map[theme._reactComponents.Global?.cornerStyle];
  };
};
