import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';

declare module '@nucleus/sermon-theme-elements' {
  interface NotesEditorElements {
    link?: {
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
      letterSpacing?: string;
    };
  }
}

export const LinkElement = ({ attributes, children, element }: RenderElementProps): JSX.Element => {
  return (
    <Link href={element.href} target="_blank" rel="noopener noreferrer" {...attributes}>
      {children}
    </Link>
  );
};

const Link = styled.a.attrs(({ theme }) => ({
  className: theme._sermonThemeElements.NotesEditor?.elements?.link?.className,
}))`
  font-size: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.link?.fontSize};
  color: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.link?.color ?? 'inherit'};
  line-height: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.link?.lineHeight};
  letter-spacing: ${({ theme }) => theme._sermonThemeElements.NotesEditor?.elements?.link?.letterSpacing};
  margin: 0;
`;
