import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatDistanceToNow,
  formatISO,
  getDate,
  Interval,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subHours,
  subMilliseconds,
  subMonths,
  subWeeks,
} from 'date-fns';

export type DateRange = Interval;
// branded type for ISO8601 date strings
export type Iso8601 = string & { __type: 'Iso8601' };
// branded type for IANA timezone strings
export type IanaTimezoneName = string & { __type: 'IanaTimezone' };

const isoToDate = (value: Iso8601): Date => {
  const date = parseISO(value);
  if (isValid(date) !== true) {
    throw new Error(`"${value}" is not a valid ISO8601 date string`);
  }

  return date;
};

const safeIsoToDate = (value: Iso8601 | Date | number): Date => {
  if (value instanceof Date) {
    return value;
  }

  if (typeof value === 'number') {
    return new Date(value);
  }

  return isoToDate(value);
};

const dateToIso = (date: Date): Iso8601 => {
  return formatISO(date) as Iso8601;
};

const safeDateToIso = (date: Date | number): Iso8601 => {
  if (typeof date === 'number') {
    date = new Date(date);
  }

  return dateToIso(date);
};

function getTimezoneCode(timezoneString: IanaTimezoneName): string {
  if (timezoneString === undefined) {
    return '';
  }
  const options: Intl.DateTimeFormatOptions = { timeZone: timezoneString, timeZoneName: 'short' };
  const parts = new Intl.DateTimeFormat([], options).formatToParts();
  if (parts === undefined) {
    return '';
  }
  const [timeZoneCode] = parts.find((part) => part.type === 'timeZoneName')?.value.split(' ') ?? []; // only return first part of timezone code

  return timeZoneCode;
}

export const DateApi = {
  isAfter: isAfter,
  isBefore: isBefore,
  isoToDate: safeIsoToDate,
  dateToIso: safeDateToIso,
  format: format,
  formatDistanceToNow: formatDistanceToNow,
  getDate: getDate,
  addMonths: addMonths,
  addWeeks: addWeeks,
  addDays: addDays,
  addHours: addHours,
  subMonths: subMonths,
  subWeeks: subWeeks,
  subDays: subDays,
  subHours: subHours,
  subMilliseconds: subMilliseconds,
  startOfDay: startOfDay,
  startOfWeek: startOfWeek,
  startOfMonth: startOfMonth,
  endOfDay: endOfDay,
  endOfWeek: endOfWeek,
  endOfMonth: endOfMonth,
  isSameDay: isSameDay,
  differenceInCalendarDays: differenceInCalendarDays,
  getTimezoneCode: getTimezoneCode,
};
