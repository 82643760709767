import styled from 'styled-components';

export const MediaStrip = styled.div<{ flexBasis?: string }>`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  gap: 12px;
  height: 100%;
  width: 100%;

  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 0 0;
    flex-basis: ${({ flexBasis }) => flexBasis};
    height: 100%;
    scroll-snap-coordinate: left;
    scroll-snap-align: start;
  }
`;
