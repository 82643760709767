export const BlendMode = {
  None: 'none',
  Multiply: 'multiply',
  Screen: 'screen',
  Overlay: 'overlay',
  Darken: 'darken',
  Lighten: 'lighten',
  ColorDodge: 'color-dodge',
  ColorBurn: 'color-burn',
  HardLight: 'hard-light',
  SoftLight: 'soft-light',
  Difference: 'difference',
  Exclusion: 'exclusion',
  Hue: 'hue',
  Saturation: 'saturation',
  Color: 'color',
  Luminosity: 'luminosity',
} as const;

export type BlendMode = typeof BlendMode[keyof typeof BlendMode];

export const Fade = {
  None: 'none',
  Top: 'top',
  Bottom: 'bottom',
  Left: 'left',
  Right: 'right',
} as const;

export type Fade = typeof Fade[keyof typeof Fade];

export type Filter =
  | 'none'
  | 'blur'
  | 'sepia'
  | 'grayscale'
  | 'invert'
  | 'brighten'
  | 'darken'
  | 'vibrant'
  | 'muted'
  | 'duotone';

export type Texture = 'none' | 'grid-small' | 'dots-small';
