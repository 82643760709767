import { SermonIndexDocumentWeb } from '@nucleus/sermons/types/Sermon';
import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';

export const useSermonPath = (basePath: string): ((sermon: SermonIndexDocumentWeb) => string) => {
  return useCallback(
    (sermon: SermonIndexDocumentWeb) => {
      return generatePath(':basePath/:sermonSlug', {
        basePath: basePath,
        sermonSlug: sermon.slug,
      });
    },
    [basePath]
  );
};
