import styled from 'styled-components';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    CollectionListItem?: {
      color?: string;
      background?: string;
    };
  }
}

export const CollectionListItem = styled.div`
  background: ${({ theme }) => theme._sermonThemeElements.CollectionListItem?.background};
  color: ${({ theme }) => theme._sermonThemeElements.CollectionListItem?.color};
  border-radius: 10px;
  padding: 30px 24px;
`;
