import { useCallback, useEffect, useRef, useState } from 'react';

export interface UseDocumentScrollCallbackValue {
  currentScrollTop: number;
}

export type UseDocumentScrollCallback = (value: UseDocumentScrollCallbackValue) => void;

export type UseDocumentScroll = (callback: UseDocumentScrollCallback, isEnabled?: boolean) => number;

export const useDocumentScroll: UseDocumentScroll = (callback, isEnabled = true) => {
  const timeout = useRef<number>();
  const [scrollTop, setScrollTop] = useState(0);

  const handleDocumentScroll = useCallback(() => {
    if (timeout.current) {
      window.cancelAnimationFrame(timeout.current);
    }

    timeout.current = window.requestAnimationFrame(() => {
      const { scrollTop: currentScrollTop } = document.documentElement || document.body;
      callback({ currentScrollTop });
      setScrollTop(currentScrollTop);
    });
  }, [callback]);

  useEffect(() => {
    if (isEnabled === false) {
      return;
    }

    window.addEventListener('scroll', handleDocumentScroll);

    return () => window.removeEventListener('scroll', handleDocumentScroll);
  }, [handleDocumentScroll]);

  return scrollTop;
};
