import React from 'react';
import styled, { css } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withPlaceholder = <T extends React.ComponentType<any>>(Component: T) => {
  return styled(Component)<{ $showPlaceholder?: boolean; placeholder?: string }>`
    ${({ $showPlaceholder, placeholder }) =>
      $showPlaceholder &&
      placeholder !== undefined &&
      css`
        :before {
          display: inline;
          content: attr(placeholder);
          opacity: 0.25;
          pointer-events: none;
        }

        position: relative;

        > * {
          position: absolute !important;
          inset: 0;
        }
      `}
  `;
};
