import { usePageLoading } from '@nucleus/web-theme';
import { buildTransparentColorFromCssVariable } from '@nucleus/web-theme-elements';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { LoadingSpinner } from './LoadingSpinner';

interface PageLoadingProps {
  children: React.ReactNode;
}
export const PageLoading = (props: PageLoadingProps): JSX.Element => {
  const [loading] = usePageLoading();

  if (loading === true) {
    return (
      <>
        <LoadingPageStyles />
        <LoadingSpinner />
      </>
    );
  }

  return <>{props.children}</>;
};

const LoadingPageStyles = createGlobalStyle`
  #root#root {
    --color-loading-spinner: var(--color-dark);

    background: var(--color-light);
    background-image: linear-gradient(113deg, var(--color-light) 15%, ${buildTransparentColorFromCssVariable(
      '--color-dark',
      0.1
    )} 85%);
  }
`;
