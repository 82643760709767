import {
  Publishable,
  PublishableEntity,
  PublishableRevision,
  PublishableSchedule,
  PublishableVersion,
} from '@nucleus/publishable';
import { PublishableRevisionApi } from '@nucleus/publishable/types/PublishableRevision';
import { ActionItem, ActionItemApi } from '@nucleus/types/action';

export const NavigationAction = {
  Email: 'email',
  File: 'file',
  Flow: 'flow',
  Giving: 'giving',
  InfoCard: 'infoCard',
  None: 'none',
  Page: 'page',
  Phone: 'phone',
  Prayer: 'prayer',
  Sermons: 'sermons',
  SignIn: 'signIn',
  Url: 'url',
} as const;

export type NavigationAction = (typeof NavigationAction)[keyof typeof NavigationAction];

export interface NavigationItem extends ActionItem<NavigationAction> {
  children?: Array<NavigationItem>;
}

export type NavigationItemApi = ActionItemApi<NavigationAction> & {
  children?: Array<NavigationItemApi>;
};

export type NavigationEntity = PublishableEntity;

export type NavigationVersion = PublishableVersion;

export type NavigationSchedule = PublishableSchedule;

export interface NavigationRevision extends PublishableRevision {
  children: NavigationItem[];
}

export interface NavigationRevisionApi extends PublishableRevisionApi {
  children: NavigationItemApi[];
}

export type PublishableNavigation = Publishable<NavigationRevision, NavigationEntity>;
export type PublishableNavigationApi = Publishable<NavigationRevisionApi, NavigationEntity>;
