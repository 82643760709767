import { ThemeColorPaletteBuilder } from '../ThemeConfigBuilder';
import { serviceName } from '../constants';

const builder = new ThemeColorPaletteBuilder({
  id: `${serviceName}_blackWhite`,
  title: 'Black & White 🤵🏻‍♂️',
  description:
    'Two-toned and grayscale, this palette is clean and open, putting the focus on your content with a starker, contrast-y look.',
  lightMode: {
    color1: '#FFFFFF',
    color2: '#000000',
    color3: '#EEEEEE',
    color4: '#7F7F7F',
    color5: '#FFFFFF',
    color6: '#FFFFFF',
    colorLight: '#FFFFFF',
    colorDark: '#000000',
  },
});

export default builder.build();
