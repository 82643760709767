import { FocalPoint, ImageWeb } from '@nucleus/types/media/image';

export function aspectRatioAsPercent(width?: number, height?: number): string {
  return convertNumberToPercent(aspectRatio(width, height));
}

export function inverseAspectRatioAsPercent(width?: number, height?: number): string {
  return aspectRatioAsPercent(height, width);
}

export const aspectRatioAsPercentFromImage = (image?: ImageWeb): string => {
  const size = sizeFromImage(image);
  return aspectRatioAsPercent(size.width, size.height);
};

export const inverseAspectRatioAsPercentFromImage = (image?: ImageWeb): string => {
  const size = sizeFromImage(image);
  return inverseAspectRatioAsPercent(size.width, size.height);
};

export const orientationFromImage = (image?: ImageWeb): 'landscape' | 'portrait' => {
  const size = sizeFromImage(image);
  if (isValidSize(size)) {
    return size.width > size.height ? 'landscape' : 'portrait';
  }
  return 'portrait'; // invalid size, assume portrait
};

function sizeFromImage(image?: ImageWeb): { width?: number; height?: number } {
  return { width: image?.dimensions?.width, height: image?.dimensions?.height };
}

function aspectRatio(width?: number, height?: number): number {
  const size = { width: width, height: height };
  if (isValidSize(size)) {
    return size.width / size.height;
  }
  return 1;
}

function convertNumberToPercent(value: number): string {
  return value * 100 + '%';
}

type Size = {
  width: number;
  height: number;
};

export function isValidSize(value?: any): value is Size {
  return isPositiveNumber(value?.width) && isPositiveNumber(value?.height);
}

export function isPositiveNumber(value?: any): value is number {
  return Number.isFinite(value) && value > 0;
}

export function convertFocalPointToBackgroundPositionCss(focalPoint?: FocalPoint): string {
  if (focalPoint === undefined) {
    return 'center';
  }
  let { x, y } = focalPoint;
  // check for undefined values and set fallbacks
  if (x === undefined) {
    x = 0.5;
  }
  if (y === undefined) {
    y = 0.5;
  }
  // if x and y are both between 0 and 1, return the percentages
  if (x >= 0 && y >= 0 && x <= 1 && y <= 1) {
    return `${x * 100}% ${y * 100}%`;
  }
  return 'center';
}

interface ParsedHtmlTag {
  tagName: string;
  attributes: Record<string, string>;
  content: string | null;
}

export function parseHtmlTags(htmlString: string): ParsedHtmlTag[] {
  // This function parses HTML tags and their attributes and content from a string
  // WARNING: This function is not intended to be a full HTML parser, but rather a simple way to parse
  // the HTML tags and attributes that we need to support in the HTML Snippets feature for head code.

  // 'tagRegex' is intended to match two types of HTML tags:
  // 1. An opening and closing tag with optional attributes and content between them.
  // 2. An opening tag with optional attributes but no closing tag.
  // Developed here: https://regexr.com/7cag5
  const tagRegex = /<\s*([a-z0-9]+)([\s\S]*?)(?=\/?>)(\/?>)?(?:([\s\S]*?)(?=<\/\1>)(<\/\1>))?/gi;

  // 'attributeRegex' is intended to match an attribute name and optional value.
  // The attribute value can be wrapped in single or double quotes or not wrapped at all.
  // Developed here: https://regexr.com/7cajh
  const attributeRegex = /([a-z-]+)(?:\s*=\s*(?:("|')(.*?)\2))?/gi;
  const tags: ParsedHtmlTag[] = [];

  let match: RegExpExecArray | null;
  while ((match = tagRegex.exec(htmlString)) !== null) {
    const tagName = match[1];
    const attributesString = match[2];
    const isSelfClosing = match[3] === '/>';

    const attributes: Record<string, string> = {};
    let attributeMatch: RegExpExecArray | null;
    while ((attributeMatch = attributeRegex.exec(attributesString)) !== null) {
      attributes[attributeMatch[1]] = attributeMatch[3] ?? '';
    }

    const content = isSelfClosing ? null : match[4] ?? null; // Self-closing tags have no content
    tags.push({
      tagName,
      attributes,
      content,
    });
  }

  return tags;
}
