import { useCallback, useLayoutEffect, useState } from 'react';

export const useBoundingClientRect = <T extends HTMLElement>(): [(node: T) => void, DOMRect, T | null] => {
  const [node, setNode] = useState<T | null>(null);
  const [boundingClientRect, setBoundingClientRect] = useState<DOMRect>({} as DOMRect);

  const ref = useCallback((node: T) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node === null) {
      return;
    }

    let rafId: number;

    const measure = () => {
      rafId = window.requestAnimationFrame(() => setBoundingClientRect(getDimensionObject(node)));
    };

    measure();

    window.addEventListener('resize', measure);

    return () => {
      window.removeEventListener('resize', measure);
      window.cancelAnimationFrame(rafId);
    };
  }, [node]);

  return [ref, boundingClientRect, node];
};

const getDimensionObject = (node: HTMLElement): DOMRect => {
  return node.getBoundingClientRect();
};
