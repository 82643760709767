import React from 'react';
import styled from 'styled-components';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    MediaGridItemBadge?: {
      className?: string;
      color?: string;
      background?: string;
    };
  }
}

interface Props {
  children?: React.ReactNode;
}

export const MediaGridItemBadge = (props: Props): JSX.Element => {
  return <Badge>{props.children}</Badge>;
};

const Badge = styled.div.attrs((props) => ({
  className: props.theme._sermonThemeElements.MediaGridItemBadge?.className,
}))`
  color: ${({ theme }) => theme._sermonThemeElements.MediaGridItemBadge?.color};
  background: ${({ theme }) => theme._sermonThemeElements.MediaGridItemBadge?.background};
  border: 2px solid currentColor;
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    pill: '25px',
    rounded: '10px',
  })};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  user-select: none;
  cursor: pointer;
  height: 50px;
  margin: 0;
  padding: 0 21px;
  backdrop-filter: blur(14px);
`;
