import { objectKeys } from '@nucleus/src/lib/object';
import { SectionDataWeb } from '@nucleus/types/web';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import { get } from 'lodash';

const keyMap = {
  type: 'type',
  'payload.height': 'height',
  'payload.blockLayout': 'block-layout',
  'payload.blockLayoutVariant': 'block-layout-variant',
};
export const getSectionClasses = (section: SectionDataWeb): string => {
  const classes = objectKeys(keyMap).reduce((acc: Array<string>, key) => {
    const value = get(section, key);
    if (value === undefined) {
      return acc;
    }

    return [...acc, nucleusClass(`${keyMap[key]}-${value}`)];
  }, []);

  return classNames(classes);
};
