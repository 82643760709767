import { ASSET_BUCKET_NAME } from '@nucleus/src-platform/constant/legacy';
import { SectionTypeEnum, ThemeConfig } from '@nucleus/types/web';
import { CardItemDefaults, ListItemDefaults, SectionPayloadDefaults } from '../src/defaults';
import { buildCssVariablesForColorPaletteAndVariations, buildThemeColorsVariationClassName } from '../src/utils/styles';
import { ThemeConfigBuilder } from './ThemeConfigBuilder';
import button1 from './buttons/button1';
import button2 from './buttons/button2';
import button3 from './buttons/button3';
import button4 from './buttons/button4';
import BlackWhite from './colorpalettes/BlackWhite';
import GoldenAndGrounded from './colorpalettes/GoldenAndGrounded';
import LifesABeach from './colorpalettes/LifesABeach';
import LongView from './colorpalettes/LongView';
import PioneerTown from './colorpalettes/PioneerTown';
import ShoreLine from './colorpalettes/ShoreLine';
import SpringInAlaska from './colorpalettes/SpringInAlaska';
import StoneyCreek from './colorpalettes/StoneyCreek';
import { serviceName } from './constants';
import { cardBackground } from './presetmedia/cardbackground';
import { cardMediaFull } from './presetmedia/cardmediafull';
import { cardMediaInline } from './presetmedia/cardmediainline';
import { leaderCardMediaFull } from './presetmedia/leadercardmediafull';
import { leaderCardMediaInline } from './presetmedia/leadercardmediainline';
import { listMedia } from './presetmedia/listmedia';
import { sectionBackground } from './presetmedia/sectionbackground';
import { sectionMedia } from './presetmedia/sectionmedia';
import CalendarConfig from './sections/CalendarConfig';
import CardConfig from './sections/CardConfig';
import EmbedConfig from './sections/EmbedConfig';
import GalleryConfig from './sections/GalleryConfig';
import InfoConfig from './sections/InfoConfig';
import LeaderConfig from './sections/LeaderConfig';
import ListConfig from './sections/ListConfig';
import SermonConfig from './sections/SermonConfig';
import SermonMediaConfig from './sections/SermonMediaConfig';
import SermonSearchRequestConfig from './sections/SermonSearchRequestConfig';
import SermonSearchResultsConfig from './sections/SermonSearchResultsConfig';

const builder = new ThemeConfigBuilder({
  name: 'Life',
  id: serviceName,
  buildCssVariablesForColorPaletteAndVariations: buildCssVariablesForColorPaletteAndVariations,
  buildColorPaletteVariationClassName: buildThemeColorsVariationClassName,
});

builder
  .addButtonConfig('button1', button1)
  .addButtonConfig('button2', button2)
  .addButtonConfig('button3', button3)
  .addButtonConfig('button4', button4);

builder
  .addColorPalette(BlackWhite)
  .addColorPalette(LongView)
  .addColorPalette(StoneyCreek)
  .addColorPalette(ShoreLine)
  .addColorPalette(LifesABeach)
  .addColorPalette(PioneerTown)
  .addColorPalette(GoldenAndGrounded)
  .addColorPalette(SpringInAlaska);

builder.setPreviewColors({
  mediaBackground: 'var(--color-media-background)',
  sectionBackground: 'var(--color-section-background)',
  cardBackground: 'var(--color-section-background)',
  cardMediaBackground: 'var(--color-dark)',
});

builder.addFile('LightBokeh', {
  bucket: ASSET_BUCKET_NAME ?? '',
  key: `${serviceName}/light-bokeh.jpg`,
});

builder.addSection(SectionTypeEnum.Info, InfoConfig).enabled().available();
builder.addSection(SectionTypeEnum.Card, CardConfig).enabled().available();
builder.addSection(SectionTypeEnum.List, ListConfig).enabled().available();
builder.addSection(SectionTypeEnum.Gallery, GalleryConfig);
builder.addSection(SectionTypeEnum.Embed, EmbedConfig).enabled().available();
builder.addSection(SectionTypeEnum.Calendar, CalendarConfig).enabled().available();
builder.addSection(SectionTypeEnum.Leader, LeaderConfig).enabled().available();
builder.addSection(SectionTypeEnum.Sermon, SermonConfig).enabled().available();
builder.addSection(SectionTypeEnum.SermonMedia, SermonMediaConfig);
builder.addSection(SectionTypeEnum.SermonSearchResults, SermonSearchResultsConfig);
builder.addSection(SectionTypeEnum.SermonSearchRequest, SermonSearchRequestConfig);

builder.setSectionDefaults(SectionPayloadDefaults);
builder.setCardItemDefaults(CardItemDefaults);
builder.setListItemDefaults(ListItemDefaults);

builder
  .addPresetMedia('cardBackground', cardBackground)
  .addPresetMedia('cardMediaFull', cardMediaFull)
  .addPresetMedia('listMedia', listMedia)
  .addPresetMedia('leaderCardMediaFull', leaderCardMediaFull)
  .addPresetMedia('leaderCardMediaInline', leaderCardMediaInline)
  .addPresetMedia('cardMediaInline', cardMediaInline)
  .addPresetMedia('sectionBackground', sectionBackground)
  .addPresetMedia('sectionMedia', sectionMedia);

export const LifeV1ThemeConfig = (): ThemeConfig => builder.build();
