import { MediaList } from '@nucleus/sermon-theme-elements';
import { Position } from '@nucleus/types/web';
import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';
import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockInfo } from '../../blocks/BlockInfo';
import { BlockSermon } from '../../blocks/BlockSermon';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { SermonBlockLayoutProps, getPositionRow } from './SermonLayout';
import { MediaExpandedListItem } from '@nucleus/sermon-theme-elements';

export const SermonBlockLayoutHorizontal = (props: SermonBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const itemsOnTheLeft = props.blockLayoutVariant === 'variant1';
  const row = getPositionRow(props.blockInfoPosition);

  return (
    <StyledBlockLayout {...props} itemsOnTheLeft={itemsOnTheLeft} row={row}>
      <StyledInfoColumn>
        <BlockInfo {...innerProps} block={props.blocks[0]} />
      </StyledInfoColumn>
      <StyledSermonColumn>
        <SpacerTop />
        <StyledBlockSermon {...innerProps} block={props.blocks[0]} />
      </StyledSermonColumn>
    </StyledBlockLayout>
  );
};

const itemsOnTheLeft = booleanPropHelperFactory('itemsOnTheLeft');

const StyledBlockSermon = styled(BlockSermon)``;
const StyledHorizontalColumn = styled.div``;
const StyledInfoColumn = styled(StyledHorizontalColumn)``;
const StyledSermonColumn = styled(StyledHorizontalColumn)``;

const StyledBlockLayout = styled(BlockLayout)<{ itemsOnTheLeft: boolean; row: Position }>`
  flex-direction: row;
  flex-wrap: wrap;

  ${StyledHorizontalColumn} {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    flex: 1 1 auto;
  }

  ${StyledSermonColumn} {
    ${SpacerTop} {
      display: none;
    }

    justify-content: ${(props) => {
      switch (props.row) {
        case Position.Top:
          return 'flex-start';
        case Position.Center:
          return 'center';
        case Position.Bottom:
          return 'flex-end';
        default:
          return;
      }
    }};
  }

  ${StyledBlockSermon} {
    padding: var(--unit-length);
    padding-top: ${(props) => (props.spacing?.top ? 'calc(var(--unit-length) * 0.5)' : undefined)};
    padding-bottom: ${(props) => (props.spacing?.bottom ? 'calc(var(--unit-length) * 0.5)' : undefined)};
  }

  ${MediaExpandedListItem.Media} {
    flex-basis: 600px;
  }

  ${MediaExpandedListItem.Meta} {
    flex-basis: 400px;
  }

  ${itemsOnTheLeft(css`
    ${StyledInfoColumn} {
      order: 2;
    }
    ${StyledSermonColumn} {
      order: 1;
    }
  `)}

  /* NOTE: media.tabletLandscape */
  @media (max-width: 799px) {
    ${StyledInfoColumn} {
      min-height: ${(props) => {
        switch (props.height) {
          case 'medium':
            return '40vh';
          case 'large':
            return '70vh';
          default:
            return undefined;
        }
      }};
    }
  }

  @media (min-width: 800px) {
    flex-wrap: nowrap;
    ${StyledHorizontalColumn} {
      width: 50%;
    }

    ${StyledSermonColumn} {
      ${SpacerTop} {
        display: block;
      }
    }

    ${MediaList} {
      --global-font-factor: 0.5;
    }
  }
`;
