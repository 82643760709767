import { PageWeb } from '@nucleus/types/web';
import { createContext, ReactNode } from 'react';

export interface DataResourceProviderProps {
  children: ReactNode;
  path: string;
}

export interface DataResourceContext {
  loading: boolean;
  page: PageWeb | undefined;
}

export const DataResourceContext = createContext<DataResourceContext>({} as DataResourceContext);
