import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockInfo, blockInfoHasContent } from '../../blocks/BlockInfo';
import { BlockMedia } from '../../blocks/BlockMedia';
import { BlockSermon } from '../../blocks/BlockSermon';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { BlockLayoutProps } from '../../types/component';
import { SermonBlockLayoutProps } from './SermonLayout';

export const SermonBlockLayoutVertical = (props: SermonBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout {...props} renderInfo={renderInfo}>
      <SpacerTop />
      {renderInfo && <StyledBlockInfo {...innerProps} block={props.blocks[0]} />}
      <StyledBlockSermon {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const SermonHeightMap = {
  small: 1,
  medium: 2,
  large: 3,
};

const getSermonPadding = (props: BlockLayoutProps) => {
  const defaultHeight = 'small';
  const multiplier = SermonHeightMap[props.height ?? defaultHeight];

  return css`
    padding: calc(var(--unit-length) * ${multiplier}) var(--unit-length);
  `;
};

const StyledBlockInfo = styled(BlockInfo)``;
const StyledBlockMedia = styled(BlockMedia)``;
const StyledBlockSermon = styled(BlockSermon)``;

const StyledBlockLayout = styled(BlockLayout)<{
  renderInfo: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledBlockMedia} {
    position: relative;
    z-index: 1;

    background-color: transparent;

    & > div {
      padding-top: ${(props) => {
        switch (props.height) {
          case 'large':
            return '30vh';
          case 'small':
            return '20%';
          case 'medium':
          default:
            return '30vh';
        }
      }};

      @media (min-width: 600px) {
        padding-top: ${(props) => (props.height === 'large' ? '40vh' : undefined)};
      }
    }
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${StyledBlockSermon} {
    ${getSermonPadding}
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};
  }
`;
