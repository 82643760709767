import { CardSectionWeb } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SectionLayout } from '../../sectionLayouts/SectionLayout';
import { CardBlockLayoutCollage } from './CardBlockLayoutCollage';
import { CardBlockLayoutContentOnly } from './CardBlockLayoutContentOnly';
import { CardBlockLayoutCover } from './CardBlockLayoutCover';
import { CardBlockLayoutHorizontal } from './CardBlockLayoutHorizontal';
import { CardBlockLayoutLongText } from './CardBlockLayoutLongText';
import { CardBlockLayoutVertical } from './CardBlockLayoutVertical';
import { CardBlockLayoutProps } from './CardLayout';

export const CardSection = (): JSX.Element => {
  const section = useSection<CardSectionWeb>();

  const blockProps = { ...section.payload };
  delete blockProps.backgroundMedia;

  return (
    <Section className={classNames(nucleusClass('section-card'), getSectionClasses(section))}>
      <SectionLayout {...section.payload}>
        <BlockLayout {...blockProps} />
      </SectionLayout>
    </Section>
  );
};

const BlockLayout = (props: CardBlockLayoutProps) => {
  switch (props.blockLayout) {
    case 'layout1':
      return <CardBlockLayoutHorizontal {...props} />;
    case 'layout2':
      return <CardBlockLayoutVertical {...props} />;
    case 'layout3':
      return <CardBlockLayoutCollage {...props} />;
    case 'layout4':
      return <CardBlockLayoutLongText {...props} />;
    case 'layout5':
      return <CardBlockLayoutCover {...props} />;
    case 'layout6':
      return <CardBlockLayoutContentOnly {...props} />;
    default:
      return <CardBlockLayoutHorizontal {...props} />;
  }
};
