import { SectionConfigBuilder } from '../ThemeConfigBuilder';
import { body, byline, headline, overline } from '../fields/fields';

export default new SectionConfigBuilder()
  .setContext('picker', {
    label: 'Gallery',
    description: 'Elegant and effortless photo displays ',
    icon: 'Card',
  })
  .setContext('editor', {
    label: 'Gallery Section',
    description: '',
    icon: 'Card',
  })
  .addLayout('layout1', (layout) => {
    layout
      .setIcon('Horizontal')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(1);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Text & Info Placement',
        controlType: 'left right',
        options: [
          {
            value: 'variant1',
            label: 'Left',
          },
          {
            value: 'variant2',
            label: 'Right',
          },
        ],
      });
  })
  .addLayout('layout2', (layout) => {
    layout
      .setIcon('Vertical')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(1);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Text & Info Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout3', (layout) => {
    layout
      .setIcon('Collage')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(3);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Text & Info Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout4', (layout) => {
    layout
      .setIcon('LongText')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockBodyMaxColumns(3);
  })
  .addLayout('layout5', (layout) => {
    layout
      .setIcon('Cover')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout6', (layout) => {
    layout
      .setDescription('Gallery Only')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([]);
        block.setMedia(0);
        block.setActions(0);
      });
  })
  .build();
