import React from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
  children?: React.ReactNode;
  /**
   * The HTML element into which the children will be rendered. If not provided, the default is `document.body`.
   * This can be either an HTML element, a function that returns an HTML element, or `null`.
   */
  container?: HTMLElement | (() => HTMLElement | null) | null;
}

/**
 * The Portal component is used to render children into a specified HTML container element or the document body by default.
 * It provides the ability to control where the content should be rendered in the DOM.
 *
 * @param children - The content or elements to be rendered within the portal.
 * @param container - The HTML element into which the children will be rendered. If not provided, the default is `document.body`.
 *   This can be either an HTML element, a function that returns an HTML element, or `null`.
 * @returns A JSX element representing the portal.
 */
export const Portal = ({ children, container = document.body }: PortalProps): JSX.Element => {
  const [node, setNode] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    setNode(typeof container === 'function' ? container() : container);
  }, [container]);

  return <>{node ? ReactDOM.createPortal(children, node) : node}</>;
};
