import { ImageWeb } from '@nucleus/types/media/image';
import { nucleusClass } from '@nucleus/web-theme-elements';
import { convertFocalPointToBackgroundPositionCss, orientationFromImage } from '@nucleus/web-theme/src/lib/utilities';
import React from 'react';
import styled from 'styled-components';
import { GalleryItem as TGalleryItem } from '../overlays/GalleryOverlay';

const GalleryContainer = styled.div``;

const GalleryInner = styled.div``;

const GalleryGrid = styled.div``;

const GalleryItemImageObject = styled.img<Partial<ImageWeb>>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.focalPoint)};
`;

const GalleryItemBlurHash = styled.div<Partial<ImageWeb>>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-image: filter(url(${(props) => props.blurHash}), blur(40px));
  background-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.focalPoint)};
`;

const GalleryItemContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: '#000';
`;

interface GalleryItemImageProps extends Partial<TGalleryItem> {
  className?: string;
  style?: React.CSSProperties;
}

const GalleryItemImage = styled(({ className, style, image }: GalleryItemImageProps) => {
  return (
    <GalleryItemContainer className={className} style={style}>
      <GalleryItemBlurHash focalPoint={image?.focalPoint} blurHash={image?.blurHash} />
      <GalleryItemImageObject focalPoint={image?.focalPoint} src={image?.src} />
    </GalleryItemContainer>
  );
})``;

const GalleryItemButton = styled.button`
  // remove all button styles
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  appearance: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  // add slight black overlay after on hover
  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;
const GalleryItemSizer = styled.div<{ orientation: 'landscape' | 'portrait' }>`
  height: 0px;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: ${(props) =>
    props.orientation === 'landscape' ? LANDSCAPE_ASPECT_RATIO_PERCENT : PORTRAIT_ASPECT_RATIO_PERCENT};
`;

interface GalleryItemProps extends TGalleryItem {
  className?: string;
  style?: React.CSSProperties;
  orientation: 'landscape' | 'portrait';
}

const GalleryItem = ({ className, style, orientation, onClick, id, image }: GalleryItemProps) => {
  const handleClick = (e: React.MouseEvent) => {
    if (typeof onClick === 'function') {
      onClick(e, id);
    }
  };

  return (
    <GalleryItemButton className={className} style={style} onClick={handleClick}>
      <GalleryItemSizer orientation={orientation}>
        <GalleryItemImage image={image} />
      </GalleryItemSizer>
    </GalleryItemButton>
  );
};

const LANDSCAPE_ASPECT_RATIO_PERCENT = '67%';
const PORTRAIT_ASPECT_RATIO_PERCENT = '133%';

const GridBlock = styled.div`
  display: grid;
  gap: 8px;
`;

interface GridLayoutProps {
  items: Array<TGalleryItem>;
  rowNumber: number;
  onItemClick?: (e: React.MouseEvent, id: string) => void;
}
// L - 1 column grid
const GridLayoutL = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '1 / 1 / auto / auto' }}
      />
    </GridBlock>
  );
};

// LL - 2 column grid
const GridLayoutLL = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '1 / 1 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '1 / 2 / auto / auto' }}
      />
    </GridBlock>
  );
};

// PP - 2 column grid
const GridLayoutPP = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 1 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 2 / auto / auto' }}
      />
    </GridBlock>
  );
};

// PL - 3 column grid
const GridLayoutPL = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 1 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '1 / 2 / auto / span 2' }}
      />
    </GridBlock>
  );
};

// LP - 3 column grid
const GridLayoutLP = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '1 / 1 / auto / span 2' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 3 / auto / auto' }}
      />
    </GridBlock>
  );
};

// LLP - 2 column grid
const GridLayoutLLP = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '1 / 1 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '2 / 1 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[2]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 2 / span 2 / auto' }}
      />
    </GridBlock>
  );
};

// LPL - 2 column grid
const GridLayoutLPL = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '1 / 1 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[2]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 2 / span 2 / auto' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '2 / 1 / auto / auto' }}
      />
    </GridBlock>
  );
};

// PLL - 2 column grid
const GridLayoutPLL = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 1 / span 2 / auto' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '1 / 2 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[2]}
        onClick={props.onItemClick}
        orientation="landscape"
        style={{ gridArea: '2 / 2 / auto / auto' }}
      />
    </GridBlock>
  );
};

// PPP - 3 column grid
const GridLayoutPPPNormal = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 1 / span 2 / span 2' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 3 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[2]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '2 / 3 / auto / auto' }}
      />
    </GridBlock>
  );
};
const GridLayoutPPPReverse = (props: GridLayoutProps): JSX.Element => {
  return (
    <GridBlock style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
      <GalleryItem
        {...props.items[0]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 2 / span 2 / span 2' }}
      />
      <GalleryItem
        {...props.items[1]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '1 / 1 / auto / auto' }}
      />
      <GalleryItem
        {...props.items[2]}
        onClick={props.onItemClick}
        orientation="portrait"
        style={{ gridArea: '2 / 1 / auto / auto' }}
      />
    </GridBlock>
  );
};
const GridLayoutPPP = (props: GridLayoutProps): JSX.Element => {
  const reverseLayout = props.rowNumber % 2 === 0;
  if (reverseLayout === true) {
    return <GridLayoutPPPReverse {...props} />;
  }
  return <GridLayoutPPPNormal {...props} />;
};

// LLL - stacked 1 column and 2 column grids
const GridLayoutLLL = ({ items, ...props }: GridLayoutProps): JSX.Element => {
  return (
    <>
      <GridLayoutL items={items.slice(0, 1)} {...props} />
      <GridLayoutLL items={items.slice(1)} {...props} />
    </>
  );
};

// LPP - stacked 1 column and 2 column grids
const GridLayoutLPP = ({ items, ...props }: GridLayoutProps): JSX.Element => {
  return (
    <>
      <GridLayoutL items={items.slice(0, 1)} {...props} />
      <GridLayoutPP items={items.slice(1)} {...props} />
    </>
  );
};

// PLP - stacked 2 column and 1 column grids
const GridLayoutPLP = ({ items, ...props }: GridLayoutProps): JSX.Element => {
  return (
    <>
      <GridLayoutPP items={[items[0], items[2]]} {...props} />
      <GridLayoutL items={[items[1]]} {...props} />
    </>
  );
};

// PPL - stacked 2 column and 1 column grids
const GridLayoutPPL = ({ items, ...props }: GridLayoutProps): JSX.Element => {
  return (
    <>
      <GridLayoutPP items={items.slice(0, 2)} {...props} />
      <GridLayoutL items={items.slice(2)} {...props} />
    </>
  );
};

const ImageGridLayoutKeys = [
  'P',
  'L',
  'LL',
  'PP',
  'PL',
  'LP',
  'LLP',
  'LPL',
  'PLL',
  'PPP',
  'LLL',
  'LPP',
  'PLP',
  'PPL',
] as const;
type ImageGridLayoutKey = (typeof ImageGridLayoutKeys)[number];

const ORIENTATION_KEY_TO_LAYOUT = {
  P: GridLayoutL, // single portrait too big, use landscape layout
  L: GridLayoutL,
  LL: GridLayoutLL,
  PP: GridLayoutPP,
  PL: GridLayoutPL,
  LP: GridLayoutLP,
  LLP: GridLayoutLLP,
  LPL: GridLayoutLPL,
  PLL: GridLayoutPLL,
  PPP: GridLayoutPPP,
  LLL: GridLayoutLLL,
  LPP: GridLayoutLPP,
  PLP: GridLayoutPLP,
  PPL: GridLayoutPPL,
};

function getLayoutKeyFromImageOrientations(imageOrientations: Array<'landscape' | 'portrait'>): ImageGridLayoutKey {
  return imageOrientations
    .map((str) => str.slice(0, 1))
    .join('')
    .toUpperCase() as ImageGridLayoutKey;
}

const ImageGrid = (props: {
  items: Array<TGalleryItem>;
  onItemClick?: (event: React.MouseEvent, itemId: string) => void;
}): JSX.Element => {
  // split items into groups of 3
  const items = props.items;
  const groups = [];
  for (let i = 0; i < items.length; i += 3) {
    groups.push(items.slice(i, i + 3));
  }

  // create a grid for each group
  const grids = groups.map((group, index) => {
    const imageOrientations = group.map((item) => orientationFromImage(item.image));
    const layoutKey = getLayoutKeyFromImageOrientations(imageOrientations);
    const Layout = ORIENTATION_KEY_TO_LAYOUT[layoutKey];
    const rowNumber = index + 1;
    return (
      <ImageGridGroup key={index} rowNumber={rowNumber}>
        <Layout items={group} rowNumber={rowNumber} onItemClick={props.onItemClick} />
      </ImageGridGroup>
    );
  });

  return <ImageGridContainer>{grids}</ImageGridContainer>;
};

const ImageGridContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;
  padding: 8px;
`;

const ImageGridGroup = styled.div<{ rowNumber: number }>`
  display: grid;
  grid-area: ${(props) => props.rowNumber} / 1 / auto / auto;
  gap: 8px;
`;

export interface GalleryProps {
  items?: Array<TGalleryItem>;
  onItemClick?: (event: React.MouseEvent, itemId: string) => void;
}

const AdaptiveGallery = (props: GalleryProps): JSX.Element => {
  return (
    <GalleryContainer className={nucleusClass('gallery')}>
      <GalleryInner>
        <GalleryGrid>
          {props.items && <ImageGrid {...props} items={props.items} onItemClick={props.onItemClick} />}
        </GalleryGrid>
      </GalleryInner>
    </GalleryContainer>
  );
};

export const Gallery = {
  Adaptive: AdaptiveGallery,
};
