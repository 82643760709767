import { slugifyString } from '@nucleus/src-platform/data/text';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocalization } from '../hooks/useLocalization';
import { NavItem } from './NavItem';
import { NavList } from './NavList';
import { Navbar } from './Navbar';

interface Props {
  children?: React.ReactNode;
}

export const SermonHubNav = (props: Props): JSX.Element => {
  const localize = useLocalization();

  return (
    <Navbar>
      <NavList>
        <NavItem as={NavLink} to="" end>
          {localize('home', 'Home')}
        </NavItem>
        <NavItem as={NavLink} to={slugifyString(localize('playlists', 'Playlists'))}>
          {localize('playlists', 'Playlists')}
        </NavItem>
        <NavItem as={NavLink} to={slugifyString(localize('scripture', 'bible'))}>
          {localize('scripture', 'Scripture')}
        </NavItem>
        <NavItem as={NavLink} to={slugifyString(localize('speakers', 'Speakers'))}>
          {localize('speakers', 'Speakers')}
        </NavItem>
        <NavItem as={NavLink} to={slugifyString(localize('topics', 'Topics'))}>
          {localize('topics', 'Topics')}
        </NavItem>
        {props.children}
      </NavList>
    </Navbar>
  );
};
