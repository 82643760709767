import React, { useContext } from 'react';
import { createContext } from '../../utils/context';
import { CustomElement, CustomRichText, RichTextElement } from '@nucleus/types/richText';

export type RichTextElementComponent<T extends RichTextElement> = (props: {
  children: React.ReactNode;
  node: T;
}) => JSX.Element | null;

export type RichTextElementComponentMap<T extends RichTextElement = RichTextElement> = {
  [P in T['type']]: Extract<T, { type: P }> extends never
    ? RichTextElementComponent<CustomElement>
    : RichTextElementComponent<Extract<T, { type: P }>>;
};

export type RichTextCustomRichTextComponent = (props: { children: React.ReactNode }) => JSX.Element | null;

export type RichTextCustomRichTextComponentMap = {
  [p in keyof Required<Omit<CustomRichText, 'text'>>]: RichTextCustomRichTextComponent;
};

interface Context {
  getElementMap: () => RichTextElementComponentMap;

  getElementByType: (key: keyof RichTextElementComponentMap) => RichTextElementComponent<any>;

  getTextMap: () => RichTextCustomRichTextComponentMap;

  getTextByType: (key: keyof RichTextCustomRichTextComponentMap) => RichTextCustomRichTextComponentMap[typeof key];
}

const [RichTextRegistryContextProvider, useRichTextRegistryProvider, RichTextRegistryContext] = createContext<Context>({
  name: 'RichTextRegistry',
});

export { useRichTextRegistryProvider };

interface RichTextRegistryProviderProps {
  children: React.ReactNode;
  registerElements: Partial<RichTextElementComponentMap>;
  registerText: Partial<RichTextCustomRichTextComponentMap>;
}
export const RichTextRegistryProvider = (props: RichTextRegistryProviderProps): JSX.Element => {
  return (
    <RichTextRegistryContextProvider value={useControllerValue(props.registerElements, props.registerText)}>
      {props.children}
    </RichTextRegistryContextProvider>
  );
};

const useControllerValue = (
  registerElements: RichTextRegistryProviderProps['registerElements'],
  registerText: RichTextRegistryProviderProps['registerText']
): Context => {
  const parentContext = useContext(RichTextRegistryContext);

  const elementMap = {
    ...(parentContext?.getElementMap !== undefined ? parentContext.getElementMap() : {}),
    ...registerElements,
  } as RichTextElementComponentMap;

  const textMap = {
    ...(parentContext?.getTextMap !== undefined ? parentContext.getTextMap() : {}),
    ...registerText,
  } as RichTextCustomRichTextComponentMap;

  return {
    getElementMap: () => elementMap,

    getElementByType: (key: keyof RichTextElementComponentMap) => elementMap[key],

    getTextMap: () => textMap,

    getTextByType: (key: keyof RichTextCustomRichTextComponentMap) => textMap[key],
  };
};
