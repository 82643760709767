import { BlurHash } from '@nucleus/react-components';
import React from 'react';
import styled from 'styled-components';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    Theater?: {
      background?: string;
    };
  }
}

interface Props {
  blurHash?: string;
  children?: React.ReactNode;
  className?: string;
  spacer?: React.ReactNode;
  style?: React.CSSProperties;
}

/**
 * Used to render the backdrop and layout for the Sermon Media section
 */
export const Theater = (props: Props): JSX.Element => {
  return (
    <Curtain className={props.className} style={props.style}>
      {props.blurHash && <StyledBlurHash hash={props.blurHash} />}
      {props.spacer}
      <Stage>{props.children}</Stage>
    </Curtain>
  );
};

const Curtain = styled.div`
  background: ${({ theme }) => theme._sermonThemeElements.Theater?.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const Stage = styled.div`
  width: 100%;
  transform: translateY(18px);
`;

const StyledBlurHash = styled(BlurHash)`
  position: absolute;
  inset: 0;
`;
