import styled from 'styled-components';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    DurationBadge?: {
      background?: string;
      className?: string;
      color?: string;
      fontSize?: string;
      lineHeight?: string;
    };
  }
}

export const DurationBadge = styled.span.attrs((props) => ({
  className: props.theme._sermonThemeElements.DurationBadge?.className,
}))`
  background: ${({ theme }) => theme._sermonThemeElements.DurationBadge?.background};
  color: ${({ theme }) => theme._sermonThemeElements.DurationBadge?.color};
  font-size: ${({ theme }) => theme._sermonThemeElements.DurationBadge?.fontSize};
  line-height: ${({ theme }) => theme._sermonThemeElements.DurationBadge?.lineHeight};
  text-align: right;
  border-radius: 6px;
  opacity: 0.75;
  padding: 9px 15px;
  margin: 0;
`;
