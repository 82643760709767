import { useContext } from 'react';
import { DataResourceContext } from '../components/data/dataResourceContext';

export const usePageLoading = (): [boolean] => {
  const { loading, page } = useContext(DataResourceContext);

  const isLoading = page === undefined && loading;

  return [isLoading];
};
