import { EmbedSectionWeb } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SectionLayout } from '../../sectionLayouts/SectionLayout';
import { EmbedBlockLayoutCollage } from './EmbedBlockLayoutCollage';
import { EmbedBlockLayoutContentOnly } from './EmbedBlockLayoutContentOnly';
import { EmbedBlockLayoutCover } from './EmbedBlockLayoutCover';
import { EmbedBlockLayoutHorizontal } from './EmbedBlockLayoutHorizontal';
import { EmbedBlockLayoutLongText } from './EmbedBlockLayoutLongText';
import { EmbedBlockLayoutVertical } from './EmbedBlockLayoutVertical';
import { EmbedBlockLayoutProps } from './EmbedLayout';

export const EmbedSection = (): JSX.Element => {
  const section = useSection<EmbedSectionWeb>();

  // Background logic HACK: Clean up the maps so this can be less convoluted.
  const isLayout6 = section.payload.blockLayout === 'layout6';
  const isLayoutFull = section.payload.layout === 'full' || section.payload.layout === undefined;

  const layoutProps = { ...section.payload };
  if (isLayoutFull === true && isLayout6 !== true) {
    delete layoutProps.backgroundMedia;
  }

  const blockProps = { ...section.payload };
  if (isLayoutFull !== true) {
    delete blockProps.backgroundMedia;
  }
  // HACK: END

  return (
    <Section className={classNames(nucleusClass('section-embed'), getSectionClasses(section))}>
      <SectionLayout {...section.payload}>
        <BlockLayout {...blockProps} />
      </SectionLayout>
    </Section>
  );
};

const BlockLayout = (props: EmbedBlockLayoutProps) => {
  switch (props.blockLayout) {
    case 'layout1':
      return <EmbedBlockLayoutHorizontal {...props} />;
    case 'layout2':
      return <EmbedBlockLayoutVertical {...props} />;
    case 'layout3':
      return <EmbedBlockLayoutCollage {...props} />;
    case 'layout4':
      return <EmbedBlockLayoutLongText {...props} />;
    case 'layout5':
      return <EmbedBlockLayoutCover {...props} />;
    case 'layout6':
      return <EmbedBlockLayoutContentOnly {...props} />;
    default:
      return <EmbedBlockLayoutHorizontal {...props} />;
  }
};
