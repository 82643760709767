import { Position, PositionRowBottom, PositionRowCenter, PositionRowTop, SectionTypeEnum } from '@nucleus/types/web';
import { BlockLayoutProps } from '../../types/component';

export type CardBlockLayoutProps = Extract<BlockLayoutProps, { sectionType?: SectionTypeEnum.Card }>;

const RowToPositionMap: Array<[ReadonlyArray<Position>, Position]> = [
  [PositionRowTop, Position.Top],
  [PositionRowCenter, Position.Center],
  [PositionRowBottom, Position.Bottom],
];

export const getPositionRow = (position: Position, defaultValue: Position = Position.Center): Position => {
  return (RowToPositionMap.find(([positions]) => positions.includes(position)) ?? [])[1] ?? defaultValue;
};
