import { SermonSectionWeb } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React from 'react';
import { getSectionClasses } from '../../lib/classes';
import { Section } from '../../sectionLayouts/Section';
import { SectionLayout } from '../../sectionLayouts/SectionLayout';
import { SermonBlockLayoutCollage } from './SermonBlockLayoutCollage';
import { SermonBlockLayoutContentOnly } from './SermonBlockLayoutContentOnly';
import { SermonBlockLayoutCover } from './SermonBlockLayoutCover';
import { SermonBlockLayoutHorizontal } from './SermonBlockLayoutHorizontal';
import { SermonBlockLayoutLongText } from './SermonBlockLayoutLongText';
import { SermonBlockLayoutVertical } from './SermonBlockLayoutVertical';
import { SermonBlockLayoutProps } from './SermonLayout';

export const SermonSection = (): JSX.Element => {
  const section = useSection<SermonSectionWeb>();

  const blockProps = { ...section.payload };
  delete blockProps.backgroundMedia;

  return (
    <Section className={classNames(nucleusClass('section-sermon'), getSectionClasses(section))}>
      <SectionLayout {...section.payload} height="small">
        <BlockLayout {...blockProps} />
      </SectionLayout>
    </Section>
  );
};

const BlockLayout = (props: SermonBlockLayoutProps) => {
  switch (props.blockLayout) {
    case 'layout1':
      return <SermonBlockLayoutHorizontal {...props} />;
    case 'layout2':
      return <SermonBlockLayoutVertical {...props} />;
    case 'layout3':
      return <SermonBlockLayoutCollage {...props} />;
    case 'layout4':
      return <SermonBlockLayoutLongText {...props} />;
    case 'layout5':
      return <SermonBlockLayoutCover {...props} />;
    case 'layout6':
      return <SermonBlockLayoutContentOnly {...props} />;
    default:
      return <SermonBlockLayoutHorizontal {...props} />;
  }
};
