import { SectionDataWeb } from '@nucleus/types/web';
import { createContext } from 'react';

export type MarkupComponent = (props: any) => JSX.Element | null;

export interface MarkupRegistryContext {
  getComponentMap: () => Map<string, MarkupComponent>;

  register: (key: string, markupComponent: MarkupComponent) => void;

  getByType: (key: string) => MarkupComponent | undefined;

  sectionRenderErrorData: SectionDataWeb | undefined;
}

export const MarkupRegistryContext = createContext({} as MarkupRegistryContext);
