import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCalendarGrid } from '../../blocks/BlockCalendarGrid';
import { BlockInfo, blockInfoHasContent } from '../../blocks/BlockInfo';
import { BlockMedia } from '../../blocks/BlockMedia';
import { CalendarBlockLayoutProps } from './CalendarLayout';

export const CalendarBlockLayoutVertical = (props: CalendarBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout {...props} renderInfo={renderInfo}>
      <StyledBlockMedia media={props.blocks[0]?.mediaItems?.[0]} />
      {renderInfo && <StyledBlockInfo {...innerProps} block={props.blocks[0]} />}
      <StyledBlockCalendarGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const StyledBlockInfo = styled(BlockInfo)``;
const StyledBlockMedia = styled(BlockMedia)``;
const StyledBlockCalendarGrid = styled(BlockCalendarGrid)``;

const StyledBlockLayout = styled(BlockLayout)<{
  renderInfo: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledBlockMedia} {
    position: relative;
    z-index: 1;

    background-color: transparent;

    & > div {
      padding-top: ${(props) => {
        switch (props.height) {
          case 'large':
            return '30vh';
          case 'small':
            return '20%';
          case 'medium':
          default:
            return '30vh';
        }
      }};

      @media (min-width: 600px) {
        padding-top: ${(props) => (props.height === 'large' ? '40vh' : undefined)};
      }
    }
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${StyledBlockCalendarGrid} {
    padding: var(--unit-length);
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};
  }
`;
