import { CustomRichText, ImageElement, RichTextNode, RichTextNodeArray } from '@nucleus/types/richText';
import { escape as _escape, isPlainObject as _isPlainObject } from 'lodash';

const decorateText = (node: CustomRichText): string => {
  return _escape(node.text);
};

const buildImageNode = (node: ImageElement): string => {
  let image = node.src;

  if (node.href !== undefined) {
    image = [node.href, image].join(' ');
  }

  return image;
};

const serializeNode = (node: RichTextNode): string => {
  if (_isPlainObject(node) && 'text' in node && typeof node.text === 'string') {
    if (node.text === '\n') {
      return node.text;
    }

    return decorateText(node);
  }

  const children = 'children' in node ? node.children.map((n) => serializeNode(n)).join('') : '';

  if (!('type' in node)) {
    return children;
  }

  switch (node.type) {
    case 'link':
      return [node.href, children].join(' ');
    case 'image':
      return buildImageNode(node);
    default:
      return children;
  }
};

export const serializeRichtextToText = (nodes: RichTextNodeArray): string => {
  return nodes.map((node) => serializeNode(node)).join('\n');
};
