// TODO: use this to create transition animations between overlay screens
export function calculateTransitionType(
  oldPath: string,
  newPath: string
): 'none' | 'toParent' | 'toChild' | 'toSibling' {
  // compare paths to determine transition direction
  const oldPathParts = oldPath.split(',');
  const newPathParts = newPath.split(',');
  const oldPathLength = oldPathParts.length;
  const newPathLength = newPathParts.length;
  const isSame = oldPath === newPath;
  const isChild = oldPathLength < newPathLength && oldPathParts.every((part, index) => part === newPathParts[index]);
  const isParent = oldPathLength > newPathLength && newPathParts.every((part, index) => part === oldPathParts[index]);
  const isSibling =
    oldPathLength === newPathLength &&
    newPathParts.every((part, index) => part === oldPathParts[index] || index === newPathLength - 1);
  if (isSame) {
    return 'none';
  }
  if (isChild) {
    return 'toChild';
  }
  if (isParent) {
    return 'toParent';
  }
  if (isSibling) {
    return 'toSibling';
  }
  return 'none';
}
