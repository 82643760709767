import { SpeakerWeb } from '@nucleus/types/web/sermons/speaker';
import { useUser } from '@nucleus/web-theme';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryCollection } from '../queries/collection';

type QueryResult = Awaited<ReturnType<typeof queryCollection<SpeakerWeb>>>;

export const useSpeaker = (engineId: string, slugId: string): UseQueryResult<QueryResult> => {
  const { currentUser, jwtToken } = useUser();

  return useQuery({
    queryKey: ['sermonHub', 'speakers', slugId, currentUser?.id],
    queryFn: () => queryCollection(engineId, 'speakers', slugId ?? '', jwtToken),
    suspense: true,
  });
};
