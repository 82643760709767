import { createContext } from '@nucleus/react-components';
import { SermonIndexDocumentWeb } from '@nucleus/sermons/types/Sermon';
import { flatMap as _flatMap } from 'lodash';
import React from 'react';
import { useSermonPath } from '../hooks/useSermonPath';
import { useSermonSearch } from '../hooks/useSermonSearch';

type UseSermonSearch = ReturnType<typeof useSermonSearch>;

type SermonSearchContext = Pick<UseSermonSearch, 'status' | 'hasNextPage' | 'fetchNextPage'> & {
  getSermonPath: (sermon: SermonIndexDocumentWeb) => string;
  limit: number;
  searchQuery: string;
  sermons: SermonIndexDocumentWeb[];
  setSearchQuery: (searchQuery: string) => void;
  total: number;
};

const [SermonSearchProvider, useSermonSearchController] = createContext<SermonSearchContext>({
  name: 'SermonSearchContext',
});

export { useSermonSearchController };

interface SermonSearchControllerProps extends useControllerProps {
  children: React.ReactNode;
}
export const SermonSearchController = ({ children, ...props }: SermonSearchControllerProps): JSX.Element => {
  return <SermonSearchProvider value={useController(props)}>{children}</SermonSearchProvider>;
};

interface useControllerProps {
  value: string;
  onChange: (value: string) => void;
  basePath: string;
  engineId: string;
  limit?: number;
}
const useController = ({ value, onChange, limit = 6, ...options }: useControllerProps): SermonSearchContext => {
  const { data, status, hasNextPage, fetchNextPage } = useSermonSearch(options.engineId, {
    query: value,
    limit: `${limit}`,
  });

  const getSermonPath = useSermonPath(options.basePath);

  return {
    fetchNextPage: fetchNextPage,
    getSermonPath: getSermonPath,
    hasNextPage: hasNextPage,
    limit: limit,
    searchQuery: value,
    sermons: _flatMap(data?.pages, 'sermons'),
    setSearchQuery: onChange,
    status: status,
    total: data?.pages.at(-1)?.total ?? 0,
  };
};
