/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

export const usePopoverState = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    triggerProps: {
      activated: open,
      onClick: handleClick,
    },
    popoverProps: {
      anchorEl: anchorEl,
      isOpen: open,
      onClose: handleClose,
    },
  };
};
