import { usePageJavascriptSnippets, useSiteJavascriptSnippets } from '@nucleus/web-theme';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEnabledCodeSnippets } from '../hooks/useEnabledCodeSnippets';

export const PageGlobalScripts = (): JSX.Element => {
  const siteJavascriptSnippets = useSiteJavascriptSnippets();
  const pageJavascriptSnippets = usePageJavascriptSnippets();
  const enabledJavascriptSnippets = useEnabledCodeSnippets([...siteJavascriptSnippets, ...pageJavascriptSnippets]);

  return (
    <>
      {enabledJavascriptSnippets.map((snippet) => (
        <Helmet key={snippet.id}>
          <script type="text/javascript" data-snippet-id={snippet.id}>
            {snippet.content}
          </script>
        </Helmet>
      ))}
    </>
  );
};
