import React from 'react';
import styled from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockCalendarGrid } from '../../blocks/BlockCalendarGrid';
import { BlockCollage } from '../../blocks/BlockCollage';
import { BlockInfo, blockInfoHasContent } from '../../blocks/BlockInfo';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { CalendarBlockLayoutProps } from './CalendarLayout';

export const CalendarBlockLayoutCollage = (props: CalendarBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);

  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout {...props} renderInfo={renderInfo}>
      <StyledBlockCollage {...innerProps} block={props.blocks[0]} />
      {renderInfo && <StyledBlockInfo {...innerProps} block={props.blocks[0]} />}
      <StyledBlockCalendarGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const StyledBlockCalendarGrid = styled(BlockCalendarGrid)``;
const StyledBlockCollage = styled(BlockCollage)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)<{
  renderInfo: boolean;
}>`
  ${StyledBlockInfo} {
    ${SpacerTop} {
      display: none;
    }
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${StyledBlockCalendarGrid} {
    padding: var(--unit-length);
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};
  }
`;
