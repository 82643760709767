import styled from 'styled-components';
import { buildTransparentColorFromCssVariable } from './src/utils/styles';
import { SIZE } from './style-utils';

export const MenuDivider = styled.hr`
  box-sizing: border-box;
  appearance: none;
  border: 0;
  border-top: 2px solid ${buildTransparentColorFromCssVariable('--color-dark', 0.2)};
  border-radius: 2px;
  width: ${`calc(100% - ${SIZE[1]})`};
  padding: 0;
  margin: 0.3rem auto;
`;
