import { BlurHash, useIsomorphicLayoutEffect } from '@nucleus/react-components';
import React from 'react';
import styled, { css } from 'styled-components';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    Thumbnail?: {
      background?: string;
    };
  }
}

type Props = React.ImgHTMLAttributes<HTMLImageElement> & { blurHash?: string };

/**
 * Renders a 16:9 Image
 */
export const Thumbnail = styled(({ blurHash, className, style, ...props }: Props): JSX.Element => {
  return (
    <Container className={className} style={style}>
      {blurHash && <StyledBlurHash hash={blurHash} />}
      <Image {...useImageOnLoadProps()} {...props} />
    </Container>
  );
})``;

const useImageOnLoadProps = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const ref = React.useRef<HTMLImageElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      setIsLoaded(ref.current.complete);
    }
  }, []);

  return {
    onLoad: () => setIsLoaded(true),
    isLoaded: isLoaded,
    ref: ref,
  };
};

const Container = styled.div`
  background: ${(props) => props.theme._sermonThemeElements.Thumbnail?.background};
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  border-radius: 10px;
  overflow: hidden;
`;

const Image = styled.img<{ isLoaded?: boolean }>`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;

  ${({ isLoaded }) =>
    isLoaded &&
    css`
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    `}
`;

const StyledBlurHash = styled(BlurHash)`
  position: absolute;
  inset: 0;
`;
