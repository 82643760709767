import { useIsomorphicLayoutEffect } from '@nucleus/react-components';
import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';

export const BodyScrollLock = (): JSX.Element | null => {
  const scrollX = window?.scrollX;
  const scrollY = window?.scrollY;

  const initialState = {
    offsetX: scrollX ?? 0,
    offsetY: scrollY ?? 0,
  };

  const [{ offsetX, offsetY }, setState] = useState(initialState);

  useIsomorphicLayoutEffect(() => {
    setState({
      offsetX: scrollX,
      offsetY: scrollY,
    });

    // Fixme: Lock style needs to be removed from dom before scrollTo is called
    return () => window?.scrollTo(offsetX, offsetY);
  }, []);

  return <LockedStyle offsetX={0} offsetY={scrollY} />;
};

const LockedStyle = createGlobalStyle<{ offsetX: number; offsetY: number }>`
  html {
    overflow: visible !important;

    body {
      overflow: hidden !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
`;
