import { useIsomorphicLayoutEffect } from '@nucleus/react-components';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Branding } from '../../components/Branding';
import { Navbar, NavbarLeft, NavbarRight } from '../../components/Navbar';
import { NavigationMobile, NavigationProps } from '../../components/NavigationMobile';
import { NavigationTraditional } from '../../components/NavigationTraditional';
import { useBodyMarginTop } from '../../hooks/useBodyMarginTop';
import { UseDocumentScrollCallback, useDocumentScroll } from '../../hooks/useDocumentScroll';
import { getDocument, getWindow } from '../../lib/dom';
import { buildThemeColorsVariationClassName } from '../../utils/styles';
import { HeaderBlockLayoutProps } from './HeaderLayout';

export const HeaderBlockLayout = (props: HeaderBlockLayoutProps): JSX.Element => {
  const [isStuck, setIsStuck] = useState(false);
  const bodyMarginTop = useBodyMarginTop();

  const handleDocumentScroll: UseDocumentScrollCallback = useCallback(
    (value) => {
      setIsStuck(value.currentScrollTop > bodyMarginTop);
    },
    [bodyMarginTop]
  );

  const isSticky = props.blocks[0].behavior === 'sticky';

  const scrollTop = useDocumentScroll(handleDocumentScroll, isSticky);

  // Calculate how much to push the overlay down so it doesn't overlap banners
  // Should never be less than 0
  const navOverlayTopOffset = Math.max(bodyMarginTop - scrollTop, 0);

  const backgroundBehavior = props.blocks[0].backgroundBehavior ?? 'auto';

  // Override the "match" color behavior by applying the section color palette
  const navbarClassName =
    isStuck !== true && backgroundBehavior === 'auto'
      ? ''
      : buildThemeColorsVariationClassName(props.colorPaletteVariation);

  const brandIdentity = props.blocks[0].brandIdentity ?? {};

  const stickyNavDisplay = isStuck
    ? { ...brandIdentity, logo: props.blocks[0]?.stickyNavLogo?.logo ?? brandIdentity.logo }
    : brandIdentity;

  const ref = useThemeColorMatchRef(isStuck);

  return (
    <NavContainer>
      <Navbar
        backgroundBehavior={props.blocks[0].backgroundBehavior}
        isSticky={isSticky}
        isStuck={isStuck}
        className={navbarClassName}
        ref={ref}
      >
        <NavbarLeft>
          <BrandingContainer>
            <Branding brandIdentity={stickyNavDisplay} />
          </BrandingContainer>
        </NavbarLeft>
        <NavbarRight>
          <Navigation topOffset={navOverlayTopOffset} {...props} />
        </NavbarRight>
      </Navbar>
    </NavContainer>
  );
};

const Navigation = (props: NavigationProps): JSX.Element | null => {
  switch (props.blocks[0].navStyle) {
    case 'style1':
      return <NavigationMobile {...props} />;
    case 'style2':
      return <NavigationTraditional {...props} />;
    default:
      return <NavigationMobile {...props} />;
  }
};

const NavContainer = styled.div``;

const useThemeColorMatchRef = (isStuck: boolean) => {
  const initialColorRef = useRef(getThemeColor());
  const ref = useRef(null);

  useIsomorphicLayoutEffect(() => {
    if (ref.current === null) {
      return;
    }

    const themeColor = getCssVar(ref.current, '--color-navigation-background');

    if (themeColor === '') {
      return;
    }

    setThemeColor(themeColor);

    return () => {
      if (initialColorRef.current === null) {
        return;
      }

      setThemeColor(initialColorRef.current);
    };
  }, [isStuck]);

  return ref;
};

const getCssVar = (element: HTMLElement, value: string) =>
  getWindow()?.getComputedStyle(element).getPropertyValue(value) ?? '';
const getThemeColor = () => getDocument()?.querySelector("meta[name='theme-color']")?.getAttribute('content') ?? null;
const setThemeColor = (color: string) =>
  getDocument()?.querySelector("meta[name='theme-color']")?.setAttribute('content', color);

const BrandingContainer = styled.div`
  height: var(--header-content-height);
`;
