import styled from 'styled-components';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    Navbar?: {
      background?: string;
      color?: string;
    };
  }
}

export const Navbar = styled.nav`
  display: inline-block;
  background: ${({ theme }) => theme._sermonThemeElements.Navbar?.background};
  color: ${({ theme }) => theme._sermonThemeElements.Navbar?.color};
  border-radius: 10px;
  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    rounded: '10px',
    pill: '25px',
  })};
  height: 50px;
  width: 100%;
  padding: 7px;
`;
