import { isNonNullable } from '@nucleus/lib-shape';
import { Alignment, CalendarBlockWeb } from '@nucleus/types/web';
import { useSection } from '@nucleus/web-theme';
import { nucleusClass, positionToFlexStyles } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import { pick } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import { Calendar, CalendarHeaderContainer } from '../components/Calendar';
import { BlockProps } from '../types/component';

type BlockCalendarGridProps = Extract<BlockProps, { block?: CalendarBlockWeb }>;

export const BlockCalendarGrid = (props: BlockCalendarGridProps): JSX.Element => {
  const section = useSection();
  const items = (props.block?.items ?? []).filter(isNonNullable);

  const containerProps = pick(props, ['blockLayout']);

  return (
    <LayoutGrid className={classNames([props.className, nucleusClass('block-calendar')])}>
      <GridContainer {...containerProps}>
        {items.map((calendar) => (
          <Calendar
            key={calendar.id}
            sectionId={section.id}
            apiEndpoint={calendar.apiEndpoint}
            displayData={calendar.displayData}
            defaultLimit={calendar.limit}
            initialIntervalMode={calendar.displayBy}
            subscribeUrl={calendar.feedUrl}
          />
        ))}
      </GridContainer>
    </LayoutGrid>
  );
};

const LayoutGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 100%;
`;

type GridContainerProps = {
  gridGap?: number;
  gridColumnsMax?: number;
  gridAlignment?: Alignment;
  areItemsInset?: boolean;
} & Pick<BlockCalendarGridProps, 'blockLayout'>;

const GridContainer = styled.div<GridContainerProps>`
  --grid-gap: ${(props) => (props.gridGap !== undefined && props.gridGap > 0 ? props.gridGap + 'rem' : '2.4rem')};
  --grid-column-count: ${(props) => props.gridColumnsMax ?? 1};
  --grid-item--width: 100%;
  --grid-padding: ${(props) =>
    props.gridGap !== undefined && props.gridGap > 0 ? 'calc(var(--grid-gap) * 1)' : '2.4rem'};

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-gap));
  --grid-item--max-width: min(90rem, calc((100% - var(--total-gap-width)) / var(--grid-column-count)));

  width: 100%;
  display: flex;
  flex-direction: row;
  ${(props) => positionToFlexStyles(props.gridAlignment ?? 'center')};
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
    width: var(--grid-item--width);
    max-width: var(--grid-item--max-width);
  }

  grid-gap: var(--grid-gap);

  ${CalendarHeaderContainer} {
    ${(props) => {
      switch (props.blockLayout) {
        case 'layout1':
          return css`
            @media (min-width: 800px) and (max-width: 1200px) {
              grid-template-columns: minmax(0, 1fr) minmax(auto, max-content);
              grid-template-areas:
                'center center'
                'left right';
            }
          `;
        default:
          return;
      }
    }};
  }
`;
