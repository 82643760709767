import styled from 'styled-components';
import React, { useState } from 'react';
import { useSermonSearchController } from '../controllers/SermonSearchController';
import { SearchButton } from './SearchButton';
import { cornerStyleInterpolationFactory } from '../lib/cornerStyle';
import { IconMagnifyingGlass } from '../icons/IconMagnifyingGlass';

declare module '@nucleus/sermon-theme-elements' {
  interface SermonThemeElementsTheme {
    Input?: {
      border?: string;
      className?: string;
      color?: string;
      background?: string;
      hoverBackground?: string;
      fontSize?: string;
      lineHeight?: string;
    };
  }
}

interface SearchInputProps {
  placeholder?: string;
}
export const SearchInput = (props: SearchInputProps): JSX.Element => {
  const { searchQuery, setSearchQuery } = useSermonSearchController();
  const [value, setValue] = useState(searchQuery);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClick = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSearchQuery(value);
  };

  return (
    <StyledForm onSubmit={handleClick}>
      <StyledContainer>
        <StyledInputWrapper>
          <StyleIconWrapper>
            <IconMagnifyingGlass width="14px" />
          </StyleIconWrapper>
          <StyledInput value={value} onChange={handleChange} placeholder={props.placeholder ?? 'Type here'} />
        </StyledInputWrapper>
        <SearchButton onClick={handleClick} />
      </StyledContainer>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  max-width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 24px;
  width: 508px;
  max-width: 100%;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  flex-grow: 1;

  color: ${({ theme }) => theme._sermonThemeElements.Input?.color};
  background: ${({ theme }) => theme._sermonThemeElements.Input?.background};
  font-size: ${({ theme }) => theme._sermonThemeElements.Input?.fontSize};
  line-height: ${({ theme }) => theme._sermonThemeElements.Input?.lineHeight};
  border: ${({ theme }) => theme._sermonThemeElements.Input?.border};

  border-radius: ${cornerStyleInterpolationFactory({
    square: '0px',
    pill: '25px',
    rounded: '10px',
  })};

  :hover {
    background: ${({ theme }) => theme._sermonThemeElements.Input?.hoverBackground};
  }
`;

const StyleIconWrapper = styled.div`
  padding: 3px 18px 0;
`;

const StyledInput = styled.input`
  background: none;
  border: 0;
  color: inherit;
  caret-color: inherit;
  display: inline-block;
  font-family: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
  max-width: 100%;
`;
