import { usePageCssSnippets } from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import React from 'react';
import { useEnabledCodeSnippets } from '../hooks/useEnabledCodeSnippets';
import { GlobalStyle } from './GlobalStyle';

export const PageGlobalStyle = (): JSX.Element => {
  const cssSnippets = usePageCssSnippets();
  const enabledCssSnippets = useEnabledCodeSnippets(cssSnippets);
  const pageClass = nucleusClass('page');

  return (
    <>
      {enabledCssSnippets.map((snippet) => (
        <GlobalStyle key={snippet.id} content={snippet.content} selector={`.${pageClass}.${pageClass}`} />
      ))}
    </>
  );
};
