import { ThemeColorPaletteBuilder } from '../ThemeConfigBuilder';
import { serviceName } from '../constants';

const builder = new ThemeColorPaletteBuilder({
  id: `${serviceName}_shoreline `,
  title: 'Shoreline 🌅',
  description:
    'Inspired by the shores of a lake at sunrise, this color palette pairs those cool waking up tones with warm, morning light.',
  lightMode: {
    color1: '#F1E9E4',
    color2: '#084256',
    color3: '#EDA182',
    color4: '#065556',
    color5: '#F6F4F0',
    color6: '#EA8C5B',
    colorLight: '#F6F4F0',
    colorDark: '#043344',
  },
});

export default builder.build();
