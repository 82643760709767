import { createCompositeString } from '@nucleus/lib-shape';
import { BlendMode, Filter, Texture } from '@nucleus/types/media/blending';
import { ImageScale, ImageTile } from '@nucleus/types/media/image';
import { MediaWeb } from '@nucleus/types/web';
import classNames from 'classnames';
import { sortBy as _sortBy } from 'lodash';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { buildFadeCss, convertFocalPointToBackgroundPositionCss } from '../../lib/utilities';
import { BackgroundVideoPlayer } from './BackgroundVideoPlayer';
import { nucleusClass } from '../../lib/class';

interface BackgroundMediaProps {
  background?: MediaWeb;
  baseClassName?: string;
  fallbackColor?: string;
}

export const BackgroundComposition = ({
  background,
  baseClassName,
  fallbackColor = 'transparent',
}: BackgroundMediaProps): JSX.Element => {
  const getBlockClass = (name: string): string => {
    if (baseClassName === undefined) {
      return '';
    }
    return nucleusClass(createCompositeString([baseClassName, name], '-'));
  };
  const renderVideo = doesBackgroundHaveValidVideo(background);

  return (
    <BackgroundMediaWrapper
      className={nucleusClass(baseClassName)}
      background={background}
      fallbackColor={fallbackColor}
    >
      <BackgroundMediaBaseColor
        className={classNames(getBlockClass('color'))}
        background={background}
        fallbackColor={fallbackColor}
      />
      <BackgroundMedia className={classNames(getBlockClass('asset'))} background={background}>
        <BackgroundMediaImageWrapper image={background?.image} />
        {renderVideo && <BackgroundMediaVideo video={background.video} />}
      </BackgroundMedia>
      <BackgroundMediaOpacity
        className={classNames(getBlockClass('opacity'))}
        background={background}
        fallbackColor={fallbackColor}
      />
      <BackgroundMediaFilterColor background={background} />
      <BackgroundMediaFilterColor2 className={classNames(getBlockClass('filter'))} background={background} />
      <BackgroundMediaFade className={classNames(getBlockClass('fade'))} background={background} />
      <BackgroundMediaTexture className={classNames(getBlockClass('texture'))} background={background} />
    </BackgroundMediaWrapper>
  );
};

interface BackgroundMediaWrapperProps {
  image: MediaWeb['image'];
}
const BackgroundMediaImageWrapper = ({ image }: BackgroundMediaWrapperProps) => {
  if (image === undefined) {
    return null;
  }
  if (image.src === undefined || image.src === '') {
    return null;
  }

  if (doesBackgroundImageTile(image) === true) {
    return <BackgroundMediaImageBackground image={image} />;
  }

  const sortedSets = _sortBy(image.srcSet ?? [], 'dimensions.width');
  const srcSet = sortedSets.map((srcSet) => `${srcSet.src} ${srcSet.dimensions.width}w`).join(', ');

  const fallbackImage = sortedSets[0]?.src ?? image.src;
  const largestDimensions = sortedSets[sortedSets.length - 1]?.dimensions;

  const [imageAsyncState, imageStateProps] = useImageState();

  const preloadBackgroundImage = imageAsyncState !== 'fulfilled' ? fallbackImage : undefined;

  return (
    <BackgroundMediaImage
      image={image}
      src={fallbackImage}
      srcSet={srcSet}
      preloadBackgroundImage={preloadBackgroundImage}
      dimensions={image.dimensions ?? largestDimensions}
      {...imageStateProps}
    />
  );
};

const useImageState = () => {
  const [state, setState] = useState<'pending' | 'fulfilled' | 'error'>('pending');

  const onLoad = React.useCallback(() => setState('fulfilled'), []);

  const onError = React.useCallback(() => setState('error'), []);

  return [state, { onLoad, onError }] as const;
};

const doesBackgroundImageTile = (image: NonNullable<MediaWeb['image']>): boolean => {
  if ([undefined, 'cover'].includes(image.scale) === true) {
    return false;
  }

  return [undefined, 'none'].includes(image.tile) === false;
};

const doesBackgroundHaveValidVideo = (
  background?: MediaWeb
): background is MediaWeb & { video: NonNullable<MediaWeb['video']> } => {
  return background?.video?.src !== undefined;
};

const BackgroundMediaLayer = styled.div<BackgroundMediaProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

const BackgroundMedia = styled(BackgroundMediaLayer)<BackgroundMediaProps>``;

const ImageTileToBackgroundRepeatCss: Record<ImageTile, string> = {
  none: 'no-repeat',
  repeat: 'repeat',
  'repeat-x': 'repeat-x',
  'repeat-y': 'repeat-y',
};

const ImageScaleToBackgroundSizeCss: Record<ImageScale, string> = {
  none: 'auto',
  cover: 'cover',
  contain: 'contain',
};

const ImageScaleToObjectFitCss: Record<ImageScale, string> = {
  none: 'unset',
  cover: 'cover',
  contain: 'contain',
};

const BackgroundMediaImageBackground = styled(BackgroundMediaLayer)<{ image: NonNullable<MediaWeb['image']> }>`
  background-image: url(${(props) => props.image?.src ?? ''});
  background-size: ${(props) => ImageScaleToBackgroundSizeCss[props.image?.scale ?? 'cover'] ?? 'cover'};
  background-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.image?.focalPoint)};
  background-repeat: ${(props) => ImageTileToBackgroundRepeatCss[props.image?.tile ?? 'none'] ?? 'no-repeat'};
`;

const setDimensions = (props: BackgroundMediaImageProps) =>
  props.image?.scale === 'none' && props.dimensions !== undefined;

interface BackgroundMediaImageProps {
  image: NonNullable<MediaWeb['image']>;
  preloadBackgroundImage?: string;
  dimensions?: { width: number; height: number };
}
const BackgroundMediaImage = styled.img<BackgroundMediaImageProps>`
  position: absolute;
  height: ${(props) => (setDimensions(props) ? `${props.dimensions?.height}px` : '100%')};
  width: ${(props) => (setDimensions(props) ? `${props.dimensions?.width}px` : '100%')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;

  object-fit: ${(props) => ImageScaleToObjectFitCss[props.image?.scale ?? 'cover'] ?? 'cover'};
  object-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.image?.focalPoint)};

  ${({ preloadBackgroundImage }) =>
    preloadBackgroundImage !== undefined &&
    css<BackgroundMediaImageProps>`
      background-image: ${(props) => `url(${props.preloadBackgroundImage})`};
      background-size: ${(props) => (props.image?.scale === 'none' ? 'cover' : props.image?.scale ?? 'cover')};
      background-position: ${(props) => convertFocalPointToBackgroundPositionCss(props.image?.focalPoint)};

      background-repeat: no-repeat;
    `}
`;

const BackgroundMediaVideo = styled(BackgroundVideoPlayer)<BackgroundMediaProps>``;

const BackgroundMediaBaseColor = styled(BackgroundMediaLayer)<BackgroundMediaProps>`
  background-color: ${getColorFromProps};
`;

const BackgroundMediaOpacity = styled(BackgroundMediaLayer)<BackgroundMediaProps>`
  background-color: ${getColorFromProps};
  opacity: ${(props) => 1 - (props.background?.blending?.opacity ?? 1)}; // we want to invert the opacity
`;

const BackgroundMediaFilterColor = styled(BackgroundMediaLayer)<BackgroundMediaProps>`
  background-color: var(--color-dark);
  opacity: 0;
`;

const BackgroundMediaFilterColor2 = styled(BackgroundMediaLayer)<BackgroundMediaProps>`
  // This is used for duotone effects
  background-color: var(--color-6);
  opacity: 0;
`;

const BackgroundMediaFade = styled(BackgroundMediaLayer)<BackgroundMediaProps>`
  opacity: 0;
`;

const BackgroundMediaTexture = styled(BackgroundMediaLayer)<BackgroundMediaProps>`
  opacity: 0;
`;

function getColorFromProps(props: BackgroundMediaProps) {
  return props.background?.color?.hex ?? props.fallbackColor;
}

const BackgroundMediaBlendModeToCss: Record<BlendMode, any> = {
  [BlendMode.None]: css``,
  [BlendMode.Multiply]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: multiply;
    }
  `,
  [BlendMode.Screen]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: screen;
    }
  `,
  [BlendMode.Overlay]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: overlay;
    }
  `,
  [BlendMode.Darken]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: darken;
    }
  `,
  [BlendMode.Lighten]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: lighten;
    }
  `,
  [BlendMode.ColorDodge]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: color-dodge;
    }
  `,
  [BlendMode.ColorBurn]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: color-burn;
    }
  `,
  [BlendMode.HardLight]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: hard-light;
    }
  `,
  [BlendMode.SoftLight]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: soft-light;
    }
  `,
  [BlendMode.Difference]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: difference;
    }
  `,
  [BlendMode.Exclusion]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: exclusion;
    }
  `,
  [BlendMode.Hue]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: hue;
    }
  `,
  [BlendMode.Saturation]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: saturation;
    }
  `,
  [BlendMode.Color]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: color;
    }
  `,
  [BlendMode.Luminosity]: css`
    ${BackgroundMedia} {
      opacity: 1;
      mix-blend-mode: luminosity;
    }
  `,
};

const BackgroundMediaFilterToCss: Record<Filter, any> = {
  none: css``,
  sepia: css`
    ${BackgroundMedia} {
      filter: sepia(100%);
    }
  `,
  duotone: css`
    ${BackgroundMedia} {
      filter: grayscale(100%);
    }
    ${BackgroundMediaFilterColor} {
      opacity: 1;
      mix-blend-mode: lighten;
    }
    ${BackgroundMediaFilterColor2} {
      opacity: 1;
      mix-blend-mode: multiply;
    }
  `,
  grayscale: css`
    ${BackgroundMedia} {
      filter: grayscale(100%);
    }
  `,
  invert: css`
    ${BackgroundMedia} {
      filter: invert(100%);
    }
  `,
  blur: css`
    ${BackgroundMedia} {
      filter: blur(10px);
    }
  `,
  muted: css`
    ${BackgroundMedia} {
      filter: saturate(80%);
    }
  `,
  vibrant: css`
    ${BackgroundMedia} {
      filter: saturate(120%);
    }
  `,
  darken: css`
    ${BackgroundMedia} {
      filter: brightness(80%);
    }
  `,
  brighten: css`
    ${BackgroundMedia} {
      filter: brightness(120%);
    }
  `,
};

const BackgroundTextureToCss: Record<Texture, any> = {
  none: css``,
  'dots-small': css`
    ${BackgroundMediaTexture} {
      background-image: radial-gradient(var(--color-section-background-texture) 1px, transparent 1px),
        radial-gradient(var(--color-section-background-texture) 1px, transparent 1px);
      background-position:
        0 0,
        5px 5px;
      background-size: 10px 10px;
      mix-blend-mode: overlay;
      opacity: 1;
    }
  `,
  'grid-small': css`
    ${BackgroundMediaTexture} {
      background-image: linear-gradient(var(--color-section-background-texture) 1px, transparent 1px),
        linear-gradient(90deg, var(--color-section-background-texture) 1px, transparent 1px);
      background-size: 10px 10px;
      mix-blend-mode: overlay;
      opacity: 1;
    }
  `,
};

const BackgroundMediaWrapper = styled.div<BackgroundMediaProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  user-select: none;
  pointer-events: none;

  ${(props) => BackgroundMediaBlendModeToCss[props.background?.blending?.blendMode ?? 'none']}
  ${(props) => BackgroundMediaFilterToCss[props.background?.blending?.filter ?? 'none']}
  ${(props) => BackgroundTextureToCss[props.background?.blending?.texture ?? 'none']}

  // Fade Styles
  ${BackgroundMediaFade} {
    ${(props) =>
      buildFadeCss(
        props.background?.color?.hex ?? props.fallbackColor,
        props.background?.blending?.fade,
        props.background?.blending?.fadeIntensity
      )}
  }
`;
