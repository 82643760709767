import { SectionConfigBuilder } from '../ThemeConfigBuilder';
import { body, byline, headline, overline } from '../fields/fields';

export default new SectionConfigBuilder()
  .setContext('picker', {
    label: 'Information',
    description: 'Display additional details or description',
    icon: 'Info',
  })
  .setContext('editor', {
    label: 'Information Section',
    description: '',
    icon: 'Info',
  })
  .addLayout('layout1', (layout) => {
    layout
      .setIcon('Horizontal')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(1);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Image Placement',
        controlType: 'left right',
        options: [
          {
            value: 'variant1',
            label: 'Left',
          },
          {
            value: 'variant2',
            label: 'Right',
          },
        ],
      });
  })
  .addLayout('layout2', (layout) => {
    layout
      .setIcon('Vertical')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(1);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Image Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout3', (layout) => {
    layout
      .setIcon('Collage')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(3);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Image Placement',
        controlType: 'top bottom',
        options: [
          {
            value: 'variant1',
            label: 'Top',
          },
          {
            value: 'variant2',
            label: 'Bottom',
          },
        ],
      });
  })
  .addLayout('layout4', (layout) => {
    layout
      .setIcon('LongText')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockBodyMaxColumns(3);
  })
  .addLayout('layout5', (layout) => {
    layout
      .setIcon('Cover')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout6', (layout) => {
    layout
      .setIcon('Double')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body], 'Left Column Text');
        block.setMedia(
          1,
          'Left Column Media',
          'The media area has an anamorphic widescreen aspect ratio of 2.4:1  (for example 1500 x 625)'
        );
        block.setActions(Infinity, 'Left Column Actions');
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body], 'Right Column Text');
        block.setMedia(
          1,
          'Right Column Media',
          'The media area has an anamorphic widescreen aspect ratio of 2.4:1  (for example 1500 x 625)'
        );
        block.setActions(Infinity, 'Right Column Actions');
      });
  })
  .build();
