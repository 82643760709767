export const Position = {
  Center: 'center',
  Left: 'left',
  Right: 'right',
  Top: 'top',
  Bottom: 'bottom',
  TopLeft: 'top-left',
  TopRight: 'top-right',
  BottomLeft: 'bottom-left',
  BottomRight: 'bottom-right',
} as const;

export type Position = typeof Position[keyof typeof Position];

export const PositionRowTop: ReadonlyArray<Position> = [Position.TopLeft, Position.Top, Position.TopRight] as const;
export const PositionRowCenter: ReadonlyArray<Position> = [Position.Left, Position.Center, Position.Right] as const;
export const PositionRowBottom: ReadonlyArray<Position> = [
  Position.BottomLeft,
  Position.Bottom,
  Position.BottomRight,
] as const;

export const PositionColumnLeft: ReadonlyArray<Position> = [
  Position.TopLeft,
  Position.Left,
  Position.BottomLeft,
] as const;
export const PositionColumnCenter: ReadonlyArray<Position> = [Position.Top, Position.Center, Position.Bottom] as const;
export const PositionColumnRight: ReadonlyArray<Position> = [
  Position.TopRight,
  Position.Right,
  Position.BottomRight,
] as const;
