import copy from 'copy-to-clipboard';
import invariant from 'invariant';
import { isEqual as _isEqual } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { Descendant } from 'slate';
import { ReactEditor } from 'slate-react';
import { useLocalStorageState } from '../hooks/useLocalStorageState';
import { CustomEditor } from '../slate/types/custom-types';

interface NotesController {
  editorProps: {
    key: number;
    ref: React.RefObject<CustomEditor>;
    initialValue?: Descendant[];
    onBlur: () => void;
    onValueChange: (notes: Descendant[]) => void;
    onFocus: () => void;
  };
  blurEditor: () => void;
  focusEditor: () => void;
  isEnabled: boolean;
  isFocused: boolean;
  isModified: boolean;
  onCopy: () => void;
  onDelete: () => void;
  onDownload: (filename?: string) => void;
  onMailTo: (subject?: string) => void;
  onShare: () => void;
}

const Context = React.createContext<NotesController>(null!);
Context.displayName = 'NotesController';

export const NotesController = ({ children, ...options }: React.PropsWithChildren<Options>): JSX.Element => {
  return <Context.Provider value={useController(options)}>{children}</Context.Provider>;
};

export const useNotesController = (): NotesController => {
  invariant(
    useContext(Context) !== null,
    'useNotesController() may be used only in the context of a <NotesController> object'
  );
  return useContext(Context);
};

type Options = {
  sermonId: string;
  template?: Descendant[];
};

const useController = (options: Options): NotesController => {
  const [state, setState, deleteState] = useLocalStorageState<{ data: Descendant[] }>(getStorageKey(options.sermonId));
  const [isFocused, setIsFocused] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const editorRef = useRef<CustomEditor>(null);

  const handleDelete = () => {
    deleteState();
    setEditorKey((prev) => prev + 1);
  };

  const handleCopy = () => {
    if (!editorRef.current) {
      return;
    }
    copy(editorRef.current.toPlainText());
  };

  const handleDownload = (filename: string = 'sermon-notes') => {
    if (!editorRef.current) {
      return;
    }
    downloadTextFile(`${filename}.txt`, editorRef.current.toPlainText());
  };

  const handleMailTo = (subject: string = '') => {
    if (!editorRef.current) {
      return;
    }
    mailTo(subject, editorRef.current.toPlainText());
  };

  const handleShare = () => {
    if (!editorRef.current) {
      return;
    }

    if (navigator.share) {
      navigator.share({
        text: editorRef.current.toPlainText(),
      });
    }
  };

  const initialState = state?.data ?? options.template;

  return {
    editorProps: {
      key: editorKey,
      ref: editorRef,
      initialValue: initialState,
      onValueChange: (value) => setState({ data: value }),
      onBlur: () => setIsFocused(false),
      onFocus: () => setIsFocused(true),
    },
    blurEditor: () => editorRef.current && ReactEditor.blur(editorRef.current),
    focusEditor: () => editorRef.current && ReactEditor.focus(editorRef.current),
    isEnabled: options.template !== undefined || state?.data !== undefined,
    isFocused: isFocused,
    isModified: state?.data !== undefined && _isEqual(options.template, state?.data) === false,
    onCopy: handleCopy,
    onDelete: handleDelete,
    onDownload: handleDownload,
    onMailTo: handleMailTo,
    onShare: handleShare,
  };
};

const NameSpace = 'SermonNotes';

const getStorageKey = (id: string) => `${NameSpace}.${id}`;

const mailTo = (subject: string, body: string) => {
  const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  window.location.href = mailtoLink;
};

const downloadTextFile = (filename: string, text: string) => {
  const blob = new Blob([text], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);

  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

  window.URL.revokeObjectURL(url);
};
