import {
  ReactComponentsTheme,
  ReactComponentsThemeCornerStyle,
  ReactComponentsThemeProvider,
} from '@nucleus/react-components';
import {
  SermonThemeElementsCornerStyle,
  SermonThemeElementsTheme,
  SermonThemeElementsThemeProvider,
} from '@nucleus/sermon-theme-elements';
import {
  WebThemeElementsTheme,
  WebThemeElementsThemeProvider,
  buildTransparentColorFromCssVariable,
} from '@nucleus/web-theme-elements';
import React from 'react';
import { useButtonConfig } from '../hooks/useButtonConfig';
import { IconAngleLeft, IconAngleRight } from './Icons';

export const StyledThemeProvider = (props: { children: React.ReactNode }): JSX.Element => {
  return (
    <ReactComponentsThemeProvider theme={useReactComponentsTheme()}>
      <WebThemeElementsThemeProvider theme={webThemeElementsTheme}>
        <SermonThemeElementsThemeProvider theme={useSermonThemeElementsTheme()}>
          {props.children}
        </SermonThemeElementsThemeProvider>
      </WebThemeElementsThemeProvider>
    </ReactComponentsThemeProvider>
  );
};

const clampFontFactory = (min: string, cssVariable: string, max: string) =>
  `clamp(${min}, var(${cssVariable}) * var(--global-font-factor), ${max})`;

const reactComponentsTheme = {
  Menu: {
    background: `var(--color-light)`,
    color: `var(--color-dark)`,
    boxShadow: `0.2rem 0.2rem 2rem 0 ${buildTransparentColorFromCssVariable('--color-dark', 0.2)}`,
  },
  MenuButton: {
    background: buildTransparentColorFromCssVariable('--color-section-text', 0.05),
    foreground: `var(--color-section-background)`,
    color: `var(--color-section-text)`,
    hover: {
      background: buildTransparentColorFromCssVariable('--color-section-text', 0.1),
    },
  },
  MenuItem: {
    childMenuIndicator: <IconAngleRight style={{ width: '16px' }} />,
    backMenuIndicator: <IconAngleLeft style={{ width: '16px' }} />,
  },
  MenuItemButton: {
    className: 'theme-text-button-4',
    color: `var(--color-dark)`,
    fontSize: clampFontFactory('14px', '--button-4-font-size', '22px'),
    lineHeight: '1em',
  },
  Select: {
    background: buildTransparentColorFromCssVariable('--color-section-text', 0.05),
    className: 'theme-text-button-4',
    color: 'var(--color-section-text)',
    fontSize: clampFontFactory('14px', '--button-4-font-size', '22px'),
    lineHeight: '1em',
  },
} satisfies ReactComponentsTheme;

const useReactComponentsTheme = (): ReactComponentsTheme => {
  const buttonConfig = useButtonConfig('button1');

  const ButtonCornerStyleMap: Record<string, ReactComponentsThemeCornerStyle> = {
    cornerStyle1: 'square',
    cornerStyle2: 'rounded',
    cornerStyle3: 'pill',
  };

  return {
    ...reactComponentsTheme,
    Global: {
      cornerStyle: ButtonCornerStyleMap[buttonConfig?.cornerStyle ?? 'cornerStyle2'] ?? 'rounded',
    },
  };
};

const webThemeElementsTheme = {} satisfies WebThemeElementsTheme;

const sermonThemeElementsTheme = {
  Button: {
    background: buildTransparentColorFromCssVariable('--color-section-text', 0.05),
    className: 'theme-text-label4',
    color: 'var(--color-section-text)',
    fontSize: clampFontFactory('14px', '--label4-font-size', '22px'),
    lineHeight: '1em',
    hover: {
      background: buildTransparentColorFromCssVariable('--color-section-text', 0.1),
    },
  },
  CollectionListItem: {
    background: buildTransparentColorFromCssVariable(`--color-section-text`, 0.07),
    color: `var(--color-section-text)`,
  },
  DurationBadge: {
    background: `var(--color-dark)`,
    color: `var(--color-light)`,
    className: 'theme-text-label4',
    fontSize: clampFontFactory('14px', '--label4-font-size', '22px'),
    lineHeight: '1em',
  },
  InfoCard: {
    background: buildTransparentColorFromCssVariable(`--color-section-text`, 0.07),
    buttonClassName: 'theme-text-label4',
    color: `var(--color-section-text)`,
    fontSize: clampFontFactory('14px', '--label4-font-size', '22px'),
    lineHeight: '1em',
  },
  InfoItem: {
    background: buildTransparentColorFromCssVariable(`--color-section-text`, 0.07),
    color: `var(--color-section-text)`,
  },
  Input: {
    color: `var(--color-button-primary-background)`,
    background: `var(--color-button-primary-text)`,
    hoverBackground: buildTransparentColorFromCssVariable(`--color-button-primary-background`, 0.1),
    border: `solid 2px var(--color-button-primary-background)`,
    fontSize: clampFontFactory('14px', '--label4-font-size', '22px'),
  },
  Navbar: {
    background: buildTransparentColorFromCssVariable('--color-section-text', 0.05),
  },
  NavItem: {
    className: 'theme-text-label4',
    fontSize: clampFontFactory('14px', '--label4-font-size', '22px'),
    lineHeight: '1em',
    color: `var(--color-section-text)`,
    background: 'none',
    active: {
      color: `var(--color-button-primary-text)`,
      background: `var(--color-button-primary-background)`,
    },
    hover: {
      color: `var(--color-section-text)`,
      background: buildTransparentColorFromCssVariable('--color-section-text', 0.12),
    },
  },
  MediaGridItemBadge: {
    className: 'theme-text-label3',
    color: 'var(--color-light)',
    background: buildTransparentColorFromCssVariable('--color-dark', 0.35),
  },
  MediaListItem: {
    background: buildTransparentColorFromCssVariable(`--color-section-text`, 0.07),
    hover: {
      background: 'var(--color-section-background)',
      shadow: buildTransparentColorFromCssVariable(`--color-section-text`, 0.18),
    },
    color: `var(--color-section-text)`,
  },
  MediaListButton: {
    className: 'theme-text-label4',
    color: 'var(--color-button-secondary-text)',
    background: 'var(--color-button-secondary-background)',
    fontSize: clampFontFactory('14px', '--label4-font-size', '22px'),
    lineHeight: '1em',
    hover: {
      color: 'var(--color-button-secondary-background-hover)',
      background: 'var(--color-button-secondary-text-hover)',
    },
    active: {
      color: 'var(--color-button-secondary-background)',
      background: 'var(--color-button-secondary-text)',
    },
  },
  MediaGridItem: {
    color: `var(--color-section-text)`,
  },
  MediaToggle: {
    className: 'theme-text-label4',
    fontSize: clampFontFactory('14px', '--label4-font-size', '22px'),
    lineHeight: '1em',
    background: buildTransparentColorFromCssVariable('--color-section-text', 0.05),
  },
  MediaToggleButton: {
    color: `var(--color-section-text)`,
    background: 'none',
    activated: {
      color: `var(--color-button-primary-text)`,
      background: `var(--color-button-primary-background)`,
    },
  },
  NotesCard: {
    background: buildTransparentColorFromCssVariable(`--color-section-text`, 0.07),
    color: `var(--color-section-text)`,
    padding: `max(12px, calc(0.5 * var(--unit-length))) max(12px, calc(0.5 * var(--unit-length))) max(12px, calc(0.5 * var(--unit-length)))`,
  },
  NotesEditor: {
    background: `var(--color-section-background)`,
    border: `6px solid ${buildTransparentColorFromCssVariable(`--color-section-text`, 0.25)}`,
    color: `var(--color-section-text)`,
    focus: {
      border: `6px solid var(--color-section-text)`,
      boxShadow: `6px 12px 24px ${buildTransparentColorFromCssVariable(`--color-dark`, 0.24)}`,
    },
    padding: `max(18px, calc(0.8 * var(--unit-length)))`,
    elements: {
      body: {
        className: 'theme-text-paragraph3',
        color: `var(--color-section-text)`,
      },
      title: {
        className: 'theme-text-headline6',
        color: `var(--color-section-text)`,
      },
      paragraph: {
        className: 'theme-text-paragraph3',
        color: `var(--color-section-text)`,
      },
      textarea: {
        background: buildTransparentColorFromCssVariable(`--color-section-text`, 0.07),
        className: 'theme-text-paragraph3',
        color: `var(--color-section-text)`,
      },
    },
  },
  NotesToolbar: {
    padding: `calc(0.5 * var(--unit-length)) calc(0.5 * var(--unit-length)) 0`,
  },
  ViewToggle: {
    background: buildTransparentColorFromCssVariable('--color-section-text', 0.05),
  },
  ViewToggleButton: {
    color: `var(--color-section-text)`,
    background: 'none',
    activated: {
      color: `var(--color-button-primary-text)`,
      background: `var(--color-button-primary-background)`,
    },
  },
  Theater: {
    background: buildTransparentColorFromCssVariable('--color-section-text', 0.05),
  },
  Thumbnail: {
    background: `#fff linear-gradient(194deg, ${buildTransparentColorFromCssVariable(
      '--color-dark',
      0.19
    )} 20%, ${buildTransparentColorFromCssVariable('--color-dark', 0.37)} 100%)`,
  },
  SearchButton: {
    background: `var(--color-button-primary-background)`,
    color: `var(--color-button-primary-text)`,
    fontSize: clampFontFactory('14px', '--label4-font-size', '22px'),
    lineHeight: '1em',
    hover: {
      background: `var(--color-button-primary-background-hover)`,
      color: `var(--color-button-primary-text-hover)`,
    },
  },
} satisfies SermonThemeElementsTheme;

const useSermonThemeElementsTheme = (): SermonThemeElementsTheme => {
  const buttonConfig = useButtonConfig('button1');

  const ButtonCornerStyleMap: Record<string, SermonThemeElementsCornerStyle> = {
    cornerStyle1: 'square',
    cornerStyle2: 'rounded',
    cornerStyle3: 'pill',
  };

  return {
    ...sermonThemeElementsTheme,
    Global: {
      cornerStyle: ButtonCornerStyleMap[buttonConfig?.cornerStyle ?? 'cornerStyle2'] ?? 'rounded',
    },
  };
};
