import React from 'react';
import styled, { css } from 'styled-components';
import { BlockLayout, blockLayoutInnerProps } from '../../blockLayouts/Base';
import { BlockListGrid } from '../../blocks/BlockListGrid';
import { BlockCollage } from '../../blocks/BlockCollage';
import { BlockInfo, blockInfoHasContent } from '../../blocks/BlockInfo';
import { SpacerTop } from '../../sectionLayouts/Spacers';
import { ListBlockLayoutProps } from './ListLayout';
import { StandardList } from '../../components/StandardList';
import { AccordionList } from '../../components/AccordionList';
import { booleanPropHelperFactory } from '@nucleus/web-theme-elements';

export const ListBlockLayoutCollage = (props: ListBlockLayoutProps): JSX.Element => {
  const innerProps = blockLayoutInnerProps(props);
  const mediaOnTheBottom = props.blockLayoutVariant === 'variant2';

  const renderInfo = blockInfoHasContent(props.blocks[0]);

  return (
    <StyledBlockLayout {...props} mediaOnTheBottom={mediaOnTheBottom} renderInfo={renderInfo}>
      <StyledBlockCollage {...innerProps} block={props.blocks[0]} />
      {renderInfo && <StyledBlockInfo {...innerProps} block={props.blocks[0]} />}
      <StyledBlockListGrid {...innerProps} block={props.blocks[0]} />
    </StyledBlockLayout>
  );
};

const mediaOnTheBottom = booleanPropHelperFactory('mediaOnTheBottom');

const StyledBlockListGrid = styled(BlockListGrid)``;
const StyledBlockCollage = styled(BlockCollage)``;
const StyledBlockInfo = styled(BlockInfo)``;

const StyledBlockLayout = styled(BlockLayout)<{
  mediaOnTheBottom?: boolean;
  renderInfo: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  ${StyledBlockInfo} {
    ${SpacerTop} {
      display: none;
    }
  }

  ${StyledBlockInfo} {
    min-height: ${(props) => {
      switch (props.height) {
        case 'medium':
          return '40vh';
        case 'large':
          return '55vh';
        default:
          return undefined;
      }
    }};
  }

  ${StyledBlockListGrid} {
    display: flex;
    justify-content: center;
    padding: var(--unit-length);
    padding-top: ${(props) => (props.renderInfo ? '0' : undefined)};

    ${StandardList} {
      padding: var(--unit-length);
      max-width: 1600px;
    }

    ${AccordionList} {
      padding: var(--unit-length);
      max-width: 912px;
    }
  }

  ${mediaOnTheBottom(css`
    ${StyledBlockCollage} {
      order: 2;
    }

    ${StyledBlockInfo} {
      order: 1;
    }
  `)}
`;
