import React from 'react';
import styled, { useTheme } from 'styled-components';
import { MenuList, useMenuListContext } from './MenuList';
import { MenuItemButton } from './MenuItemButton';
import { Portal } from './Portal';
import { useMenuContext } from './Menu';

declare module '@nucleus/react-components' {
  interface ReactComponentsTheme {
    MenuItem?: {
      childMenuIndicator?: JSX.Element;
      backMenuIndicator?: JSX.Element;
    };
  }
}

export interface MenuItemProps extends React.HTMLAttributes<HTMLLIElement> {
  /**
   * Optional: The Component used for matching a Menu Button child.
   * Use this if you are using a Button component other than <MenuItemButton>
   */
  menuButtonComponent: React.JSXElementConstructor<any>;
  /**
   * Optional: The Component used for matching a MenuList child.
   * Use this if you are using a MenuList component other than <MenuList>
   *
   * Example:
   *
   * ```jsx
   * const StyledMenuList = styled(MenuList)``
   *
   * <MenuItem menuListComponent={StyledMenuList}>
   *   <MenuItemButton>Sub Menu</MenuItemButton>
   *   <StyledMenuList></StyledMenuList>
   * </MenuItem
   * ```
   */
  menuListComponent: React.JSXElementConstructor<any>;
}

/**
 * A React component for rendering a menu item.
 *
 * If the children include a menu list component, this component will render as a menu item with a submenu.
 * Otherwise, it will render a plain menu item.
 *
 * @param props - The props for the MenuItem component.
 * @returns A JSX element representing the menu item.
 */
export const MenuItem = (props: MenuItemProps): JSX.Element => {
  if (hasMenuListChild(props.children, props.menuListComponent)) {
    return <MenuItemWithChildMenuList {...props} />;
  }

  return <ListItem {...props}>{props.children}</ListItem>;
};

MenuItem.defaultProps = {
  menuButtonComponent: MenuItemButton,
  menuListComponent: MenuList,
};

const MenuItemWithChildMenuList = (props: MenuItemProps): JSX.Element => {
  const theme = useTheme();
  const { setChildMenuListIsVisible } = useMenuListContext();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <ListItem>
      {React.Children.map(props.children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        switch (child.type) {
          case props.menuListComponent: {
            return (
              <ChildMenuList
                isVisible={isOpen}
                onClose={() => {
                  setIsOpen(false);
                  setChildMenuListIsVisible(false);
                }}
              >
                {child}
              </ChildMenuList>
            );
          }

          case props.menuButtonComponent: {
            return React.cloneElement<any>(child, {
              right: theme._reactComponents.MenuItem?.childMenuIndicator,
              onClick: () => {
                setIsOpen((prev) => !prev);
                setChildMenuListIsVisible(true);
              },
            });
          }

          default:
            return child;
        }
      })}
    </ListItem>
  );
};

/**
 * Renders a child <MenuList> though a <Portal> into the Menu Panel component.
 * This is necessary to facilitate the animated slide left and right of child <MenuList>
 *
 * The child <MenuList> is cloned, and we inject a Back Button into its children prop.
 *
 * @param props
 * @returns JSX.Element
 */
const ChildMenuList = (props: {
  children: React.ReactElement;
  isVisible: boolean;
  onClose: () => void;
}): JSX.Element => {
  const { panelId } = useMenuContext();

  return (
    <Portal container={() => document.getElementById(panelId)}>
      {React.cloneElement<any>(
        props.children,
        { isVisible: props.isVisible },
        <BackButtonItem onClick={props.onClose} />,
        React.Children.map(props.children.props.children, (child) => child)
      )}
    </Portal>
  );
};

const BackButtonItem = (props: { onClick: () => void }) => {
  const theme = useTheme();

  return (
    <MenuItem>
      <MenuItemButton left={theme._reactComponents.MenuItem?.backMenuIndicator} onClick={props.onClick}>
        Back
      </MenuItemButton>
    </MenuItem>
  );
};

const ListItem = styled.li``;

const hasMenuListChild = (children: React.ReactNode, componentType: React.JSXElementConstructor<any>) => {
  let hasMenuListChild = false;

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type === componentType) {
      hasMenuListChild = true;
    }
  });

  return hasMenuListChild;
};
