import { isEqual as _isEqual, omit as _omit, pick as _pick } from 'lodash';
import { Publishable } from '../../publishable';
import { ProtectedEntityKeys } from '../repositories/publishableEntityRepositoryHelpers';
import { ProtectedRevisionKeys } from '../repositories/publishableRevisionRepositoryHelpers';

const ManagedEntityKeys = [...ProtectedEntityKeys] as const;
const ManagedRevisionKeys = [
  ...ProtectedRevisionKeys,
  // These keys are managed by this service through various functions.
  'index',
  'publishedAt',
  'publishedBy',
  'revertedAt',
  'revertedBy',
  'revisionSource',
  'revisionType',
  'unpublishedAt',
  'unpublishedBy',
] as const;

export const mergePublishable = <B extends Publishable, U extends Partial<Publishable>>(base: B, update: U): B & U => {
  return {
    ...base,
    ...update,
    id: base.id,
    entity: {
      ..._omit(update.entity ?? base.entity, ManagedEntityKeys),
      ..._pick(base.entity, ManagedEntityKeys),
    },
    lock: base.lock ?? update.lock,
    metadata: base.metadata ?? update.metadata,
    revision: {
      ..._omit(update.revision ?? base.revision, ManagedRevisionKeys),
      ..._pick(base.revision, ManagedRevisionKeys),
    },
  };
};

export const isPublishableModified = <B extends Partial<Publishable>, U extends Partial<Publishable>>(
  a: B,
  b: U
): boolean => {
  return _isEqual(prepareForComparison(a), prepareForComparison(b)) === false;
};

const IncomparablePublishableKeys = ['lock', 'metadata'];

const prepareForComparison = <P extends Partial<Publishable>>(publishable: P): Partial<P> => {
  return {
    ..._omit(publishable, IncomparablePublishableKeys),
    entity: _omit(publishable.entity, ManagedEntityKeys),
    revision: _omit(publishable.revision, ManagedRevisionKeys),
  };
};
