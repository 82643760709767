import React, { useContext } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { PageLoader } from '../pages/PageLoader';
import { SiteContext } from '../site/SiteContext';

type RoutesProps = {
  children: React.ReactNode;
};

export const Routes = (props: RoutesProps): JSX.Element | null => {
  const { getSite } = useContext(SiteContext);
  const site = getSite();

  const hubs = site.hubs;

  const hubPaths = hubs.reduce((acc: Array<RouteObject>, hub) => {
    const paths = hub.paths.map(
      (path): RouteObject => ({
        path: `${path}/*`,
        element: <PageLoader pathname={path}>{props.children}</PageLoader>,
      })
    );

    acc.push(...paths);

    return acc;
  }, []);

  return useRoutes([...hubPaths, { path: '*', element: <PageLoader>{props.children}</PageLoader> }]);
};
