import { Alignment, InfoButtonsLayout, InfoButtonsWidth, ListItemWeb } from '@nucleus/types/web';
import { inverseAspectRatioAsPercent } from '@nucleus/web-theme';
import { buildTransparentColorFromCssVariable, media, nucleusClass, SIZE, Text } from '@nucleus/web-theme-elements';
import classNames from 'classnames';
import React, { MouseEvent, ReactNode, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { BlockMedia } from '../blocks/BlockMedia';
import { hasText } from '../lib/richtext';
import { Body, Byline, Headline, Overline } from './Base';
import { ButtonPrimary, ButtonSecondaryAlt } from './Button';
import { IconXThin } from './Icons';
import { InfoButtons } from './InfoButtons';

type Props = {
  className?: string;
  itemBodyAlignment?: Alignment;
  itemButtonAlignment?: Alignment;
  itemButtonsLayout?: InfoButtonsLayout;
  itemButtonsWidth?: InfoButtonsWidth;
  itemHeadlineAlignment?: Alignment;
  items: Array<ListItemWeb>;
  truncateAt?: number;
};

export const AccordionList = styled(({ className, items = [], truncateAt, ...props }: Props): JSX.Element => {
  const [truncate, setTruncate] = useState<number | undefined>(truncateAt);
  const showMore = truncateAt !== undefined && truncate !== undefined;

  const handleShowMoreClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setTruncate(undefined);
  };

  return (
    <AccordionListContainer className={classNames([className, nucleusClass('accordion')])}>
      <Accordion>
        {items.slice(0, truncate ?? items.length).map((item) => {
          const renderMedia = item.mediaItems?.[0]?.image !== undefined;
          return (
            <Accordion.Item
              key={item.id}
              itemBodyAlignment={props.itemBodyAlignment}
              itemButtonAlignment={props.itemButtonAlignment}
              itemHeadlineAlignment={props.itemHeadlineAlignment}
              header={
                <Title>
                  {hasText(item.overline) && <ListOverline nodes={item.overline} />}
                  {hasText(item.headline) && <ListHeadline nodes={item.headline} />}
                  {hasText(item.byline) && <ListByline nodes={item.byline} />}
                </Title>
              }
            >
              {renderMedia && <BlockMedia media={item.mediaItems?.[0]} />}
              {hasText(item.body) && <ListBody nodes={item.body} />}
              <InfoButtons
                buttonsAlignment={props.itemButtonAlignment}
                buttonsWidth={props.itemButtonsWidth}
                buttonsLayout={props.itemButtonsLayout}
                buttons={item.buttons}
              />
            </Accordion.Item>
          );
        })}
      </Accordion>

      {truncateAt && (
        <TruncateActions>
          {showMore && (
            <ButtonPrimary widthMode="full" onClick={handleShowMoreClick}>
              Show more
            </ButtonPrimary>
          )}
        </TruncateActions>
      )}
    </AccordionListContainer>
  );
})``;

const ListOverline = styled(Overline).attrs({ className: Text.ClassName['label6'] })`
  && {
    margin: 0;
  }
`;

const ListHeadline = styled(Headline).attrs({ className: Text.ClassName['headline5'] })`
  && {
    margin: 0;
    line-height: 1.2em;
  }
`;

const ListByline = styled(Byline).attrs({ className: Text.ClassName['paragraph3'] })`
  && {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
`;

const ListBody = styled(Body).attrs({ className: Text.ClassName['paragraph3'] })`
  margin: 1em 0;
`;

const AccordionListContainer = styled.div``;

const TruncateActions = styled.div`
  padding: ${SIZE[4]} 0;
`;

type AccordionItemProps = {
  header?: ReactNode;
  children?: ReactNode;
  itemBodyAlignment?: Alignment;
  itemButtonAlignment?: Alignment;
  itemHeadlineAlignment?: Alignment;
};

const AccordionItem = (props: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const click = (e: MouseEvent) => {
    e.preventDefault();
    if (ref.current === null) {
      return;
    }
    let height = isOpen ? 0 : ref.current.offsetHeight;
    setHeight(height);
    setIsOpen((isOpen) => !isOpen);
  };

  // if open recalculate height on resize
  React.useEffect(() => {
    if (ref.current === null || isOpen === false) {
      return;
    }

    const handleResize = () => {
      if (ref.current === null) {
        return;
      }
      setHeight(ref.current.offsetHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  return (
    <StyledAccordionItem
      className={nucleusClass('accordion-item')}
      isOpen={isOpen}
      itemBodyAlignment={props.itemBodyAlignment}
      itemButtonAlignment={props.itemButtonAlignment}
      itemHeadlineAlignment={props.itemHeadlineAlignment}
    >
      <Header onClick={(e) => click(e)}>
        {props.header}
        <HeaderRight>
          <ToggleButton />
        </HeaderRight>
      </Header>
      <Content style={{ height: height }}>
        <div ref={ref}>{props.children}</div>
      </Content>
    </StyledAccordionItem>
  );
};

const Header = styled.a`
  display: flex;
  position: relative;
  color: var(--color-section-text);
  padding: 1.5rem 0.5rem;
  text-decoration: none !important;
  cursor: pointer;
`;

const Title = styled.div`
  & > :first-child > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

const HeaderRight = styled.div`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
`;

const ToggleIcon = styled(IconXThin)`
  display: block;
  transition: all 200ms linear;
  transform-origin: center;
  transform: rotate(45deg);
  color: var(--color-section-text);

  && {
    width: 3rem;
    height: 3rem;
  }
`;

const ToggleButton = styled(ButtonSecondaryAlt).attrs({
  shape: 'square',
  icon: <ToggleIcon />,
  size: 'medium',
})`
  --color-button-secondary-alt-background-h: var(--color-section-text-h);
  --color-button-secondary-alt-background-S: var(--color-section-text-s);
  --color-button-secondary-alt-background-l: var(--color-section-text-l);
  --color-button-secondary-alt-text: var(--color-section-text);
`;

const Content = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 200ms ease-in-out;
  color: var(--color-section-text);

  & > div {
    padding: 0 0.5rem 2.4rem;
  }

  ${BlockMedia} {
    & > div {
      padding-top: ${inverseAspectRatioAsPercent(2.6, 1.4)};
    }
  }
`;

const JustifyContentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

type StyledAccordionItemProps = {
  isOpen: boolean;
  itemBodyAlignment?: Alignment;
  itemButtonAlignment?: Alignment;
  itemHeadlineAlignment?: Alignment;
};

const StyledAccordionItem = styled.li<StyledAccordionItemProps>`
  --border: 2px solid ${buildTransparentColorFromCssVariable('--color-section-text', 0.5)};
  background-color: transparent;
  border-top: var(--border);
  border-bottom: var(--border);
  transition: background-color 250ms ease-out;
  padding: 0;

  & + & {
    border-top: none; // one line between items
  }

  ${ToggleButton} {
    ${ToggleIcon} {
      transform: ${(props) => (props.isOpen ? 'rotate(0deg)' : 'rotate(45deg)')};
    }

    ${(props) =>
      !props.isOpen &&
      css`
        & > div {
          background: transparent;
        }
      `}
  }

  ${Header} {
    justify-content: ${({ itemHeadlineAlignment }) =>
      itemHeadlineAlignment ? JustifyContentMap[itemHeadlineAlignment] : 'flex-start'};

    ${Title} {
      ${({ itemHeadlineAlignment }) => {
        if (itemHeadlineAlignment === 'center') {
          return css`
            margin: 0 7.2rem;
          `;
        }
        return css`
          margin-right: 7.2rem;
        `;
      }}
    }

    ${ListHeadline}, ${ListOverline}, ${ListByline} {
      text-align: ${({ itemHeadlineAlignment }) => itemHeadlineAlignment};
    }
  }

  ${ListBody} {
    text-align: ${({ itemBodyAlignment }) => itemBodyAlignment};
  }

  ${InfoButtons} {
    margin: clamp(3rem, 4vw, 3.6rem) 0;
  }

  ${Content} {
    ${BlockMedia}, ${ListBody} {
      ${media.tabletLandscapeAndUp`
        max-width: 83%;
      `};
    }
  }

  ${Content} {
    ${({ itemBodyAlignment, itemButtonAlignment }) => {
      if (itemBodyAlignment === 'center') {
        return css`
          ${BlockMedia}, ${ListBody} {
            margin-left: auto;
            margin-right: auto;
          }
        `;
      }

      if (itemBodyAlignment === 'right') {
        return css`
          ${BlockMedia}, ${ListBody} {
            margin-left: auto;
          }
        `;
      }

      if (itemButtonAlignment === 'center') {
        return css`
          ${InfoButtons} {
            margin-left: auto;
            margin-right: auto;
          }
        `;
      }

      if (itemButtonAlignment === 'right') {
        return css`
          ${InfoButtons} {
            margin-left: auto;
          }
        `;
      }
    }}
  }
`;

const StyledAccordion = styled.ul`
  padding: 0 0.6rem;
  list-style: none;
  margin: calc(var(--unit-length) * 0.5) 0;
  width: 100%;
  max-width: 100%;

  ${media.tabletLandscapeAndUp`
    margin-left: auto;
    margin-right: auto;
  `};
`;

const Accordion = Object.assign(StyledAccordion, {
  Item: AccordionItem,
});
