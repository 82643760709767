import { isPlainObject as _isPlainObject } from 'lodash';

export const isNonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

export function isNotEmpty<T>(value: Array<T>): value is Array<T>;
export function isNotEmpty<T extends { [key: string]: any }>(value: T): value is T;
export function isNotEmpty(value: string): value is string;
export function isNotEmpty<T>(value: T | null | undefined): value is T;
export function isNotEmpty(value: any): boolean {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'string') {
    return value.length > 0;
  }

  if (_isPlainObject(value)) {
    for (const _ in value) {
      // It has iterable properties so it's not empty.
      return true;
    }
    return false;
  }

  return isNonNullable(value);
}
