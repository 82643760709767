import { useEffect } from 'react';

/**
 * Use Global/Window Event Listener
 */
export const useEventListener = <K extends keyof ReactEventMap>(
  type: K,
  callback: (this: Window, ev: ReactEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): void => {
  useEffect(() => {
    window.addEventListener(type, callback, options);
    return () => window.removeEventListener(type, callback);
  }, [type, callback, options]);
};
