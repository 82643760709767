import React from 'react';

/** Returns a Ref object where ref.current is a boolean representation of the mount state of the calling component */
export const useMountSafeRef = (): React.MutableRefObject<boolean> => {
  const ref = React.useRef<boolean>(true);

  React.useEffect(
    () => () => {
      ref.current = false;
    },
    []
  );

  return ref;
};

/**
 * `useMountSafeCallback` will return a mount-safe and memoized version of the callback that only changes if one of the `inputs` has changed.
 *
 * This can be used to solve errors like `Warning: Can't perform a React state update on an unmounted component.`
 *
 * Use it to wrap callbacks and state setters that get called in async useEffect hooks
 * */
export const useMountSafeCallback = <T extends (...args: any[]) => any>(
  callback: T,
  deps: React.DependencyList = []
): T => {
  const isMountedRef = useMountSafeRef();

  return React.useCallback(
    (...args: any[]): any => {
      if (isMountedRef.current) {
        return callback(...args);
      }
    },
    deps
  ) as T;
};

/** Returns a stateful value, and a mount safe function to set it */
export const useMountSafeState = <S>(initialState: S | (() => S)): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const [state, setState] = React.useState<S>(initialState);
  return [state, useMountSafeCallback(setState)];
};
