import { CodeSnippet } from '@nucleus/sites/src/types/CodeSnippet';

export const ContentType = {
  Stack: 'stack',
  Section: 'section',
  Hub: 'hub',
  Navigation: 'navigation',
} as const;

export type ContentType = typeof ContentType[keyof typeof ContentType];

type BaseContentData<T extends ContentType, P> = {
  id: string;
  type: T;
  payload: P;
  codeSnippets?: CodeSnippet[];
};

export type StackContent = BaseContentData<typeof ContentType.Stack, { stackId: string }>;
export type SectionContent = BaseContentData<typeof ContentType.Section, { sectionId: string }>;
export type HubContent = BaseContentData<typeof ContentType.Hub, { hubId: string }>;
export type NavigationContent = BaseContentData<typeof ContentType.Navigation, { navigationId: string }>;

export type ContentData = StackContent | SectionContent | HubContent | NavigationContent;

export const KEY_PREFIX_CONTENT = 'content';
