import { SectionAction, SectionActionType } from '@nucleus/types/web';

interface ActionProps {
  href?: string;
  to?: string;
  onClick?: React.EventHandler<React.MouseEvent>;
  target?: '_blank';
  rel?: string;
  displayAs?: SectionAction['displayAs'];
}

export const useActionProps = (action: SectionAction): ActionProps => {
  const props: ActionProps = {
    displayAs: action.displayAs,
  };

  switch (action.type) {
    case SectionActionType.None:
      props.onClick = (e) => {
        e.preventDefault();
      };
      break;

    case SectionActionType.Page:
      props.to = action.destination;
      break;

    case SectionActionType.Overlay:
      props.to = `?overlay=${action.overlayId}&overlayPath=item&overlayDataItem=${action.destination}`;
      break;

    default:
      props.href = action.destination;
      break;
  }

  if (action.openInNewTab) {
    props.target = '_blank';
    props.rel = 'noopener noreferrer';
  }

  return props;
};
