import invariant from 'invariant';
import React, { createContext, forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { createPortal } from 'react-dom';
import { StyleSheetManager } from 'styled-components';

type FrameContext = {
  document?: Document;
  window?: Window;
};

const FrameContext = createContext<FrameContext>(null!);

/**
 * Provides access to a ReactFrames document and window variables
 */
export const useFrameContext = (): FrameContext => {
  invariant(useContext(FrameContext) !== null, 'useFrameContext() may be used only in the context of <ReactFrame>');
  return useContext(FrameContext);
};

type Props = {
  children?: React.ReactNode;
  head?: React.ReactNode;
  /** Dangerously sets the innerHTML of the body element, will execute any <script> tags within */
  innerHTML?: string;
} & React.IframeHTMLAttributes<HTMLIFrameElement>;

/**
 * A React component for rendering JSX directly into an iframe, via children and head prop.
 * It also exposes the document, and window variables through Context and may be accessed by calling
 * useFrameContext().
 */
export const ReactFrame = forwardRef<HTMLIFrameElement, Props>(({ children, head, innerHTML, ...props }, ref) => {
  const nodeRef = useRef<HTMLIFrameElement>(null);

  useImperativeHandle(ref, () => nodeRef.current!);

  const headNode = nodeRef.current?.contentWindow?.document?.head;
  const bodyNode = nodeRef.current?.contentWindow?.document?.body;

  useEffect(() => {
    if (bodyNode !== undefined && innerHTML !== undefined) {
      // https://dev.to/christo_pr/render-dangerous-content-with-react-2j7j
      const parsedHTML = document.createRange().createContextualFragment(innerHTML);
      bodyNode.appendChild(parsedHTML);
    }
  }, [bodyNode]);

  const context: FrameContext = {
    document: nodeRef.current?.contentWindow?.document,
    window: nodeRef.current?.contentWindow ?? undefined,
  };

  return (
    <iframe {...props} ref={nodeRef}>
      <FrameContext.Provider value={context}>
        {headNode && head && createPortal(head, headNode)}
        {bodyNode && children && createPortal(children, bodyNode)}
      </FrameContext.Provider>
    </iframe>
  );
});

ReactFrame.displayName = 'ReactFrame';

/**
 * A Wrapped ReactFrame component that inject Styled css into the iframe head
 */
export const StyledReactFrame = forwardRef<HTMLIFrameElement, Props>(({ children, ...props }, ref) => {
  return (
    <ReactFrame {...props} ref={ref}>
      <InjectFrameStyles>{children}</InjectFrameStyles>
    </ReactFrame>
  );
});

StyledReactFrame.displayName = 'StyledReactFrame';

const InjectFrameStyles = (props: { children?: React.ReactNode }) => {
  const { document } = useFrameContext();

  if (document === undefined) {
    return <>{props.children}</>;
  }

  return <StyleSheetManager target={document.head}>{props.children}</StyleSheetManager>;
};
