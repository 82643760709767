import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getPost } from '../../query/post';
import { Renderer } from '../content/ContentRenderer';
import { PostHubContext } from '../hubContext/PostHubContext';

export const Post = (): JSX.Element => {
  const { hubId } = useContext(PostHubContext);
  const { post } = useParams();
  const { isLoading, error, data } = useQuery({
    queryKey: ['postHub', 'post', post],
    queryFn: () => getPost(hubId, post ?? ''),
    suspense: true,
  });

  return <Renderer isLoading={isLoading} error={error} page={data?.page} />;
};
