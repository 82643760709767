import { SectionConfigBuilder } from '../ThemeConfigBuilder';
import { body, byline, headline, overline } from '../fields/fields';

export default new SectionConfigBuilder()
  .setContext('picker', {
    label: 'Calendar',
    description: 'Specifically collect date, time and ranges',
    icon: 'Calendar',
  })
  .setContext('editor', {
    label: 'Calendar Section',
    description: '',
    icon: 'Calendar',
  })
  .addLayout('layout1', (layout) => {
    layout
      .setIcon('CalendarLayout1')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockVariants({
        label: 'Calendar Placement',
        controlType: 'left right',
        options: [
          {
            value: 'variant1',
            label: 'Left',
          },
          {
            value: 'variant2',
            label: 'Right',
          },
        ],
      });
  })
  .addLayout('layout2', (layout) => {
    layout
      .setIcon('CalendarLayout2')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(1, 'Media');
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(1);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout3', (layout) => {
    layout
      .setIcon('CalendarLayout3')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(3);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout4', (layout) => {
    layout
      .setIcon('CalendarLayout4')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .setBlockBodyMaxColumns(3);
  })
  .addLayout('layout5', (layout) => {
    layout
      .setIcon('CalendarLayout5')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      })
      .addBlock((block) => {
        block.setText([overline, headline, byline, body]);
        block.setMedia(0);
        block.setActions(Infinity);
      });
  })
  .addLayout('layout6', (layout) => {
    layout
      .setDescription('Calendar Only')
      .setIcon('CalendarLayout6')
      .setInsetBlock((block) => {
        block.setText([overline, headline, body]);
        block.setMedia(0);
        block.setActions(0);
      })
      .addBlock((block) => {
        block.setText([]);
        block.setMedia(0);
        block.setActions(0);
      });
  })
  .build();
