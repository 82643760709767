import { GalleryItemWeb } from '@nucleus/types/web/sections/GallerySection';
import { Text, media } from '@nucleus/web-theme-elements';
import copy from 'copy-to-clipboard';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { usePreviousState } from '../../hooks/usePreviousState';
import { useSearchParams } from '../../hooks/useSearchParams';
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryAlt } from '../Button';
import { IconAngleLeft, IconAngleRight, IconShare, IconX } from '../Icons';
import { Gallery } from '../galleries/Gallery';
import { OVERLAY_SEARCH_PARAMS, Overlay, OverlayContext, OverlayController } from './Overlay';
import { calculateTransitionType } from './lib/transitions';
import { nucleusClass } from '@nucleus/web-theme-elements';

export interface GalleryItem extends GalleryItemWeb {
  onClick?: (e: React.MouseEvent, itemId: string) => void;
}

export interface GalleryOverlayProps {
  id: string;
  items: Array<GalleryItem>;
}

export interface GalleryOverlayContextValue {
  items: Array<GalleryItem>;
  currentPath: string;
  previousPath: string;
  transitionType: string;
}
const GalleryOverlayContext = React.createContext({} as GalleryOverlayContextValue);

// Scrolling gallery with nested individual item overlays with prev/next buttons
export const GalleryOverlay = (props: GalleryOverlayProps): JSX.Element => {
  const [searchParams] = useSearchParams({ include: OVERLAY_SEARCH_PARAMS });
  const isOpen = (searchParams['overlay'] as string) === props.id;
  const path = searchParams['overlayPath'] as string;
  const galleryItem = searchParams['overlayDataItem'] as string;

  const PATHS = ['gallery', 'gallery,item'] as const;
  const defaultPath = PATHS[0];
  type Paths = (typeof PATHS)[number];

  const routes: Record<Paths, React.ReactNode> = {
    gallery: <GalleryOverlayPanel items={props.items} />,
    'gallery,item': <GalleryItemOverlayPanel items={props.items} itemId={galleryItem} />,
  };

  const availablePaths = Object.keys(routes);
  const previousPath = usePreviousState(path) ?? '';
  const currentPath = (availablePaths.includes(path) ? path : defaultPath) as Paths;
  const currentScreen = routes[currentPath];
  const transitionType = calculateTransitionType(previousPath, currentPath);

  const contextValue = React.useMemo(
    (): GalleryOverlayContextValue => ({
      items: props.items,
      currentPath: currentPath,
      previousPath: previousPath,
      transitionType: transitionType,
    }),
    [props.items, currentPath, previousPath, transitionType]
  );

  return (
    <OverlayController currentPath={currentPath} defaultPath={defaultPath}>
      <GalleryOverlayContext.Provider value={contextValue}>
        <Overlay show={isOpen} contentWidth="100%">
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div key={currentPath} initial="in" animate="center" exit="out" variants={animationVariants}>
              {currentScreen}
            </motion.div>
          </AnimatePresence>
        </Overlay>
      </GalleryOverlayContext.Provider>
    </OverlayController>
  );
};

// Individual gallery item overlays with prev/next buttons
export const GalleryItemOverlay = (props: GalleryOverlayProps): JSX.Element => {
  const [searchParams] = useSearchParams({ include: OVERLAY_SEARCH_PARAMS });
  const isOpen = (searchParams['overlay'] as string) === props.id;
  const path = searchParams['overlayPath'] as string;
  const galleryItem = searchParams['overlayDataItem'] as string;

  const PATHS = ['item'] as const;
  const defaultPath = PATHS[0];
  type Paths = (typeof PATHS)[number];

  const routes: Record<Paths, React.ReactNode> = {
    item: <GalleryItemOverlayPanel items={props.items} itemId={galleryItem} />,
  };

  const availablePaths = Object.keys(routes);
  const previousPath = usePreviousState(path) ?? '';
  const currentPath = (availablePaths.includes(path) ? path : defaultPath) as Paths;
  const currentScreen = routes[currentPath];
  const transitionType = calculateTransitionType(previousPath, currentPath);

  const contextValue = React.useMemo(
    (): GalleryOverlayContextValue => ({
      items: props.items,
      currentPath: currentPath,
      previousPath: previousPath,
      transitionType: transitionType,
    }),
    [props.items, currentPath, previousPath, transitionType]
  );

  return (
    <OverlayController currentPath={currentPath} defaultPath={defaultPath}>
      <GalleryOverlayContext.Provider value={contextValue}>
        <Overlay show={isOpen}>
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div key={currentPath} initial="in" animate="center" exit="out" variants={animationVariants}>
              {currentScreen}
            </motion.div>
          </AnimatePresence>
        </Overlay>
      </GalleryOverlayContext.Provider>
    </OverlayController>
  );
};

const animationVariants = {
  in: {
    opacity: 1,
  },
  center: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const GalleryOverlayPanel = (props: { items: Array<GalleryItem> }): JSX.Element => {
  const { navigateTo, navigateBack } = React.useContext(OverlayContext);

  const handleClose = () => {
    navigateBack();
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleClose();
  };

  const handleShare = () => {
    const shareLink = `${window.location.origin}${window.location.pathname}${window.location.search}`;
    copy(shareLink);
    alert('Link copied');
  };

  const handleShareClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleShare();
  };

  const handleItemClick = (e: React.MouseEvent, itemId: string) => {
    e.stopPropagation();
    navigateTo('item', { overlayDataItem: itemId });
  };

  return (
    <>
      <Overlay.KeyboardControls onClose={handleClose} />
      <Overlay.PanelHeader
        left={
          <ButtonSecondary shape="square" size="medium" onClick={handleCloseClick} icon={<IconX />}></ButtonSecondary>
        }
        right={
          <ButtonSecondary
            shape="square"
            size="medium"
            onClick={handleShareClick}
            icon={<IconShare />}
          ></ButtonSecondary>
        }
      />
      <GalleryContainer>
        <Gallery.Adaptive items={props.items} onItemClick={handleItemClick} />
      </GalleryContainer>
    </>
  );
};

const GalleryContainer = styled.div`
  width: 83%;
  margin: 0 auto;

  ${media.tabletPortraitAndUp`
    width: 67%;
  `}
`;

export const GalleryItemOverlayPanel = (props: { itemId: string; items: Array<GalleryItem> }): JSX.Element => {
  const { navigateBack, navigateTo } = React.useContext(OverlayContext);

  const itemId = props.itemId;
  const itemIndex = props.items.findIndex((item) => item.id === itemId);

  React.useEffect(() => {
    // redirect back if item not found
    if (itemIndex === -1) {
      console.error('[Gallery] Item not found');
      // navigateBack();
    }
  }, [itemIndex, navigateBack]);

  const currentItem = props.items[itemIndex] ?? {};

  const handlePrev = () => {
    const prevItemIndex = (((itemIndex - 1) % props.items.length) + props.items.length) % props.items.length;
    const prevItem = props.items[prevItemIndex];
    navigateTo('', { overlayDataItem: prevItem.id });
  };

  const handleNext = () => {
    const nextItemIndex = (((itemIndex + 1) % props.items.length) + props.items.length) % props.items.length;
    const nextItem = props.items[nextItemIndex];
    navigateTo('', { overlayDataItem: nextItem.id });
  };

  const handleClose = () => {
    navigateBack({ overlayDataItem: currentItem.id });
  };

  const handleShare = () => {
    const shareLink = `${window.location.origin}${window.location.pathname}${window.location.search}`;
    copy(shareLink);
    alert('Link copied');
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleClose();
  };

  const handlePrevClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handlePrev();
  };

  const handleNextClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleNext();
  };

  const handleShareClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleShare();
  };

  return (
    <Overlay.Panel className={nucleusClass('gallery-overlay')}>
      <Overlay.KeyboardControls onClose={handleClose} onPrev={handlePrev} onNext={handleNext} />
      <Overlay.PanelContent>
        <Overlay.PanelHeader
          left={<ButtonSecondary shape="square" size="medium" icon={<IconAngleLeft />} onClick={handleCloseClick} />}
          center={
            <Counter>
              {itemIndex + 1} / {props.items.length}
            </Counter>
          }
          right={
            <ButtonSecondary
              shape="square"
              size="medium"
              onClick={handleShareClick}
              icon={<IconShare />}
            ></ButtonSecondary>
          }
        />
        <Overlay.PanelBody>
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div key={currentItem.id} initial="in" animate="center" exit="out" variants={animationVariants}>
              <GalleryItem item={currentItem} />
            </motion.div>
          </AnimatePresence>
        </Overlay.PanelBody>
        <Overlay.PanelFooter>
          <FooterInner>
            <FooterContent>
              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div key={currentItem.id} initial="in" animate="center" exit="out" variants={animationVariants}>
                  <Caption>{currentItem.caption}</Caption>
                  <DownloadLink href={currentItem.image?.src} target="_blank">
                    filename.jpg — Download
                  </DownloadLink>
                </motion.div>
              </AnimatePresence>
            </FooterContent>
            <PrevNextButtons>
              <ButtonSecondaryAlt shape="square" size="medium" icon={<IconAngleLeft />} onClick={handlePrevClick} />
              <ButtonPrimary shape="square" size="medium" icon={<IconAngleRight />} onClick={handleNextClick} />
            </PrevNextButtons>
          </FooterInner>
        </Overlay.PanelFooter>
      </Overlay.PanelContent>
    </Overlay.Panel>
  );
};

const FooterInner = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  ${media.tabletPortraitAndUp`
    justify-content: center;
  `}
`;

const FooterContent = styled.div`
  ${media.tabletPortraitAndUp`
    text-align: center;
  `}
`;

const PrevNextButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  ${media.tabletPortraitAndUp`
    position: fixed;
    top: calc(50% - 10rem); // minus half footer height
    right: 2.5rem;
    flex-direction: column;
    gap: 2rem;

    & :first-of-type {
      order: 2;
    }
  `}
`;

const Counter = styled.span`
  font-size: 1.6rem;
  color: #000;
  padding: 0 1.2rem;
  opacity: 0.5;
`;

const Caption = styled(Text.P3)`
  max-width: 50rem;
`;

const DownloadLink = styled.a`
  ${Text.Styles.L6};
  text-decoration: underline;
  max-width: 40rem;
  color: #000;
  opacity: 0.5;
`;

const GalleryItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const GalleryItemContent = styled.div`
  display: flex;
  width: calc(100vw - 4rem);
  height: 100%;

  ${media.tabletPortraitAndUp`
    width: calc(100vw - 20rem);
  `}

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const GalleryItem = (props: { item: GalleryItem }) => {
  return (
    <GalleryItemContainer className={nucleusClass('gallery-item')} key={props.item.id}>
      <GalleryItemContent>
        {props.item && (
          <img
            src={props.item.image?.src}
            alt=""
            height={props.item.image?.dimensions?.height}
            width={props.item?.image?.dimensions?.width}
          />
        )}
      </GalleryItemContent>
    </GalleryItemContainer>
  );
};
