export enum ContactType {
  EmailAddress = 'emailaddress',
  PhoneNumber = 'phonenumber',
  PostalAddress = 'postaladdress',
  SocialMediaLink = 'socialmedialink',
  Url = 'url',
}

interface ContactBase<T extends ContactType> {
  id: string;
  contactType: T;
}

export enum EmailAddressType {
  General = 'general',
  Personal = 'personal',
}

export interface EmailAddressContact extends ContactBase<ContactType.EmailAddress> {
  emailType: EmailAddressType;
  label: string;
  emailAddress: string;
}

export enum PhoneNumberType {
  Fax = 'fax',
  Local = 'local',
  Mobile = 'mobile',
  National = 'national',
  TollFree = 'tollfree',
}

export interface PhoneNumberContact extends ContactBase<ContactType.PhoneNumber> {
  phoneType: PhoneNumberType;
  label: string;
  countryCode?: string;
  phoneNumber: string;
  extension?: string;
}

export enum PostalAddressType {
  General = 'general',
  Personal = 'personal',
  PostalBox = 'postalbox',
}

export interface PostalAddressContact extends ContactBase<ContactType.PostalAddress> {
  postalAddressType: PostalAddressType;
  label: string;
  streetAddress: string;
  streetAddress2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export enum SocialMediaPlatform {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Pinterest = 'Pinterest',
  Reddit = 'Reddit',
  Snapchat = 'Snapchat',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  WhatsApp = 'WhatsApp',
  YouTube = 'YouTube',
}

export interface SocialMediaLinkContact extends ContactBase<ContactType.SocialMediaLink> {
  platform: SocialMediaPlatform;
  label?: string;
  username?: string;
  url: string;
}

export interface UrlContact extends ContactBase<ContactType.Url> {
  label?: string;
  url: string;
}

export type Contact =
  | EmailAddressContact
  | PhoneNumberContact
  | PostalAddressContact
  | SocialMediaLinkContact
  | UrlContact;
