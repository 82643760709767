import { QueryWebEndpoint } from '@nucleus/web-hosting';
import { useUser } from '@nucleus/web-theme';
import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { querySearch } from '../queries/search';

type QueryResult = Awaited<ReturnType<typeof querySearch>>;

export const useSermonSearch = (
  engineId?: string,
  params?: QueryWebEndpoint['queryStringParameters']
): UseInfiniteQueryResult<QueryResult> => {
  const [useSuspense, setUseSuspense] = useState(true);
  const { currentUser, jwtToken } = useUser();

  useEffect(() => {
    setUseSuspense(false);
  }, []);

  return useInfiniteQuery({
    queryKey: ['sermonHub', 'sermon', currentUser?.id, params],
    queryFn: ({ pageParam }) => querySearch(engineId, jwtToken, { ...params, cursor: pageParam }),
    suspense: useSuspense,
    getNextPageParam: (lastPage) => lastPage.cursor,
  });
};
