import { formatDateToIso as libFormatDateToIso, parseIsoToDate as libParseIsoToDate } from '@nucleus/lib-datetime';
import { isAfter, isValid, parseISO } from 'date-fns';
import * as yup from 'yup';

export const ISO8601Schema = yup
  .string()
  .matches(
    /^\d{4}-(0[1-9]|10|11|12)-(0[1-9]|[1-2][0-9]|30|31)T([0-1][0-9]|2[0-3]):[0-5]\d:[0-5]\d(\.\d{3})?(Z|[+-](0\d|1[0-4])(:?[0-5]\d)?)$/
  );

export const dateValidation = (value: any): boolean => {
  if (value === undefined || value === '') {
    return false;
  }
  if (value instanceof Date === false) {
    value = parseISO(value);
  }
  return isValid(value);
};

export const futureDate = (value: any, compareValue?: any): boolean => {
  if (value === undefined || value === '') {
    return false;
  }
  const date = parseISO(value);

  if (!isValid(date)) {
    return false;
  }

  const compareDate = compareValue ? parseISO(compareValue) : new Date();

  if (!isValid(compareDate)) {
    return false;
  }

  return isAfter(date, compareDate);
};

/**
 * @deprecated use import { parseIsoToDate } from '@nucleus/lib-datetime'
 */
export const parseIsoToDate = libParseIsoToDate;

/**
 * @deprecated use import { formatDateToIso } from '@nucleus/lib-datetime'
 */
export const formatDateToIso = libFormatDateToIso;
