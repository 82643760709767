import { FontPaletteConfig } from '@nucleus/types/web';

// TODO: replace me with package font packs when developed
export const fallbackFontPack: FontPaletteConfig = {
  baseScale: 16,
  scale: 16,
  id: 'dmSans',
  updatedAt: '2021-03-27T12:00:00.000Z',
  styles: {
    headline1: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 90,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -1.5 / 90,
      lineHeightEm: 90 / 90,
      source: 'google',
    },
    headline2: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 76,
      fontStyle: 'normal',
      fontWeight: '700',
      forceBold: false,
      letterSpacingEm: -1.75 / 76,
      lineHeightEm: 74 / 76,
      source: 'google',
    },
    headline3: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 60,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -1.25 / 60,
      lineHeightEm: 62 / 60,
      source: 'google',
    },
    headline4: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 46,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 46,
      lineHeightEm: 50 / 46,
      source: 'google',
    },
    headline5: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 38,
      fontStyle: 'normal',
      fontWeight: '700',
      forceBold: false,
      letterSpacingEm: -1.5 / 38,
      lineHeightEm: 38 / 38,
      source: 'google',
    },
    headline6: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 32,
      fontStyle: 'normal',
      fontWeight: '700',
      forceBold: false,
      letterSpacingEm: -1 / 32,
      lineHeightEm: 32 / 32,
      source: 'google',
    },
    paragraph1: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 26,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.75 / 26,
      lineHeightEm: 36 / 26,
      source: 'google',
    },
    paragraph2: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 24,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 24,
      lineHeightEm: 32 / 24,
      source: 'google',
    },
    paragraph3: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 22,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 22,
      lineHeightEm: 32 / 22,
      source: 'google',
    },
    paragraph4: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 16,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 16,
      lineHeightEm: 20 / 16,
      source: 'google',
    },
    description1: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 18,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 18,
      lineHeightEm: 24 / 18,
      source: 'google',
    },
    description2: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 16,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 16,
      lineHeightEm: 20 / 16,
      source: 'google',
    },
    blockquote1: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 24,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 24,
      lineHeightEm: 32 / 24,
      source: 'google',
    },
    blockquote2: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 22,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 22,
      lineHeightEm: 32 / 22,
      source: 'google',
    },
    label1: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 28,
      fontStyle: 'normal',
      fontWeight: '700',
      forceBold: false,
      letterSpacingEm: -1 / 28,
      lineHeightEm: 26 / 28,
      source: 'google',
    },
    label2: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 24,
      fontStyle: 'normal',
      fontWeight: '500',
      forceBold: false,
      letterSpacingEm: -1 / 24,
      lineHeightEm: 26 / 24,
      source: 'google',
    },
    label3: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 22,
      fontStyle: 'normal',
      fontWeight: '500',
      forceBold: false,
      letterSpacingEm: -0.5 / 22,
      lineHeightEm: 24 / 22,
      source: 'google',
    },
    label4: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 18,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 18,
      lineHeightEm: 22 / 18,
      source: 'google',
    },
    label5: {
      capitalization: 'uppercase',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 16,
      fontStyle: 'normal',
      fontWeight: '500',
      forceBold: false,
      letterSpacingEm: 3 / 16,
      lineHeightEm: 20 / 16,
      source: 'google',
    },
    label6: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 14,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 14,
      lineHeightEm: 20 / 14,
      source: 'google',
    },
    navigation1: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 46,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 46,
      lineHeightEm: 50 / 46,
      source: 'google',
    },
    navigation2: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 26,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.75 / 26,
      lineHeightEm: 36 / 26,
      source: 'google',
    },
    navigation3: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 24,
      fontStyle: 'normal',
      fontWeight: '700',
      forceBold: false,
      letterSpacingEm: -0.75 / 24,
      lineHeightEm: 28 / 24,
      source: 'google',
    },
    navigation4: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 18,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 18,
      lineHeightEm: 22 / 18,
      source: 'google',
    },
    navigation5: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 18,
      fontStyle: 'normal',
      fontWeight: '400',
      forceBold: false,
      letterSpacingEm: -0.5 / 18,
      lineHeightEm: 22 / 18,
      source: 'google',
    },
    button1: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 22,
      fontStyle: 'normal',
      fontWeight: '500',
      forceBold: false,
      letterSpacingEm: -0.5 / 22,
      lineHeightEm: 24 / 22,
      source: 'google',
    },
    button2: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 18,
      fontStyle: 'normal',
      fontWeight: '500',
      forceBold: false,
      letterSpacingEm: -0.5 / 18,
      lineHeightEm: 20 / 18,
      source: 'google',
    },
    button3: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 22,
      fontStyle: 'normal',
      fontWeight: '500',
      forceBold: false,
      letterSpacingEm: -0.5 / 22,
      lineHeightEm: 24 / 22,
      source: 'google',
    },
    button4: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 18,
      fontStyle: 'normal',
      fontWeight: '500',
      forceBold: false,
      letterSpacingEm: -0.5 / 18,
      lineHeightEm: 20 / 18,
      source: 'google',
    },
    title1: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 32,
      fontStyle: 'normal',
      fontWeight: '700',
      forceBold: false,
      letterSpacingEm: -1 / 32,
      lineHeightEm: 32 / 32,
      source: 'google',
    },
    title2: {
      capitalization: 'none',
      decoration: 'none',
      fontFamily: 'DM Sans',
      fontSizePx: 18,
      fontStyle: 'normal',
      fontWeight: '700',
      forceBold: false,
      letterSpacingEm: -0.6 / 18,
      lineHeightEm: 18 / 18,
      source: 'google',
    },
  },
};
