import { createCompositeString } from '@nucleus/lib-shape';
import { filterNotUndefined } from '@nucleus/src-platform/data';
import { isPlainObject } from 'lodash';

const classPrefix = 'nucleus';

export const nucleusClass = <T extends string | Array<string> | Record<string, unknown> | undefined>(value: T): T => {
  const prefixed = (name: string): string => createCompositeString([classPrefix, name], '-');

  if (typeof value === 'string') {
    return prefixed(value) as T;
  }

  if (Array.isArray(value)) {
    return (filterNotUndefined(value) as Array<string>).map((item) => prefixed(item)) as T;
  }

  if (isPlainObject(value) === true) {
    const result = {} as Record<string, unknown>;
    for (const key in value) {
      if (!value[key]) {
        continue;
      }
      result[prefixed(key)] = value[key];
    }
    return result as T;
  }

  return value;
};
