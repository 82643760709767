import { ColorPaletteKey } from '@nucleus/types/web';

export const BaseThemeColorKey = {
  // Base
  Dark: 'dark',
  Light: 'light',
  // Section
  SectionBackground: 'section-background',
  SectionBackgroundTexture: 'section-background-texture',
  SectionText: 'section-text',
  SectionTextHeadline: 'section-text-headline',
  SectionTextBody: 'section-text-body',
  SectionTextOverline: 'section-text-overline',
  SectionTextByline: 'section-text-byline',
  // Section Inset Block
  SectionInsetBlockBackground: 'section-inset-block-background',
  SectionInsetBlockText: 'section-inset-block-text',
  SectionInsetBlockTextHeadline: 'section-inset-block-text-headline',
  SectionInsetBlockTextBody: 'section-inset-block-text-body',
  SectionInsetBlockTextOverline: 'section-inset-block-text-overline',
  SectionInsetBlockTextByline: 'section-inset-block-text-byline',
  SectionInsetBlockButtonPrimaryBackground: 'section-inset-block-button-primary-background',
  SectionInsetBlockButtonPrimaryText: 'section-inset-block-button-primary-text',
  SectionInsetBlockButtonSecondaryBackground: 'section-inset-block-button-secondary-background',
  SectionInsetBlockButtonSecondaryText: 'section-inset-block-button-secondary-text',
  SectionInsetBlockButtonTertiaryBackground: 'section-inset-block-button-tertiary-background',
  SectionInsetBlockButtonTertiaryText: 'section-inset-block-button-tertiary-text',
  // Card
  CardBackground: 'card-background',
  CardText: 'card-text',
  CardTextHeadline: 'card-text-headline',
  CardTextBody: 'card-text-body',
  CardTextOverline: 'card-text-overline',
  CardTextByline: 'card-text-byline',
  // Buttons
  ButtonPrimaryBackground: 'button-primary-background',
  ButtonPrimaryText: 'button-primary-text',
  ButtonSecondaryBackground: 'button-secondary-background',
  ButtonSecondaryText: 'button-secondary-text',
  ButtonTertiaryBackground: 'button-tertiary-background',
  ButtonTertiaryText: 'button-tertiary-text',
  ButtonSecondaryAltBackground: 'button-secondary-alt-background',
  ButtonSecondaryAltText: 'button-secondary-alt-text',
  // Navigation
  NavigationBackground: 'navigation-background',
  NavigationText: 'navigation-text',
  // Logo
  Logo: 'logo',
  LogoNavigation: 'logo-navigation',
} as const;
export type BaseThemeColorKey = (typeof BaseThemeColorKey)[keyof typeof BaseThemeColorKey];

const Tier1DerivedThemeColorKey = {
  ButtonPrimaryBackgroundHover: 'button-primary-background-hover',
  ButtonPrimaryTextHover: 'button-primary-text-hover',
  ButtonSecondaryBackgroundHover: 'button-secondary-background-hover',
  ButtonSecondaryTextHover: 'button-secondary-text-hover',
  ButtonTertiaryBackgroundHover: 'button-tertiary-background-hover',
  ButtonTertiaryTextHover: 'button-tertiary-text-hover',
  SectionInsetBlockButtonPrimaryBackgroundHover: 'section-inset-block-button-primary-background-hover',
  SectionInsetBlockButtonPrimaryTextHover: 'section-inset-block-button-primary-text-hover',
  SectionInsetBlockButtonSecondaryBackgroundHover: 'section-inset-block-button-secondary-background-hover',
  SectionInsetBlockButtonSecondaryTextHover: 'section-inset-block-button-secondary-text-hover',
  SectionInsetBlockButtonTertiaryBackgroundHover: 'section-inset-block-button-tertiary-background-hover',
  SectionInsetBlockButtonTertiaryTextHover: 'section-inset-block-button-tertiary-text-hover',
  MediaBackground: 'media-background',
  SectionInsetBlockMediaBackground: 'section-inset-block-media-background',
  HighlightBackground: 'highlight-background',
  SectionInsetBlockHighlightBackground: 'section-inset-block-highlight-background',
} as const;
export type Tier1DerivedThemeColorKey = (typeof Tier1DerivedThemeColorKey)[keyof typeof Tier1DerivedThemeColorKey];

const Tier2DerivedThemeColorKey = {
  MediaBackground2: 'media-background2',
  SectionInsetBlockMediaBackground2: 'section-inset-block-media-background2',
  HighlightBackground2: 'highlight-background2',
  SectionInsetBlockHighlightBackground2: 'section-inset-block-highlight-background2',
} as const;
export type Tier2DerivedThemeColorKey = (typeof Tier2DerivedThemeColorKey)[keyof typeof Tier2DerivedThemeColorKey];

const Tier3DerivedThemeColorKey = {
  MediaBackground3: 'media-background3',
  SectionInsetBlockMediaBackground3: 'section-inset-block-media-background3',
} as const;
export type Tier3DerivedThemeColorKey = (typeof Tier3DerivedThemeColorKey)[keyof typeof Tier3DerivedThemeColorKey];

export type BaseThemeColors = Record<BaseThemeColorKey, ColorPaletteKey>;
export type Tier1DerivedThemeColors = Record<Tier1DerivedThemeColorKey, BaseThemeColorKey>;
export type Tier2DerivedThemeColors = Record<Tier2DerivedThemeColorKey, Tier1DerivedThemeColorKey>;
export type Tier3DerivedThemeColors = Record<Tier3DerivedThemeColorKey, Tier2DerivedThemeColorKey>;
export type ThemeColorKey =
  | BaseThemeColorKey
  | Tier1DerivedThemeColorKey
  | Tier2DerivedThemeColorKey
  | Tier3DerivedThemeColorKey;

// NOTE: The order of the color is important, as they will be looped over in the order they are defined.
// Colors that depend on other colors for contrast should be defined after the colors they depend on.
// This is particularly important for button text and background colors, depending on the style of the
// button - text or background may need to be defined first.
export const THEME_COLORS_VARIATION_1: BaseThemeColors = {
  [BaseThemeColorKey.Dark]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.Light]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionBackground]: ColorPaletteKey.Color1,
  [BaseThemeColorKey.SectionBackgroundTexture]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockBackground]: ColorPaletteKey.Color2,
  [BaseThemeColorKey.SectionInsetBlockText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryBackground]: ColorPaletteKey.Color2,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryText]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardBackground]: ColorPaletteKey.Color1,
  [BaseThemeColorKey.CardText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.ButtonPrimaryBackground]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.ButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryBackground]: ColorPaletteKey.Color1,
  [BaseThemeColorKey.ButtonSecondaryText]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.ButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryAltBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonSecondaryAltText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.NavigationBackground]: ColorPaletteKey.Color1,
  [BaseThemeColorKey.NavigationText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.Logo]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.LogoNavigation]: ColorPaletteKey.ColorDark,
};

export const THEME_COLORS_VARIATION_2: BaseThemeColors = {
  [BaseThemeColorKey.Dark]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.Light]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionBackground]: ColorPaletteKey.Color2,
  [BaseThemeColorKey.SectionBackgroundTexture]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockBackground]: ColorPaletteKey.Color1,
  [BaseThemeColorKey.SectionInsetBlockText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryBackground]: ColorPaletteKey.Color1,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryText]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardBackground]: ColorPaletteKey.Color2,
  [BaseThemeColorKey.CardText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.ButtonPrimaryBackground]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.ButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryBackground]: ColorPaletteKey.Color2,
  [BaseThemeColorKey.ButtonSecondaryText]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.ButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryAltBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonSecondaryAltText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.NavigationBackground]: ColorPaletteKey.Color2,
  [BaseThemeColorKey.NavigationText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.Logo]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.LogoNavigation]: ColorPaletteKey.ColorDark,
};

export const THEME_COLORS_VARIATION_3: BaseThemeColors = {
  [BaseThemeColorKey.Dark]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.Light]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionBackground]: ColorPaletteKey.Color3,
  [BaseThemeColorKey.SectionBackgroundTexture]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockBackground]: ColorPaletteKey.Color2,
  [BaseThemeColorKey.SectionInsetBlockText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryBackground]: ColorPaletteKey.Color2,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryText]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardBackground]: ColorPaletteKey.Color3,
  [BaseThemeColorKey.CardText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.ButtonPrimaryBackground]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.ButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryBackground]: ColorPaletteKey.Color3,
  [BaseThemeColorKey.ButtonSecondaryText]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.ButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryAltBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonSecondaryAltText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.NavigationBackground]: ColorPaletteKey.Color3,
  [BaseThemeColorKey.NavigationText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.Logo]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.LogoNavigation]: ColorPaletteKey.ColorDark,
};

export const THEME_COLORS_VARIATION_4: BaseThemeColors = {
  [BaseThemeColorKey.Dark]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.Light]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionBackground]: ColorPaletteKey.Color4,
  [BaseThemeColorKey.SectionBackgroundTexture]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockBackground]: ColorPaletteKey.Color1,
  [BaseThemeColorKey.SectionInsetBlockText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryBackground]: ColorPaletteKey.Color1,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryText]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardBackground]: ColorPaletteKey.Color4,
  [BaseThemeColorKey.CardText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextHeadline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextBody]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextOverline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.CardTextByline]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.ButtonPrimaryBackground]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.ButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryBackground]: ColorPaletteKey.Color4,
  [BaseThemeColorKey.ButtonSecondaryText]: ColorPaletteKey.Color6,
  [BaseThemeColorKey.ButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryAltBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonSecondaryAltText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.NavigationBackground]: ColorPaletteKey.Color4,
  [BaseThemeColorKey.NavigationText]: ColorPaletteKey.Color5,
  [BaseThemeColorKey.Logo]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.LogoNavigation]: ColorPaletteKey.ColorDark,
};

export const THEME_COLORS_VARIATION_5: BaseThemeColors = {
  [BaseThemeColorKey.Dark]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.Light]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionBackgroundTexture]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionTextHeadline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionTextBody]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionTextOverline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionTextByline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockTextHeadline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockTextBody]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockTextOverline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockTextByline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.CardBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.CardText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardTextHeadline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardTextBody]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardTextOverline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardTextByline]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonPrimaryBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonPrimaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonSecondaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonTertiaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonTertiaryBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonSecondaryAltBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryAltText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.NavigationBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.NavigationText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.Logo]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.LogoNavigation]: ColorPaletteKey.ColorDark,
};

export const THEME_COLORS_VARIATION_6: BaseThemeColors = {
  [BaseThemeColorKey.Dark]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.Light]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionBackgroundTexture]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionTextHeadline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionTextBody]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionTextOverline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionTextByline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockTextHeadline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockTextBody]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockTextOverline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockTextByline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.CardText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.CardTextHeadline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.CardTextBody]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.CardTextOverline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.CardTextByline]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonPrimaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonPrimaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonSecondaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonTertiaryText]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonTertiaryBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.ButtonSecondaryAltBackground]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.ButtonSecondaryAltText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.NavigationBackground]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.NavigationText]: ColorPaletteKey.ColorDark,
  [BaseThemeColorKey.Logo]: ColorPaletteKey.ColorLight,
  [BaseThemeColorKey.LogoNavigation]: ColorPaletteKey.ColorLight,
};

export const ThemeColorsVariationKey = {
  Variation1: 'variation-1',
  Variation2: 'variation-2',
  Variation3: 'variation-3',
  Variation4: 'variation-4',
  Variation5: 'variation-5',
  Variation6: 'variation-6',
} as const;
export type ThemeColorsVariationKey = (typeof ThemeColorsVariationKey)[keyof typeof ThemeColorsVariationKey];

export type ThemeColorsVariations = Record<ThemeColorsVariationKey, BaseThemeColors>;
export type ThemeColorsCssVariables = Record<ThemeColorKey, string | number>;
export type ThemeColorsCssVariablesByVariation = Record<ThemeColorsVariationKey, ThemeColorsCssVariables>;

export const ThemeColorsVariations: ThemeColorsVariations = {
  [ThemeColorsVariationKey.Variation1]: THEME_COLORS_VARIATION_1,
  [ThemeColorsVariationKey.Variation2]: THEME_COLORS_VARIATION_2,
  [ThemeColorsVariationKey.Variation3]: THEME_COLORS_VARIATION_3,
  [ThemeColorsVariationKey.Variation4]: THEME_COLORS_VARIATION_4,
  [ThemeColorsVariationKey.Variation5]: THEME_COLORS_VARIATION_5,
  [ThemeColorsVariationKey.Variation6]: THEME_COLORS_VARIATION_6,
};

const ContrastRatio = {
  Element: 1.5,
  Text: 2.2,
  ButtonText: 2,
};

export const GlobalColorValues = {
  '--global-hue-offset': 0,
  '--global-saturation-multiplier': 1,
  '--global-lightness-multiplier': 1,
  '--global-darkening-amount': -10,
  '--global-lightening-amount': 10,
};

export const CONTRAST_CHECKS: Partial<Record<BaseThemeColorKey, [BaseThemeColorKey, number]>> = {
  [BaseThemeColorKey.SectionBackgroundTexture]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionInsetBlockText]: [BaseThemeColorKey.SectionInsetBlockBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionInsetBlockTextHeadline]: [
    BaseThemeColorKey.SectionInsetBlockBackground,
    ContrastRatio.Text,
  ],
  [BaseThemeColorKey.SectionInsetBlockTextBody]: [BaseThemeColorKey.SectionInsetBlockBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionInsetBlockTextOverline]: [
    BaseThemeColorKey.SectionInsetBlockBackground,
    ContrastRatio.Text,
  ],
  [BaseThemeColorKey.SectionInsetBlockTextByline]: [BaseThemeColorKey.SectionInsetBlockBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionInsetBlockTextBody]: [BaseThemeColorKey.SectionInsetBlockBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionText]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionTextHeadline]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionTextBody]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionTextOverline]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.SectionTextByline]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.CardText]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.CardTextHeadline]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.CardTextBody]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  // button 1 background color determines text color
  [BaseThemeColorKey.ButtonPrimaryBackground]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Element],
  [BaseThemeColorKey.ButtonPrimaryText]: [BaseThemeColorKey.ButtonPrimaryBackground, ContrastRatio.ButtonText],
  // button 2 has a transparent background but is set to the same color as the section background
  [BaseThemeColorKey.ButtonSecondaryText]: [BaseThemeColorKey.ButtonSecondaryBackground, ContrastRatio.ButtonText],
  // button 3 text color determines background color
  [BaseThemeColorKey.ButtonTertiaryText]: [BaseThemeColorKey.SectionBackground, ContrastRatio.ButtonText],
  [BaseThemeColorKey.ButtonTertiaryBackground]: [BaseThemeColorKey.ButtonTertiaryText, ContrastRatio.ButtonText],
  // inset block checks for each button style
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground]: [
    BaseThemeColorKey.SectionInsetBlockBackground,
    ContrastRatio.Element,
  ],
  [BaseThemeColorKey.SectionInsetBlockButtonPrimaryText]: [
    BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground,
    ContrastRatio.ButtonText,
  ],
  [BaseThemeColorKey.SectionInsetBlockButtonSecondaryText]: [
    BaseThemeColorKey.SectionInsetBlockButtonSecondaryBackground,
    ContrastRatio.ButtonText,
  ],
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryText]: [
    BaseThemeColorKey.SectionInsetBlockBackground,
    ContrastRatio.ButtonText,
  ],
  [BaseThemeColorKey.SectionInsetBlockButtonTertiaryBackground]: [
    BaseThemeColorKey.SectionInsetBlockButtonTertiaryText,
    ContrastRatio.Element,
  ],
  [BaseThemeColorKey.NavigationText]: [BaseThemeColorKey.NavigationBackground, ContrastRatio.Text],
  [BaseThemeColorKey.Logo]: [BaseThemeColorKey.SectionBackground, ContrastRatio.Text],
  [BaseThemeColorKey.LogoNavigation]: [BaseThemeColorKey.NavigationBackground, ContrastRatio.Text],
};

// Currently derived colors are just a lightening/darkening of the base color, depending on whether the current
// lightness is above or below 50%. This is not a perfect solution, but it works for now. Tier2 and Tier3 colors
// will follow in the same direction as Tier1 colors (lighten or darken) by basing the css clamp value off of the
// original base color lightness. This should give at least a headroom of 50 for the shifting the lightness value.
// This is fine for now, because our steps are increments of 10 and there are only 3 steps. If we ever need more
// steps or higher step increments, we will need to revisit this as lightness is 0-100. Ideally, we would be able
// to generate a whole array of shades normalized based of perceived brightness, but this is all we need now.

export const TIER_1_DERIVED_THEME_COLORS: Tier1DerivedThemeColors = {
  [Tier1DerivedThemeColorKey.ButtonPrimaryBackgroundHover]: BaseThemeColorKey.ButtonPrimaryBackground,
  [Tier1DerivedThemeColorKey.ButtonPrimaryTextHover]: BaseThemeColorKey.ButtonPrimaryText,
  [Tier1DerivedThemeColorKey.ButtonSecondaryBackgroundHover]: BaseThemeColorKey.ButtonSecondaryBackground,
  [Tier1DerivedThemeColorKey.ButtonSecondaryTextHover]: BaseThemeColorKey.ButtonSecondaryText,
  [Tier1DerivedThemeColorKey.ButtonTertiaryBackgroundHover]: BaseThemeColorKey.ButtonTertiaryBackground,
  [Tier1DerivedThemeColorKey.ButtonTertiaryTextHover]: BaseThemeColorKey.ButtonTertiaryText,
  [Tier1DerivedThemeColorKey.SectionInsetBlockButtonPrimaryBackgroundHover]:
    BaseThemeColorKey.SectionInsetBlockButtonPrimaryBackground,
  [Tier1DerivedThemeColorKey.SectionInsetBlockButtonPrimaryTextHover]:
    BaseThemeColorKey.SectionInsetBlockButtonPrimaryText,
  [Tier1DerivedThemeColorKey.SectionInsetBlockButtonSecondaryBackgroundHover]:
    BaseThemeColorKey.SectionInsetBlockButtonSecondaryBackground,
  [Tier1DerivedThemeColorKey.SectionInsetBlockButtonSecondaryTextHover]:
    BaseThemeColorKey.SectionInsetBlockButtonSecondaryText,
  [Tier1DerivedThemeColorKey.SectionInsetBlockButtonTertiaryBackgroundHover]:
    BaseThemeColorKey.SectionInsetBlockButtonTertiaryBackground,
  [Tier1DerivedThemeColorKey.SectionInsetBlockButtonTertiaryTextHover]:
    BaseThemeColorKey.SectionInsetBlockButtonTertiaryText,
  [Tier1DerivedThemeColorKey.MediaBackground]: BaseThemeColorKey.SectionBackground,
  [Tier1DerivedThemeColorKey.SectionInsetBlockMediaBackground]: BaseThemeColorKey.SectionInsetBlockBackground,
  [Tier1DerivedThemeColorKey.HighlightBackground]: BaseThemeColorKey.SectionBackground,
  [Tier1DerivedThemeColorKey.SectionInsetBlockHighlightBackground]: BaseThemeColorKey.SectionInsetBlockBackground,
};

export const TIER_2_DERIVED_THEME_COLORS: Tier2DerivedThemeColors = {
  [Tier2DerivedThemeColorKey.MediaBackground2]: Tier1DerivedThemeColorKey.MediaBackground,
  [Tier2DerivedThemeColorKey.SectionInsetBlockMediaBackground2]:
    Tier1DerivedThemeColorKey.SectionInsetBlockMediaBackground,
  [Tier2DerivedThemeColorKey.HighlightBackground2]: Tier1DerivedThemeColorKey.HighlightBackground,
  [Tier2DerivedThemeColorKey.SectionInsetBlockHighlightBackground2]:
    Tier1DerivedThemeColorKey.SectionInsetBlockHighlightBackground,
};

export const TIER_3_DERIVED_THEME_COLORS: Tier3DerivedThemeColors = {
  [Tier3DerivedThemeColorKey.MediaBackground3]: Tier2DerivedThemeColorKey.MediaBackground2,
  [Tier3DerivedThemeColorKey.SectionInsetBlockMediaBackground3]:
    Tier2DerivedThemeColorKey.SectionInsetBlockMediaBackground2,
};
