export default {
  contexts: {
    editor: {
      label: 'Button 4 - Alternate Style',
    },
  },
  layouts: [
    {
      layoutId: 'simpleButton',
      fields: [
        {
          fieldId: 'cornerStyle',
          options: [],
        },
      ],
    },
  ],
};
