import {
  ThemeCustomElementType as CustomElementType,
  ThemeRichTextNode as RichTextNode,
  ThemeRichTextNodeArray as RichTextNodeArray,
} from '@nucleus/types/web';

export function buildRichTextNodeArray(
  nodes: {
    text: string;
    element: CustomElementType;
    styles?: Array<string>;
  }[]
): RichTextNodeArray {
  return nodes.map((node) => buildRichTextNode(node.text, node.element));
}

function buildRichTextNode(text: string, elementType: CustomElementType, styles: Array<string> = []): RichTextNode {
  return {
    type: elementType,
    children: [
      {
        text: text,
        ...styles.reduce((acc, style) => ({ ...acc, [style]: true }), {}),
      },
    ],
  };
}
