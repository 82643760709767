import invariant from 'invariant';
import React from 'react';

type createContextReturn<T> = [React.Provider<T>, () => T, React.Context<T>];

interface createContextOptions<T> {
  name?: string;
  errorMessage?: string;
  defaultValue?: T;
}

export const createContext = <T>({ name, ...options }: createContextOptions<T>): createContextReturn<T> => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const context = React.createContext<T>(options.defaultValue ?? null!);
  context.displayName = name;

  const useContext = (): T => {
    const providedContext = React.useContext(context);
    invariant(providedContext !== null, options.errorMessage ?? `hook must be used within a ${name}Provider`);
    return providedContext;
  };

  return [context.Provider, useContext, context];
};
