import React from 'react';
import styled from 'styled-components';

interface Props {
  left?: React.ReactNode;
  children?: React.ReactNode;
  right?: React.ReactNode;
}

export const CollectionListItemHeader = (props: Props): JSX.Element => {
  return (
    <Header>
      {props.left && <div>{props.left}</div>}
      {props.children && <div style={{ flex: '1 1 auto' }}>{props.children}</div>}
      {props.right && <div>{props.right}</div>}
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;
