import React from 'react';
import { ContentLoader } from './components/content/ContentLoader';
import { usePageContent } from './hooks/usePageContent';

type ContentRendererProps = {
  contentArea: string;
};

export const ContentRenderer = (props: ContentRendererProps): JSX.Element => {
  const content = usePageContent(props.contentArea) ?? [];

  return (
    <>
      {content.map((item) => (
        <ContentLoader key={item.id} data={item} />
      ))}
    </>
  );
};
