import { ListCollectionsWebEndpoint } from '@nucleus/web-hosting';
import { useUser } from '@nucleus/web-theme';
import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import { queryCollections } from '../queries/collection';

type QueryResult = Awaited<ReturnType<typeof queryCollections>>;

export const useCollections = (
  engineId: string | undefined,
  collection?: 'playlists' | 'tags' | 'speakers' | 'books',
  params?: ListCollectionsWebEndpoint<any>['queryStringParameters']
): UseInfiniteQueryResult<QueryResult> => {
  const { currentUser, jwtToken } = useUser();

  return useInfiniteQuery({
    queryKey: ['sermonHub', 'collection', collection, currentUser?.id, params],
    queryFn: ({ pageParam }) => queryCollections(engineId, collection, jwtToken, { ...params, cursor: pageParam }),
    suspense: true,
    getNextPageParam: (lastPage) => lastPage.cursor,
  });
};
