import { ISO8601Schema } from '@nucleus/src-platform/data';
import { yupKeyStringSchema } from '@nucleus/lib-shape';
import { ISO8601 } from '@nucleus/types/iso8601';
import * as yup from 'yup';
import { PublishableEngine, PublishableEngineSchema } from './PublishableEngine';

export interface PublishableEntityBase {
  id: string;
  engineId: PublishableEngine['id'];
  readonly createdAt: ISO8601;
  readonly createdBy: string;
  updatedAt: ISO8601;
  updatedBy: string;
}

export const PublishableEntityBaseSchema: yup.SchemaOf<PublishableEntityBase> = yup.object({
  id: yupKeyStringSchema.required(),
  engineId: PublishableEngineSchema.fields.id.required(),
  createdAt: ISO8601Schema.required(),
  createdBy: yup.string().required(),
  updatedAt: ISO8601Schema.required(),
  updatedBy: yup.string().required(),
});
