import { PageWeb, Site } from '@nucleus/types/web';
import { ApplicationProvider, DynamicResourceProvider } from '@nucleus/web-theme';
import { dehydrate, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './css/normalize.css';
import './css/reset.css';
import './index.css';

type PreloadedState = {
  hydrate: boolean;
  site: Site;
  page: PageWeb;
  queryState: ReturnType<typeof dehydrate>;
};

Auth.configure({
  region: process.env['REACT_APP_AWS_REGION'],
  userPoolId: process.env['REACT_APP_AWS_USER_POOL_ID'],
  userPoolWebClientId: process.env['REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID'],
  storage: window.localStorage,
});

if (window?.__PRELOADED_STATE__ === undefined) {
  throw new Error('preloaded state not found');
}

const preloaded: PreloadedState = JSON.parse(decodeURIComponent(escape(atob(window.__PRELOADED_STATE__)))); // atob is not deprecated in the browser
if (preloaded?.site === undefined) {
  throw new Error('site not found');
}

const queryClient = new QueryClient({});

const BaseApp = () => (
  <QueryClientProvider client={queryClient}>
    <Hydrate state={preloaded.queryState}>
      <HelmetProvider>
        <ApplicationProvider
          site={preloaded.site}
          DataResourceProvider={DynamicResourceProvider({ pages: [preloaded.page] })}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApplicationProvider>
      </HelmetProvider>
    </Hydrate>
  </QueryClientProvider>
);

const rootDomContainer = document.getElementById('root');
if (rootDomContainer === null) {
  throw new Error('Container not found');
}

const dynamicRender = async () => {
  const root = ReactDOM.createRoot(rootDomContainer);

  root.render(<BaseApp />);
};

if (preloaded.hydrate === true) {
  ReactDOM.hydrateRoot(rootDomContainer, <BaseApp />);
} else {
  dynamicRender();
}
