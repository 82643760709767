import { SrcSet } from '@nucleus/types/media/image';
import { sortBy as _sortBy } from 'lodash';
import { Element, Node } from 'slate';

/**
 * Checks whether a given Slate node or array of nodes contains text.
 *
 * @param {Node[] | string | undefined} nodes - The Slate node(s) to check for text.
 * @returns {boolean} - Returns `true` if the node(s) contain text, `false` if empty string only.
 */
export const hasText = (nodes: Node[] | string | undefined): boolean => {
  if (typeof nodes === 'undefined') {
    return false;
  }

  if (typeof nodes === 'string') {
    return nodes.trim() !== '';
  }

  return nodes.some((node) => (Element.isElement(node) ? hasText(node.children) : node.text !== ''));
};

export const formatSrcSet = (srcSet?: SrcSet | SrcSet[]): string | undefined => {
  if (srcSet === undefined) {
    return;
  }

  if (!Array.isArray(srcSet)) {
    srcSet = [srcSet];
  }

  return _sortBy(srcSet, 'dimensions.width')
    .map((srcSet) => `${srcSet.src} ${srcSet.dimensions.width}w`)
    .join(', ');
};
