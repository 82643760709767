import React from 'react';
import styled from 'styled-components';

export interface NavListProps {
  children?: React.ReactNode;
}

export const NavList = (props: NavListProps): JSX.Element => {
  return <List>{props.children}</List>;
};

const List = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0;
  margin: 0;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
