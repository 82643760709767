import { EmbedItemWeb } from '@nucleus/types/web';
import { StyledReactFrame } from '@nucleus/web-theme-elements';
import { throttle as _throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

interface IframeProps {
  content: string;
  sizing: EmbedItemWeb['sizing'];
}

export const Iframe = (props: IframeProps): JSX.Element => {
  const { iframeHeight, iframeRef } = useIframeHeight();

  const setHeight = props.sizing === 'original' && iframeHeight;
  const setMinHeight = props.sizing === 'responsive' && iframeHeight;

  return (
    <IframeContainer
      style={{
        height: setHeight ? `${iframeHeight}px` : 'auto',
        minHeight: setMinHeight ? `${iframeHeight}px` : 'auto',
      }}
      $sizing={props.sizing}
    >
      <StyledIframe
        src="about:blank"
        scrolling="no"
        frameBorder="0"
        $sizing={props.sizing}
        innerHTML={props.content}
        ref={iframeRef}
      >
        <InnerGlobalStyle $sizing={props.sizing} />
      </StyledIframe>
    </IframeContainer>
  );
};

const IframeContainer = styled.div<{ $sizing: EmbedItemWeb['sizing'] }>`
  width: 100%;
  position: relative;
  overflow: hidden;

  ${(props) => {
    switch (props.$sizing) {
      case '16:9':
        return css`
          height: 0;
          padding-bottom: 56.25%;
        `;
      case '4:3':
        return css`
          height: 0;
          padding-bottom: 75%;
        `;
      default:
        break;
    }
  }};
`;

const StyledIframe = styled(StyledReactFrame)<{ $sizing: EmbedItemWeb['sizing'] }>`
  border: none;
  width: 100%;
  min-height: 100%;

  ${(props) => {
    switch (props.$sizing) {
      case 'original':
        return css`
          width: 100%;
          height: auto;
        `;
      case '16:9':
      case '4:3':
      case 'responsive':
        return css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
        `;
      default:
        break;
    }
  }}
`;

const InnerGlobalStyle = createGlobalStyle<{ $sizing: EmbedItemWeb['sizing'] }>`
    body {
      margin: 0;
      padding: 0;
      border: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ${(props) => {
      switch (props.$sizing) {
        case '16:9':
        case '4:3':
        case 'responsive': {
          return css`
            iframe,
            object,
            embed {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              min-width: 100%;
              min-height: 100%;
            }
          `;
        }
        default:
          break;
      }
    }}
`;

const IFRAME_HEIGHT_CHECK_INTERVAL = 500;
const IFRAME_HEIGHT_CHECK_TIMEOUT = 10000;

function useIframeHeight() {
  const [iframeHeight, setIframeHeight] = useState<number | undefined>(undefined);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  function updateHeight() {
    if (iframeRef.current) {
      const iframeHeight = getIframeHeight(iframeRef.current);
      setIframeHeight(iframeHeight);
    }
  }

  useEffect(() => {
    updateHeight();

    const handleResize = _throttle(updateHeight, 250, { leading: true, trailing: true });

    // keep calling update height to handle dynamic content
    const interval = setInterval(updateHeight, IFRAME_HEIGHT_CHECK_INTERVAL);
    // only run until timeout
    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, IFRAME_HEIGHT_CHECK_TIMEOUT);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return { iframeHeight, iframeRef };
}

function getIframeHeight(iframe: HTMLIFrameElement) {
  // get the height of any margin, padding, and border on the iframe body
  const style = iframe.contentWindow?.getComputedStyle(iframe.contentWindow?.document.body);
  const marginHeight = parseInt(style?.marginTop || '0', 10) + parseInt(style?.marginBottom || '0', 10);
  const paddingHeight = parseInt(style?.paddingTop || '0', 10) + parseInt(style?.paddingBottom || '0', 10);
  const borderHeight = parseInt(style?.borderTopWidth || '0', 10) + parseInt(style?.borderBottomWidth || '0', 10);
  const bodyStylesHeight = marginHeight + paddingHeight + borderHeight;
  // iterate over each child of iframe body and add up their heights and vertical margins
  const children = iframe.contentWindow?.document.body.children;
  let childrenHeight = 0;
  if (children) {
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      childrenHeight += child.scrollHeight;
      const childStyle = iframe.contentWindow?.getComputedStyle(child);
      const childMarginHeight =
        parseInt(childStyle?.marginTop || '0', 10) + parseInt(childStyle?.marginBottom || '0', 10);
      childrenHeight += childMarginHeight;
    }
  }
  const iframeHeight = childrenHeight + bodyStylesHeight;
  return iframeHeight;
}
