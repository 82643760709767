const TABLE_SEPARATOR = '-';

export function getTableName(tableName, serviceName, stage) {
  // NOTE: Testing doesn't do variable replacement in yaml files
  if (process.env.NODE_ENV === 'test') {
    return tableName;
  }

  // NOTE: This was added to support the export-models script
  if (stage === undefined) {
    return tableName;
  }
  return [serviceName, stage, tableName].join(TABLE_SEPARATOR);
}
