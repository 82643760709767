export const InfoMaxWidth = {
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
} as const;

export type InfoMaxWidth = typeof InfoMaxWidth[keyof typeof InfoMaxWidth];

export const InfoButtonsLayout = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
} as const;

export type InfoButtonsLayout = typeof InfoButtonsLayout[keyof typeof InfoButtonsLayout];

export const InfoButtonsWidth = {
  Auto: 'auto',
  Stretch: 'stretch',
} as const;

export type InfoButtonsWidth = typeof InfoButtonsWidth[keyof typeof InfoButtonsWidth];
